import firebase from 'firebase';
import 'firebase/auth';
import { DateTime } from 'luxon';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import * as api from '../Api/Auth';
import { getOtpForLogin, loginWithMail, verifyGeneratedOtp } from '../Api/Auth';
import axios from '../Config';
import initializeAppWithFirebase, { askForPermissionToReceiveNotifications } from '../Firebase';
import logError, { generateSnackbarPayload } from '../Helper';
import learnerAction from '../Helper/Analytics/learnerAction';
import screenNames from '../Helper/Analytics/screenNames';
import { trackEvent } from '../Helper/EventTracker';
import * as types from '../Store/actionTypes';
import { getUserDetails } from '../Store/reduxSelectors';
// import { useLazyQuery } from '@apollo/client';
// import { USER_CURRENT } from '../ApolloQueries/User';
const sagaPath = 'src/Sagas/Auth.js';
const timezone = DateTime.fromJSDate(new Date()).zoneName;

function* loginWithPhoneNumber(action) {
  const { payload, callback } = action;
  try {
    const response = yield call(getOtpForLogin, payload);
    // const data = response.data;

    yield put({
      type: types.TOGGLE_SNACKBAR,
      payload: generateSnackbarPayload(
        'success',
        `OTP has been sent to your mobile.${isNaN(response) ? '' : response}`,
      ),
    });
    callback(true);
  } catch (e) {
    yield put({
      type: types.TOGGLE_SNACKBAR,
      payload: generateSnackbarPayload('error', e.message),
    });
    logError(e, sagaPath + 'loginWithPhoneNumber');
    callback();
  }
}
function* verifyOtp(action) {
  const { payload, callback } = action;

  try {
    const data = yield call(verifyGeneratedOtp, payload);
    localStorage.setItem('@authToken', data.data.verify_otp.token);
    localStorage.setItem('@refreshToken', data.data.verify_otp.refresh_token);
    localStorage.setItem('@firebaseAuthToken', data.data.verify_otp.firebase_token);
    axios.defaults.headers.common = { Authorization: `Bearer ${data.data.verify_otp.token}` };
    const userResponse = yield call(api.getCurrentUserDetails);
    const userId = userResponse.data.user.id;
    const user = userResponse.data.user;

    trackEvent('submit', {
      screen: screenNames.VerifyOtp,
      Action: learnerAction.OTP_VERIFY,
      userid: userId,
      orgid: user.organization.id,
      orgName: user.organization?.organizationName,
      ...(user?.enrolledInProgramIds && {
        programid: user?.enrolledInProgramIds && user.enrolledInProgramIds[0],
      }),
      ...(user?.program && { programname: user?.program }),
    });
    let menteeDetails = {};
    if (user.organization.splashLogo != null) {
      localStorage.setItem('organizationLogo', user.organization.splashLogo);
    } else {
      localStorage.removeItem('organizationLogo');
    }
    try {
      const { data: menteeData } = yield call(api.getMenteeDetails, { menteeId: userId });
      menteeDetails = { ...menteeData };
    } catch (e) {}
    yield call(api.patchCurrentUser, { userId: userId, timezone });
    yield put({ type: types.CURRENT_USER_SUCCESS, data: { ...user, ...menteeDetails } });
    yield put({ type: types.FIREBASE_CONFIG_REQUEST, callback });
  } catch (e) {
    yield put({
      type: types.TOGGLE_SNACKBAR,
      payload: { variant: 'error', message: e.message },
    });
    logError(e, sagaPath + 'verifyOtp');
    callback();
  }
}

function* setAccountDetails(action) {
  const { payload, callback } = action;
  try {
    const response = yield call(api.setUserNameAndPassword, payload);
    const data = response.data;
    if (data.message === 'Username Already Exists') {
      yield put({
        type: types.TOGGLE_SNACKBAR,
        payload: generateSnackbarPayload('error', data.message),
      });
      return;
    }
    const { token, user } = data.data;
    localStorage.setItem('@authToken', token);
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    const userResponse = yield call(api.getCurrentUserDetails);
    const currentuser = userResponse.data.user;
    if (currentuser.organization.splashLogo != null) {
      localStorage.setItem('organizationLogo', currentuser.organization.splashLogo);
    } else {
      localStorage.removeItem('organizationLogo');
    }
    const { data: menteeData } = yield call(api.getMenteeDetails, { menteeId: user.id });
    yield put({ type: types.CURRENT_USER_SUCCESS, data: { ...user, ...menteeData } });
    yield put({ type: types.FIREBASE_CONFIG_REQUEST, callback });
    // window.location.replace('/app-tour');
  } catch (err) {
    yield put({
      type: types.TOGGLE_SNACKBAR,
      payload: generateSnackbarPayload('error', err.message),
    });
    logError(err, sagaPath + 'setAccountDetails');
    callback();
  }
}

function* loginWithEmail(action) {
  const { payload, callback } = action;
  try {
    const response = yield call(loginWithMail, payload);
    const data = response.data.login;
    const { token, refresh_token } = data;
    localStorage.setItem('@authToken', token);
    localStorage.setItem('@refreshToken', refresh_token);
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    const userResponse = yield call(api.getCurrentUserDetails);
    const userId = userResponse.data.user.id;
    const user = userResponse.data.user;
    console.log('email', user);
    if (user.organization.splashLogo != null) {
      localStorage.setItem('organizationLogo', user.organization.splashLogo);
    } else {
      localStorage.removeItem('organizationLogo');
    }
    trackEvent('click', {
      screen: screenNames.VerifyOtp,
      Action: learnerAction.OTP_VERIFY,
      userid: userId,
      orgid: user.organization.id,
      orgName: user.organization?.organizationName,
      programid: user?.enrolledInProgramIds && user.enrolledInProgramIds[0],
      ...(user?.program && { programname: user?.program }),
    });
    const { data: menteeData } = yield call(api.getMenteeDetails, { menteeId: userId });
    const { availableFeatures } = menteeData;
    yield call(api.patchCurrentUser, { userId: userId, timezone });
    yield put({ type: types.CURRENT_USER_SUCCESS, data: { ...user, ...menteeData } });
    yield put({ type: types.FIREBASE_CONFIG_REQUEST, callback });
  } catch (e) {
    yield put({
      type: types.TOGGLE_SNACKBAR,
      payload: generateSnackbarPayload('error', 'Invalid Username/Email or Password'),
    });
    logError(e, sagaPath + 'loginWithEmail');
    callback('error');
  }
}

function* getCurrentUserDetails(action, dispatch) {
  const { callback } = action;
  try {
    const token = localStorage.getItem('@authToken');
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    const response = yield call(api.getCurrentUserDetails);
    const data = response.data;
    console.log('data', data);
    const isValidated = data && data.status;
    if (response.data.user.organization.splashLogo != null) {
      localStorage.setItem('organizationLogo', response.data.user.organization.splashLogo);
    } else {
      localStorage.removeItem('organizationLogo');
    }
    const { data: menteeData } = yield call(api.getMenteeDetails, { menteeId: data.user.id });

    if (isValidated) {
      yield call(api.patchCurrentUser, { userId: data.user.id, timezone });
      yield put({ type: types.CURRENT_USER_SUCCESS, data: { ...data.user, ...menteeData } });
      yield put({ type: types.FIREBASE_CONFIG_REQUEST, callback });
    } else throw data;
    callback(true);
  } catch (e) {
    const refreshToken = localStorage.getItem('@refreshToken');
    if (refreshToken) {
      const response = yield call(api.getNewToken, refreshToken);
      const { token } = response.data.generate_token;
      localStorage.setItem('@authToken', token);
      axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
      const user = yield call(api.getCurrentUserDetails);
      const data = user.data;

      const isValidated = data && data.status;

      const { data: menteeData } = yield call(api.getMenteeDetails, { menteeId: data.user.id });

      if (isValidated) {
        yield call(api.patchCurrentUser, { userId: data.user.id, timezone });
        yield put({ type: types.CURRENT_USER_SUCCESS, data: { ...data.user, ...menteeData } });
        yield put({ type: types.FIREBASE_CONFIG_REQUEST, callback });
      } else throw data;
      callback(true);
    } else {
      yield put({
        type: types.TOGGLE_SNACKBAR,
        payload: { variant: 'error', message: JSON.stringify(e.message) },
      });
      yield put({ type: types.RESET_STORE, callback });
      localStorage.removeItem('@authToken');
      window.location.replace('/');
      callback();
      logError(e, sagaPath + 'getCurrentUserDetails');
    }
    // window.location.replace('/');
  }
}

function* getNewTokenOnExpire(action, dispatch) {
  try {
    const refreshToken = localStorage.getItem('@refreshToken');
    const response = yield call(api.getNewToken, refreshToken);
    localStorage.setItem('@authToken', response.data.token);
    axios.defaults.headers.common = { Authorization: `Bearer ${response.data.token}` };
    window.location.reload();
  } catch (e) {
    console.error('error', e);
  }
}
// FIREBASE SECTION
function* getFirebaseConfig(action) {
  const { callback } = action;
  try {
    // const response = yield call(api.getFirebaseConfig);
    // const data = response.data;

    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
      databaseURL: process.env.REACT_APP_FIREBASE_DBURL,
      projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APPID,
      measurementId: process.env.REACT_APP_FIREBASE_MESUREMENTID,
    };

    localStorage.setItem('@messagingSenderId', firebaseConfig.messagingSenderId);
    yield call(() => initializeAppWithFirebase(firebaseConfig));
    yield put({ type: types.FIREBASE_CONFIG_SUCCESS, firebaseConfig });
    yield put({ type: types.FIREBASE_USER_REQUEST, firebaseConfig });
    yield put({ type: types.FIREBASE_GET_MESSAGE_TOKEN, firebaseConfig });
  } catch (e) {
    yield put({
      type: types.TOGGLE_SNACKBAR,
      payload: generateSnackbarPayload('error', e.message || e),
    });
    logError(e, sagaPath + 'getFirebaseConfig');
    callback();
  }
}
function* getFirebaseUser(action) {
  const { callback } = action;
  try {
    const response = yield call(api.getFirebaseUser);
    const data = response.data;
    yield call(() => firebase.auth().signInWithEmailAndPassword(data.email, data.password));

    // const { data: firebaseData } = yield call(() => api.getFirebaseToken());
    // const userCred = yield call(() =>
    //   firebase.auth().signInWithCustomToken(firebaseData.firebaseToken),
    // );
    // const data = userCred.user;
    yield put({ type: types.FIREBASE_USER_SUCCESS, data });
  } catch (e) {
    yield put({
      type: types.TOGGLE_SNACKBAR,
      payload: generateSnackbarPayload('error', e.message || e),
    });
    logError(e, sagaPath + 'getFirebaseUser');
    callback();
  }
}
function* getFirebaseMessageToken(action) {
  try {
    const state = yield select();
    const userInfo = getUserDetails(state);
    const notificationResponse = yield call(() => checkNotificationPermission());
    const deviceToken = yield call(() => askForPermissionToReceiveNotifications());
    const payload = {
      userId: userInfo.id,
      data: { deviceToken },
    };
    yield call(api.registerDeviceToken, payload);
    yield put({ type: types.TOGGLE_NOTIFICATION_PERMISSION_STATUS, data: notificationResponse });
  } catch (error) {
    const notificationError = error && error.notification;
    if (notificationError)
      yield put({ type: types.TOGGLE_NOTIFICATION_PERMISSION_STATUS, data: error });
  }
}

const checkNotificationPermission = () => {
  const successResponse = { success: true, message: 'Permission Granted' };
  const permissionBlocked = {
    error: true,
    message: 'Permission Denied',
    notification: true,
  };
  const browserSupportError = {
    error: true,
    message: 'This browser does not support desktop notification',
    notification: true,
  };
  return new Promise((resolve, reject) => {
    if (!('Notification' in window)) {
      reject(browserSupportError);
    } else if (Notification.permission === 'granted') {
      resolve(successResponse);
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(function (permission) {
        if (permission === 'granted') resolve(successResponse);
        else reject(permissionBlocked);
      });
    } else reject(permissionBlocked);
  });
};

function* watchAuthenticationRequests() {
  yield takeLatest(types.LOGIN_REQUEST, loginWithPhoneNumber);
  yield takeLatest(types.VERIFY_OTP_REQUEST, verifyOtp);
  yield takeLatest(types.CURRENT_USER_REQUEST, getCurrentUserDetails);
  yield takeLatest(types.FIREBASE_USER_REQUEST, getFirebaseUser);
  yield takeLatest(types.FIREBASE_CONFIG_REQUEST, getFirebaseConfig);
  yield takeLatest(types.FIREBASE_GET_MESSAGE_TOKEN, getFirebaseMessageToken);
  yield takeLatest(types.GET_NEW_TOKEN, getNewTokenOnExpire);
  yield takeLatest(types.SET_ACCOUNT_DETAILS_REQUEST, setAccountDetails);
  yield takeLatest(types.LOGIN_WITH_EMAIL_REQUEST, loginWithEmail);
}

const authenticationSagas = [fork(watchAuthenticationRequests)];

export default authenticationSagas;
