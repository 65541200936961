import { Button } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import learnerAction from '../../Helper/Analytics/learnerAction';
import screenNames from '../../Helper/Analytics/screenNames';
import { trackEvent } from '../../Helper/EventTracker';
import theme from '../../MaterialUiTheme';
import getRoutes from '../../Routes/RoutePaths';
import { updateUserDetails } from '../../Store/Actions/Auth';
import { getUserDetails } from '../../Store/reduxSelectors';
import Mentorhublogo from './../../Assets/Images/Mentorhublogo.svg';
import PriorityLanding from './../../Assets/Images/PriorityLanding.svg';
import WhiteBgLogo from './../../Assets/Images/WhiteBgLogo.svg';

export const StartPriority = () => {
  const currentUser = useSelector((state) => getUserDetails(state));
  const dispatch = useDispatch();
  const history = useHistory();
  const currentDate = new Date();
  const firstLoggedInDate = currentUser.firstLoggedInDate;
  const orgLogo = localStorage.getItem('organizationLogo');
  const SetPriority = () => {
    trackEvent('click', {
      screen: screenNames.StartPriority,
      Action: learnerAction.START_PRIORITY,
    });
    dispatch(
      updateUserDetails({
        FtueShowMSS: false,
        takeMoodRingAssessment: false,
      }),
    );
    history.push(getRoutes.ftueSetPriority);
  };

  return (
    <div style={{ backgroundColor: 'white' }}>
      <div className="ftueHeader" style={{ padding: orgLogo && '12px 0px 15px 20px' }}>
        <img src={orgLogo ? orgLogo : Mentorhublogo} style={{ height: orgLogo && '49px' }} />
        <span style={{ color: 'white', marginRight: '20px' }}>
          Welcome, {currentUser.firstName}
        </span>
      </div>
      <div
        style={{
          minHeight: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <img src={PriorityLanding} />
          <div style={{ marginLeft: '131px', maxHeight: '339px', marginBottom: '60px' }}>
            <img src={orgLogo ? orgLogo : WhiteBgLogo} style={{ height: orgLogo && '80px' }} />
            <h4
              style={{
                fontSize: '30px',
                fontWeight: '600px',
              }}>
              What do you want your mentor’s help with?
            </h4>
            <span style={{ fontSize: '16px', fontWeight: '400px' }}>
              Click start to select up to three priorities that you would like to focus on with your
              mentor. <br /> You can always change your selections later.
            </span>
            <br />
            <Button
              style={{
                marginTop: '30px',
                color: 'white',
                width: '152px',
                fontFamily: 'Lato',
                fontWeight: 'bold',
                color: 'white',
                textTransform: 'capitalize',
                backgroundColor: theme.palette.primary.main,
              }}
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => {
                SetPriority();
              }}>
              Start
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
