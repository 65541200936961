import { Button, TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Zipcode from '../../Assets/Images/Zipcode.svg';
import learnerAction from '../../Helper/Analytics/learnerAction';
import screenNames from '../../Helper/Analytics/screenNames';
import { trackEvent } from '../../Helper/EventTracker';
import theme from '../../MaterialUiTheme';
import { updateUserDetails } from '../../Store/Actions/Auth';
import getLoggedUserDetails from '../services/getLoggedUserDetails';
import updateProfileDetails from '../services/patchUserDetails.js';

export const FtueFive = (props) => {
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);
  const [zipCode, setZipCode] = React.useState('');
  const dispatch = useDispatch();

  const sSnackbar = () => {
    setSuccessSnackbar(true);
  };

  const eSnackbar = () => {
    setErrorSnackbar(true);
  };

  const eSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorSnackbar(false);
  };
  const sSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessSnackbar(false);
    setTimeout(() => {
      props.stepUpdate('next');
    }, 100);
  };

  const profileUpdate = () => {
    trackEvent('click', {
      screen: screenNames.FtueWelcome,
      Action: learnerAction.FTUE_ZIP_CODE,
    });
    if (zipCode) {
      let data = {
        zipCode: zipCode,
      };

      updateProfileDetails(props.userDetails.id, data)
        .then((response) => {
          getLoggedUserDetails(props.userDetails.id).then((res) => {
            props.setLoggedData(res);
            dispatch(updateUserDetails({ zipCode: res.data.zipCode }));
          });
          sSnackbar();
        })
        .catch((error) => {
          eSnackbar();
        });
    } else {
      eSnackbar();
    }
  };

  useEffect(() => {
    setZipCode(props.loggedData ? props.loggedData.data.zipCode : '');
  }, [props]);

  return (
    <div>
      <div>
        <img src={Zipcode} />
        <h3 style={{ marginTop: '40px' }}>Can we get your Zip Code?</h3>
        <TextField
          id="standard-basic"
          label="Zip Code"
          defaultValue={props.loggedData ? props.loggedData.data.zipCode : ''}
          fullWidth
          onChange={(e) => setZipCode(e.target.value)}
          inputProps={{ maxLength: 6 }}
          sx={{
            '& .MuiFormLabel-root': {
              fontFamily: 'Lato',
              left: '-11px',
            },
            '& .MuiOutlinedInput-input': {
              padding: '0px',
              paddingLeft: '0px',
              paddingTop: '17px',
              paddingBottom: '7px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              left: '-2px',
            },
          }}
        />
        <Button
          style={{
            marginTop: '20px',
            color: 'white',
            width: '100%',
            height: '50px',
            backgroundColor: theme.palette.primary.main,
            fontFamily: 'Lato',
            fontWeight: 'bold',

            textTransform: 'capitalize',
          }}
          fullWidth
          variant="contained"
          color="primary"
          onClick={profileUpdate}>
          Save
        </Button>
      </div>
      <Snackbar
        open={errorSnackbar}
        style={{ zIndex: '10000' }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={eSnackbarClose}>
        <Alert variant="filled" severity="error">
          Please enter your Zip Code
        </Alert>
      </Snackbar>
      <Snackbar
        open={successSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id="ratingSuccess"
        autoHideDuration={3000}
        onClose={sSnackbarClose}>
        <Alert variant="filled" severity="success">
          Zip Code successfully updated.
        </Alert>
      </Snackbar>
    </div>
  );
};
