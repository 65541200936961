import axios from '../Config';
import handleAxiosError from '../Helper/AxiosErrorHanlder';

export const getAvgMoodRingApp = ({userId, filter}) => {
  return axios({
    url: `/users/dashboard/mentee/${userId}/avgMoodRingApp`,
    params: {
      filter,
    },
  }).catch((err) => handleAxiosError(err));
};

export const getUserBadges = (userId) => {
  return axios({
    url: `/user/${userId}/menteeProfile`,
  }).catch((err) => handleAxiosError(err));
};

export const getPointSummary = ({userId, filter}) => {
  return axios({
    url: `/users/mentee/${userId}/pointSummary`,
  }).catch((err) => handleAxiosError(err));
};
