import { gql, useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { generateSnackbarPayload } from '../../../../Helper';
import * as types from '../../../../Store/actionTypes';

const POST_ANSWER = gql`
  mutation CreateSurvey(
    $assessmentId: ID!
    $answers: [CreateSurveyQuestionnaireInput!]!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    createSurvey(
      assessmentId: $assessmentId
      answers: $answers
      startDate: $startDate
      endDate: $endDate
    ) {
      _id
    }
  }
`;

const usePostAnswers = (redirectToDashboard) => {
  const dispatch = useDispatch();
  const [postAnswer] = useMutation(POST_ANSWER, {
    context: { clientName: 'assessment' },
    onCompleted: () => {
      redirectToDashboard();
      dispatch({
        type: types.TOGGLE_SNACKBAR,
        payload: generateSnackbarPayload('success', 'Assessment submitted successfully'),
      });
    },
    onError: (error) => {
      redirectToDashboard();
      console.error(error);
      dispatch({
        type: types.TOGGLE_SNACKBAR,
        payload: generateSnackbarPayload('error', error.message),
      });
    },
  });
  return { postAnswer };
};

export default usePostAnswers;
