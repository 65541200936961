import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import MoodringLogo from '../../Assets/Images/MentorHub-Moodring-Logo.svg';
import { getSideDrawerState } from '../../Store/reduxSelectors';

const PieChart = ({
  data = [],
  titleStyles = {},
  pieSize = 200,
  chartOptions = {},
  rootStyles = {},
  mainChartOptions = {},
}) => {
  const total = useMemo(() => {
    return data.length ? data.reduce((prev, current) => prev + current.y, 0) : 0;
  }, [JSON.stringify(data)]);

  const open = useSelector((state) => getSideDrawerState(state));
  const [, setRefresh] = React.useState(false);
  const orgLogo = localStorage.getItem('organizationLogo');
  React.useEffect(() => {
    setTimeout(() => {
      if (Highcharts && Highcharts.charts.length) {
        Highcharts.charts.forEach((chart) => {
          if (chart && chart.reflow) chart.reflow();
        });
      }
    }, 200);
  }, [open]);

  const handleRefresh = () => {
    setTimeout(() => {
      setRefresh((prev) => true);
    }, 2000);
    return ' ';
  };

  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      ...mainChartOptions,
    },
    title: {
      verticalAlign: 'middle',
      floating: true,
      useHTML: true,
      align: 'center',
      text: `<img width="162px" src=${
        orgLogo ? orgLogo : MoodringLogo
      } onload="javascript: ${handleRefresh()}"/>`,

      ...titleStyles,
    },
    tooltip: {
      pointFormat: ':<b> {point.percentage:.1f}%</b>',
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: {
        size: total > 0 ? pieSize : pieSize - 30,
        innerSize: total > 0 ? '80%' : '100%',
        slicedOffset: 20,
        borderWidth: total > 0 ? 3 : 25,
        borderColor: total > 0 ? '#FFFFFF' : 'D3D3D3',
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        // name: 'Brands',
        colorByPoint: true,
        data,
      },
    ],

    ...chartOptions,
  };

  return (
    <div style={{ ...rootStyles }}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
export default PieChart;
