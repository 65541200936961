import { applyMiddleware, combineReducers, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
// import {composeWithDevTools} from 'redux-devtools-extension';

import authentication from './Auth';
import moodRingDashboard from './MoodRingDashboard';
import global from './Global';
import moodRings from './MoodRings';
import programs from './Programs';
import dashboard from './Dashboard';
import tools from './Tools';
import chat from './Chat';
import quickLink from './Quicklink';
import notifications from './Notifications';
import messages from './Messages';
import Profile from './Profile';

import rootSaga from '../../Sagas';
import * as types from '../actionTypes';

const sagaMiddleware = createSagaMiddleware();

const appReducer = combineReducers({
  authentication,
  notifications,
  chat,
  tools,
  programs,
  moodRingDashboard,
  moodRings,
  dashboard,
  global,
  quickLink,
  messages,
  Profile,
});

const rootReducer = (state, action) => {
  if (action.type === types.RESET_STORE) state = undefined;
  return appReducer(state, action);
};

const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);

export default store;
