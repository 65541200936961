import { useReactiveVar } from '@apollo/client';
import { Button, CircularProgress, Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import RewardIcn from '../../../Assets/Images/Reward.svg';
import { redirectToHomeVar } from '../../../Routes/Vars';
import {
  getBackgroundColor,
  getColorConfirmationPopup,
  getPriorityLabel,
} from '../services/constants';

const GoalConfirmation = ({
  handleSubmit,
  isLoading,
  showConfirmation,
  setShowConfirmation,
  goalsByProblems,
}) => {
  const classes = useStyles();

  const [selectedGoals, setSelectedGoals] = useState([]);
  const redirectToHome = useReactiveVar(redirectToHomeVar);

  useEffect(() => {
    let allSelectedGoals = [];
    goalsByProblems.forEach((problem, problemIndex) => {
      problem?.goals?.forEach((goal, goalIndex) => {
        if (goal.userGoal?.priority === 'IMPORTANT' || goal.userGoal?.priority === 'NOT_IMPORTANT')
          allSelectedGoals.push({ ...goal, problemIndex, goalIndex });
      });
    });
    setSelectedGoals(allSelectedGoals);
  }, [goalsByProblems]);

  const handleButtonClick = () => {
    const problems = [...goalsByProblems];
    selectedGoals.forEach((goal) => {
      problems[goal.problemIndex].goals[goal.goalIndex].userGoal.priority = goal.userGoal.priority;
    });
    handleSubmit(problems);
  };

  const handleChangePriority = (goalIndex, priority) => {
    const goals = [...selectedGoals];
    const currPriority = priority || 'LOW';
    let nextPriority;
    if (currPriority === 'LOW') {
      nextPriority = 'NOT_IMPORTANT';
    } else if (currPriority === 'NOT_IMPORTANT') {
      nextPriority = 'IMPORTANT';
    } else if (currPriority === 'IMPORTANT') {
      nextPriority = 'NOT_IMPORTANT';
    }

    goals[goalIndex].userGoal.priority = nextPriority;
    setSelectedGoals([...goals]);
  };

  return (
    <div className="profile-edit-wrap">
      <Modal
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        className="profile-edit goal-confirmation">
        <div className="profile-edit-inner reward-screen">
          <div className="profile-common">
            <div
              style={{
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: '100%',
                top: '0',
                left: '0',
                borderRadius: '10px',
              }}>
              <Confetti recycle={false} numberOfPieces={600} width={510} height={600} />
            </div>
            <div className="reward-image">
              <img
                src={RewardIcn}
                tabindex="0"
                style={{
                  width: '224px',
                  height: '204px',
                }}
                alt="reward icon"
              />
            </div>

            <span tabindex="0" className={classes.headingWrap}>
              Congratulations!
            </span>
            <span tabindex="0" className={classes.headingWrap}>
              You've set mentoring priorities!
            </span>
            <div className={classes.contentWrap}>
              <div className="type">
                <p tabindex="0">Set Priority Level </p>
                <div className="imp">
                  <div className="type-one" tabindex="0">
                    <div
                      className="color"
                      style={{ backgroundColor: getBackgroundColor('NOT_IMPORTANT') }}></div>
                    Priority
                  </div>
                  <div className="type-one" tabindex="0">
                    <div
                      className="color"
                      style={{ backgroundColor: getBackgroundColor('IMPORTANT') }}></div>
                    High Priority
                  </div>
                </div>
              </div>
              <div className="goal-type">
                <div
                  // aria-live="assertive"
                  aria-label="press enter to set your goal and press enter again to make it a high priority goal"
                  className="goal-type-element"
                  style={
                    {
                      // marginBottom: '10px',
                      // minHeight: '100px',
                    }
                  }>
                  {selectedGoals?.map((goal, goalIndex) => (
                    <Button
                      size="small"
                      onClick={() =>
                        handleChangePriority(
                          goalIndex,
                          goal.userGoal ? goal.userGoal.priority : null,
                        )
                      }
                      // onKeyDown={(e) => {
                      //   if (e.key === 'Enter') {
                      //     handleChangePriority(
                      //       goalIndex,
                      //       goal.userGoal ? goal.userGoal.priority : null,
                      //     );
                      //   }
                      // }}
                      style={{
                        textTransform: 'inherit',
                        backgroundColor: getBackgroundColor(
                          goal.userGoal ? goal.userGoal.priority : null,
                        ),
                        color: getColorConfirmationPopup(
                          goal.userGoal ? goal.userGoal.priority : null,
                        ),
                      }}
                      aria-label={`${goal.goalTitle} ${getPriorityLabel(goal?.userGoal?.priority)}`}
                      aria-live="assertive">
                      {goal.goalTitle}
                    </Button>
                  ))}
                </div>
              </div>
            </div>

            <div className="done-btn">
              <Button
                color="primary"
                fullWidth
                variant="contained"
                onClick={() => handleButtonClick()}>
                {isLoading ? (
                  <CircularProgress size={20} color="secondary" />
                ) : redirectToHome ? (
                  'Almost Done'
                ) : (
                  'Done'
                )}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  headingWrap: {
    fontSize: '24px',
    fontWeight: '600',
    lineHeight: '33px',
  },
  contentWrap: {
    width: '-webkit-fill-available',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    textAlign: 'left',
  },
}));

export default GoalConfirmation;
