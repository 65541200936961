import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Authentication from '../Components/Authentication';
import EnterReferalCode from '../Components/Authentication/EnterReferalCode';
import ForgotPassword from '../Components/Authentication/ForgotPassword';
import { ResetPswd } from '../Components/Authentication/ResetPassword';
import SelectLogin from '../Components/Authentication/SelectLogin';
import VerifyOtp from '../Components/Authentication/VerifyOtp';
import ErrorBoundary from '../Routes/ErrorBoundary';
import getRoutes from './RoutePaths';
const PublicRoutes = () => (
  <div>
    <img
      width="80px"
      src="https://admin-stag.mentorhubapp.org/checkmate/images/logo_login.png"
      alt="cache-placeholder"
      style={{ display: 'none' }}
    />
    <ErrorBoundary state={'public'}>
      <Switch>
        <Route path={getRoutes.login} exact component={Authentication} />
        <Route path={getRoutes.verifyOtp} exact component={VerifyOtp} />
        <Route path={getRoutes.selectLogin} exact component={SelectLogin} />
        <Route path={getRoutes.setupAccount} exact component={EnterReferalCode} />
        <Route path={getRoutes.forgotPassword} exact component={ForgotPassword} />
        <Route path={getRoutes.resetPassword} exact component={ResetPswd} />
        <Route
          render={() => (
            <Redirect
              to={{
                pathname: getRoutes.selectLogin,
              }}
            />
          )}
        />
      </Switch>
    </ErrorBoundary>
  </div>
);

export default PublicRoutes;
