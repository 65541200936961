import { Button, Card, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import { forgotPassword } from '../../Api/Auth';
import { generateSnackbarPayload } from '../../Helper';
import getRoutes from '../../Routes/RoutePaths';
import * as types from '../../Store/actionTypes';

import { makeStyles } from '@mui/styles';
import themeColors from '../../Constants/themeColors';
import learnerAction from '../../Helper/Analytics/learnerAction';
import screenNames from '../../Helper/Analytics/screenNames';
import { trackEvent } from '../../Helper/EventTracker';
import theme from '../../MaterialUiTheme';
import AppBar from '../Common/AppBar';

const useStyles = makeStyles((theme, options) => {
  return {
    root: {
      display: 'flex',
    },
    appBar: {
      backgroundColor: themeColors.chatRoomRoot,
      minHeight: 64,
      // zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: `calc(100% - 25%)`,
      left: '0 !important',
      zIndex: 0,
      maxWidth: `64%`,
      borderBottom: `1px solid #e0e0e0`,
    },
  };
});
const ForgotPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async () => {
    trackEvent('click', {
      screen: screenNames.ForgotPassword,
      Action: learnerAction.FORGOT_PASSWORD,
    });
    if (!email) {
      setError('Email is required');
    } else if (!isEmail(email)) {
      setError('Email is invalid');
    }

    if (email && isEmail(email)) {
      setLoading(true);
      const payload = {
        email,
      };
      try {
        const resp = await forgotPassword(payload);

        dispatch({
          type: types.TOGGLE_SNACKBAR,
          payload: generateSnackbarPayload('success', resp.data.message),
        });
        setLoading(false);
        history.replace(getRoutes.login);
      } catch (err) {
        setLoading(false);
        dispatch({
          type: types.TOGGLE_SNACKBAR,
          payload: generateSnackbarPayload('error', err.message),
        });
      }
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (isEmail(e.target.value)) {
      setError('');
    }
  };

  return (
    <React.Fragment>
      <div style={{ position: 'fixed', top: 0, left: 0, width: '100%' }}>
        <AppBar></AppBar>
      </div>
      <section className="login-common">
        <div className="ligin-wrap">
          <Card
            className="ligin-wrap-inner"
            sx={{
              borderRadius: '25px',
              display: 'flex',
              flexDirection: 'row',
              overflow: 'visible',
              padding: '30px 30px',
              width: '400px',
            }}>
            <Grid container direction="column" spacing={2}>
              <Grid item sx={{ textAlign: 'center' }}>
                <Typography
                  className="wel-mentor"
                  sx={{
                    color: '#000',
                    fontSize: '24px',
                    fontFamily: 'Lato',
                    marginTop: '14px',
                    fontWeight: 700,
                  }}>
                  Reset Password
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  className="description"
                  color="textSecondary"
                  sx={{
                    fontSize: '14px !important',
                    textAlign: 'left !important',
                  }}>
                  Please enter the email address used to setup your account:
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  autoFocus={true}
                  name="email"
                  label="Email"
                  sx={{
                    '& .MuiFormLabel-root.Mui-focused': {
                      color: '#27cad2',
                      fontFamily: 'Lato',
                    },
                    '& .MuiFormLabel-root': {
                      fontFamily: 'Lato',
                    },
                    '& .MuiInputBase-root': {
                      borderRadius: '4px',
                      backgroundColor: '#F8F8F8',
                      fontFamily: 'Lato',
                    },
                  }}
                  value={email}
                  onChange={handleEmailChange}
                  helperText={error}
                  // required
                  id="standard-email"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  fullWidth
                  size="large"
                  color="primary"
                  sx={{
                    marginTop: '20px',
                    color: '#fff',
                    fontSize: '14px',
                    backgroundColor: '#27cad2',

                    fontFamily: 'Lato',
                    textTransform: 'capitalize',
                    fontWeight: 'bold',
                    minHeight: '50px',
                    borderRadius: '4px',
                    '&:hover': {
                      backgroundColor: '#27cad2',
                    },
                  }}
                  onClick={handleSubmit}
                  variant="contained">
                  Submit
                </Button>
              </Grid>
              <Grid item>
                <Typography color="textSecondary" sx={{ fontSize: '12px', fontFamily: 'Lato' }}>
                  If you have not added an email address to your account please reach out to your
                  program contact.
                </Typography>
                <Typography
                  className="description"
                  color="textSecondary"
                  sx={{ marginTop: '20px' }}>
                  <a
                    style={{ color: theme.palette.primary.main, cursor: 'pointer' }}
                    onClick={() => {
                      history.push(getRoutes.login);
                      trackEvent('click', {
                        screen: screenNames.ForgotPassword,
                        Action: learnerAction.BACK_TO_LOGIN,
                      });
                    }}>
                    Back
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ForgotPassword;
