import { takeLatest, call, put, fork } from 'redux-saga/effects';
import * as api from '../Api/Messages';
import * as types from '../Store/actionTypes';
import { generateSnackbarPayload } from '../Helper';

function* getMenteeDetails(action) {
  const { payload } = action;

  try {
    const response = yield call(api.getMenteeDetails, payload);
    const data = response.data;
    yield put({
      type: types.GET_MENTEE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: types.TOGGLE_SNACKBAR,
      payload: generateSnackbarPayload('error', e.message),
    });
    // logError(e, sagaPath + 'getMenteeDetails');
    // callback();
  }
}

function* watchMessageRequests() {
  yield takeLatest(types.GET_MENTEE_DETAILS, getMenteeDetails);
}

const messageSagas = [fork(watchMessageRequests)];

export default messageSagas;