import axios from 'axios';
const updateProfileDetails = (userId, data) => {
  const token = localStorage.getItem('@authToken');

  return axios({
    method: 'PATCH',
    url: process.env.REACT_APP_BASE_URL + `/user/${userId}` + '?access_token=' + token,
    data: data,
  });
};
export default updateProfileDetails;
