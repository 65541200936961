import { Button, Card, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getSetupAccountDetails } from '../../Api/Auth';
import themeColors from '../../Constants/themeColors';
import { generateSnackbarPayload } from '../../Helper';
import learnerAction from '../../Helper/Analytics/learnerAction';
import screenNames from '../../Helper/Analytics/screenNames';
import { trackEvent } from '../../Helper/EventTracker';
import theme from '../../MaterialUiTheme';
import getRoutes from '../../Routes/RoutePaths';
import * as types from '../../Store/actionTypes';
import AppBar from '../Common/AppBar';
import SetupAccount from './SetupAccount';

const useStyles = makeStyles((theme, options) => {
  return {
    root: {
      display: 'flex',
    },
    appBar: {
      backgroundColor: themeColors.chatRoomRoot,
      minHeight: 64,
      // zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: `calc(100% - 25%)`,
      left: '0 !important',
      zIndex: 0,
      maxWidth: `64%`,
      borderBottom: `1px solid #e0e0e0`,
    },
  };
});

const LoginReferal = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [userDetails, setUserDetails] = useState(null);
  const [referralCode, setReferralCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async () => {
    trackEvent('click', {
      screen: screenNames.OneTimeCodeSet,
      Action: learnerAction.ONE_TIME_CODE_SET,
    });
    if (!referralCode) {
      setError('Please enter the access code');
      return;
    }
    setLoading(true);
    try {
      const response = await getSetupAccountDetails(referralCode);
      if (response && response.data && response.data.message === 'Invalid Token or Token Expired') {
        dispatch({
          type: types.TOGGLE_SNACKBAR,
          payload: generateSnackbarPayload('error', 'The code you entered is incorrect'),
        });
        setLoading(false);
        return;
      }
      setUserDetails(response.data);
      setLoading(false);
    } catch (err) {
      dispatch({
        type: types.TOGGLE_SNACKBAR,
        payload: generateSnackbarPayload('error', err.message),
      });
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div style={{ position: 'fixed', top: 0, left: 0, width: '100%' }}>
        <AppBar></AppBar>
      </div>
      <section className="login-common">
        <div className="ligin-wrap">
          {!userDetails ? (
            <Card
              className="ligin-wrap-inner"
              sx={{
                borderRadius: '25px',
                display: 'flex',
                flexDirection: 'row',
                overflow: 'visible',
                padding: '30px 30px',
                width: '400px',
                height: '320px',
              }}>
              <Grid container direction="column" spacing={2}>
                <Grid item sx={{ textAlign: 'center' }}>
                  <Typography
                    className="wel-mentor"
                    sx={{
                      color: '#000',
                      fontSize: '24px',
                      fontFamily: 'Lato',
                      marginTop: '14px',
                      marginBottom: '20px',
                      fontWeight: 700,
                    }}>
                    One-Time Code
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    autoFocus={true}
                    name="Referral Code"
                    label="Enter One-Time Code"
                    id="standard-basic"
                    sx={{ fontSize: '14px' }}
                    variant="outlined"
                    value={referralCode}
                    onChange={(e) =>
                      e.target.value.length > referralCode.length
                        ? referralCode.length <= 2 && setReferralCode(e.target.value)
                        : setReferralCode(e.target.value)
                    }
                    helperText={error}
                    fullWidth
                  />
                  <Button
                    sx={{ marginTop: '20px', color: '#fff', fontSize: '14px' }}
                    // type="submit"
                    fullWidth
                    size="large"
                    color="primary"
                    onClick={handleSubmit}
                    variant="contained">
                    {loading ? <CircularProgress size={24} /> : 'Setup Account'}
                  </Button>
                </Grid>
                <Grid item>
                  <Typography
                    className="description"
                    aria-hidden={false}
                    color="textSecondary"
                    style={{ fontSize: '14px' }}>
                    One-Time Codes are Provided by Programs Directly
                  </Typography>

                  <Typography
                    className="description"
                    color="textSecondary"
                    sx={{ marginTop: '20px' }}>
                    <a
                      style={{ color: theme.palette.primary.main, cursor: 'pointer' }}
                      onClick={() => {
                        history.push(getRoutes.selectLogin);
                        trackEvent('click', {
                          screen: screenNames.OneTimeCodeSet,
                          Action: learnerAction.BACK_TO_SELECT_LOGIN,
                        });
                      }}>
                      Back
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          ) : (
            <div style={{ marginTop: 120 }}>
              <SetupAccount userDetails={userDetails} setUserDetails={setUserDetails} />
            </div>
          )}
        </div>
      </section>
    </React.Fragment>
  );
};

export default LoginReferal;
