import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { getProblemList } from '../../Store/Actions/MoodRings';
// import SiteLogo from '../../Assets/Images/logo-black.svg';
// import FtueBanner from '../../Assets/Images/start-golas-banner.svg';
import { useQuery } from '@apollo/client/react';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PriorityLanding from '../../Assets/Images/PriorityLanding.svg';
import { LIST_CUSTOM_GOALS } from '../../Components/Priority/services/gql';
import getRoutes from '../../Routes/RoutePaths';
import { redirectToHomeVar } from '../../Routes/Vars';
import { getUserDetails } from '../../Store/reduxSelectors';
import CustomGoals from '../Priority/SetGoal/CustomGoals';
import MyGoals from '../Priority/SetGoal/MyGoals';
import BlackLogo from './../../Assets/Images/Blacklogo.svg';
import Mentorhublogo from './../../Assets/Images/Mentorhublogo.svg';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SetGoalsWrapper = ({ children }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    redirectToHomeVar(true);
    dispatch(getProblemList('', () => {}));
  }, []);

  const [value, setValue] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const currentUser = useSelector((state) => getUserDetails(state));
  const orgLogo = localStorage.getItem('organizationLogo');
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const history = useHistory();

  const { data, loading } = useQuery(LIST_CUSTOM_GOALS, {
    variables: {
      skip: page * rowsPerPage,
      limit: rowsPerPage,
      customGoalOnly: true,
    },
    context: { clientName: 'referral' },
  });

  const handleSkip = () => {
    // ftuePriorityScreen(currentUser.id, false);
    // dispatch(
    //   updateUserDetails({
    //     FtueShowMSS: false,
    //     ftueSetGoals: false,
    //     takeMoodRingAssessment: false,
    //   }),
    // );
    // redirectToHomeVar(false);
    history.push(getRoutes.ftueMenteeAdvice);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (data && data?.listAllGoalEntry && data?.listAllGoalEntry?.length) {
      setValue(1);
    } else {
      setValue(0);
    }
  }, [data]);
  return (
    <section>
      {/* <Appbar /> */}
      <div className="ftueHeader" style={{ padding: orgLogo && '12px 0px 15px 20px' }}>
        <img src={orgLogo ? orgLogo : Mentorhublogo} style={{ height: orgLogo && '49px' }} />
        <span style={{ color: 'white', marginRight: '20px' }}>
          Welcome, {currentUser.firstName}
        </span>
      </div>
      <div>
        <Grid container spacing={1}>
          <Grid item md={4} style={{ backgroundColor: '#f8f8f8', marginTop: '10px' }}>
            <div style={{ margin: '150px 70px 118px 50px', width: '100%' }}>
              <img
                src={orgLogo ? orgLogo : BlackLogo}
                style={{ width: !orgLogo && '199px', height: orgLogo ? '80px' : '54px' }}
              />
              <h3>Welcome to MentorHub!</h3>
              <span style={{ fontSize: '16px', fontWeight: '400' }}>
                Click start to select up to three priorities
                <br />
                that you would like to focus on with your mentor. <br />
                You can always change your selections later.
              </span>
              <img src={PriorityLanding} style={{ marginTop: '50px' }} />
            </div>
          </Grid>
          <Grid item md={8} style={{ backgroundColor: 'white', marginTop: '10px' }}>
            <div>
              {/* {data && data.listAllGoalEntry && data.listAllGoalEntry.length ? ( */}
              <div>
                <div style={{ position: 'relative' }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    indicatorColor="primary"
                    style={{ borderBottom: '1px solid #e8e8e8' }}
                    textColor="primary">
                    <Tab
                      classes={{
                        wrapper: classes.tabWrapper,
                        selected: classes.selectedTab,
                      }}
                      style={{
                        textTransform: 'capitalize',
                        fontWeight: '600',
                        fontFamily: 'Lato',
                        width: '160px',
                      }}
                      label="My Priorities"
                      {...a11yProps(0)}
                    />
                    {/* {data?.listAllGoalEntry?.length > 0 && ( */}
                    <Tab
                      style={{
                        textTransform: 'capitalize',
                        fontWeight: '600',
                        fontFamily: 'Lato',
                        width: '160px',
                      }}
                      classes={{
                        wrapper: classes.tabWrapper,
                        selected: classes.selectedTab,
                      }}
                      label="Custom Priorities"
                      {...a11yProps(1)}
                    />
                    {/* )} */}
                  </Tabs>

                  <span
                    onClick={handleSkip}
                    tabindex="0"
                    style={{
                      position: 'absolute',
                      right: '35px',
                      bottom: '14px',
                      fontSize: '14px',
                      color: '#787878',
                      cursor: 'pointer',
                    }}>
                    Skip
                  </span>
                </div>
                <TabPanel value={value} index={0}>
                  <MyGoals customGoalCount={0} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <CustomGoals
                    data={data}
                    loading={loading}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                  />
                </TabPanel>
              </div>
              {/* ) : (
                <MyGoals customGoalCount={0} />
              )} */}
            </div>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

const useStyles = makeStyles((theme) => ({
  tabWrapper: {
    fontWeight: 600,
    textTransform: 'capitalize',
    '&$selected': {},
  },
  selectedTab: {
    color: '#000000 !important',
  },
}));

export default SetGoalsWrapper;
