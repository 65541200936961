import * as types from '../actionTypes';

export const toggleSnackbar = (payload) => {
  return {
    type: types.TOGGLE_SNACKBAR,
    payload,
  };
};

export const toggleSideDrawer = () => {
  return {
    type: types.TOGGLE_SIDE_DRAWER,
  };
};

export const toggleModal = () => {
  return {
    type: types.TOGGLE_MODAL,
  };
};

export const toggleNotificationStatus = (data) => {
  return {
    type: types.TOGGLE_NOTIFICATION_PERMISSION_STATUS,
    data,
  };
};


