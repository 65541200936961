import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { CircularProgress, Divider, Drawer } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { generateSnackbarPayload } from '../../../Helper';
import learnerAction from '../../../Helper/Analytics/learnerAction';
import screenNames from '../../../Helper/Analytics/screenNames';
import { trackEvent } from '../../../Helper/EventTracker';
import getRoutes from '../../../Routes/RoutePaths';
import { redirectToHomeVar } from '../../../Routes/Vars';
import * as types from '../../../Store/actionTypes';
import ContentLoader from '../../Common/ContentLoader/ContentLoader.Card';
import { getBackgroundColor, getColor, getPriorityLabel } from '../services/constants';
import {
  ASSIGN_MULTIPLE_GOALS,
  DELETE_MULTIPLE_GOALS,
  LIST_ALL_GOALS,
  UPDATE_MULTIPLE_GOALS,
} from '../services/gql';
import useGetGoalProblems from '../services/useGetGoalProblems';
import AddGoal from './AddGoal';
import GoalConfirmation from './GoalConfirmation';
import { goalsVar } from './vars';

const MyGoals = ({ customGoalCount }) => {
  const history = useHistory();
  const [editopen, setEditOpen] = useState(false);
  const [, setAnchorEl] = React.useState(null);
  const [goalsByProblems, setGoalsByProblems] = useState([]);
  const [initialGoalObj, setInitialGoalObj] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [executionCount, setExecutionCount] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  let allSelectedGoals = [];
  goalsByProblems.forEach((problem) => {
    problem?.goals?.forEach((goal) => {
      if (goal.userGoal?.priority === 'IMPORTANT' || goal.userGoal?.priority === 'NOT_IMPORTANT')
        allSelectedGoals.push(goal);
    });
  });

  const rediectToHome = useReactiveVar(redirectToHomeVar);

  const dispatch = useDispatch();

  // const { data: goalProblems } = useQuery(LIST_GOAL_PROBLEMS, {
  //   context: { clientName: 'referral' },
  // });

  const [getGoals, { data, loading }] = useLazyQuery(LIST_ALL_GOALS, {
    context: { clientName: 'referral' },
    fetchPolicy: 'network-only',
  });
  // const problemList = goalProblems?.listGoalProblems
  //   ? cloneDeep(goalProblems?.listGoalProblems)
  //   : [];

  const { problemList } = useGetGoalProblems();

  useEffect((e) => {
    getGoals({
      variables: {
        skip: 0,
        limit: 10000,
      },
    });
  }, []);

  useEffect(() => {
    if (data && data.listAllGoalEntry && problemList && problemList.length) {
      const goalObj = {};
      data.listAllGoalEntry.forEach((goal) => {
        goalObj[goal._id] = goal;
      });
      setInitialGoalObj({ ...goalObj });
      if (!Object.keys(goalsVar()).length) goalsVar({ ...goalObj });

      problemList.forEach((problem, index) => {
        const goals = [];
        data.listAllGoalEntry.forEach((goal) => {
          if (goal.problemId === problem.id) {
            goals.push({
              ...goal,
              userGoal: {
                ...goal.userGoal,
                ...(goal.userGoal !== null ? goalsVar()[goal._id]?.userGoal : {}),
              },
            });
          }
        });
        problemList[index].goals = goals;
      });
      setGoalsByProblems([...problemList]);
    }
  }, [data]);

  const toggleDrawerEdit = (editopen) => (event) => {
    if (editopen) {
      // trackEvent('click', {
      //   screen: screenNames.setYourGoals,
      //   Action: learnerActions.MANAGE_GOALS_CUSTOM_GOAL,
      // });
    }
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setEditOpen(editopen);
    handleClose();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangePriority = (problemIndex, goalIndex, priority, goalId) => {
    trackEvent('click', {
      screen: screenNames.MyPriorities,
      Action: learnerAction.SELECT_PRIORITY,
    });
    const problems = [...goalsByProblems];

    const currPriority = priority || 'LOW';
    let nextPriority;
    if (currPriority === 'LOW') {
      nextPriority = 'NOT_IMPORTANT';
    } else if (currPriority === 'NOT_IMPORTANT') {
      nextPriority = 'LOW';
    } else if (currPriority === 'IMPORTANT') {
      nextPriority = 'LOW';
    }
    if (
      allSelectedGoals.length <= 2 ||
      currPriority === 'NOT_IMPORTANT' ||
      currPriority === 'IMPORTANT'
    ) {
      problems[problemIndex].goals[goalIndex].userGoal.priority = nextPriority;
      setGoalsByProblems([...problems]);
      goalsVar({
        ...goalsVar(),
        [goalId]: {
          ...goalsVar()[goalId],
          userGoal: {
            priority: nextPriority,
          },
        },
      });
    } else {
      dispatch({
        type: types.TOGGLE_SNACKBAR,
        payload: generateSnackbarPayload('warning', "Let's only focus on 3 priorities for now"),
      });
    }
  };

  const [assignGoals] = useMutation(ASSIGN_MULTIPLE_GOALS, {
    context: { clientName: 'referral' },
    refetchQueries: ['listAllGoalEntry'],
    onCompleted: (data) => {
      setSuccessCount((successCount) => successCount + 1);
    },
  });

  const [updateGoals] = useMutation(UPDATE_MULTIPLE_GOALS, {
    context: { clientName: 'referral' },
    refetchQueries: ['listAllGoalEntry'],
    onCompleted: (data) => {
      setSuccessCount((successCount) => successCount + 1);
    },
  });

  const [deleteGoals] = useMutation(DELETE_MULTIPLE_GOALS, {
    context: { clientName: 'referral' },
    refetchQueries: ['listAllGoalEntry'],
    onCompleted: (data) => {
      setSuccessCount((successCount) => successCount + 1);
    },
  });

  const redirect = () => {
    // ftuePriorityScreen(currentUser.id, false);
    // dispatch(
    //   updateUserDetails({
    //     FtueShowMSS: false,
    //     ftueSetGoals: false,
    //   }),
    // );
    setIsLoading(false);
    if (rediectToHome) {
      redirectToHomeVar(false);
      history.push(getRoutes.ftueMenteeAdvice);
      // history.push(getRoutes.firstMessageToSsg);
    } else history.push(getRoutes.priorityprogress);
  };

  const handleSubmit = async (goalsByProblems) => {
    const deleteGoalsArr = [];
    const assignGoalsArr = [];
    const updateGoalsArr = [];

    goalsByProblems.forEach((problem) => {
      problem.goals.forEach((goal) => {
        if (goal.userGoal && !goal.userGoal._id) {
          if (goal.userGoal.priority && goal.userGoal.priority !== 'LOW') {
            assignGoalsArr.push({
              goalId: goal._id,
              priority: goal.userGoal.priority,
            });
          }
        } else if (goal.userGoal && goal.userGoal._id) {
          if (
            goal.userGoal.priority &&
            goal.userGoal.priority !== 'LOW' &&
            goal.userGoal.priority !== initialGoalObj[goal._id].userGoal.priority
          ) {
            updateGoalsArr.push({
              goalId: goal._id,
              userGoalId: goal.userGoal._id,
              priority: goal.userGoal.priority,
              progress: goal.userGoal.progress,
              dueDate: goal.userGoal.dueDate,
            });
          } else if (
            goal.userGoal.priority &&
            goal.userGoal.priority === 'LOW' &&
            goal.userGoal.priority !== initialGoalObj[goal._id].userGoal.priority
          ) {
            deleteGoalsArr.push(goal.userGoal._id);
          }
        }
      });
    });

    const sumArr = [
      deleteGoalsArr.length ? 1 : 0,
      assignGoalsArr.length ? 1 : 0,
      updateGoalsArr.length ? 1 : 0,
    ];
    const sum = sumArr.reduce((prev, next) => prev + next);
    setExecutionCount(sum);

    if (deleteGoalsArr.length) await deleteGoals({ variables: { userGoalIds: deleteGoalsArr } });

    if (assignGoalsArr.length) assignGoals({ variables: { data: assignGoalsArr } });

    if (updateGoalsArr.length) updateGoals({ variables: { data: updateGoalsArr } });

    if (sum === 0) redirect();
  };

  useEffect(() => {
    if (executionCount > 0) setIsLoading(true);
    if (successCount > 0 && executionCount > 0 && successCount === executionCount) redirect();
  }, [successCount, executionCount]);

  const handleSave = () => {
    trackEvent('submit', {
      screen: screenNames.MyPriorities,
      Action: learnerAction.SAVE_PRIORITY,
    });
    if (allSelectedGoals.length > 3) {
      dispatch({
        type: types.TOGGLE_SNACKBAR,
        payload: generateSnackbarPayload('warning', "Let's only focus on 3 priorities for now"),
      });
    } else {
      setShowConfirmation(true);
    }
  };

  useEffect(() => {
    trackEvent('view', {
      screen: screenNames.MyPriorities,
    });
  }, []);

  // const buttonColour = (userGoal) => {
  //   let buttonColor = 'white'; // Default color is white

  //   if (userGoal.priority) {
  //     switch (userGoal.priority) {
  //       case 'IMPORTANT':
  //         return (buttonColor = 'black');
  //         break;
  //       case 'NOT_IMPORTANT':
  //         return (buttonColor = '#D3F8FA');
  //         break;
  //       case 'Low':
  //         return (buttonColor = 'white');
  //         break;
  //       default:
  //         return (buttonColor = 'white');
  //     }
  //   }
  //   if (userGoal.goalId) {
  //     return (buttonColor = 'white');
  //   }

  //   if (Object.keys(userGoal).length === 0) {
  //     return (buttonColor = 'white');
  //   }
  // };

  return (
    <>
      <div className="setgoals-common">
        <div
          className="goal-head-button"
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h2 style={{ fontWeight: '600', fontSize: '24px' }}>Set Priorities</h2>
          <div className="btn-goals">
            {!customGoalCount && (
              <button
                style={{ cursor: 'pointer' }}
                onClick={toggleDrawerEdit(true)}
                aria-label="Press enter to add custom Priority "
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    toggleDrawerEdit(true);
                  }
                }}>
                Add Custom Priority
              </button>
            )}
            <button
              tabIndex="0"
              className="save"
              aria-label="Press enter to save Priority "
              onClick={() => handleSave()}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSave();
                }
              }}>
              {isLoading ? <CircularProgress size={20} color="secondary" /> : 'Save'}
            </button>
          </div>
        </div>
        <Divider />
        <div className="setgoals-head">
          {/* {rediectToHome ? (
            <h2 style={{ fontWeight: '600', fontSize: '24px' }} tabindex="0">
              Set Priorities
            </h2>
          ) : (
            <h2 tabIndex="0" style={{ fontSize: '24px', fontWeight: 400 }} tabindex="0">
              What do you hope to accomplish this semester?{' '}
            </h2>
          )} */}

          <h2 style={{ fontSize: '24px', fontWeight: 400 }}>Three priorities I have are to:</h2>
          <br />
        </div>

        <Drawer
          className="common-drawer"
          anchor={'right'}
          open={editopen}
          onClose={toggleDrawerEdit(false)}>
          <AddGoal setOpen={setEditOpen} goalData={{ goalTitle: '' }} type="Add" />
        </Drawer>
        {/* {rediectToHome && (
          <>
            <Divider></Divider>
            <h2 style={{ fontWeight: '400', fontSize: '24px' }}>
              {' '}
              Three priorities I have are to:
            </h2>
          </>
        )} */}
        {loading && <ContentLoader height={500} rootStyles={{ marginTop: '40px' }} />}
        {!loading &&
          goalsByProblems &&
          goalsByProblems.length > 0 &&
          goalsByProblems.map((problem, problemIndex) => (
            <div className="goal-type" style={{ borderBottom: '1px solid #F5F5F5' }}>
              <h2>
                <img src={problem.problemImage} alt="" style={{ width: '25px' }} />{' '}
                {problem.problemName}
              </h2>
              <div
                // aria-live="assertive"
                aria-label="press enter to set your priority and press enter again to remove the priority"
                className="goal-type-element"
                style={{ marginBottom: '10px' }}>
                {problem.goals &&
                  problem.goals.length > 0 &&
                  problem.goals.map((goal, goalIndex) => (
                    <button
                      tabIndex={0}
                      aria-hidden={false}
                      onClick={() =>
                        handleChangePriority(
                          problemIndex,
                          goalIndex,
                          goal.userGoal ? goal.userGoal.priority : null,
                          goal._id,
                        )
                      }
                      // onKeyDown={(e) => {
                      //   if (e.key === 'Enter') {
                      //     handleChangePriority(
                      //       problemIndex,
                      //       goalIndex,
                      //       goal.userGoal ? goal.userGoal.priority : null,
                      //       goal._id,
                      //     );
                      //   }
                      // }}
                      style={{
                        // backgroundColor: buttonColour(goal?.userGoal),
                        backgroundColor: getBackgroundColor(
                          goal.userGoal ? goal.userGoal.priority : null,
                          'setGoals',
                        ),
                        color: getColor(goal.userGoal ? goal.userGoal.priority : null, 'setGoals'),
                      }}
                      aria-label={`${goal.goalTitle} ${getPriorityLabel(goal?.userGoal?.priority)}`}
                      // aria-live="assertive"
                    >
                      {goal.goalTitle}
                    </button>
                  ))}
              </div>
            </div>
          ))}
      </div>
      <GoalConfirmation
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        showConfirmation={showConfirmation}
        setShowConfirmation={setShowConfirmation}
        goalsByProblems={goalsByProblems}
      />
    </>
  );
};

export default MyGoals;
