import { useReactiveVar } from '@apollo/client';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { uniqBy } from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import assessmentLogo from '../../../Assets/Images/assessmentLogo.svg';
import Mentorhublogo from '../../../Assets/Images/Mentorhublogo.svg';
import { getUserDetails } from '../../../Store/reduxSelectors';
import PageLoader from '../../Common/PageLoader';
import { AssessmentDetail } from './assessmentDetail';
import useGetMultipleAssessments, {
  useGetAssessmentDetail,
} from './hooks/useGetMultipleAssessment';
import { assessmentCollection, step } from './var';

const AskForAssessment = () => {
  const history = useHistory();
  const classes = useStyles();
  const { data } = useGetMultipleAssessments();
  const allAssessentsIds = useReactiveVar(assessmentCollection);
  const stepVar = useReactiveVar(step);
  const orgLogo = localStorage.getItem('organizationLogo');
  useEffect(() => {
    if (data) {
      data?.map((ids) => {
        return assessmentCollection(
          uniqBy([...assessmentCollection(), { id: ids, status: false }], 'id'),
        );
      });
    }
  }, [data]);

  const { data: assessmentData, loading: assessmentLoading } = useGetAssessmentDetail({
    assessmentId: allAssessentsIds[stepVar]?.id,
    skip: data.length > 0 ? false : true,
  });

  const currentUser = useSelector((state) => getUserDetails(state));
  return assessmentLoading ? (
    <PageLoader />
  ) : (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <div className="ftueHeader" style={{ padding: orgLogo && '12px 0px 15px 20px' }}>
        <img src={orgLogo ? orgLogo : Mentorhublogo} style={{ height: orgLogo && '49px' }} />
        <span style={{ color: 'white', marginRight: '20px', fontSize: '20px' }}>
          Welcome, {currentUser.firstName}
        </span>
      </div>
      <div className="welcomeContainer">
        <Grid item xs={6} className={classes.centerImage}>
          <img src={assessmentLogo} />
        </Grid>
        <Grid item xs={6} className={classes.mainDetail}>
          <div className="steps-col assessmentMultiple">
            <ul className="step-indicator assessmentMultipleIndicator" tabIndex="0">
              {allAssessentsIds?.length !== 1 &&
                allAssessentsIds.map((item, index) => {
                  // const isActive = index === 0 || item.status;
                  const isActive = index <= stepVar;
                  return <li className={isActive ? 'active' : ''}></li>;
                })}
            </ul>
            <Typography className={classes.progressText}>
              Assessment{' '}
              {allAssessentsIds.filter((assessment) => assessment.status === true).length + 1}/
              {allAssessentsIds.length}
            </Typography>
            {stepVar < allAssessentsIds.length && (
              <AssessmentDetail
                assessmentData={assessmentData}
                id={allAssessentsIds[stepVar]?.id}
                stepVar={stepVar}
                assessmentLoading={assessmentLoading}
              />
            )}
          </div>
        </Grid>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  centerImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  mainDetail: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  progressText: {
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: 400,
  },
}));

export default AskForAssessment;
