import { gql } from '@apollo/client';

export const GET_GOAL_PLAN_MENTEE = gql`
  query listGoal($menteeId: String!) {
    listGoal(menteeId: $menteeId) {
      _id
      title
      description
      activities {
        _id
        title

        attachments {
          s3URL
          type
          status
          order
        }
        description
        completed
        dueDate
        assignedTo
        assignedToData {
          _id
          firstName
          lastName
          profileImage
        }
        unreadCommentsCount
        createdBy
      }
    }
  }
`;

export const ADD_COMMENT = gql`
  mutation addComment($taskId: String!, $commentText: String, $attachments: [attachments]) {
    addComment(taskId: $taskId, commentText: $commentText, attachments: $attachments) {
      commentText
      readStatus
    }
  }
`;
export const LIST_COMMENT = gql`
  query listCommentsForATask($taskId: String!, $skip: Int) {
    listCommentsForATask(taskId: $taskId, skip: $skip, limit: 10) {
      count
      data {
        _id
        commentText
        readStatus
        attachments {
          s3URL
          type
          fileName
          extension
          status
          createdBy
        }
        senderId
        senderFirstName
        senderLastName
        senderProfileImage
        createdBy
        createdDate
        updatedDate
      }
    }
  }
`;
export const EDIT_COMMENT = gql`
  mutation editComment($commentId: String!, $commentText: String, $attachments: [attachments]) {
    editComment(commentId: $commentId, commentText: $commentText, attachments: $attachments) {
      _id
    }
  }
`;

export const UPDATE_ASSIGNED_TASK = gql`
  mutation editAssignedTask(
    $taskId: String!
    $goalId: String
    $title: String
    $description: String
    $attachments: [attachments]
    $completed: Boolean
    $dueDate: String
    $assignedTo: String
  ) {
    editAssignedTask(
      taskId: $taskId
      goalId: $goalId
      title: $title
      description: $description
      attachments: $attachments
      completed: $completed
      dueDate: $dueDate
      assignedTo: $assignedTo
    ) {
      _id
      title
      description
      assignedTo
      completed
      assignedToRole
      dueDate
    }
  }
`;

export const ADD_GOAL = gql`
  mutation addGoal($userId: String!, $title: String!, $description: String) {
    addGoal(userId: $userId, title: $title, description: $description) {
      _id
      userId
      title
      description
    }
  }
`;

export const ADD_TASK = gql`
  mutation addActivityToGoal(
    $title: String!
    $description: String
    $goalId: String!
    $order: Int
    $assignedTo: String
  ) {
    addActivityToGoal(
      title: $title
      goalId: $goalId
      description: $description
      order: $order
      assignedTo: $assignedTo
    ) {
      _id
      title
      description
    }
  }
`;

export const UPDATE_ASSIGNED_GOAL = gql`
  mutation editGoal($goalId: String!, $title: String, $description: String) {
    editGoal(goalId: $goalId, title: $title, description: $description) {
      _id
      title
      description
    }
  }
`;

export const GET_ASSIGNED_TO = gql`
  query getAssignedToList($menteeId: String!) {
    getAssignedToList(menteeId: $menteeId) {
      _id
      firstName
      lastName
      profileImage
      role
    }
  }
`;

export const MARK_AS_READ = gql`
  mutation markAsRead($taskId: String!) {
    markAsRead(taskId: $taskId) {
      status
      message
    }
  }
`;

export const FAVOURITE_BADGE = gql`
  mutation favouriteAssignedBadge($badgeId: String!, $favourite: Boolean) {
    favouriteAssignedBadge(badgeId: $badgeId, favourite: $favourite) {
      _id
      title
      favourite
    }
  }
`;
