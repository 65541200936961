import { Button } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import {
  getSideDrawerState,
  getUnreadNotificationsCount,
  getUserDetails,
} from '../../Store/reduxSelectors';
import { showFocus } from '../Common/reactiveVar';

import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import themeColors from '../../Constants/themeColors';
import getRoutes from '../../Routes/RoutePaths';
import { getUnreadNotificationCount } from '../../Store/Actions/Notifications';
import PageLoader from '../Common/PageLoader';
import CurrentUserInfo from './CurrentUserInfo';

import ApptourIcon from '../../Assets/Images/app-tour.svg';
import ToolsIcon from '../../Assets/Images/Apps.svg';
import CourseIcon from '../../Assets/Images/Course.svg';
import guidesImage from '../../Assets/Images/GuidesIcon.svg';
import HomeIcon from '../../Assets/Images/Home.svg';
import BrandLogo from '../../Assets/Images/MentorHub-Logo.svg';
import MentorIcon from '../../Assets/Images/mentors-icon.svg';
import messagesIcon from '../../Assets/Images/Message.svg';
import MoodRingIcon from '../../Assets/Images/mood-ring-icon.svg';
import UnionIcon from '../../Assets/Images/Notification.svg';
import SettingsIcon from '../../Assets/Images/Settings.svg';
import goalsImage from '../../Assets/Images/Target.svg';
import VideoConfIcon from '../../Assets/Images/Video.svg';
import { toggleSideDrawer } from '../../Store/Actions/Global';
import { menteeDetails } from '../../Store/Actions/Messages';

import { useReactiveVar } from '@apollo/client';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Badge } from '@mui/material';
import { startCase } from 'lodash';
import PriorityLeftWing from '../../Assets/Images/PriorityLeftWing.svg';
import { showMainContent, showOnMouseClick } from '../Common/reactiveVar';
import SsgProfile from '../Common/SsgProfile';
import GoalsCurriculum from '../Goals/Components/goalsCurriculum';
import GuidesDetail from '../Guides/components/GuidesDetail';
import Guides from '../Guides/components/index';
import GetChats from '../Messages/GetChats';
import { chatListVar, groupListVar } from '../Messages/services/vars';
import { showDetailVar } from '../Referrals/vars';
import ErrorBoundary from './../../Routes/ErrorBoundary';
import { barSizeVar } from './vars';
const Meetings = React.lazy(() => import('../Meetings'));
const Dashboard = React.lazy(() => import('../Dashboard'));
const MoodsDashboard = React.lazy(() => import('../MoodsDashboard'));
const TakeMoodRing = React.lazy(() => import('../Assessment/MoodRing'));
const Tools = React.lazy(() => import('../Tools'));
const Notifications = React.lazy(() => import('../Notifications'));
const Resources = React.lazy(() => import('../Resources'));
const ResourcesDetails = React.lazy(() => import('../Resources/ResourcesDetails'));
const JoinMeeting = React.lazy(() => import('../Meetings/JoinMeeting'));
const QuickLinks = React.lazy(() => import('../QuickLinks'));
const Referrals = React.lazy(() => import('../Referrals/index'));
const EmergencyInformation = React.lazy(() => import('../EmergencyInfo'));
const Messages = React.lazy(() => import('../Messages'));
const Settings = React.lazy(() => import('../Settings'));
const Profile = React.lazy(() => import('../Profile'));
const PriorityProgress = React.lazy(() => import('../Priority/GoalProgress'));
const SetGoals = React.lazy(() => import('../Priority/SetGoal/SetGoals'));

const drawerWidth = 245;
const useStyles = makeStyles((theme, options) => {
  return {
    root: {
      display: 'flex',
    },
    appBar: {
      backgroundColor: themeColors.chatRoomRoot,
      minHeight: 64,
      // zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: `calc(100% - 25%)`,
      left: '0 !important',
      zIndex: 0,
      maxWidth: `64%`,
      borderBottom: `1px solid #e0e0e0`,
    },
    appBarReferral: {
      backgroundColor: themeColors.chatRoomRoot,
      minHeight: 64,
      zIndex: 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: `100%`,
      left: '0 !important',
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - (${drawerWidth}px + 360px))`,
      // width: `calc(100% - ${drawerWidth}px - 25%)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      left: '0 !important',

      maxWidth: `64%`,
    },
    appBarShiftReferral: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      left: '0 !important',
    },

    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      backgroundColor: '#fff',
      zIndex: 1,
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1),

      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      backgroundColor: themeColors.headerLeft,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      marginTop: 64,
      height: 'calc(100vh - 116px)',
      overflowY: 'auto',
      position: 'relative',
    },
  };
});
const MenuLinks = [
  {
    title: 'Home',
    path: getRoutes.home,
    Icon: (props) => <ReactSVG src={HomeIcon} {...props} />,
  },
  {
    title: 'Mood Ring',
    path: getRoutes.mood,
    className: 'moodIcon',
    Icon: (props) => <ReactSVG src={MoodRingIcon} {...props} />,
  },
  {
    title: 'Messages',
    path: getRoutes.messages,
    Icon: (props) => (
      <Badge showZero={false} color="error" badgeContent={props.unreadNotifCount}>
        <ReactSVG aria-hidden="true" tabindex="-1" src={messagesIcon} {...props} />
      </Badge>
    ),
    // Icon: (props) => <ReactSVG src={messagesIcon} {...props} />,
  },
  {
    title: 'Meetings',
    path: getRoutes.meetings,
    Icon: (props) => <ReactSVG src={VideoConfIcon} {...props} />,
  },
  {
    title: 'Resources',
    path: getRoutes.resources,
    Icon: (props) => <ReactSVG src={CourseIcon} {...props} />,
  },
  {
    title: 'Priority Progress',
    path: getRoutes.priorityprogress,
    Icon: (props) => <ReactSVG src={PriorityLeftWing} {...props} />,
  },
  {
    title: 'Goals',
    path: getRoutes.goalsCurriculum,

    Icon: (props) => <ReactSVG src={goalsImage} {...props} />,
  },
  {
    title: 'Guides',
    path: getRoutes.guides,
    Icon: (props) => <ReactSVG src={guidesImage} {...props} />,
  },
  {
    title: 'Third Party Apps',
    path: getRoutes.tools,
    Icon: (props) => <ReactSVG src={ToolsIcon} {...props} />,
  },
  {
    title: 'My Profile',
    path: getRoutes.profile,
    Icon: (props) => <ReactSVG src={MentorIcon} {...props} />,
  },
  {
    title: 'Mentor Profile',
    path: getRoutes.ssgProfile,
    Icon: (props) => <ReactSVG src={MentorIcon} {...props} />,
  },

  {
    title: 'Notifications',
    path: getRoutes.notifications,
    Icon: (props) => (
      <Badge showZero={false} color="error" badgeContent={props.unreadNotifCount}>
        <ReactSVG aria-hidden="true" tabindex="-1" src={UnionIcon} {...props} />
      </Badge>
    ),
  },
  {
    title: 'App Tutorial',
    path: getRoutes.appTour,
    Icon: (props) => <ReactSVG src={ApptourIcon} {...props} />,
  },
  {
    title: 'Settings',
    path: getRoutes.settings,
    Icon: (props) => <ReactSVG src={SettingsIcon} {...props} />,
  },
];

const getHeaderTitle = {
  [getRoutes.home]: 'Home',
  [getRoutes.mood]: 'Mood Ring',
  [getRoutes.meetings]: 'Meetings',
  [getRoutes.tools]: 'Third Party Apps',
  [getRoutes.resources]: 'Resources',
  [getRoutes.notifications]: 'Notifications',
  [getRoutes.about]: 'About',
  [getRoutes.optOut]: 'Opt Out',
  [getRoutes.appTour]: 'App Tour',
  [getRoutes.ssgProfile]: 'Mentor Profile ',
  [getRoutes.features]: 'Features',
  [getRoutes.howItWorks]: 'How it works',
  [getRoutes.termsAndConditions]: 'Terms & Conditions',
  [getRoutes.takeMoodRing]: 'Mood Ring',
  [getRoutes.referrals]: 'Referrals',
  [getRoutes.emergency]: 'Emergency Information',
  [getRoutes.chooseArticle]: 'Choose Article',
  [getRoutes.savedArticles]: 'Saved Articles',
  [getRoutes.messages]: 'Messages',
  [getRoutes.guides]: 'Guides',
  [getRoutes.goalsCurriculum]: 'Goals',
  [getRoutes.settings]: 'Settings',
  [getRoutes.profile]: 'Profile',
  [getRoutes.priorityprogress]: 'Priority Progress',
  [getRoutes.setPriority]: 'Set Priorities',
  [getRoutes.customePriority]: 'Manage Custom Priorities',
};

export default function MiniDrawer(props) {
  const focusStatus = useReactiveVar(showFocus);
  const barSize = useReactiveVar(barSizeVar);
  const showDetail = useReactiveVar(showDetailVar);
  const showStatus = useReactiveVar(showMainContent);
  const location = useLocation();
  const showSkip = useReactiveVar(showOnMouseClick);
  const chatList = useReactiveVar(chatListVar);
  const groupList = useReactiveVar(groupListVar);
  const orgLogo = localStorage.getItem('organizationLogo');
  // useEffect(() => {
  //   if (
  //     location.pathname.includes(getRoutes.goalsCurriculum) ||
  //     location.pathname.includes(getRoutes.guides) ||
  //     location.pathname.includes(getRoutes.referrals) ||
  //     location.pathname.split('/')[1] === 'meetingDetail'
  //   ) {
  //     barSizeVar(true);
  //   } else {
  //     barSizeVar(false);
  //   }
  // }, [location.pathname]);

  const classes = useStyles(props);

  const history = useHistory();
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => getUserDetails(state));
  const open = useSelector((state) => getSideDrawerState(state));
  const [logoutOpen, setLogoutOpen] = React.useState(false);

  const unreadNotificationCountPayload = useSelector((state) => getUnreadNotificationsCount(state));
  const unreadNotifCount =
    unreadNotificationCountPayload && unreadNotificationCountPayload.data > 0
      ? unreadNotificationCountPayload && unreadNotificationCountPayload.data
      : 0;

  const chatUnreadArr = Object.values(chatList).map((chat) => chat.unreadMessageCount);
  const chatUnreadCount =
    chatUnreadArr.reduce((a, b) => a + b, 0) > 0 ? chatUnreadArr.reduce((a, b) => a + b, 0) : 0;

  const groupUnreadArr = Object.values(groupList).map((group) => {
    if (!group.unreadCounts) return 0;
    if (Object.keys(group.unreadCounts).includes(userDetails.firestoreId))
      return group?.unreadCounts[userDetails.firestoreId] > 0
        ? group?.unreadCounts[userDetails.firestoreId]
        : 0;
    else return 0;
  });
  const groupUnreadCount = groupUnreadArr.reduce((a, b) => a + b, 0);

  React.useEffect(() => {
    dispatch(getUnreadNotificationCount());
    dispatch(menteeDetails({ userId: userDetails.id }));
  }, []);

  const removeMenuItem = (title) => {
    const index = MenuLinks.findIndex((item) => item.title === title);
    if (index > 0) {
      MenuLinks.splice(index, 1);
    }
  };

  if (!userDetails.availableFeatures.goalsAndGuides) {
    removeMenuItem('Guides');
    removeMenuItem('Goals');
  }

  if (!userDetails.thirdPartyApps) removeMenuItem('Third Party Apps');

  if (!userDetails.enrolledInPrograms[0].videoEnabled) removeMenuItem('Meetings');

  // const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    // trackEvent();
    dispatch(toggleSideDrawer());
    // setOpen(true);
  };

  const handleDrawerClose = () => {
    // trackEvent();
    dispatch(toggleSideDrawer());
    // setOpen(false);
  };

  const handleClose = () => {
    //  trackEvent();
    setLogoutOpen(!logoutOpen);
  };

  const pathMatch = (path) => {
    const currentPathSplit = location.pathname.split('/')[1] || '/';
    const menuPathSplit = path.split('/')[1] || '/';
    return currentPathSplit === menuPathSplit;
  };

  return (
    <div className={classes.root}>
      {/* <Tour steps={steps} isOpen={isTourOpen} onRequestClose={() => setIsTourOpen(false)} /> */}
      <AppBar
        position="fixed"
        sx={{
          paddingRight: getHeaderTitle[location.pathname] == 'Goals' ? '100px ' : '0',
          backgroundColor: '#ffff',
          width: 'calc(100% - 245px)',
        }}
        className={clsx(
          barSize && classes.appBarReferral,
          !barSize && classes.appBar,
          barSize && {
            [classes.appBarShiftReferral]: open,
          },
          !barSize && {
            [classes.appBarShift]: open,
          },
        )}>
        <Toolbar>
          {/* <IconButton
            tabindex="0"
            color="inherit"
            aria-label="expand menu"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}>
            <MenuIcon />
          </IconButton> */}
          <GetChats />
          <Grid container alignItems="center" justifyContent="space-between">
            {!showDetail ? (
              <Grid item>
                <Typography
                  id="title-head"
                  tabIndex={0}
                  onBlur={() => {
                    let element = document.getElementById(
                      `${getHeaderTitle[location.pathname]}-main`,
                    );
                    if (element) {
                      document.getElementById(`${getHeaderTitle[location.pathname]}-main`).focus();
                      // showMainContent(true)
                      showFocus({});
                    }
                  }}
                  variant="h1"
                  noWrap>
                  <strong>{getHeaderTitle[location.pathname]}</strong>
                </Typography>
              </Grid>
            ) : (
              <React.Fragment>
                <Grid item>
                  <Typography variant="h1" noWrap>
                    <span className="referral-title">
                      {/* <img src={BackArrowIcon} alt="Back" onClick={() => showDetailVar(null)} /> */}
                      <ArrowBackIosIcon alt="Back" onClick={() => showDetailVar(null)} />
                      {showDetail.referralTitle}
                    </span>
                  </Typography>
                </Grid>
              </React.Fragment>
            )}
            <Grid item>
              <Typography variant="h1" noWrap>
                {`Welcome, ${startCase(userDetails.firstName)} ${startCase(userDetails.lastName)}`}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}>
        <div
          className={classes.toolbar}
          style={{
            boxShadow:
              '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
          }}>
          {orgLogo ? (
            <img src={orgLogo} style={{ height: 50, paddingLeft: '10px' }} alt="Org Logo" />
          ) : (
            <img
              src={BrandLogo}
              style={{ width: 116, height: 17, padding: '10px' }}
              alt="Brand Logo"
            />
          )}
        </div>
        <Divider sx={{ backgroundColor: '#464646' }} />
        <List component="nav" className="left-menu" tabIndex="0">
          {MenuLinks.map((item, index) =>
            item.isFeedback ? (
              <ListItem button component={'a'} href={'mailto:support@mentorhubapp.org'}>
                <div className={pathMatch(item.path) ? 'list-wrap active' : 'list-wrap'}>
                  <ListItemIcon aria-hidden="true">
                    <Grid
                      aria-hidden="true"
                      sx={{
                        display: 'flex',
                        backgroundColor: pathMatch(item.path)
                          ? themeColors.secondary
                          : 'transparent',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '20px',
                        width: '30px',
                        height: '30px',
                        borderRadius: '100px',
                      }}>
                      {item.Icon({
                        unreadNotifCount:
                          item.title === 'Messages'
                            ? chatUnreadCount + groupUnreadCount
                            : unreadNotifCount,
                        className: pathMatch(item.path) ? 'menu-item-active' : 'menu-item-inactive',
                      })}
                    </Grid>
                  </ListItemIcon>
                </div>
                <ListItemText primary={item.title} />
              </ListItem>
            ) : (
              <ListItem
                autoFocus={
                  location.pathname == focusStatus.path &&
                  focusStatus.status &&
                  item.title == focusStatus.page
                    ? true
                    : false
                }
                button
                key={index}
                onClick={() => {
                  history.push(item.path);
                  setTimeout(() => {
                    document.getElementById(`title-head`).focus();
                  }, 2000);
                }}>
                <div className={pathMatch(item.path) ? 'list-wrap active' : 'list-wrap'}>
                  <ListItemIcon aria-hidden={item.title === 'Notifications' ? 'false' : 'true'}>
                    <Grid
                      aria-hidden={item.title === 'Notifications' ? 'false' : 'true'}
                      sx={{
                        display: 'flex',
                        // backgroundColor: pathMatch(item.path)
                        //   ? themeColors.secondary
                        //   : 'transparent',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '20px',
                        width: '30px',
                        height: '30px',
                        borderRadius: '100px',
                      }}>
                      {item.Icon({
                        unreadNotifCount:
                          item.title === 'Messages'
                            ? chatUnreadCount + groupUnreadCount
                            : unreadNotifCount,
                        className: 'menu-item-active',
                      })}
                    </Grid>
                  </ListItemIcon>

                  <ListItemText primary={item.title} />
                </div>
              </ListItem>
            ),
          )}
        </List>

        <Grid>
          <CurrentUserInfo userDetails={userDetails} open={open} />
        </Grid>
      </Drawer>
      <main className={classes.content}>
        <React.Suspense
          fallback={
            <div>
              <PageLoader />
            </div>
          }>
          {showStatus && (
            <Button
              aria-hidden={showSkip ? 'true' : 'false'}
              aria-live="polite"
              role="button"
              aria-label="Skip to Main Navigation"
              autoFocus={true}
              variant="outlined"
              color="primary"
              tabindex="0"
              className="status-btn"
              sx={{
                position: 'absolute',
                background: '#000',
                top: '10px',
                margin: 'auto',
                zIndex: 3000,
                color: '#fffffe',
                border: 'solid #c00 1px',
                left: '10px',
                minHeight: '20px',
              }}
              onKeyDown={(e) => {
                (e.code === 'Enter' || (e.ctrlKey && e.altKey && e.code === 'Space')) &&
                  showFocus({
                    path: location.pathname,
                    status: true,
                    page: getHeaderTitle[location.pathname],
                  });
              }}
              onBlur={() => showMainContent(false)}>
              Skip to Main Navigation
            </Button>
          )}
          <ErrorBoundary state={'private'}>
            <Switch>
              <Route exact path={getRoutes.home} component={Dashboard}></Route>
              <Route exact path={getRoutes.meetings} component={Meetings}></Route>
              <Route exact path={getRoutes.mood} component={MoodsDashboard}></Route>
              <Route exact path={getRoutes.takeMoodRing} component={TakeMoodRing}></Route>
              <Route exact path={getRoutes.tools} component={Tools}></Route>
              <Route exact path={getRoutes.resources} component={Resources}></Route>
              <Route exact path={getRoutes.notifications} component={Notifications}></Route>
              <Route exact path={getRoutes.joinMeeting} component={JoinMeeting}></Route>
              <Route exact path={getRoutes.resourceDetails} component={ResourcesDetails}></Route>
              {userDetails.availableFeatures.goalsAndGuides && (
                <Route exact path={getRoutes.guides} component={Guides} />
              )}
              {userDetails.availableFeatures.goalsAndGuides && (
                <Route exact path={getRoutes.guidesdetails} component={GuidesDetail} />
              )}
              {userDetails.availableFeatures.goalsAndGuides && (
                <Route exact path={getRoutes.goalsCurriculum} component={GoalsCurriculum} />
              )}
              <Route exact path={getRoutes.referrals} component={Referrals}></Route>
              {/* <Route exact path={getRoutes.chooseArticle} component={ChooseArticle}></Route>
            <Route exact path={getRoutes.savedArticles} component={SavedArticles}></Route>
            <Route exact path={getRoutes.optOut} component={QuickLinks} /> */}
              <Route exact path={getRoutes.messages} component={Messages} />
              <Route exact path={getRoutes.about} component={QuickLinks} />
              <Route exact path={getRoutes.features} component={QuickLinks} />
              <Route exact path={getRoutes.howItWorks} component={QuickLinks} />
              <Route exact path={getRoutes.termsAndConditions} component={QuickLinks} />
              <Route exact path={getRoutes.appTour} component={QuickLinks} />
              <Route exact path={getRoutes.ssgProfile} component={SsgProfile} />
              <Route exact path={getRoutes.settings} component={Settings} />
              <Route exact path={getRoutes.profile} component={Profile} />
              <Route exact path={getRoutes.priorityprogress} component={PriorityProgress} />
              <Route exact path={getRoutes.setPriority} component={SetGoals} />
              <Route exact path={getRoutes.customePriority} component={SetGoals} />
              <Route exact path={getRoutes.emergency} component={EmergencyInformation} />
              <Route
                render={() => (
                  <Redirect
                    to={{
                      pathname: getRoutes.home,
                    }}
                  />
                )}
              />
            </Switch>
          </ErrorBoundary>
        </React.Suspense>
      </main>
    </div>
  );
}
