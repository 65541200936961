import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ContentLoaderCard from '../../Common/ContentLoader/ContentLoader.Card';
import { useRoomAccess } from './CustomHooks';

import { useReactiveVar } from '@apollo/client';
import { meetingVar } from '../services/vars';
import Room from './Room';
const VideoChatLoader = () => {
  return (
    <Grid
      className="loading_wrap"
      container
      xs={12}
      alignItems="center"
      justifyContent="center"
      direction="column">
      <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>
        Setting up the meeting room...
      </Typography>
      <Grid sx={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
        <ContentLoaderCard height={97} rootStyles={{ borderRadius: 0 }} />
      </Grid>
    </Grid>
  );
};

const Meetings = () => {
  const meetingId = useReactiveVar(meetingVar);
  const { user, data, loading } = useRoomAccess(meetingId);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [showCard, setShowCard] = useState(false);

  const showRoom = showFullScreen || showCard;

  useEffect(() => {
    if (data) setShowFullScreen(true);
  }, [data]);

  return (
    <React.Fragment>
      <div>
        {loading ? (
          <VideoChatLoader />
        ) : (
          data &&
          showRoom && (
            <Room
              token={data.getRoomAccess}
              roomName={meetingId}
              meetingData={data.getMeeting}
              user={user}
              setShowFullScreen={setShowFullScreen}
              showFullScreen={showFullScreen}
              setShowCard={setShowCard}
              showCard={showCard}
            />
          )
        )}
      </div>
    </React.Fragment>
  );
};

export default Meetings;
