import { gql } from '@apollo/client';

export const LIST_GUIDES = gql`
  query listGuides($menteeId: String!) {
    listGuides(menteeId: $menteeId) {
      _id
      title
      description
      milestones {
        _id
        title
        badgeImage
      }
      totalBadges
      totalActivities
      grantedBadges
      completedActivities
      pinned
    }
  }
`;

export const PIN_GUIDE = gql`
  mutation pinGuide($guideId: String!, $menteeId: String!, $pinned: Boolean!) {
    pinGuide(guideId: $guideId, menteeId: $menteeId, pinned: $pinned) {
      status
      message
    }
  }
`;

export const GET_LESSON_PLAN_MENTEE = gql`
  query listLessonPlanMentee($menteeId: String!, $curriculumId: String!) {
    listLessonPlanMentee(curriculumId: $curriculumId, menteeId: $menteeId) {
      _id
      title
      milestone {
        _id
        title
        description
        badgeImage
        isBadgeGranted
        order
        favourite
        activityBadgeId
        badgeCategoryId
        tasks {
          _id
          title
          goalId
          attachments {
            s3URL
            type
            status
            order
          }
          description
          completed
          dueDate
          assignedTo
          assignedToData {
            _id
            firstName
            lastName
            profileImage
          }
          unreadCommentsCount
          createdBy
        }
      }
    }
  }
`;

export const ADD_ASSIGNED_ACTIVITY = gql`
  mutation addAssignedActivity(
    $milestoneId: String!
    $title: String!
    $description: String
    $dueDate: String
    $assignedTo: String
  ) {
    addAssignedActivity(
      milestoneId: $milestoneId
      title: $title
      description: $description
      dueDate: $dueDate
      assignedTo: $assignedTo
    ) {
      _id
      title
      description
      assignedTo
      completed
      assignedToRole
    }
  }
`;

export const UPDATE_ASSIGNED_TASK = gql`
  mutation editAssignedTask(
    $taskId: String!
    $goalId: String
    $title: String
    $description: String
    $attachments: [attachments]
    $completed: Boolean
    $dueDate: String
    $assignedTo: String
  ) {
    editAssignedTask(
      taskId: $taskId
      goalId: $goalId
      title: $title
      description: $description
      attachments: $attachments
      completed: $completed
      dueDate: $dueDate
      assignedTo: $assignedTo
    ) {
      _id
      title
      description
      assignedTo
      completed
      assignedToRole
      dueDate
    }
  }
`;

export const MARK_AS_READ = gql`
  mutation Mutation($taskId: String!) {
    markAsRead(taskId: $taskId) {
      status
      message
    }
  }
`;

export const GET_ASSIGNED_TO = gql`
  query getAssignedToList($menteeId: String!) {
    getAssignedToList(menteeId: $menteeId) {
      _id
      firstName
      lastName
      profileImage
      role
    }
  }
`;

export const ADD_COMMENT = gql`
  mutation addComment($taskId: String!, $commentText: String, $attachments: [attachments]) {
    addComment(taskId: $taskId, commentText: $commentText, attachments: $attachments) {
      commentText
      readStatus
    }
  }
`;
export const LIST_COMMENT = gql`
  query listCommentsForATask($taskId: String!, $skip: Int, $limit: Int) {
    listCommentsForATask(taskId: $taskId, skip: $skip, limit: $limit) {
      count
      data {
        _id
        commentText
        readStatus
        attachments {
          s3URL
          type
          fileName
          extension
          status
          createdBy
        }
        senderId
        senderFirstName
        senderLastName
        senderProfileImage
        createdBy
        createdDate
        updatedDate
      }
    }
  }
`;
export const EDIT_COMMENT = gql`
  mutation editComment($commentId: String!, $commentText: String, $attachments: [attachments]) {
    editComment(commentId: $commentId, commentText: $commentText, attachments: $attachments) {
      _id
    }
  }
`;

export const FAVOURITE_BADGE = gql`
  mutation favouriteAssignedBadge($badgeId: String!, $favourite: Boolean) {
    favouriteAssignedBadge(badgeId: $badgeId, favourite: $favourite) {
      _id
      title
      favourite
    }
  }
`;

export const AVAILABLE_GUIDES = gql`
  query listAvailableGuide($menteeId: String!, $search: String, $myAgeGrp: Boolean, $guidecategoryId: String) {
    listAvailableGuides(menteeId: $menteeId, search: $search, myAgeGrp: $myAgeGrp, guidecategoryId: $guidecategoryId) {
      _id
      title
      description
      milestoneImages
    }
  }
`;

export const ASSIGN_GUIDE_TO_MENTEE = gql`
  mutation assignCurriculumToMentee($menteeId: String!, $curriculumId: String!) {
    assignCurriculumToMentee(menteeId: $menteeId, curriculumId: $curriculumId) {
      status
      message
    }
  }
`;

export const LIST_GOALS = gql`
  query listGoal($menteeId: String!) {
    listGoal(menteeId: $menteeId) {
      _id
      title
    }
  }
`;

export const UNASSIGN_GUIDE = gql`
  mutation unAssignGuide($curriculumId: String!) {
    unAssignGuide(curriculumId: $curriculumId) {
      status
      message
    }
  }
`;

export const LIST_GUIDE_CATEGORIES = gql`
  query listGuideCategories {
    listGuideCategories {
      _id
      title
    }
  }
`;
