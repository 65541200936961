import axios from '../Config';

export const getAssessmentQuestions = (id) => {
  return axios({
    url: `/programs/${id}/assessmentQuestions`,
  });
};

export const postAssessmentQuestions = (id, questions) => {
  return axios({
    method: 'POST',
    url: `/programs/${id}/assessment`,
    data: questions,
  });
};
