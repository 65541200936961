import { useQuery, useReactiveVar } from '@apollo/client';
import { Avatar, Button, CircularProgress } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import Linkify from 'react-linkify';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import AttachIcon from '../../../Assets/Images/Attach.svg';
import { getUserDetails } from '../../../Store/reduxSelectors';
import { LIST_COMMENT } from '../services/gql';
import { limitVar } from '../vars/reactiveVars';
import AddComment from './AddComment';

const ListComment = (props) => {
  const [skipValue, setSkip] = useState(0);
  const [editData, setEditData] = useState({});
  const limitValue = useReactiveVar(limitVar);
  const currentUser = useSelector((state) => getUserDetails(state));

  const commentList = useQuery(LIST_COMMENT, {
    variables: { taskId: props._id, skip: skipValue, limit: limitValue },
    context: { clientName: 'curriculum' },
    fetchPolicy: 'network-only',
    onCompleted: (resp) => {
      props.setCommentsCount(resp.listCommentsForATask.count);
    },
  });

  const scrollEnd = (e) => {};
  const refetchData = () => {
    setSkip(0);
    commentList.refetch({
      variables: { taskId: props._id, skip: 0, limit: limitValue },
      context: { clientName: 'curriculum' },
    });
  };
  const getAttachments = (attachment) => {
    let list = [];
    attachment.forEach((rec) => {
      list.push(
        <a target="_blank" rel="noreferrer" href={rec.s3URL}>
          {rec.fileName}
        </a>,
        <img src={AttachIcon} alt="Attach" style={{ width: '20px' }} />,
      );
    });
    return list;
  };
  const editClick = (data) => {
    setEditData({
      ...editData,
      editId: data._id,
      attachmentImage: data.attachments.map((image) => image.s3URL),
      alredyImage: data.attachments.map((image) => image.s3URL),
      attachmentUpload: [],
      comment: data.commentText,
    });
  };
  const changeOpenComment = () => {
    setEditData({});
  };
  const componentDecorator = (decoratedHref, decoratedText, key) => {
    return (
      <a target="blank" className="link" href={decoratedHref} key={key}>
        {decoratedText}
      </a>
    );
  };
  return (
    <React.Fragment>
      <div className="task-discription">
        <div className="message-page mentee-message">
          <div className="scrollbar-container">
            <PerfectScrollbar onScrollY={(e) => scrollEnd(e)}>
              {props._id && commentList.data && commentList.data.listCommentsForATask.count > 0 ? (
                commentList.data.listCommentsForATask.data.map((rec) => {
                  let date = new Date(Number(rec.createdDate));
                  return rec.createdBy === currentUser.id ? (
                    Object.values(editData).length && editData.editId === rec._id ? (
                      <AddComment
                        {...props}
                        refetchData={refetchData}
                        editData={editData}
                        changeOpenComment={changeOpenComment}
                      />
                    ) : (
                      <div className="each-message send-message">
                        <div className="msg-inner">
                          {/* <span onClick={() => editClick(rec)}>Edit</span> */}
                          <p>
                            <div>
                              <Linkify componentDecorator={componentDecorator}>
                                {rec.commentText}
                              </Linkify>
                              <span className="cmnt-link">{getAttachments(rec.attachments)}</span>
                            </div>

                            <time>{moment(date).format('MMMM DD, YYYY  LT')}</time>
                          </p>
                        </div>
                        {rec.senderProfileImage !== '' ? (
                          <Avatar src={rec.senderProfileImage} />
                        ) : (
                          <Avatar>{rec.senderFirstName.charAt(0).toUpperCase()}</Avatar>
                        )}
                      </div>
                    )
                  ) : (
                    <div className="each-message ">
                      {rec.senderProfileImage !== '' ? (
                        <Avatar src={rec.senderProfileImage} />
                      ) : (
                        <Avatar>{rec.senderFirstName.charAt(0).toUpperCase()}</Avatar>
                      )}
                      <div className="msg-inner">
                        <p>
                          <div>
                            <Linkify componentDecorator={componentDecorator}>
                              {rec.commentText}
                            </Linkify>{' '}
                            <span className="cmnt-link">{getAttachments(rec.attachments)}</span>
                          </div>{' '}
                          <time>{moment(date).format('MMMM DD, YYYY  LT')}</time>
                        </p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div></div>
              )}
              {commentList.loading && limitValue > 10 ? (
                <Button>
                  <CircularProgress />
                </Button>
              ) : (
                commentList.data &&
                commentList.data.listCommentsForATask.count > limitValue && (
                  <Button onClick={() => limitVar(limitValue + 10)} color="primary">
                    Show More
                  </Button>
                )
              )}
            </PerfectScrollbar>
          </div>
        </div>
        <AddComment
          {...props}
          refetchData={refetchData}
          editData={editData}
          changeOpenComment={changeOpenComment}
        />
      </div>
    </React.Fragment>
  );
};

export default ListComment;
