import { Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Checked from '../../Assets/Images/Checked.svg';
import Unchecked from '../../Assets/Images/Unchecked.svg';
import learnerAction from '../../Helper/Analytics/learnerAction';
import screenNames from '../../Helper/Analytics/screenNames';
import { trackEvent } from '../../Helper/EventTracker';
import theme from '../../MaterialUiTheme';
import getRoutes from '../../Routes/RoutePaths';
import { updateUserDetails } from '../../Store/Actions/Auth';
import { getUserDetails } from '../../Store/reduxSelectors';
import getLoggedUserDetails from '../services/getLoggedUserDetails';
import updateProfileDetails from '../services/patchUserDetails.js';
import { ftueScreen } from './../../Api/Profile';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const FtueSeven = (props) => {
  const [yes, setYes] = React.useState(false);
  const [no, setNo] = React.useState(true);
  const [noToStay, setNoToSay] = React.useState(false);
  const [school, setSchool] = React.useState(true);
  const [college, setCollege] = React.useState(false);
  const [grade, setGrade] = React.useState('');
  const dispatch = useDispatch();
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);
  const history = useHistory();
  const currentUser = useSelector((state) => getUserDetails(state));

  const sSnackbar = () => {
    setSuccessSnackbar(true);
  };

  const eSnackbar = () => {
    setErrorSnackbar(true);
  };

  const eSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorSnackbar(false);
  };
  const sSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessSnackbar(false);
    setTimeout(() => {
      props.stepUpdate('next');
    }, 100);
  };

  const handleChange = (event) => {
    setGrade(event.target.value);
  };

  const handleYes = () => {
    setYes(!yes);
    if (no) {
      setNo(!no);
    }
  };

  const handleNo = () => {
    setNo(!no);
    if (yes) {
      setYes(!yes);
    }
  };

  const handleLevel = () => {
    setSchool(!school);
    if (college) {
      setCollege(!college);
    }
    if (noToStay) {
      setNoToSay(!noToStay);
    }
  };

  const handleCollege = () => {
    setCollege(!college);
    if (school) {
      setSchool(!school);
    }
    if (noToStay) {
      setNoToSay(!noToStay);
    }
  };

  const PreferNo = () => {
    setNoToSay(!noToStay);
    if (school) {
      setSchool(!school);
    }
    if (college) {
      setCollege(!college);
    }
  };

  const updateSchool = () => {
    trackEvent('click', {
      screen: screenNames.FtueWelcome,
      Action: learnerAction.FTUE_SCHOOL_ENROLL,
    });
    let data = {
      schoolDetails: {
        attendSchool: yes === true ? true : false,
        schoolType: school === true ? 'K-12' : college === true ? 'College' : 'Prefer not to say',
        grade: grade,
      },
    };
    updateProfileDetails(props.userDetails.id, data)
      .then((response) => {
        getLoggedUserDetails(props.userDetails.id).then((res) => {
          props.setLoggedData(res);
          dispatch(updateUserDetails({ schoolDetails: res.data.schoolDetails }));
        });
        sSnackbar();
        ftueScreen(currentUser.id, false);
        dispatch(
          updateUserDetails({
            FtueShowMSS: false,
            takeMoodRingAssessment: false,
          }),
        );
        history.push(getRoutes.startPriority);
      })
      .catch((error) => {});
  };

  const GRADE = [
    'Kindergarten',
    '1st Grade',
    '2nd Grade',
    '3rd Grade',
    '4th Grade',
    '5th Grade',
    '6th Grade',
    '7th Grade',
    '8th Grade',
    '9th Grade',
    '10th Grade',
    '11th Grade',
    '12th Grade',
  ];

  return (
    <div>
      <div>
        <h2 style={{ height: '20px' }}>Are you enrolled in School?</h2>
        <span>You can always change this later </span>
        <div style={{ display: 'flex', marginTop: '22px' }}>
          <div>
            <Checkbox
              icon={<img src={Unchecked} />}
              checkedIcon={<img src={Checked} />}
              checked={yes}
              onChange={handleYes}
            />
            <span>Yes</span>
          </div>
          <div>
            <Checkbox
              icon={<img src={Unchecked} />}
              checkedIcon={<img src={Checked} />}
              checked={no}
              onChange={handleNo}
            />
            <span>No</span>
          </div>
        </div>
      </div>
      {yes === true && no === false && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              icon={<img src={Unchecked} />}
              checked={school}
              onChange={handleLevel}
              checkedIcon={<img src={Checked} />}
            />
            <span>K - 12</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              icon={<img src={Unchecked} />}
              checked={college}
              checkedIcon={<img src={Checked} />}
              onChange={handleCollege}
            />
            <span>College/Post Secondary Education</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              icon={<img src={Unchecked} />}
              checked={noToStay}
              checkedIcon={<img src={Checked} />}
              onChange={PreferNo}
            />
            <span>Prefer not to say</span>
          </div>
          {school === true && (
            <div className="select-grade">
              <FormControl>
                <InputLabel id="demo-simple-select-label">Grade</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={grade}
                  onChange={handleChange}>
                  {GRADE.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Divider style={{ width: '331px', height: '1px', backgroundColor: 'black' }} />
            </div>
          )}
        </div>
      )}
      <Button
        style={{
          marginTop: '40px',
          color: 'white',

          width: '100%',
          height: '50px',
          backgroundColor: theme.palette.primary.main,
          fontFamily: 'Lato',
          fontWeight: 'bold',

          textTransform: 'capitalize',
        }}
        fullWidth
        variant="contained"
        color="primary"
        onClick={updateSchool}>
        Save
      </Button>
      <Snackbar
        open={errorSnackbar}
        style={{ zIndex: '10000' }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={eSnackbarClose}>
        <Alert severity="error" variant="filled">
          Please select your Racial and Ethnic Identity
        </Alert>
      </Snackbar>
      <Snackbar
        open={successSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id="ratingSuccess"
        autoHideDuration={3000}
        onClose={sSnackbarClose}>
        <Alert tabindex="0" severity="success" variant="filled">
          Racial and Ethnic Identity successfully updated.
        </Alert>
      </Snackbar>
    </div>
  );
};
