import { Button, Card, Grid, Typography } from '@mui/material';
import Cookies from 'js-cookie';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logOutUser } from '../../Api/Auth';
import { logoutUser } from '../../Store/Actions/Auth';
import { getUserDetails } from '../../Store/reduxSelectors';

const resendIntervalTime = 1000;
const resendOtpReActiveIn = 10;
let setIntervalSubscribed = null;
const role = Cookies.get('role');
const NotAuthorized = () => {
  const [counter, setCounter] = React.useState(resendOtpReActiveIn);
  const counterRef = React.useRef();
  counterRef.current = counter;

  const dispatch = useDispatch();
  const userDetails = useSelector((state) => getUserDetails(state));

  React.useEffect(() => {
    initLogoutTimer();
    return () => {
      if (setIntervalSubscribed) clearInterval(setIntervalSubscribed);
    };
  }, []);

  const initLogoutTimer = () => {
    setIntervalSubscribed = setInterval(() => {
      if (Number(counterRef.current) === 0) {
        clearInterval(setIntervalSubscribed);
        handleLogout();
      } else setCounter(counterRef.current - 1);
    }, resendIntervalTime);
  };

  const handleLogout = async () => {
    const resp = role !== 'no-access' && (await logOutUser(userDetails.id));
    if (resp && Object.keys(resp.data).includes('status')) {
      localStorage.removeItem('@authToken');
      localStorage.removeItem('@refreshToken');
      window.location.replace(resp.data.redirectLink);
    } else if (role === 'no-access') {
      window.location.replace('/');
      Cookies.remove('role', { domain: '.northeastern.mentorhubapp.org', path: '/' });
    } else dispatch(logoutUser());
  };

  // const handleLogout = () => {
  //   dispatch(logoutUser());
  // };

  return (
    <Grid container justifyContent="center" alignItems="center" className="autherized-common">
      <Grid item xs={12} md={4}>
        <Card className="notauthorized">
          {role === 'no-access' ? (
            <Typography className="autherized-note">
              <span style={{ fontSize: '16px', display: 'block' }}>
                You are not a user in the MentorHub NU system. That likely means that you're not a
                learner or a success guide or an admin. If you believe you should have access to the
                system, please contact{' '}
                <a href={'mailto:support@mentorhubapp.org'}>support@mentorhubapp.org</a>
              </span>
            </Typography>
          ) : (
            <Typography className="autherized-note">
              <span style={{ fontSize: '16px', display: 'block' }}>
                Sorry, you can't access this page. This web portal is for NU undergrads only. If you
                believe you should have access to the system, please contact{' '}
                <a href={'mailto:support@mentorhubapp.org'}>support@mentorhubapp.org</a>
              </span>
            </Typography>
          )}
          <Typography className="description">{`You will be logged out in ${counterRef.current}`}</Typography>
          <Button
            aria-label="Log Out"
            color="primary"
            variant="contained"
            className="note-logout"
            onClick={() => handleLogout()}>
            Log Out
          </Button>
        </Card>
      </Grid>
    </Grid>
  );
};

export default NotAuthorized;
