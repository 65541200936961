import { useMutation } from '@apollo/client';
import { Button, TextField } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import learnerAction from '../../../Helper/Analytics/learnerAction';
import screenNames from '../../../Helper/Analytics/screenNames';
import { trackEvent } from '../../../Helper/EventTracker';
import { ADD_GOAL } from '../services/gql';

const AddGoal = (props) => {
  const dispatch = useDispatch();
  const [btnLoad, setBtnLoad] = useState(false);

  const [error, setError] = useState({
    title: '',
    description: '',
  });
  const [goal, setGoal] = useState({
    title: '',
    description: '',
  });
  const [addGoal, { data }] = useMutation(ADD_GOAL, {
    context: { clientName: 'curriculum' },
    refetchQueries: ['listGoal'],
    // refetchQueries: [{ query: GET_GOAL_PLAN_MENTEE }]
  });
  const changeError = (field, e) => {
    const newErrorObj = { ...error };
    newErrorObj[field] = '';
    setError(newErrorObj);
    setGoal({ ...goal, [field]: e.target.value });
  };
  // const handleChange = (e) => {
  //     const updatedGoal = { ...goal };
  //     const inputField = e.target.name;
  //     const inputValue = e.target.value;
  //     updatedGoal[inputField] = inputValue;
  //     setGoal(updatedGoal);
  // }

  const addGoalData = async (e) => {
    try {
      let errorObj = {};
      if (!goal.title) errorObj = { ...errorObj, title: 'Title is required' };
      setError(errorObj);
      if (Object.values(errorObj).length == 0) {
        setBtnLoad(true);
        let goals = await addGoal({
          variables: {
            title: goal.title,
            description: goal.description,
            userId: props.currentUser.id,
          },
        });
        if (goals.data.addGoal) {
          trackEvent('submit', {
            screen: screenNames.Goals,
            Action: learnerAction.ADD_GOAL,
          });
          closeDrawer();
          props.refetch();
          if (props.setShowAdd) props.setShowAdd(false);
          setBtnLoad(false);
        }
      }
      setBtnLoad(false);
    } catch (err) {
      setBtnLoad(false);
    }
  };

  const closeDrawer = () => {
    setError({
      title: '',
      description: '',
    });
    setGoal({
      title: '',
      description: '',
    });
    props.setopenAddGoal(false);
  };

  return (
    <Drawer
      className="right-drawer addGoals-drawer"
      open={props.openAddGoal}
      variant="persistent"
      anchor="right"
      aria-label="add goals drawer"
      aria-hidden={false}
      tabIndex={0}>
      <h2>Add Goal</h2>
      <form onSubmit={addGoalData}>
        <div>
          <TextField
            label="Title"
            variant="outlined"
            fullWidth
            value={goal.title}
            helperText={error.title}
            sx={{
              '& .MuiInputBase-root': {
                height: '58px',
              },
            }}
            onChange={(e) => changeError('title', e)}
            // onChange={(e) => setGoal(e.target.title)}
          />
        </div>

        <br />

        <div>
          <TextField
            label="Description"
            variant="outlined"
            fullWidth
            value={goal.description}
            onChange={(e) => changeError('description', e)}
            sx={{
              '& .MuiInputBase-root': {
                height: '58px',
              },
            }}
            // onChange={(e) => setGoal(e.target.description)}
          />
        </div>

        <div className="btn-area">
          <Button
            onClick={() => {
              closeDrawer();
            }}
            disableRipple
            className="custom-button"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                closeDrawer();
              }
            }}
            style={{ backgroundColor: '#e0e0e0' }}
            aria-label="press enter to close"
            variant="contained">
            cancel
          </Button>
          <Button
            disableRipple
            className="custom-button"
            onClick={() => {
              addGoalData();
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                addGoalData();
              }
            }}
            variant="contained"
            color="primary"
            aria-label="press enter to add goal">
            <span style={{ color: '#fff' }}>Add Goal</span>
          </Button>
        </div>
      </form>
    </Drawer>
  );
};

export default AddGoal;
