import { Typography } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect, useRef, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Linkify from 'react-linkify';
import { useDispatch } from 'react-redux';
import { generateSnackbarPayload } from '../../../Helper';
import { TOGGLE_SNACKBAR } from '../../../Store/actionTypes';
import awsFileUpload from '../../services/awsFileUpload';
import { handleChangeDescription } from '../services/operations';
import { UpdateActivityHook } from '../services/updateAssignedTask';

const EditDescription = (props) => {
  const editorRef = useRef(null);

  const { updateBackend } = UpdateActivityHook();

  const dispatch = useDispatch();

  const [descHtml, setDescHtml] = useState(null);

  useEffect(() => {
    setDescHtml(props.description);
  }, [props.description]);

  const handleClick = () => {
    if (props.menteeId.toString() === props.createdBy.toString()) {
      props.setShowDescription(true);
    } else {
      dispatch({
        type: TOGGLE_SNACKBAR,
        payload: generateSnackbarPayload('error', "You don't have edit access"),
      });
    }
  };

  // const transform = (html, index) => {
  //   const isLink = html.name === 'a';
  //   if (isLink) {
  //     html.attribs.target = '_blank';
  //     return convertNodeToElement(html, index, transform);
  //   }
  // };

  const componentDecorator = (decoratedHref, decoratedText, key) => (
    <a
      id="link"
      target="blank"
      href={decoratedHref}
      key={key}
      onClick={(e) => {
        e.stopPropagation();
      }}>
      {decoratedText}
    </a>
  );

  const uploadCallback = async (file) => {
    let ImageUrl = '';
    if (file) {
      ImageUrl = await awsFileUpload(file, 'guides');
      return {
        data: {
          link: ImageUrl.publicUrl,
          type: 'image/gif',
        },
        success: true,
        status: 200,
      };
    }
  };

  const handleSave = () => {
    let description = editorRef.current.getContent();
    if (!/^<p>/i.test(description)) description = `<p>${description}</p>`;

    updateBackend(props, handleChangeDescription(description, props, 'description'));
    props.setShowDescription(false);
  };

  return (
    <React.Fragment>
      {props.description && props.showDescription === false && (
        <div className="task-discription-show" onClick={handleClick}>
          <Linkify componentDecorator={componentDecorator}>
            <Typography>{ReactHtmlParser(props.description)}</Typography>
          </Linkify>
        </div>
      )}
      {!props.description && props.showDescription === false && (
        <div className="task-discription-show" onClick={handleClick}>
          <Typography>{'Enter Description'}</Typography>
        </div>
      )}
      {props.showDescription && (
        <div className="edit-task">
          <Editor
            tinymceScriptSrc="/tinymce/tinymce.min.js"
            licenseKey="MIT"
            value={descHtml}
            onEditorChange={(e) => {
              setDescHtml(e);
            }}
            onInit={(evt, editor) => (editorRef.current = editor)}
            init={{
              height: 500,
              menubar: false,
              branding: false,
              elementpath: false,
              plugins: [
                'advlist',
                'autolink',
                'lists',
                'link',
                'image',
                'preview',
                'anchor',
                'fullscreen',
                'media',
                'paste',
              ],
              paste_as_text: true,
              toolbar: 'bold undo redo bullist link image media saveButton',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              convert_urls: true,
              default_link_target: '_blank',
              media_alt_source: false,
              media_dimensions: false,
              media_poster: false,
              media_filter_html: false,
              file_picker_types: 'file',
              file_picker_callback: (cb, value, meta) => {
                var input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.setAttribute(
                  'accept',
                  'image/*, application/pdf, .csv, .doc, .docx, .txt, application/vnd.ms-excel',
                );
                input.onchange = async function () {
                  var file = this.files[0];
                  const {
                    data: { link },
                  } = await uploadCallback(file);
                  cb(link, { title: file.name });
                };

                input.click();
              },
              setup: (editor) => {
                editor.ui.registry.addButton('saveButton', {
                  text: 'Save',
                  onAction: handleSave,
                });
                editor.on('ExecCommand', (event) => {
                  const command = event.command;
                  if (command === 'mceMedia') {
                    const tabElems = document.querySelectorAll('div[role="tablist"] .tox-tab');
                    tabElems.forEach((tabElem) => {
                      if (tabElem.innerText === 'Embed') {
                        tabElem.style.display = 'none';
                      }
                    });
                  }
                });
              },
              images_upload_handler: async (blobInfo, progress) => {
                return new Promise(async (resolve, reject) => {
                  try {
                    const file = new File([blobInfo.blob()], blobInfo.filename(), {
                      lastModified: new Date().getTime(),
                      type: blobInfo.blob().type,
                    });
                    const {
                      data: { link },
                    } = await uploadCallback(file);
                    resolve(link);
                  } catch (err) {
                    reject('Error while upoading');
                  }
                });
              },
              // media_url_resolver: function (data, resolve /*, reject*/) {
              //   const escaped = escape(data.url);
              //   const embedHtml = `<p><a href="${escaped}">${data.url}</a></p>`;
              //   resolve({ html: embedHtml });
              // },
            }}
          />
        </div>
      )}
    </React.Fragment>
  );
};
export default EditDescription;
