import {
  imageVar,
  lessonGoalMenteeVar,
  selectedGoalActivityVar,
} from '../vars/lessonGoalMenteeVar';

export const assignTo = [
  {
    value: 'mentee',
    label: 'Mentee',
  },
  {
    value: 'mentor',
    label: 'Mentor',
  },
];

export const updateLocalTask = async (badgeIndex, activityIndex, changes) => {
  try {
    const lesson = lessonGoalMenteeVar();
    const milestone = lesson.milestone[badgeIndex];
    const tasks = milestone.tasks;
    tasks[activityIndex] = { ...changes };
    lesson.milestone[badgeIndex][tasks] = tasks;
    lessonGoalMenteeVar(lesson);
  } catch (err) {
    console.error(err);
  }
};
export const handleCompletedChange = (completedVal, props, field) => {
  const changedField = {};
  changedField[field] = completedVal;
  selectedGoalActivityVar({ ...props, ...changedField });
  return changedField;
};

export const handleDueDateChange = (event, props, field) => {
  const changedField = {};
  changedField[field] = event;
  selectedGoalActivityVar({ ...props, ...changedField });
  return changedField;
};

export const handleChangeDescription = (descriptionData, props, field) => {
  const changedField = {};
  changedField[field] = descriptionData;
  selectedGoalActivityVar({ ...props, ...changedField });
  return changedField;
};

export const handleImageRender = async (event) => {
  if (event.target.files[0] != null) {
    const file = event.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      imageVar({ image: reader.result, fileToUpload: file });
    };
  }
};

export const handleChange = (event, props, field) => {
  const changedField = {};
  changedField[field] = event.target.value;
  selectedGoalActivityVar({ ...props, ...changedField });
  return changedField;
};
