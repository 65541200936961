import authSagas from './Auth';
import moodRingSagas from './MoodRings';
import programSagas from './Programs';
import dashboardSagas from './Dashboard';
import toolsSagas from './Tools';
import quickLinkSaga from './QuickLink';
import moodRingDashSaga from './MoodRingDashboard';
import notificationSaga from './Notification';
import messageSagas from './Messages';
import profileSagas from './Profile';

import { all } from 'redux-saga/effects';

export default function* rootSaga() {
  yield all([
    ...authSagas,
    ...notificationSaga,
    ...moodRingDashSaga,
    ...moodRingSagas,
    ...toolsSagas,
    ...programSagas,
    ...dashboardSagas,
    ...quickLinkSaga,
    ...messageSagas,
    ...profileSagas,
  ]);
}
