export const getUserDetails = (state) => state.authentication.userDetails;
export const getUserValidationStatus = (state) => state.authentication.validatingUser;
export const isUserLoggedIn = (state) => state.authentication.isUserLoggedIn;

export const getSideDrawerState = (state) => state.global.isSideDrawerOpen;

export const getMoodRingDashData = (state) => state.moodRingDashboard.data;
export const getMoodRingDataStatus = (state) => state.moodRingDashboard.moodRingDataStatus;

export const getNotificationPermissionStatus = (state) => state.global.notificationPermissionStatus;

export const getUnreadNotificationsCount = (state) => state.notifications.unreadNotificationPayload;
export const getNotifications = (state) => state.notifications.userNotifications;

export const getProblemListData = (state) => state.moodRings.problemList;
export const isProblemListLoading = (state) => state.moodRings.isProblemListLoading;
export const getPieChartData = (state) => Object.values(state.moodRings.pieChartData);

export const getAssessmentQuestionsData = (state) => state.programs.assessmentQuestions;
export const isAssessmentQuestionsLoading = (state) => state.programs.isAssessmentQuestionsLoading;

export const getBadgeDetails = (state) => state.dashboard.badgeDetails;
export const getAvgUserMood = (state) => state.dashboard.avgMoodRing;
export const getPointSummaryData = (state) => state.dashboard.pointSummary;

export const getUserInstalledApps = (state) => state.tools.installedApps;
export const getUserRecommendedApps = (state) => state.tools.recommendedApps;
export const getUserAllApps = (state) => state.tools.allApps;

export const getLoadingState = (state, key) => state[key].loadingStates;

export const getQuickLinkData = (state) => state.quickLink;
export const getModalState = (state) => state.global.isModalOpen;

export const getMenteeDetails = (state) => state.messages.menteeDetails;
export const getAssociatedMenteeList = (state) => state.messages.associatedMenteeList;

export const getHobbies = (state) => state.Profile.hobbies;
