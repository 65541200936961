import axios from 'axios';
const getLoggedUserDetails = (userId) => {
  const token = localStorage.getItem('@authToken');
  return axios({
    method: 'POST',
    url: process.env.REACT_APP_BASE_URL + `/user/${userId}` + '?access_token=' + token,
    data: {
      firstName: true,
      lastName: true,
      phoneNumber: true,
      username: true,
      countryCode: true,
      aboutUs: true,
      gender: true,
      email: true,
      collegeDetails: true,
      raceEthnicity: true,
      preferredName: true,
      currentCity: true,
      homeTown: true,
      college: true,
      major: true,
      collegeProgram: true,
      bio: true,
      pronoun: true,
      additionalProgramDetails: true,
      majorDetails: true,
      profileImage: true,
      name_id: true,
      lang: true,
      loginStreak: true,
      messageStreak: true,
      moodRingStreak: true,
      hobbies: true,
      strengths: true,
      zipCode: true,
      dateOfBirth: true,
      schoolDetails: true,
      feedback: true,
    },
  });
};
export default getLoggedUserDetails;
