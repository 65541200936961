import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Badge, Link } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useState } from 'react';
import learnerAction from '../../../Helper/Analytics/learnerAction';
import screenNames from '../../../Helper/Analytics/screenNames';
import { trackEvent } from '../../../Helper/EventTracker';
import { UpdateBadgeHook } from '../services/updateAssignedBadge';
import { selectedActivityVar } from '../vars/reactiveVars';
import Activity from './Activity';
const Badges = (props) => {
  useEffect(() => {
    if (props.tasks && props.tasks.length > 0 && props.badgeIndex === 0) {
      selectedActivityVar({
        ...props.tasks[0],
        key: props.tasks[0]._id,
        badgeId: props._id,
        activityIndex: 0,
        badgeIndex: props.badgeIndex,
        badgeTitle: props.title,
        showTasks: props.showTasks,
        refetch: props.refetch,
        menteeId: props.menteeId,
        curriculumId: props.curriculumId,
      });
    }
    return () => {
      selectedActivityVar(null);
    };
  }, []);
  const [fav, setFav] = useState(props.favourite);
  let arr = props.showTasks;
  const handleClick = () => {
    props.setShowtasks([props.badgeIndex]);
  };

  const { updateFavourite } = UpdateBadgeHook();

  const handleFav = () => {
    if (fav) {
      trackEvent('click', {
        screen: screenNames.GuideDetail,
        Action: learnerAction.REMOVED_FROM_FAVORITE,
      });
    } else {
      trackEvent('click', {
        screen: screenNames.GuideDetail,
        Action: learnerAction.FAVORITE_GUIDE,
      });
    }

    setFav(!fav);
    updateFavourite(props);
  };

  const getActivity = () => {
    let list = [];
    if (props.tasks.length > 0) {
      props.tasks.forEach((activity, activityIndex) => {
        if (activityIndex < props.tasks.length - 1) {
          list.push(
            <Activity
              {...activity}
              key={activity._id}
              badgeId={props._id}
              activityIndex={activityIndex}
              badgeIndex={props.badgeIndex}
              badgeTitle={props.title}
              showTasks={props.showTasks}
              refetch={props.refetch}
              menteeId={props.menteeId}
              curriculumId={props.curriculumId}
            />,
          );
        } else {
          list.push(
            <React.Fragment>
              <Activity
                {...activity}
                key={activity._id}
                badgeId={props._id}
                activityIndex={activityIndex}
                badgeIndex={props.badgeIndex}
                badgeTitle={props.title}
                showTasks={props.showTasks}
                refetch={props.refetch}
                menteeId={props.menteeId}
                curriculumId={props.curriculumId}
              />
              <Activity
                badgeId={props._id}
                activityIndex={activityIndex}
                badgeIndex={props.badgeIndex}
                badgeTitle={props.title}
                refetch={props.refetch}
                menteeId={props.menteeId}
                curriculumId={props.curriculumId}
                completed={false}
              />
            </React.Fragment>,
          );
        }
      });
    } else {
      list.push(
        <Activity
          badgeId={props._id}
          activityIndex={props.tasks.length}
          badgeIndex={props.badgeIndex}
          badgeTitle={props.title}
          refetch={props.refetch}
          menteeId={props.menteeId}
          curriculumId={props.curriculumId}
          completed={false}
        />,
      );
    }
    return list;
  };

  return (
    <div className="lesson-Qustions">
      <div className="Qustions-head">
        <h3>
          <span>
            <Badge
              badgeContent={<CheckCircleIcon className="badge-tick" />}
              overlap="circular"
              color="default"
              invisible={!props.isBadgeGranted}
              sx={{ marginRight: props.isBadgeGranted ? '10px' : '1px' }}>
              <img src={props.badgeImage} className="bagdeIcon" alt="Badge Icon" />
            </Badge>{' '}
            <span>
              <b>
                <Link component="button" color="inherit" underline="none" variant="inherit">
                  {props.title}
                </Link>
              </b>
              {props.description}
            </span>
          </span>
          <span className="button-area">
            {!props.showTasks.includes(props.badgeIndex) ? (
              <IconButton onClick={() => props.setShowtasks([props.badgeIndex])}>
                <ExpandMoreIcon />
              </IconButton>
            ) : (
              <IconButton onClick={() => props.setShowtasks([])}>
                <ExpandLessIcon />
              </IconButton>
            )}
            <IconButton onClick={handleFav}>
              {fav ? <FavoriteIcon /> : <FavoriteBorderIcon />}
            </IconButton>
          </span>
        </h3>
      </div>
      {props.badgeIndex !== undefined &&
        props.showTasks.includes(props.badgeIndex) &&
        getActivity()}
    </div>
  );
};

export default Badges;
