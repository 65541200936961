import { Button } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import learnerAction from '../../Helper/Analytics/learnerAction';
import screenNames from '../../Helper/Analytics/screenNames';
import { trackEvent } from '../../Helper/EventTracker';
import theme from '../../MaterialUiTheme';
import { getUserDetails } from '../../Store/reduxSelectors';

export const FtueOne = (props) => {
  const currentUser = useSelector((state) => getUserDetails(state));

  useEffect(() => {
    trackEvent('view', {
      screen: screenNames.FtueWelcome,
    });
  }, []);

  return (
    <div>
      <div>
        <p
          style={{
            // margin: '142px 245px 45px 245px',
            color: 'black',
            fontFamily: 'Lato',
            fontWeight: '600',
            fontSize: '24px',
          }}>
          Hi {currentUser.firstName}! I'm your Program <br /> Contact
        </p>
        <div
          style={{
            // margin: '0px 10px 0px 10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}>
          <Avatar
            alt="profileImage"
            src={currentUser.matchSupportSpecialist.profileImage}
            style={{ width: '180px', height: '180px' }}>
            {currentUser !== null &&
              currentUser.matchSupportSpecialist.firstName.charAt(0).toUpperCase()}
          </Avatar>
          <p
            style={{
              fontFamily: 'Lato',
              fontSize: '24px',
              fontWeight: '700',
              color: '#27CAD2',
            }}>
            {currentUser.matchSupportSpecialist.firstName}{' '}
            {currentUser.matchSupportSpecialist.lastName}
          </p>
          <span style={{ fontFamily: 'Lato', fontWeight: '400', fontSize: '14px' }}>
            I am here to help you get the most out of your
          </span>
          <span style={{ fontFamily: 'Lato', fontWeight: '400', fontSize: '14px' }}>
            mentoring experience. I just have a few questions
          </span>
          <span style={{ fontFamily: 'Lato', fontWeight: '400', fontSize: '14px' }}>
            that will help me get to know you better. Let's get
          </span>
          <span style={{ fontFamily: 'Lato', fontWeight: '400', fontSize: '14px' }}>started!</span>
        </div>
        <Button
          style={{
            width: '392px',
            height: '40px',
            backgroundColor: theme.palette.primary.main,
            marginTop: '40px',
            color: 'white',
            textTransform: 'capitalize',
          }}
          onClick={() => {
            props.stepUpdate('next');
            trackEvent('click', {
              screen: screenNames.FtueWelcome,
              Action: learnerAction.START_FTUE,
            });
          }}>
          Next
        </Button>
      </div>
    </div>
  );
};
