import { useMutation, useQuery } from '@apollo/client';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Button,
  Card,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  // IconButton,
  // Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { generateSnackbarPayload } from '../../../../src/Helper';
import * as types from '../../../Store/actionTypes';
import CustomCircularProgress from '../../Common/CustomCircularProgress';
import { ASSIGN_GUIDE_TO_MENTEE, AVAILABLE_GUIDES, LIST_GUIDE_CATEGORIES } from '../services/gql';
// import CardWrapper from '../../Common/CardWrapper';
import SearchIcon from '../../../Assets/Images/Search.svg';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import { upperFirst } from 'lodash';
import learnerAction from '../../../Helper/Analytics/learnerAction';
import screenNames from '../../../Helper/Analytics/screenNames';
import { trackEvent } from '../../../Helper/EventTracker';

const AvailableGuides = (props) => {
  const [search, setSearch] = useState('');
  // const [myAgeGrp, setMyAgeGrp] = useState(false);
  // const [selectedIndex, setSelectedIndex] = useState(0);
  const [guideCategory, setGuideCategory] = useState('');
  // const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  const dispatch = useDispatch();
  const guideCategoryList = useQuery(LIST_GUIDE_CATEGORIES, {
    context: { clientName: 'curriculum' },
  });

  const { data, loading } = useQuery(AVAILABLE_GUIDES, {
    variables: {
      menteeId: props.id,
      search: search,
      // myAgeGrp,
      guidecategoryId: guideCategory === 'All' ? '' : guideCategory,
    },
    fetchPolicy: 'network-only',
    context: { clientName: 'curriculum' },
  });

  const [assignGuide] = useMutation(ASSIGN_GUIDE_TO_MENTEE, {
    context: { clientName: 'curriculum' },
    refetchQueries: ['listAvailableGuide'],
  });

  const handleAssign = async (guideId) => {
    trackEvent('click', {
      screen: screenNames.AvailableGuides,
      Action: learnerAction.ADD_TO_MY_GUIDES,
    });
    let resp = await assignGuide({
      variables: {
        menteeId: props.id,
        curriculumId: guideId,
      },
    });

    if (resp.data.assignCurriculumToMentee.status) {
      dispatch({
        type: types.TOGGLE_SNACKBAR,
        payload: generateSnackbarPayload('success', 'Guide has been added successfully'),
      });
    }
  };
  // const ITEM_HEIGHT = 48;
  // const options = ['Show All Guides', 'Show Guides in my age group'];

  // const handleChangeItem = (event, index) => {
  //   if (index === 0) setMyAgeGrp(false);
  //   if (index === 1) setMyAgeGrp(true);
  //   setSelectedIndex(index);
  //   handleClose();
  // };

  // const capitalizeEachWord = (message) => {
  //   const capitalized = message.split(' ').map((word) => upperFirst(word));
  //   return capitalized.join(' ');
  // };

  const getGuideCategories = () => {
    let list = [];
    list.push(<MenuItem value={'All'}>{'All'}</MenuItem>);
    guideCategoryList.data &&
      guideCategoryList.data.listGuideCategories.forEach((rec, index) => {
        list.push(
          <MenuItem value={rec._id} key={index}>
            {rec.title}
          </MenuItem>,
        );
      });
    return list;
  };

  const handleChange = (event) => {
    setGuideCategory(event.target.value);
  };

  useEffect(() => {
    trackEvent('view', {
      screen: screenNames.AvailableGuides,
    });
  }, []);

  return (
    <div className="guides-list-wrap">
      <div className="search-filter-wrap filter-guide">
        <TextField
          variant="outlined"
          className="search-filter-input"
          // fullWidth
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={SearchIcon} style={{ width: '20px' }} alt="Search" />
              </InputAdornment>
            ),
          }}
          placeholder="Search by title"></TextField>

        <FormControl variant="outlined" className="Grade guidefilter">
          <InputLabel id="category-field">Guide Category</InputLabel>
          <Select
            label="Category"
            value={guideCategory}
            width="300px"
            labelId="grade-field"
            onChange={(e) => handleChange(e)}
            inputProps={{
              name: 'status',
              id: 'outlined-age-native-simple',
            }}>
            {getGuideCategories()}
          </Select>
        </FormControl>

        {/* <IconButton
          aria-label="more"
          id="long-button"
          aria-controls="long-menu"
          // aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}>
          <MoreVertIcon />
        </IconButton> */}
      </div>
      {/* <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '25ch',
          },
        }}>
        {options.map((option, i) => (
          <MenuItem key={option} selected={i === selectedIndex} onClick={(event) => handleChangeItem(event, i)}>
            {option}
          </MenuItem>
        ))}
      </Menu> */}
      {loading && <CustomCircularProgress />}
      <Grid container spacing={2}>
        {!loading &&
          data &&
          data.listAvailableGuides.map((guide) => (
            <Grid key={guide._id} item xs={4}>
              <div className="each-guide">
                <h2>{upperFirst(guide.title)}</h2>
                <p>{upperFirst(guide.description)}</p>
                {guide.milestoneImages.length > 0 ? (
                  <ul>
                    {guide.milestoneImages.map((image) => (
                      <li>
                        <img src={image} alt="badge" />
                      </li>
                    ))}
                  </ul>
                ) : (
                  <span
                    style={{ display: 'block', marginTop: '63px', color: 'red', fontSize: '14px' }}>
                    No Badges added
                  </span>
                )}
                <div className="guide-bottom">
                  <Button
                    // disabled={guide.milestoneImages.length === 0}
                    className="assign-guide-btn"
                    aria-label="Press enter to add to my guides"
                    onClick={() => handleAssign(guide._id)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleAssign(guide._id);
                      }
                    }}>
                    <AddCircleOutlineIcon /> Add to My Guides
                  </Button>
                </div>
              </div>
            </Grid>
          ))}
        {!loading && data && data.listAvailableGuides.length < 1 && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card
                sx={{
                  minHeight: '300px',
                  padding: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid
                      sx={{
                        paddingBottom: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: 800,
                          color: '#000000',
                          marginBottom: '20px',
                        }}>
                        No Guides Available
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default AvailableGuides;
