import { useReactiveVar } from '@apollo/client';
// import { DatePicker } from '@material-ui/pickers';
import CancelIcon from '@mui/icons-material/Cancel';
import { Avatar, Badge, Button, FormHelperText, Grid, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Drawer from '@mui/material/Drawer';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import imageCompression from 'browser-image-compression';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import isEmail from 'validator/es/lib/isEmail';
import Checked from '../../Assets/Images/Checked.svg';
import Unchecked from '../../Assets/Images/Unchecked.svg';
import learnerAction from '../../Helper/Analytics/learnerAction';
import screenNames from '../../Helper/Analytics/screenNames';
import { trackEvent } from '../../Helper/EventTracker';
import { generateSnackbarPayload } from '../../Helper/index.js';
import theme from '../../MaterialUiTheme';
import { updateUserDetails } from '../../Store/Actions/Auth';
import { toggleModal } from '../../Store/Actions/Global';
import { getAllHobbies } from '../../Store/Actions/Profile';
import * as types from '../../Store/actionTypes';
import { getHobbies, getModalState, getUserDetails } from '../../Store/reduxSelectors';
import ReactCropper from '../Common/croper';
import { cropperModalVar } from '../Common/reactiveVar';
import awsFileUpload from '../services/awsFileUpload.js';
import getLoggedUserDetails from '../services/getLoggedUserDetails';
import updateLanguage from '../services/patchLanguage';
import updateProfileDetails from '../services/patchUserDetails.js';
const EditProfile = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllHobbies(() => {}));
  }, []);
  const cropperModal = useReactiveVar(cropperModalVar);

  const userDetails = useSelector((state) => getUserDetails(state));
  const open = useSelector((state) => getModalState(state));
  const hobbies = useSelector((state) => getHobbies(state));

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState([]);
  const [username, setUsername] = React.useState('');
  const [pronouns, setPronouns] = React.useState();
  const [preferredName, setPreferredName] = React.useState();
  const [language, setLanguage] = React.useState('');
  const [currentCity, setCurrentCity] = React.useState();
  const [homeTown, setHomeTown] = React.useState();
  const [bio, setBio] = React.useState();
  const [phoneNumber, setPhoneNumber] = React.useState();
  const [countryCode, setCountryCode] = React.useState();
  const [profileImagerror, setProfileImagerror] = React.useState(false);
  const [email, setEmail] = React.useState();
  const [aboutUs, setAboutUs] = React.useState();
  const [strengths, setStrengths] = React.useState();
  const [dateOfBirth, handleDateChange] = React.useState();
  const [zipCode, setZipCode] = React.useState();
  const [raceEthnicity, setRaceEthnicity] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [allHobbies, setAllHobbies] = React.useState({});
  const [selectedHobbies, setSelectedHobbies] = React.useState([]);
  const [error, setError] = React.useState({
    username: '',
    countryCode: '',
    phoneNumber: '',
  });
  const [school, setSchool] = React.useState(false);
  const [college, setCollege] = React.useState(false);
  const [grade, setGrade] = React.useState('');
  const [yes, setYes] = React.useState(false);
  const [no, setNo] = React.useState(false);
  const [noToStay, setNoToSay] = React.useState(false);
  const [feedback, setFeedback] = React.useState('');

  useEffect(() => {
    let hobbiesObj = {};
    hobbies.forEach((hobby) => {
      hobbiesObj[hobby._id] = hobby;
    });
    setAllHobbies({ ...hobbiesObj });
  }, [hobbies]);

  useEffect(() => {
    if (userDetails) {
      setSelectedHobbies(userDetails.hobbyIds);
      setFeedback(userDetails?.feedback || '');
    }
  }, [userDetails]);

  // document.title = 'MentorHub - Profile';

  useEffect(() => {
    setFirstName(props.loggedData ? props.loggedData.data.firstName : '');
    setLastName(props.loggedData ? props.loggedData.data.lastName : '');
    setUsername(props.loggedData ? props.loggedData.data.username : '');
    setPronouns(props.loggedData ? props.loggedData.data.pronoun : '');
    setPhoneNumber(props.loggedData ? props.loggedData.data.phoneNumber : '');
    setCountryCode(props.loggedData ? props.loggedData.data.countryCode : '');
    setEmail(props.loggedData ? props.loggedData.data.email : '');
    setPreferredName(props.loggedData ? props.loggedData.data.preferredName : '');
    setCurrentCity(props.loggedData ? props.loggedData.data.currentCity : '');
    setHomeTown(props.loggedData ? props.loggedData.data.homeTown : '');
    setBio(props.loggedData ? props.loggedData.data.bio : '');
    setAboutUs(props.loggedData ? props.loggedData.data.aboutUs : '');
    setLanguage(props.loggedData ? props.loggedData.data.lang : '');
    handleDateChange(
      props?.loggedData?.data?.dateOfBirth
        ? moment(props.loggedData.data.dateOfBirth).format('YYYY-MM-DD')
        : null,
    );
    setZipCode(props.loggedData ? props.loggedData.data.zipCode : '');
    setRaceEthnicity(props.loggedData ? props.loggedData.data.raceEthnicity : '');
    setCollege(
      props.loggedData && props.loggedData.data?.schoolDetails?.schoolType === 'college'
        ? true
        : false,
    );
    setSchool(
      props.loggedData && props.loggedData.data?.schoolDetails?.schoolType === 'K-12'
        ? true
        : false,
    );
    setGrade(
      props.loggedData && props.loggedData.data?.schoolDetails?.grade
        ? props.loggedData.data?.schoolDetails?.grade
        : '',
    );
    setLoading(false);
    cropperModalVar({
      ...cropperModal,
      croppedImage: props.loggedData ? props.loggedData.data.profileImage : '',
    });
    setYes(
      props.loggedData && props?.loggedData?.data?.schoolDetails?.attendSchool === true
        ? true
        : false,
    );
    setNo(
      props.loggedData && props?.loggedData?.data?.schoolDetails?.attendSchool === false
        ? true
        : false,
    );
    setNoToSay(
      props.loggedData && props?.loggedData?.data?.schoolDetails?.schoolType === 'prefer not to say'
        ? true
        : false,
    );
  }, [props, open]);

  const handlePhoneNumber = (e) => {
    const fromattedPhoneNumber = getPhoneNumberString(e);
    const errorObj = { ...error };
    if (errorObj.phoneNumber && fromattedPhoneNumber.length >= 7)
      setError({ ...error, phoneNumber: '' });
    setPhoneNumber(e);
  };
  const handleCountryCode = (e) => {
    const errorObj = { ...error };
    const code = e.replace('+', '').trim();
    if (errorObj.countryCode && code.length >= 1) setError({ ...error, countryCode: '' });
    setCountryCode(e);
  };

  const handleEmail = (e) => {
    if (!isEmail(email) && isEmail(e)) setError({ ...error, email: '' });
    setEmail(e);
  };

  const handleChangeHobbies = (hobbies) => {
    const hobbyIds = hobbies.map((hobby) => hobby._id);
    setSelectedHobbies(hobbyIds);
  };

  const clearImage = () => {
    cropperModalVar({ ...cropperModal, croppedImage: '', imgURL: '', croppedFile: '' });
  };

  const getOrganImagePreview = (file) => {
    var reader = new FileReader();
    reader.onload = () => {
      cropperModalVar({ ...cropperModal, showModal: true, imgURL: reader.result });
      setProfileImagerror(false);
    };
    reader.readAsDataURL(file);
  };

  const getPhoneNumberString = (phoneNumber) => {
    return phoneNumber.split('-').join('').trim();
  };

  async function profileUpdate() {
    trackEvent('submit', {
      screen: screenNames.MenteeProfile,
      Action: learnerAction.SAVE_PROFILE_CHANGES,
    });
    const numberOfWords = username.trim().split(' ').length;
    const fromattedCountryCode = countryCode.replace('+', '');
    const fromattedPhoneNumber = getPhoneNumberString(phoneNumber);
    const errorObj = {
      username: '',
      countryCode: '',
      phoneNumber: '',
      email: '',
    };

    if (numberOfWords > 1) errorObj.username = 'The username cannot contain spaces';
    if (phoneNumber.length && !fromattedCountryCode.length)
      errorObj.countryCode = 'Country code is required';
    if (fromattedPhoneNumber.length && fromattedPhoneNumber.length < 7)
      errorObj.phoneNumber = 'Phone number should be at least 7 digits';
    if (email.length && isEmail(email) === false) errorObj.email = 'Email is Not Valid';

    setError({ ...errorObj });

    const checkEmail = email ? isEmail(email) : true;
    const checkPhone = fromattedPhoneNumber.length
      ? fromattedPhoneNumber.length >= 7
        ? fromattedCountryCode.length
          ? true
          : false
        : false
      : true;

    const errorsLength = Object.values(errorObj).reduce((acc, curr) => {
      return (acc?.length || 0) + (curr?.length || 0);
    }, 0);

    if (
      firstName.trim() !== '' &&
      checkEmail === true &&
      numberOfWords === 1 &&
      checkPhone === true &&
      !errorsLength
    ) {
      setLoading(true);
      let proImg = cropperModal.croppedImage;
      if (cropperModal.croppedFile !== '') {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };
        try {
          const compressedFile = await imageCompression(cropperModal.croppedFile, options);
          const res = await awsFileUpload(compressedFile, 'profileImage/mentee');
          proImg = res.publicUrl;
        } catch (error) {
          console.error('error error', error);
          dispatch(toggleModal());
        }
      }
      profileEditUpdate(proImg);
    }
  }

  const profileEditUpdate = (profileImage) => {
    let data = {
      firstName: firstName,
      lastName: lastName,
      preferredName: preferredName,
      username: username.trim(),
      currentCity: currentCity,
      homeTown: homeTown,
      bio: bio,
      countryCode: getPhoneNumberString(phoneNumber).length ? countryCode.trim() : '',
      phoneNumber: getPhoneNumberString(phoneNumber),
      email: email,
      aboutUs: aboutUs,
      pronoun: pronouns,
      profileImage: profileImage,
      hobbyIds: selectedHobbies,
      lang: language,
      strengths: strengths,
      dateOfBirth: dateOfBirth || undefined,
      zipCode: zipCode,
      raceEthnicity: raceEthnicity,
      schoolDetails: {
        attendSchool: yes === true ? true : false,
        schoolType:
          school === true
            ? 'K-12'
            : college === true
            ? 'College/Post Secondary Education'
            : 'Prefer not to say',
        grade: yes === false ? '' : grade,
      },
      feedback: feedback.trim(),
    };
    updateProfileDetails(userDetails.id, data)
      .then((response) => {
        if (props.loggedData.data.lang !== language) {
          updateLanguage(userDetails.id, { lang: language, dateLocale: 'en-us' });
        }
        getLoggedUserDetails(userDetails.id).then((res) => {
          props.setLoggedData(res);
          dispatch(
            updateUserDetails({
              countryCode,
              phoneNumber,
              email,
              username,
              firstName,
              lastName,
              profileImage,
              hobbyIds: selectedHobbies,
              hobbys: selectedHobbies?.map((hobbyId) => allHobbies[hobbyId]),
              aboutUs,
              feedback: feedback.trim(),
            }),
          );
        });
        dispatch({
          type: types.TOGGLE_SNACKBAR,
          payload: generateSnackbarPayload('success', 'Profile successfully updated.'),
        });
        clearImage();
        dispatch(toggleModal());
      })
      .catch((error) => {
        dispatch({
          type: types.TOGGLE_SNACKBAR,
          payload: generateSnackbarPayload('error', error.response.data.error.message),
        });
        dispatch(toggleModal());
      });
  };

  const handleLevel = () => {
    setSchool(!school);
    if (college) {
      setCollege(!college);
    }
    if (noToStay) {
      setNoToSay(!noToStay);
    }
  };

  const handleCollege = () => {
    setCollege(!college);
    if (school) {
      setSchool(!school);
    }
    if (noToStay) {
      setNoToSay(!noToStay);
    }
  };

  const PreferNo = () => {
    setNoToSay(!noToStay);
    if (school) {
      setSchool(!school);
    }
    if (college) {
      setCollege(!college);
    }
  };

  const handleChange = (event) => {
    setGrade(event.target.value);
  };

  const handleYes = () => {
    setYes(!yes);
    // setNo((prev) => (prev ? false : false));
    setNo(false);
    // if (no) {
    //   setNo(!no);
    // }
  };

  const handleNo = () => {
    setNo(!no);
    // setYes((prev) => (prev ? false : false));
    setYes(false);
    // if (yes) {
    //   setYes(!yes);
    // }
    // setYes(false);
  };

  const resetState = () => {
    dispatch(toggleModal());
    setPhoneNumber('');
    setUsername('');
    setSelectedHobbies([]);
    setError('');
  };

  const GRADE = [
    'Kindergarten',
    '1st Grade',
    '2nd Grade',
    '3rd Grade',
    '4th Grade',
    '5th Grade',
    '6th Grade',
    '7th Grade',
    '8th Grade',
    '9th Grade',
    '10th Grade',
    '11th Grade',
    '12th Grade',
  ];

  return (
    <div className="profile-edit-wrap">
      {props && (
        <>
          <Drawer
            style={{ heigh: '100vh' }}
            open={open}
            anchor={'right'}
            className="profile-edit"
            onClose={() => dispatch(toggleModal())}>
            <div className="profile-edit-inner">
              <div className="profile-head">
                <h1>Profile </h1>
              </div>
              <div className="profile-common">
                {props.loggedData && cropperModal.croppedImage == '' ? (
                  <div className="thumb-image">
                    <Avatar
                      alt="profileImage"
                      src={cropperModal.croppedImage}
                      onClick={() => document.getElementById('new-image-upload').click()}
                      style={{ cursor: 'pointer' }}>
                      {props.loggedData && props.loggedData.data.firstName.charAt(0).toUpperCase()}
                    </Avatar>
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      id="new-image-upload"
                      onChange={(e) => {
                        getOrganImagePreview(e.target.files[0]);
                      }}
                    />
                    <p
                      onClick={() => document.getElementById('new-image-upload').click()}
                      style={{
                        cursor: 'pointer',
                        fontSize: '17px',
                        fontWeight: '600',
                      }}>
                      Choose Image
                    </p>
                    {/* {userDetails && (
                      <span
                        style={{
                          display: 'block',
                          fontSize: '12px',
                          color: '#3E3C37',
                          marginTop: 10,
                        }}>
                        Joined on {moment(userDetails.firstLoggedInDate).format('MMMM DD, YYYY ')}
                      </span>
                    )} */}
                  </div>
                ) : (
                  <Badge
                    overlap="circular"
                    badgeContent={
                      <div onClick={() => clearImage()} style={{ cursor: 'pointer' }}>
                        <CancelIcon />
                      </div>
                    }>
                    <div className="thumb-image" style={{ objectFit: 'cover' }}>
                      <Avatar
                        alt="profileImage"
                        src={cropperModal.croppedImage}
                        onClick={() => document.getElementById('edit-image-upload').click()}
                        style={{ cursor: 'pointer' }}></Avatar>
                      <input
                        type="file"
                        style={{ display: 'none' }}
                        id="edit-image-upload"
                        onChange={(e) => {
                          getOrganImagePreview(e.target.files[0]);
                        }}
                      />
                      {profileImagerror === true && (
                        <span
                          style={{
                            color: theme.palette.primary.main,
                            fontSize: '10px',
                            display: 'block',
                          }}>
                          Uploaded image was too large
                        </span>
                      )}

                      <p
                        onClick={() => {
                          clearImage();
                        }}
                        style={{
                          cursor: 'pointer',
                          fontSize: '17px',
                          fontWeight: '600',
                        }}>
                        Remove Image
                      </p>
                      {/* {userDetails && (
                        <span
                          style={{
                            display: 'block',
                            fontSize: '12px',
                            color: '#3E3C37',
                            marginTop: 10,
                          }}>
                          Joined on {moment(userDetails.firstLoggedInDate).format('MMMM DD, YYYY ')}
                        </span>
                      )} */}
                    </div>
                  </Badge>
                )}
                <div className="profile-data">
                  {/* <img src={profileImagePreview}/>*/}
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <h2>Account Info</h2>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        placeholder="First Name"
                        id="name"
                        label="First Name"
                        defaultValue={props.loggedData ? props.loggedData.data.firstName : ''}
                        variant="outlined"
                        onChange={(event) => setFirstName(event.target.value)}
                        helperText={firstName.trim() === '' ? 'First Name is a required field' : ''}
                        error={firstName.trim() === '' ? true : false}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        placeholder="Last Name"
                        id="name"
                        label="Last Name"
                        variant="outlined"
                        defaultValue={props.loggedData ? props.loggedData.data.lastName : ''}
                        onChange={(event) => setLastName(event.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          <FormControl>
                            <NumberFormat
                              label={'Country'}
                              value={countryCode}
                              format="+####"
                              sx={{
                                '& .MuiFormLabel-root': {
                                  maxWidth: 'calc(100% - 19px)',
                                },
                              }}
                              fullWidth={true}
                              variant="outlined"
                              onChange={(e) => handleCountryCode(e.target.value)}
                              customInput={TextField}
                              error={error.countryCode ? true : false}
                            />
                            {error.countryCode && (
                              <FormHelperText
                                id="my-helper-text"
                                error={error.countryCode ? true : false}>
                                {error.countryCode}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={8}>
                          <FormControl>
                            <NumberFormat
                              label={'Phone Number'}
                              fullWidth={true}
                              variant="outlined"
                              value={phoneNumber}
                              onChange={(event) => handlePhoneNumber(event.target.value)}
                              customInput={TextField}
                              format="###-###-####"
                              error={error.phoneNumber ? true : false}
                            />
                            {error.phoneNumber && (
                              <FormHelperText
                                id="my-helper-text"
                                error={error.phoneNumber ? true : false}>
                                {error.phoneNumber}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        placeholder=""
                        label="Email"
                        variant="outlined"
                        defaultValue={props.loggedData ? props.loggedData.data.email : ''}
                        onChange={(event) => handleEmail(event.target.value)}
                        helperText={error.email ? error.email : ''}
                        error={error.email ? true : false}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        aria-label="Username"
                        label="Username"
                        variant="outlined"
                        value={username}
                        onChange={(event) => setUsername(event.target.value)}
                        helperText={error.username}
                        error={error.username ? true : false}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        select
                        aria-label="Language"
                        label="Language"
                        variant="outlined"
                        disabled
                        value={language}
                        onChange={(e) => setLanguage(e.target.value)}>
                        <MenuItem key={'eng'} value={'eng'}>
                          {'English'}
                        </MenuItem>
                        <MenuItem key={'spa'} value={'spa'}>
                          {'Spanish'}
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <h2>Personal Info</h2>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        type="text"
                        inputProps={{ maxLength: 60 }}
                        placeholder="Preffered Name"
                        label="Preferred Name"
                        variant="outlined"
                        defaultValue={props.loggedData ? props.loggedData.data.preferredName : ''}
                        onChange={(event) => setPreferredName(event.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl variant="outlined">
                        <InputLabel id="pronouns-label">Pronouns</InputLabel>
                        <Select
                          labelId="pronouns-label"
                          defaultValue={props.loggedData ? props.loggedData.data.pronoun : ''}
                          onChange={(event) => setPronouns(event.target.value)}
                          label="Pronouns">
                          <MenuItem value="They/Them/Theirs">They/Them/Theirs</MenuItem>
                          <MenuItem value="She/Her/Hers">She/Her/Hers</MenuItem>
                          <MenuItem value="He/Him/His">He/Him/His</MenuItem>
                          <MenuItem value="My pronoun isn't listed">
                            My pronoun isn't listed{' '}
                          </MenuItem>
                          <MenuItem value="Prefer not to say"> Prefer not to say</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                       
                        <DatePicker
                          // defaultValue={null}
                          style={{ marginTop: '35px' }}
                          // openTo="year"
                          views={['year', 'month']}
                          inputVariant="outlined"
                          fullWidth
                          disableFuture
                          value={dateOfBirth || null}
                          onChange={handleDateChange}
                          format="MM/yyyy"
                          label="Birth Month & Year"
                          InputLabelProps={{
                            shrink: dateOfBirth ? true : false,
                          }}
                        />
                      </MuiPickersUtilsProvider> */}
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          // defaultValue={null}
                          style={{ marginTop: '35px' }}
                          // openTo="year"
                          disableFuture
                          views={['year', 'month']}
                          label="Year and Month"
                          value={dayjs(dateOfBirth) || null}
                          onChange={(newValue) => {
                            handleDateChange(newValue);
                          }}
                          inputFormat="MM/YYYY"
                          format="MM/YYYY"
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      {/* 
                      <div className="dob-picker">
                        <TextField
                          fullWidth
                          id="date"
                          type="date"
                          defaultValue={dateOfBirth}
                          onChange={(e) => setDateOfBirth(e.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder="Date/Month"
                        />
                      </div> */}
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        placeholder="Hometown"
                        label="Hometown"
                        id="home-town"
                        variant="outlined"
                        defaultValue={props.loggedData ? props.loggedData.data.homeTown : ''}
                        onChange={(event) => setHomeTown(event.target.value)}
                      />
                    </Grid>
                    {props && (
                      <Grid item xs={12}>
                        <Autocomplete
                          multiple
                          id="Hobbies"
                          options={Object.values(allHobbies)}
                          value={
                            selectedHobbies
                              ? selectedHobbies.map((hobbyId) => allHobbies[hobbyId])
                              : []
                          }
                          getOptionLabel={(option) => option.title}
                          onChange={(event, value) => handleChangeHobbies(value)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="filled"
                              label={'Hobbies'}
                              fullWidth={true}
                            />
                          )}
                          renderTags={(value, getTagProps) => {
                            return value.map((hobby, index) => (
                              <Chip
                                variant="outlined"
                                label={hobby.title}
                                {...getTagProps({ index })}
                              />
                            ));
                          }}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <TextField
                        placeholder="Zip Code"
                        label="Zip Code"
                        variant="outlined"
                        defaultValue={props.loggedData ? props.loggedData.data.zipCode : ''}
                        onChange={(event) => setZipCode(event.target.value)}
                        inputProps={{ maxLength: 6 }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className="user-bio"
                        multiline
                        rows={4}
                        label="Bio"
                        id="user-bio"
                        inputProps={{ maxLength: 400 }}
                        variant="outlined"
                        defaultValue={props.loggedData ? props.loggedData.data.aboutUs : ''}
                        onChange={(event) => {
                          setAboutUs(event.target.value.trim());
                        }}
                      />
                      {props.loggedData && aboutUs && (
                        <span className="chara-count">{aboutUs && aboutUs.length}/400</span>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className="user-bio"
                        label="Future Field of Interest"
                        id="user-bio"
                        inputProps={{ maxLength: 400 }}
                        variant="outlined"
                        defaultValue={props.loggedData ? props.loggedData.data.strengths : ''}
                        onChange={(event) => {
                          setStrengths(event.target.value);
                        }}
                      />
                      {props.loggedData && strengths && (
                        <span className="chara-count">{strengths.length}/400</span>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl variant="outlined">
                        <InputLabel>Racial/Ethnic Identity</InputLabel>
                        <Select
                          labelId="Racial/Ethnic Identity"
                          defaultValue={props.loggedData ? props.loggedData.data.raceEthnicity : ''}
                          onChange={(event) => setRaceEthnicity(event.target.value)}
                          label="Pronouns">
                          <MenuItem value="American Indian or Alaska Native">
                            American Indian or Alaska Native
                          </MenuItem>
                          <MenuItem value="Black or African American">
                            Black or African American
                          </MenuItem>
                          <MenuItem value="Hispanic or Latino">Hispanic or Latino</MenuItem>
                          <MenuItem value="Native Hawaiian or Other Pacific Islander">
                            Native Hawaiian or Other Pacific Islander{' '}
                          </MenuItem>
                          <MenuItem value="White Non-Hispanic or Latino">
                            White Non-Hispanic or Latino
                          </MenuItem>
                          <MenuItem value="Asian">Asian </MenuItem>
                          <MenuItem value="Multi-racial">Multi-racial</MenuItem>
                          <MenuItem value="Prefer not to say">Prefer not to say</MenuItem>
                          <MenuItem value="Other">Other</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <h2>School</h2>
                      <div style={{ display: 'flex', marginTop: '22px' }}>
                        <div>
                          <Checkbox
                            icon={<img src={Unchecked} />}
                            checkedIcon={<img src={Checked} />}
                            checked={yes}
                            onChange={handleYes}
                            sx={{ padding: '7px !important' }}
                          />
                          <span>Yes</span>
                        </div>
                        <div>
                          <Checkbox
                            icon={<img src={Unchecked} />}
                            checkedIcon={<img src={Checked} />}
                            checked={no}
                            onChange={handleNo}
                            sx={{ padding: '7px !important' }}
                          />
                          <span>No</span>
                        </div>
                      </div>
                      {yes === true && no === false && (
                        <>
                          <div style={{ display: 'flex', marginBottom: '10px' }}>
                            <div>
                              <Checkbox
                                icon={<img src={Unchecked} />}
                                checkedIcon={<img src={Checked} />}
                                onChange={handleLevel}
                                checked={school}
                                sx={{ padding: '7px !important' }}
                              />
                              <span>K-12</span>
                            </div>
                            <div>
                              <Checkbox
                                icon={<img src={Unchecked} />}
                                checkedIcon={<img src={Checked} />}
                                onChange={handleCollege}
                                checked={college}
                                sx={{ padding: '7px !important' }}
                              />
                              <span>College/Post Secondary Education</span>
                            </div>
                            <div>
                              <Checkbox
                                icon={<img src={Unchecked} />}
                                checkedIcon={<img src={Checked} />}
                                onChange={PreferNo}
                                checked={noToStay}
                                sx={{ padding: '7px !important' }}
                              />
                              <span>Prefer Not To Say</span>
                            </div>
                          </div>
                          {school === true && (
                            <div className="select-grade">
                              <FormControl variant="outlined">
                                <InputLabel id="demo-simple-select-label">Grade</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={grade}
                                  onChange={handleChange}>
                                  {GRADE.map((item) => {
                                    return (
                                      <MenuItem key={item} value={item}>
                                        {item}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </div>
                          )}
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <h2>Advice</h2>
                      <TextField
                        className="user-bio"
                        multiline
                        minRows={4}
                        label="Advice"
                        id="user-advice"
                        inputProps={{ maxLength: 400 }}
                        variant="outlined"
                        value={feedback || ''}
                        onChange={(event) => {
                          setFeedback(event.target.value);
                        }}
                      />
                      {feedback && Boolean(feedback.length) && (
                        <span className="chara-count">{feedback.length}/400</span>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </div>
              <Grid container spacing={2} style={{ padding: '20px 40px' }}>
                <Grid item xs={6} md={6}>
                  <Button
                    tabIndex={0}
                    fullWidth
                    disableRipple
                    onClick={() => {
                      resetState();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        resetState();
                      }
                    }}
                    className="custom-button"
                    variant="outlined"
                    color="primary">
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Button
                    aria-label="Profile update Button"
                    fullWidth
                    tabIndex={0}
                    disableRipple
                    onClick={() => profileUpdate()}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        profileUpdate();
                      }
                    }}
                    disabled={loading}
                    className="custom-button"
                    variant="contained"
                    color="primary">
                    {loading == true ? 'Saving...' : 'Save'}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Drawer>
          <ReactCropper />
        </>
      )}
    </div>
  );
};
export default EditProfile;
