import MicNoneIcon from '@mui/icons-material/MicNone';
import MicOffIcon from '@mui/icons-material/MicOff';
import { IconButton } from '@mui/material';
import { startCase } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserDetails } from '../../../Store/reduxSelectors';
import NoVideo from './NoVideo';

export const Participant = ({
  participant,
  participantDetails,
  setSelectedParticipant,
  selectedParticipant,
  screenTrack,
  videoTrack,
  fromSelected,
  room,
  showParticipants,
}) => {
  const user = useSelector((state) => getUserDetails(state));
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const videoRef = useRef();
  const [videoEnabled, setVideoEnabled] = useState(true);
  const [audioEnabled, setAudioEnabled] = useState(true);
  const audioRef = useRef();
  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);
  let audio = false;
  let video = false;
  participant.audioTracks.forEach((track) => {
    audio = track.isTrackEnabled;
  });
  participant.videoTracks.forEach((track) => {
    if (track.trackName !== 'screen') video = track.isTrackEnabled;
  });
  useEffect(() => {
    setAudioEnabled(audio);
  }, [audio]);
  useEffect(() => {
    setVideoEnabled(video);
  }, [video]);

  useEffect(() => {
    const trackSubscribed = (track) => {
      if (track.kind === 'video') {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackDisabled = (track) => {
      let videoTracksCount;
      setVideoTracks((videoTracks) => {
        videoTracksCount = videoTracks.length;
        return videoTracks;
      });
      if (track.kind === 'video' && videoTracksCount === 1) {
        setVideoEnabled(false);
      } else if (track.kind === 'audio') {
        setAudioEnabled(false);
      }
    };

    const trackEnabled = (track) => {
      if (track.kind === 'video') {
        setVideoEnabled(true);
      } else if (track.kind === 'audio') {
        setAudioEnabled(true);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === 'video') {
        setVideoEnabled(false);
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else {
        setAudioEnabled(false);
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);
    participant.on('trackDisabled', trackDisabled);
    participant.on('trackEnabled', trackEnabled);

    return () => {
      setAudioTracks([]);
      setVideoTracks([]);
      // !fromSelected && participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    let video;
    if (videoTracks.length) {
      videoTracks.forEach((track) => {
        video = track.isEnabled;
      });
      setVideoEnabled(video);
    }
  }, [videoTracks]);

  useEffect(() => {
    const screenTrack = videoTracks.find(
      (videoTrack) => videoTrack && !videoTrack.isStopped && videoTrack.name === 'screen',
    );
    const videoTrack = videoTracks.find(
      (videoTrack) => videoTrack && !videoTrack.isStopped && videoTrack.name === 'camera',
    );
    const track = screenTrack || videoTrack;

    setTimeout(() => {
      track?.attach(videoRef.current);
    }, 100);

    return () => {
      !fromSelected &&
        videoTracks.forEach((videoTrack) => {
          if (videoTrack) {
            videoTrack.detach();
          }
        });
    };
  }, [videoTracks, videoEnabled, selectedParticipant, screenTrack, videoTrack, room, fromSelected]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack && audioTrack.kind != 'data') {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks, audioEnabled, selectedParticipant, screenTrack, videoTrack]);

  const handleClick = (participant) => {
    setSelectedParticipant(participant);
  };

  return (
    <div className="participant" onClick={() => handleClick(participant)}>
      {videoEnabled ? (
        <>
          {videoTracks[0] && (
            <div style={{ borderRadius: '10px', position: 'relative' }}>
              <div
                style={{
                  position: 'absolute',
                  right: '15px',
                  top: '15px',
                  borderRadius: '50%',
                  backgroundColor: '#292929',
                }}>
                <IconButton sx={{ padding: '5px' }}>
                  {audioEnabled ? (
                    <MicNoneIcon color="secondary" fontSize="small" />
                  ) : (
                    <MicOffIcon color="secondary" fontSize="small" />
                  )}
                </IconButton>
              </div>
              <video
                ref={videoRef}
                autoPlay={true}
                style={{ borderRadius: '10px' }}
                className={
                  selectedParticipant && selectedParticipant.identity === participant.identity
                    ? showParticipants
                      ? 'drawer_open active'
                      : 'drawer_closed active'
                    : showParticipants
                    ? 'drawer_open'
                    : 'drawer_closed'
                }
              />
              <span
                style={{
                  position: 'absolute',
                  left: '15px',
                  bottom: '15px',
                  fontSize: '14px',
                  color: '#fff',
                  textShadow: '2px 2px 8px #000',
                }}>
                {user?.id === participantDetails?._id
                  ? 'You'
                  : `${startCase(participantDetails.firstName)} ${startCase(
                      participantDetails.lastName,
                    )}`}{' '}
              </span>
            </div>
          )}
        </>
      ) : (
        <NoVideo user={participantDetails} audioEnabled={audioEnabled} currentUser={user} />
      )}
      <audio ref={audioRef} autoPlay={true} muted={false} />
    </div>
  );
};
