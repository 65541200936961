import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';
import { lessonGoalMenteeVar } from '../vars/lessonGoalMenteeVar';
import { GET_GOAL_PLAN_MENTEE } from './gql';
export const GetGoalPlanMentee = (menteeId) => {
  const [getGoalPlanQuery, { loading, data, error, refetch }] = useLazyQuery(GET_GOAL_PLAN_MENTEE, {
    fetchPolicy: 'network-only',
    context: { clientName: 'curriculum' },
  });

  useEffect(() => {
    getGoalPlanQuery({
      variables: {
        menteeId: menteeId,
      },
    });
  }, [menteeId]);
  useEffect(() => {
    if (!error && data && data.listGoal) lessonGoalMenteeVar(data.listGoal);
    else lessonGoalMenteeVar(null);
    console.error(error);
  }, [loading, data]);
  return {
    loading,
    data: useReactiveVar(lessonGoalMenteeVar),
    refetch,
  };
};
