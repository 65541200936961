import React from 'react';
import ContentLoader from 'react-content-loader';

const MyInstagramLoader = ({ height, width, rootStyles = {} }) => (
  <ContentLoader
    style={{
      width: width || '100%',
      borderRadius: 4,
      height: height || 300,
      ...rootStyles,
    }}>
    <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
  </ContentLoader>
);

export default MyInstagramLoader;
