import axios from '../Config';
import { generateSnackbarPayload } from '../Helper';
import * as types from '../Store/actionTypes';
import store from '../Store/Reducer';

const handleAxiosError = async (error) => {
  if (
    error?.response?.status === 401 &&
    !error?.response?.config?.url?.includes('loggedinApp') &&
    error?.response?.config?.url !== 'users/verifyOtp'
  ) {
    const refreshToken = localStorage.getItem('@refreshToken');
    if (refreshToken) {
      store.dispatch({
        type: types.GET_NEW_TOKEN,
      });
    } else {
      store.dispatch({
        type: 'TOGGLE_SNACKBAR',
        payload: generateSnackbarPayload(
          'warning',
          'Session has expired. Please login back to continue',
        ),
      });
      delete axios.defaults.headers.common['Authorization'];
      localStorage.clear();
      setTimeout(() => {
        window.location.href = '/login';
      }, 100);
    }
  } else {
    const response = error?.response;
    const data = response?.data;
    if (data?.error) throw data?.error;
    throw error;
  }
};

export default handleAxiosError;
