import { takeLatest, call, put, fork } from 'redux-saga/effects';
import * as api from '../Api/MoodRings';
import * as types from '../Store/actionTypes';

import logError, { generateSnackbarPayload } from '../Helper';
import getRoutes from '../Routes/RoutePaths';

const sagaPath = 'src/Sagas/Auth.js';

function* getProblemist(action) {
  const { payload, callback } = action;
  try {
    const response = yield call(api.getProblemist, payload);
    const data = response.data;

    let pieChartData = {};
    data.forEach((item) => {
      pieChartData[item.id] = { name: item.problemName, y: 0, color: item.color };
    });

    yield put({ type: types.PROBLEM_LIST_SUCCESS, data });
    yield put({ type: types.SET_MOODRING_LOCAL, payload: pieChartData });

    callback(true);
  } catch (e) {
    logError(e, sagaPath + 'getProblemist');
    callback();
  }
}
function* setMoodByMenteeId(action) {
  const { payload, callback } = action;
  try {
    const response = yield call(api.setMoodByMenteeId, payload);
    const data = response.data;
    if (data.message) {
      yield put({
        type: types.TOGGLE_SNACKBAR,
        payload: generateSnackbarPayload('success', data.message),
      });
    }
    callback();
  } catch (e) {
    yield put({
      type: types.TOGGLE_SNACKBAR,
      payload: generateSnackbarPayload('error', e.message || 'Failed'),
    });
    logError(e, sagaPath + 'setMoodByMenteeId');
    callback();
  }
}

function* watchMoodRingSagas() {
  yield takeLatest(types.PROBLEM_LIST_REQUEST, getProblemist);
  yield takeLatest(types.SET_MOOD_REQUEST, setMoodByMenteeId);
}

const moodRingSagas = [fork(watchMoodRingSagas)];

export default moodRingSagas;
