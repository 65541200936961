import { Button, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import prevArrow from '../../Assets/Images/Down-Arrow.svg';
import learnerAction from '../../Helper/Analytics/learnerAction';
import screenNames from '../../Helper/Analytics/screenNames';
import { trackEvent } from '../../Helper/EventTracker';
import getRoutes from '../../Routes/RoutePaths';
import { updateUserDetails } from '../../Store/Actions/Auth';
import { getUserDetails } from '../../Store/reduxSelectors';
import getLoggedUserDetails from '../services/getLoggedUserDetails';
import updateProfileDetails from '../services/patchUserDetails.js';
import { ftueScreen } from './../../Api/Profile';
import BlackLogo from './../../Assets/Images/Blacklogo.svg';
import Mentorhublogo from './../../Assets/Images/Mentorhublogo.svg';
import welcomeLogo from './../../Assets/Images/welcomeLogo.svg';
import { FtueFive } from './FtueFive';
import { FtueFour } from './FtueFour';
import { FtueSeven } from './FtueSeven';
import { FtueSix } from './FtueSix';
import FtueThree from './FtueThree';
import FtueTwo from './FtueTwo';
import { FtueOne } from './index';

export const FtueSteps = () => {
  const [step, setStep] = useState(0);
  const history = useHistory();
  const currentUser = useSelector((state) => getUserDetails(state));
  const dispatch = useDispatch();
  const [showSteps, setShowSteps] = useState(false);
  const userDetails = useSelector((state) => getUserDetails(state));
  const [loggedData, setLoggedData] = React.useState({ data: currentUser });
  const orgLogo = localStorage.getItem('organizationLogo');
  const setPage = () => {
    setShowSteps(true);
  };

  useEffect(() => {
    setPage();
    getLoggedUserDetails(userDetails.id).then((res) => {
      setLoggedData(res);
    });
  }, []);

  const stepUpdate = (e) => {
    if (e === 'next') {
      step === 1 && updateProfileDetails(userDetails.id, { ftueProfileSetup: false });

      setStep(step + 1);
    } else {
      setStep(step - 1);
    }
  };

  const finalSkip = () => {
    ftueScreen(currentUser.id, false);
    dispatch(
      updateUserDetails({
        FtueShowMSS: false,
      }),
    );
    history.push(getRoutes.askForAssessment);
  };

  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <div className="ftueHeader" style={{ padding: orgLogo && '12px 0px 15px 20px' }}>
        <img src={orgLogo ? orgLogo : Mentorhublogo} style={{ height: orgLogo && '49px' }} />
        <span style={{ color: 'white', marginRight: '20px' }}>
          Welcome, {currentUser.firstName}
        </span>
      </div>
      <div className="welcomeContainer">
        <Grid item xs={4} style={{ backgroundColor: '#f8f8f8' }}>
          <div style={{ padding: '122px 50px 50px 50px' }}>
            <img src={orgLogo ? orgLogo : BlackLogo} style={{ height: orgLogo && '80px' }} />
            <h2>Welcome to MentorHub!</h2>
            {step === 0 ? ` Tap next to start your journey with the Program Contact!` : ''}
          </div>
          <img src={welcomeLogo} />
        </Grid>
        <Grid item xs={8} style={{ backgroundColor: 'white' }}>
          <div className="steps-col">
            <ul className="step-indicator" tabIndex="0">
              <li className={step >= 0 ? 'active' : ''}></li>
              <li className={step >= 1 ? 'active' : ''}></li>
              <li className={step >= 2 ? 'active' : ''}></li>
              <li className={step >= 3 ? 'active' : ''}></li>
              <li className={step >= 4 ? 'active' : ''}></li>
              <li className={step >= 5 ? 'active' : ''}></li>
              <li className={step >= 6 ? 'active' : ''}></li>
            </ul>
            {step == 0 && (
              <FtueOne
                userDetails={userDetails}
                stepUpdate={stepUpdate}
                loggedData={loggedData}
                setLoggedData={setLoggedData}
              />
            )}
            {step == 1 && (
              <FtueTwo
                userDetails={userDetails}
                stepUpdate={stepUpdate}
                loggedData={loggedData}
                setLoggedData={setLoggedData}
              />
            )}
            {step == 2 && (
              <FtueThree
                loggedData={loggedData}
                userDetails={userDetails}
                setLoggedData={setLoggedData}
                stepUpdate={stepUpdate}
              />
            )}
            {step == 3 && (
              <FtueFour
                loggedData={loggedData}
                userDetails={userDetails}
                setLoggedData={setLoggedData}
                stepUpdate={stepUpdate}
              />
            )}
            {step == 4 && (
              <FtueFive
                loggedData={loggedData}
                userDetails={userDetails}
                setLoggedData={setLoggedData}
                stepUpdate={stepUpdate}
              />
            )}
            {step == 5 && (
              <FtueSix
                loggedData={loggedData}
                userDetails={userDetails}
                setLoggedData={setLoggedData}
                stepUpdate={stepUpdate}
              />
            )}
            {step == 6 && (
              <FtueSeven
                loggedData={loggedData}
                userDetails={userDetails}
                setLoggedData={setLoggedData}
                stepUpdate={stepUpdate}
              />
            )}

            <div className="step-control">
              {step > 0 && (
                <Button
                  style={{
                    width: '115px',
                    backgroundColor: '#e0e0e0',
                    textTransform: 'capitalize',
                    fontFamily: 'Lato',
                    fontWeight: 'bold',
                  }}
                  onClick={() => {
                    stepUpdate('prev');
                    trackEvent('click', {
                      screen: screenNames.FtueWelcome,
                      Action: learnerAction.PREVIOUS_FTUE,
                    });
                  }}
                  aria-label="Previous"
                  variant="contained">
                  <img src={prevArrow} alt="prevArrow" />
                  Previous
                </Button>
              )}
              {step == 0 && <span></span>}
              {step < 6 && step > 0 && (
                <Button
                  style={{ textTransform: 'capitalize', fontFamily: 'Lato', fontWeight: 'bold' }}
                  onClick={() => {
                    stepUpdate('next');
                    trackEvent('click', {
                      screen: screenNames.FtueWelcome,
                      Action: learnerAction.SKIP_FTUE,
                    });
                  }}
                  aria-label="skip">
                  Skip
                </Button>
              )}
              {step == 6 && (
                <Button
                  style={{ textTransform: 'capitalize', fontFamily: 'Lato', fontWeight: 'bold' }}
                  onClick={() => {
                    finalSkip();
                    trackEvent('click', {
                      screen: screenNames.FtueWelcome,
                      Action: learnerAction.SKIP_FTUE,
                    });
                  }}
                  aria-label="skip">
                  Skip
                </Button>
              )}
            </div>
          </div>
        </Grid>
      </div>
    </div>
  );
};
