import { useMutation } from '@apollo/client';
import _ from 'lodash';
import { FAVOURITE_BADGE, GET_LESSON_PLAN_MENTEE } from './gql';

export const UpdateBadgeHook = (propsMain) => {
  const [favouriteBadge] = useMutation(FAVOURITE_BADGE, {
    context: { clientName: 'curriculum' },
  });

  const updateFavourite = async (props) => {
    try {
      await favouriteBadge({
        variables: {
          badgeId: props._id,
          favourite: !props.favourite,
        },
        update(cache, mutnRes) {
          const cacheObj = cache.readQuery({
            query: GET_LESSON_PLAN_MENTEE,
            variables: {
              menteeId: props.menteeId,
              curriculumId: props.curriculumId,
            },
          });
          const clone = _.cloneDeep(cacheObj);
          clone.listLessonPlanMentee.milestone[props.badgeIndex].favourite = !props.favourite;
          cache.writeQuery({
            query: GET_LESSON_PLAN_MENTEE,
            variables: {
              menteeId: props.menteeId,
              curriculumId: props.curriculumId,
            },
            data: clone,
          });
        },
      });
    } catch (error) {
      console.error(error);
    }
  };
  return { updateFavourite };
};
