 
import axios from 'axios';
import handleAxiosError from '../../Helper/AxiosErrorHanlder';

const multipleSsg = (userId) => {
  const token = localStorage.getItem('@authToken');

  return axios({
    method: 'POST',
    url: process.env.REACT_APP_BASE_URL + `/menteeDetails/${userId}` + '?access_token=' + token,
    data:{ 
      "fields":[
        "primaryMentor","secondaryMentors", "loginStreak", "messageStreak", "moodRingStreak"
        ]
      }
  }).catch((err) => handleAxiosError(err));
};
export default multipleSsg;


