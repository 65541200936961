import { takeLatest, call, put, fork } from 'redux-saga/effects';
import * as api from '../Api/Profile';
import * as types from '../Store/actionTypes';

import logError, { generateSnackbarPayload } from '../Helper';

const sagaPath = 'src/Sagas/Auth.js';

function* getAssessmentQuestions(action) {
  const { activeProgram, callback } = action;
  try {
    const response = yield call(api.getHobbies);
    const data = response.data;

    yield put({ type: types.HOBBIES_SUCCESS, data });
    callback(true);
  } catch (e) {
    logError(e, sagaPath + 'getHobbies');
    callback();
  }
}

function* watchProfileSaga() {
  yield takeLatest(types.HOBBIES_REQUEST, getAssessmentQuestions);
}

const profileSagas = [fork(watchProfileSaga)];

export default profileSagas;
