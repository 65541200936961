import * as types from '../actionTypes';

const initialState = {
  assessmentQuestions: [],
  isAssessmentQuestionsLoading: true,
};

const programs = (state = initialState, action) => {
  switch (action.type) {
    case types.ASSESSMENT_QUES_SUCCESS:
      return {
        ...state,
        assessmentQuestions: action.data,
        isAssessmentQuestionsLoading: false,
      };
    default:
      return state;
  }
};

export default programs;
