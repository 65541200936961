import * as types from '../actionTypes';

const initialState = {
  isUserLoggedIn: false,
  validatingUser: true,
  userDetails: {},
  firebaseConfig: {},
  firebaseUserDetails: {},
  loadingStates: {
    firebaseConfig: true,
    firebaseUser: true,
  },
  menteeDetails: {},
};

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case types.CURRENT_USER_SUCCESS:
      return {
        ...state,
        userDetails: action.data,
        isUserLoggedIn: true,
        validatingUser: false,
      };
    case types.SET_USER_VALIDATION:
      return {
        ...state,
        validatingUser: false,
      };
    case types.UPDATE_USER_DETAILS:
      return {
        ...state,
        userDetails: { ...state.userDetails, ...action.payload },
      };
    case types.FIREBASE_CONFIG_SUCCESS:
      return {
        ...state,
        firebaseConfig: action.data,
        loadingStates: { ...state.loadingStates, ...{ firebaseConfig: false } },
      };
    case types.FIREBASE_USER_SUCCESS:
      return {
        ...state,
        firebaseUserDetails: action.data,
        loadingStates: { ...state.loadingStates, ...{ firebaseUser: false } },
      };
    default:
      return state;
  }
};

export default authentication;
