import AttachFileIcon from '@mui/icons-material/AttachFile';
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import React, { useEffect, useState } from 'react';
// import awsMultiFileUpload from '../../services/awsMultiUpload';
import { useMutation } from '@apollo/client';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch } from 'react-redux';
import { generateSnackbarPayload } from '../../../../src/Helper';
import FileIcon from '../../../Assets/Images/Terms.svg';
import * as types from '../../../Store/actionTypes';
import awsFileUpload from '../../Common/aws/awsFileUpload';
import awsKey from '../../Common/aws/awskey';
import CustomCircularProgress from '../../Common/CustomCircularProgress';
import { ADD_COMMENT, EDIT_COMMENT } from '../services/gql';

const AddComment = (props) => {
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  const [attachmentUpload, setAttachmentUpload] = useState(
    Object.values(props.editData).length > 0 ? props.editData.attachmentUpload : [],
  );
  const [attachmentImage, setAttachmentImage] = useState(
    Object.values(props.editData).length > 0 ? props.editData.attachmentImage : [],
  );
  //   const [alredyImage, setalreadyImage] = useState(
  //     Object.values(props.editData).length > 0 ? props.editData.alredyImage : [],
  //   );
  const [comment, setComment] = useState(
    Object.values(props.editData).length > 0 ? props.editData.comment : '',
  );
  const [addComment] = useMutation(ADD_COMMENT, {
    context: { clientName: 'curriculum' },
  });
  const [editComment] = useMutation(EDIT_COMMENT, {
    context: { clientName: 'curriculum' },
  });

  //   const awsKey = async () => {
  //     return getAwsKey();
  //   };
  useEffect(() => {
    setAttachmentImage(
      Object.values(props.editData).length > 0 ? props.editData.attachmentImage : [],
    );
    setAttachmentUpload([]);
  }, [props.taskIndex]);
  const handleFiles = (event) => {
    Array.from(event.target.files).map((record, index) => {
      let reader = new FileReader();
      attachmentUpload.push(record);
      setAttachmentUpload([...attachmentUpload]);
      reader.readAsDataURL(record);
      reader.onload = () => {
        attachmentImage.push(reader.result);
        setAttachmentImage([...attachmentImage]);
      };
    });
  };
  const removeScreenImg = (img, index) => {
    attachmentImage.splice(index, 1);
    setAttachmentImage([...attachmentImage]);
    let ind = attachmentUpload.indexOf(img);
    attachmentUpload.splice(ind, 1);
    setAttachmentUpload(attachmentUpload);
    if (Object.values(props.editData).length) {
      props.editData.alredyImage.splice(props.editData.alredyImage.indexOf('img'), 1);
    }
  };
  const enterComment = async () => {
    setLoad(true);
    let attachmentArr = [];
    if (Object.values(props.editData).length) {
      if (attachmentUpload.length || comment.trim() !== '' || props.editData.alredyImage.length) {
        const key = await awsKey();
        let arr = attachmentUpload.map((data) =>
          awsFileUpload(data, 'commentAttachment', key.data.keyId, key.data.secretKey),
        );
        let data = await Promise.all(arr);
        data.forEach((record, index) => {
          props.editData.alredyImage.push(record.location);
          setAttachmentImage([...props.editData.alredyImage]);
        });
        attachmentArr = data.map((rec, index) => {
          return {
            s3URL: rec.location,
            type: attachmentImage[index].type,
            fileName: attachmentImage[0].name,
            extension: rec.location.split('.').pop(),
            status: true,
            order: index + 1,
          };
        });

        let editCommentData = await editComment({
          variables: {
            commentId: props.editData.editId,
            commentText: comment.trim() !== '' ? comment : '',
            attachments: attachmentArr,
          },
        });
        if (editCommentData.data.editComment) {
          setLoad(false);
          props.changeOpenComment();
          props.refetchData();
          dispatch({
            type: types.TOGGLE_SNACKBAR,
            payload: generateSnackbarPayload('success', 'Comment has been updated successfully'),
          });
          setComment('');
          setAttachmentImage([]);
          setAttachmentUpload([]);
        }
      }
    } else {
      if (props._id) {
        if (attachmentUpload.length || comment.trim() !== '') {
          if (attachmentUpload.length) {
            const key = await awsKey();
            let arr = attachmentUpload.map((data) =>
              awsFileUpload(data, 'commentAttachment', key.data.keyId, key.data.secretKey),
            );
            let data = await Promise.all(arr);
            attachmentArr = data.map((rec, index) => {
              return {
                s3URL: rec.location,
                type: attachmentUpload[index].type,
                fileName: attachmentUpload[0].name,
                extension: rec.location.split('.').pop(),
                status: true,
                order: index + 1,
              };
            });
          }
          let addCommentData = await addComment({
            variables: {
              taskId: props._id,
              commentText: comment.trim() !== '' ? comment : '',
              attachments: attachmentArr,
            },
          });

          if (addCommentData.data.addComment) {
            setLoad(false);
            props.refetchData();
            dispatch({
              type: types.TOGGLE_SNACKBAR,
              payload: generateSnackbarPayload('success', 'Comment has been added successfully'),
            });
            setComment('');
            setAttachmentImage([]);
            setAttachmentUpload([]);
          }
        }
      } else {
        dispatch({
          type: types.TOGGLE_SNACKBAR,
          payload: generateSnackbarPayload('error', 'Please add the task'),
        });
      }
    }
    setLoad(false);
  };

  return (
    <div className="Add-comment">
      {attachmentImage.length > 0 && (
        <div className="slected-file-wrap">
          {attachmentImage.map((imgValue, index) => {
            return (
              <React.Fragment>
                <div className="each-slected-file">
                  <div className="slected-file">
                    {attachmentUpload[index].type === 'image/png' ? (
                      <img src={imgValue} className="bagdeIcon" alt="Attachment" />
                    ) : (
                      <img src={FileIcon} className="bagdeIcon" alt="Attachment" />
                    )}
                    {/* <a target="_blank" href={imgValue} download> */}
                    <span>
                      {attachmentUpload[index].name.length > 15
                        ? `${attachmentUpload[index].name.substring(0, 15)}.....`
                        : attachmentUpload[index].name}
                    </span>
                    {/* </a> */}
                    {/* <img src={imgValue} style={{ width: '25px' }}></img> */}
                  </div>
                  <ClearIcon onClick={(e) => removeScreenImg(imgValue, index)} />
                </div>
              </React.Fragment>
            );
          })}
        </div>
      )}
      <OutlinedInput
        onKeyUp={(event) => {
          if (!load && event.key === 'Enter') enterComment();
        }}
        id="comment-id"
        placeholder="Add Comment and Press Enter"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        startAdornment={
          <InputAdornment position="start">
            <input
              accept="image/*, .pdf, .csv, .xlsx"
              id="icon-button-file"
              type="file"
              hidden
              multiple
              onChange={(event) => handleFiles(event)}
            />
            <label htmlFor="icon-button-file">
              <IconButton color="primary" aria-label="upload picture" component="span">
                <AttachFileIcon />
              </IconButton>
            </label>
          </InputAdornment>
        }
        endAdornment={
          load && (
            <InputAdornment position="end">
              <CustomCircularProgress />
            </InputAdornment>
          )
        }
        fullWidth={true}
      />
    </div>
  );
};

export default AddComment;
