import { gql, useApolloClient, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

export const GET_MULTIPLE_ASSESSMENTS = gql`
  query TodayAssessments($startDate: DateTime!, $endDate: DateTime!) {
    todayAssessments(startDate: $startDate, endDate: $endDate)
  }
`;

export const ASSESSMENT_DETAIL = gql`
  query AssessmentById($assessmentByIdId: ID!, $limit: Int, $sort: JSON) {
    assessmentById(id: $assessmentByIdId) {
      _id
      assessmentBank {
        _id
        title
        description
        questionnaires(limit: $limit, sort: $sort) {
          question {
            order
            value
          }
          expectedAnswers {
            value
            order
            _id
          }
          _id
        }
      }
    }
  }
`;

const useGetMultipleAssessments = () => {
  const { cache } = useApolloClient();
  const currentDate = new Date();
  const startOfDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
  );
  const endOfDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + 1,
    0,
    0,
    -1,
  );
  const { data, error, loading } = useQuery(GET_MULTIPLE_ASSESSMENTS, {
    context: { clientName: 'assessment' },
    variables: {
      startDate: startOfDay.toISOString(),
      endDate: endOfDay.toISOString(),
    },

    onCompleted: () => {},
    onError: (error) => {
      console.error(error.message);
    },
  });
  const clearCacheData = () => {
    cache.writeQuery({
      query: GET_MULTIPLE_ASSESSMENTS,
      variables: {
        startDate: startOfDay.toISOString(),
        endDate: endOfDay.toISOString(),
      },
      data: { todayAssessments: [] },
      overwrite: true,
    });
  };
  return { data: data ? data.todayAssessments : [], loading, error, clearCacheData };
};
export default useGetMultipleAssessments;

export const useGetAssessmentDetail = ({ assessmentId, skip }) => {
  const { id } = useParams();
  const { data, loading, error } = useQuery(ASSESSMENT_DETAIL, {
    context: { clientName: 'assessment' },
    variables: {
      assessmentByIdId: assessmentId ? assessmentId : id,
      limit: 100,
      sort: {
        'question.order': 1,
      },
    },
    skip,
  });
  return { data: data, loading, error };
};
