import {takeLatest, call, put, fork} from 'redux-saga/effects';
import * as api from '../Api/Tools';
import * as types from '../Store/actionTypes';
import graphQlClient from '../Config/graphQLConf';
import {GET_NOTIFICATION_COUNT} from '../ApolloQueries/Notifications';

import logError from '../Helper';

const sagaPath = 'src/Sagas/Notification.js';

function* getUnreadNotificationCount(action) {
  try {
    const response = yield call(() =>
      graphQlClient.query({
        query: GET_NOTIFICATION_COUNT,
        fetchPolicy: 'no-cache',
      }),
    );
    yield put({type: types.UNREAD_NOTIFICATION_COUNT_SUCCESS, payload: {loading: response.loading, data: response.data.getUnreadCount}});
  } catch (e) {
    logError(e, sagaPath + 'getUnreadNotificationCount');
    yield put({type: types.UNREAD_NOTIFICATION_COUNT_SUCCESS, payload: {loading: false, error: e}});
  }
}

function* watchNotificationSagas() {
  yield takeLatest(types.UNREAD_NOTIFICATION_COUNT_REQUEST, getUnreadNotificationCount);
}

const notificationSagas = [fork(watchNotificationSagas)];

export default notificationSagas;
