import { Card, Grid, Typography } from '@mui/material';
import React from 'react';
const CardWrapper = ({
  children,
  title,
  rightComponent,
  titleStyles = {},
  rootStyles = {},
  contentStyle = {},
}) => {
  return (
    <Card sx={{ minHeight: '250px', padding: '20px', ...rootStyles }}>
      <Grid container>
        <Grid item xs={12}>
          <Grid
            sx={{
              paddingBottom: '10px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Typography
              variant="h5"
              component="h2"
              sx={{ fontSize: '16px', fontWeight: 700, color: '#000000', ...titleStyles }}>
              {title}
            </Typography>
            {rightComponent}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <p style={{ color: '#3E3C37', ...contentStyle }}>{children}</p>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CardWrapper;
