export const priorities = {
  'High Priority Goal': 'IMPORTANT',
  Goal: 'NOT_IMPORTANT',
};

export const prioritiesBackend = {
  IMPORTANT: 'High Priority Goal',
  NOT_IMPORTANT: 'Goal',
};

export const getPriorityLabel = (priority) => {
  if (!priority) return 'is Not Set';
  return prioritiesBackend[priority] ? `is a ${prioritiesBackend[priority]}` : 'is Not Set';
};

export const progress = {
  New: 'NEW',
  'On track': 'ON_TRACK',
  'Not on track': 'NOT_ON_TRACK',
  Completed: 'COMPLETE',
};

export const progressList = {
  New: 'NEW',
  'On-track': 'ON_TRACK',
  'Not on-track': 'NOT_ON_TRACK',
  Completed: 'COMPLETE',

  NEW: { text: 'New', backgroundColor: '#0057D9', color: '#0057D9' },
  ON_TRACK: { text: 'On track', backgroundColor: '#B2DF8A', color: '#48BB78' },
  NOT_ON_TRACK: { text: 'Not on track', backgroundColor: '#D41B2C', color: '#D41B2C' },
  COMPLETE: { text: 'Completed', backgroundColor: '#068A08', color: '#068A08' },
};

export const getBackgroundColor = (priority, fromPage) => {
  switch (priority) {
    case 'IMPORTANT':
      return fromPage === 'setGoals' ? '#D3F8FA' : 'black';
    case 'NOT_IMPORTANT':
      return '#D3F8FA';
    case 'Low':
      return '#fff';
    default:
      return '#fff';
  }
};

export const getColor = (priority, fromPage) => {
  switch (priority) {
    case 'IMPORTANT':
      return fromPage === 'setGoals' ? 'black' : '#fff';
    case 'NOT_IMPORTANT':
      return 'black';
    case 'Low':
      return 'black';
    default:
      return 'black';
  }
};

export const getColorConfirmationPopup = (priority, fromPage) => {
  switch (priority) {
    case 'IMPORTANT':
      return fromPage === 'setGoals' ? 'black' : '#fff';
    case 'NOT_IMPORTANT':
      return 'black';
    case 'Low':
      return 'black';
    default:
      return 'black';
  }
};
