import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { generateSnackbarPayload } from '../../../Helper';
import * as types from '../../../Store/actionTypes';
import { DELETE_CUSTOM_GOAL } from './gql';

export const useDeleteCustomGoal = () => {
  const dispatch = useDispatch();

  const [deleteCustomGoal] = useMutation(DELETE_CUSTOM_GOAL, {
    context: { clientName: 'referral' },
    refetchQueries: ['listAllGoalEntry', 'listCustomGoalEntry', 'listAllGoalEntryCount'],
    onError: (error) => {
      dispatch({
        type: types.TOGGLE_SNACKBAR,
        payload: generateSnackbarPayload('error', error.message),
      });
    },
    onCompleted: (data) => {
      dispatch({
        type: types.TOGGLE_SNACKBAR,
        payload: generateSnackbarPayload('success', 'Priority Deleted Successfully'),
      });
    },
  });
  return deleteCustomGoal;
};
