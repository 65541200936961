import { selectedActivityVar } from '../vars/reactiveVars';

export const handleAssignedTo = (assignedTo) => {
  switch (assignedTo) {
    case 'mentee':
      return 'Mentee';
    case 'mentor':
      return 'Mentor';
    case 'programManager':
      return 'Program Manager';
    case 'matchSupportSpecialist':
      return 'Match Support Specialist';
    default:
      return '';
  }
};

export const handleChange = (event, props, field) => {
  const changedField = {};
  changedField[field] = event.target.value;
  selectedActivityVar({ ...props, ...changedField });
  return changedField;
};

export const handleCompletedChange = (completed, props, field) => {
  const changedField = {};
  changedField[field] = completed;
  // selectedActivityVar({ ...props, ...changedField });
  return changedField;
};

export const handleAssignedToChange = (event, props) => {
  const changedField = {};
  changedField.assignedTo = event.target.value._id;
  changedField.assignedToData = event.target.value;
  // selectedActivityVar({ ...props, ...changedField });
  return changedField;
};

export const handleDueDateChange = (event, props, field) => {
  const changedField = {};
  changedField[field] = event;
  // selectedActivityVar({ ...props, ...changedField });
  return changedField;
};

export const handleChangeDescription = (descriptionData, props, field) => {
  const changedField = {};
  changedField[field] = descriptionData;
  selectedActivityVar({ ...props, ...changedField });
  return changedField;
};
