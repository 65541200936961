import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
//import { trackEvent } from '../../Helper/EventTracker';
import { Avatar, Button, Grid } from '@mui/material';

import { getUserDetails } from '../../Store/reduxSelectors';

import getSsgDetails from '../services/getSsgDetails.js';
import getMultipleSsg from '../services/getSsgScPmDetails.js';

import { startCase } from 'lodash';

import moment from 'moment';
import BioImage from '../../Assets/Images/bio-icon.svg';
import HobbesImage from '../../Assets/Images/Hobbies-Icon.svg';
import HomeTownImage from '../../Assets/Images/HomeTown-image.svg';
import OccupationImage from '../../Assets/Images/occupation-icon.svg';
import PreferredImage from '../../Assets/Images/preferredname-Icon.svg';
import PronounImage from '../../Assets/Images/prefferd-pronoun.svg';
import learnerAction from '../../Helper/Analytics/learnerAction';
import screenNames from '../../Helper/Analytics/screenNames';
import { trackEvent } from '../../Helper/EventTracker';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '25px',
  },
  paper: {
    boxShadow: theme.shadows[5],
    width: '600px',
    borderRadius: '25px',
  },
}));

export default function TransitionsModal() {
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [sssg, setSssg] = React.useState(false);
  const [ssgList, setSsgList] = useState();
  const [ssgDetails, setSsgDetails] = useState();
  const userDetails = useSelector((state) => getUserDetails(state));

  useEffect(() => {
    getMultipleSsg(userDetails.id).then((res) => {
      setSsgList(res.data);
      sSsgPopup(res.data.primaryMentor._id);
    });

    trackEvent('view', {
      screen: screenNames.MentorProfile,
    });
  }, []);

  document.title = 'MentorHub - Mentor Profile';

  const handleClose = () => {
    trackEvent('click', {
      screen: screenNames.MentorProfile,
      Action: learnerAction.CLOSE_MENTOR_PROFILE,
    });
    setOpen(false);
    setSssg(false);
    history.push({
      pathname: '/',
    });
  };

  const ssgPopup = () => {
    setOpen(true);
  };

  const sSsgPopup = (ssgId) => {
    getSsgDetails(ssgId).then((res) => {
      setSsgDetails(res.data);
      setSssg(true);
    });
  };

  return (
    <div className="ssg-page" tabIndex={0} id="Mentor Profile-main">
      {ssgList && (
        <ul className="ssg-list" style={{ display: 'none' }}>
          <li>
            <a onClick={() => ssgPopup()}>
              <span>
                <Avatar
                  className="img-fluid"
                  alt="profileImageURL"
                  src={ssgList.primaryMentor.profileImage}>
                  {ssgList.primaryMentor.firstName.charAt(0).toUpperCase()}
                </Avatar>
                <span>
                  <span className="name">
                    {ssgList.primaryMentor.firstName + ' ' + ssgList.primaryMentor.lastName}
                  </span>
                  Mentor
                </span>
              </span>
              <span></span>
            </a>
          </li>

          {ssgList.secondaryMentors.map((item) => (
            <li style={{ display: 'none' }}>
              <a onClick={() => sSsgPopup(item._id)}>
                <span>
                  <Avatar className="img-fluid" alt="profileImageURL" src={item.profileImage}>
                    {item.firstName.charAt(0).toUpperCase()}
                  </Avatar>
                  <span>
                    <span className="name">{item.firstName + ' ' + item.lastName}</span>
                    Secondary SSG
                  </span>
                </span>
                <span></span>
              </a>
            </li>
          ))}
        </ul>
      )}

      {/*  Primary ssg popup  */}

      <Modal
        id="Ssg Profile"
        aria-label="Ssg Profile Modal"
        aria-modal="true"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        sx={{
          '& .MuiBackdrop-root': {
            zIndex: '-1',
          },
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={open}>
          <div className={classes.paper}>
            <div className="profile-edit ssg-profile-view">
              <div className="profile-edit-inner">
                <div className="profile-head">
                  <h1>Mentor Profile </h1>
                </div>
                {userDetails && (
                  <div className="profile-common">
                    <Avatar src={userDetails.mentor.profileImage} alt="Mentor profile image" />
                    <h2>
                      {startCase(ssgDetails && ssgDetails.firstName)}{' '}
                      {ssgDetails &&
                        ssgDetails.lastName.charAt(0).toUpperCase() + ssgDetails.lastName.slice(1)}
                      <span>
                        Joined on{' '}
                        {moment(userDetails.mentor.firstLoggedInDate).format('MMMM DD, YYYY ')}
                      </span>
                    </h2>
                    <div className="profile-common-more" style={{ width: '100%' }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <h2>Personal Info</h2>
                        </Grid>
                        <Grid item xs={6}>
                          <h3>
                            <img
                              style={{ width: '40px', height: '40px' }}
                              src={PreferredImage}
                              alt="PreferredImage"></img>
                            <b>
                              Preferred Name{' '}
                              {userDetails.mentor.preferredName ? (
                                <span>{userDetails.mentor.preferredName}</span>
                              ) : (
                                <span>N/A</span>
                              )}
                            </b>
                          </h3>
                        </Grid>
                        <Grid item xs={6}>
                          <h3>
                            <img
                              style={{ width: '40px', height: '40px' }}
                              src={HomeTownImage}
                              alt="HomeTownImage"></img>
                            <b>
                              Hometown{' '}
                              {userDetails.mentor.homeTown ? (
                                <span>{userDetails.mentor.homeTown}</span>
                              ) : (
                                <span>N/A</span>
                              )}
                            </b>
                          </h3>
                        </Grid>
                        <Grid item xs={6}>
                          <h3>
                            <img
                              style={{ width: '40px', height: '40px' }}
                              src={PronounImage}
                              alt="PronounImage"></img>
                            <b>
                              Pronouns{' '}
                              {userDetails.mentor.pronoun ? (
                                <span>{userDetails.mentor.pronoun}</span>
                              ) : (
                                <span>N/A</span>
                              )}{' '}
                            </b>
                          </h3>
                        </Grid>
                        <Grid item xs={6}>
                          <h3>
                            <img
                              style={{ width: '40px', height: '40px' }}
                              src={OccupationImage}
                              alt="OccupationImage"></img>
                            <b>
                              Occupation{' '}
                              {ssgDetails && ssgDetails.occupation ? (
                                <span>{ssgDetails.occupation}</span>
                              ) : (
                                <span>N/A</span>
                              )}{' '}
                            </b>
                          </h3>
                        </Grid>
                        <Grid item xs={12}>
                          <h3>
                            <img
                              style={{ width: '40px', height: '40px' }}
                              src={HobbesImage}
                              alt="HobbiesImage"></img>
                            <b>
                              Hobbies{' '}
                              {ssgDetails && ssgDetails.hobbies ? (
                                <span>
                                  {ssgDetails.hobbies.map((item, index) => (
                                    <t style={{ marginRight: '5px' }}>
                                      {item.title}
                                      {index === ssgDetails.hobbies.length - 1 ? '' : ','}
                                    </t>
                                  ))}
                                </span>
                              ) : (
                                <span>N/A</span>
                              )}{' '}
                            </b>
                          </h3>
                        </Grid>

                        <Grid item xs={12}>
                          <h3>
                            <img
                              style={{ width: '40px', height: '40px' }}
                              src={BioImage}
                              alt="BioImage"></img>
                            <b>
                              Bio{' '}
                              {userDetails.mentor.bio ? (
                                <span>{userDetails.mentor.bio}</span>
                              ) : (
                                <span>N/A</span>
                              )}
                            </b>
                          </h3>
                        </Grid>
                      </Grid>
                    </div>
                    <br />
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <div className="ssg-btn-box">
                          <Button
                            fullWidth
                            variant="outlined"
                            onClick={handleClose}
                            disableRipple
                            className="custom-button"
                            aria-label="press enter to close the profile"
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handleClose();
                              }
                            }}
                            color="primary">
                            Close
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
