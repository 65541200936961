import { Button, Card, Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { generateSnackbarPayload } from '../../Helper';
import getRoutes from '../../Routes/RoutePaths';
import * as types from '../../Store/actionTypes';
import ResetPassword from '../services/useResetPassword';

export const ResetPswd = () => {
  const tokenElement = useParams();
  const dispatch = useDispatch();

  const history = useHistory();
  const {
    register,
    reset,
    getValues,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  const submitForm = (data) => {
    ResetPassword(data.confirmpassword, tokenElement.id)
      .then((res) => {
        dispatch({
          type: types.TOGGLE_SNACKBAR,
          payload: generateSnackbarPayload('success', 'Password has been changed successfully.'),
        });
        localStorage.clear();
        history.push(getRoutes.login);
      })
      .catch((err) => {
        dispatch({
          type: types.TOGGLE_SNACKBAR,
          payload: generateSnackbarPayload('error', err.response.data.error.message),
        });
      });
  };

  return (
    <React.Fragment>
      <section className="login-common" style={{ marginTop: '5%' }}>
        <form className="ligin-wrap" onSubmit={handleSubmit(submitForm)}>
          <Card
            className="ligin-wrap-inner"
            style={{
              borderRadius: 25,
              display: 'flex',
              flexDirection: 'row',
              overflow: 'visible',
              padding: '30px 30px',
              width: '400px',
            }}>
            <Grid container direction="column" spacing={2}>
              <Grid item style={{ textAlign: 'center' }}>
                <Typography
                  className="wel-mentor"
                  style={{
                    color: '#000',
                    fontSize: '24px',
                    fontFamily: 'Lato',
                    marginTop: '14px',
                    fontWeight: 700,
                  }}>
                  Reset Password
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  name="newpassword"
                  label="Password"
                  type="password"
                  error={errors?.newpassword?.message}
                  helperText={errors?.newpassword?.message}
                  id="createPassword"
                  variant="outlined"
                  onChange={(e) => {
                    setValue('newpassword', e.target.value);
                    trigger('newpassword');
                  }}
                  fullWidth
                  {...register('newpassword', {
                    required: 'Password is required',
                    minLength: {
                      value: 8,
                      message: 'Password must be at least 8 characters',
                    },
                    maxLength: {
                      value: 15,
                      message: 'Password must not exceed 15 characters',
                    },
                  })}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Confirm Password"
                  type="password"
                  error={errors?.confirmpassword?.message}
                  helperText={errors?.confirmpassword?.message}
                  name="confirmpassword"
                  id="createPassword"
                  variant="outlined"
                  onChange={(e) => {
                    setValue('confirmpassword', e.target.value);
                    trigger('confirmpassword');
                  }}
                  {...register('confirmpassword', {
                    required: 'Please confirm your password',
                    validate: (value) =>
                      value === getValues('newpassword') ? undefined : 'The passwords do not match',
                  })}
                  fullWidth
                />
              </Grid>
              <Grid container spacing={2} style={{ padding: '20px 7px' }}>
                <Grid item xs={6} md={6}>
                  <Button
                    tabIndex={0}
                    fullWidth
                    disableRipple
                    onClick={() => reset()}
                    className="custom-button"
                    variant="outlined"
                    color="primary">
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Button
                    style={{ color: 'white' }}
                    aria-label="Profile update Button"
                    fullWidth
                    type="submit"
                    tabIndex={0}
                    disableRipple
                    //   onClick={() => profileUpdate()}

                    //   disabled={loading}
                    className="custom-button"
                    variant="contained"
                    color="primary">
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </form>
      </section>
    </React.Fragment>
  );
};
