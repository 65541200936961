 
import axios from 'axios';
import handleAxiosError from '../../Helper/AxiosErrorHanlder';

const multipleSsg = (userId) => {
  const token = localStorage.getItem('@authToken');

  return axios({
    method: 'POST',
    url: process.env.REACT_APP_BASE_URL + `/mentorDetails/${userId}` + '?access_token=' + token,
    data:{ 
      "fields":[
         'email','firstName','lastName','username','aboutUs','phoneNumber','profileImage','gender','raceEthnicity','activeProgram','status','takeAssessment','isPasswordSet','role','lastUpdatedBy','lastActivityDate','firstLoggedInDate','totalPoints','title','createdBy','createdDate','updatedDate','programManagerInOrganizationIds','programManagerInOrganizations','programManagerInProgramIds','programManagerInPrograms','adminInOrganizationIds','adminInOrganizations','mentorInOrganizationIds','mentorInOrganizations','mentorInProgramIds','mentorInPrograms','enrolledInProgramIds','enrolledInPrograms','enrolledInOrganizationIds','enrolledInOrganizations','badgeIds','badges','flaggedByMenteeIds','flaggedByMentees','firestoreId','countryCode','name_id','dateLocale','lang','alertNumCountryFlag','receiveSmsAlert','disableShowSMSPop','lastPopUpShownDate','referralLink','preferredName','currentCity','homeTown','collegeProgram','bio','pronoun','collegeIds','collegeDetails','majorIds','majorDetails','additionalProgramIds','additionalProgramDetails','dateOfBirth', 'hobbies', 'occupation' ,
      ]
      }
  }).catch((err) => handleAxiosError(err));
};
export default multipleSsg;


