import { Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import learnerAction from '../../Helper/Analytics/learnerAction.js';
import screenNames from '../../Helper/Analytics/screenNames.js';
import { trackEvent } from '../../Helper/EventTracker';
import theme from '../../MaterialUiTheme';
import { updateUserDetails } from '../../Store/Actions/Auth';
import getLoggedUserDetails from '../services/getLoggedUserDetails';
import updateProfileDetails from '../services/patchUserDetails.js';

export const FtueSix = (props) => {
  const [Identity, setIdentity] = useState();
  const dispatch = useDispatch();
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);

  useEffect(() => {
    setIdentity(props.loggedData ? props.loggedData.data.raceEthnicity : '');
  }, [props]);

  const sSnackbar = () => {
    setSuccessSnackbar(true);
  };

  const eSnackbar = () => {
    setErrorSnackbar(true);
  };

  const eSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorSnackbar(false);
  };
  const sSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessSnackbar(false);
    setTimeout(() => {
      props.stepUpdate('next');
    }, 100);
  };

  const updateIdentity = () => {
    trackEvent('click', {
      screen: screenNames.FtueWelcome,
      Action: learnerAction.FTUE_RACIAL_AND_ETHNIC_IDENTITY,
    });
    let data = {
      raceEthnicity: Identity,
    };
    updateProfileDetails(props.userDetails.id, data)
      .then((response) => {
        getLoggedUserDetails(props.userDetails.id).then((res) => {
          props.setLoggedData(res);
          dispatch(updateUserDetails({ raceEthnicity: res.data.raceEthnicity }));
        });
        sSnackbar();
      })
      .catch((error) => {});
  };

  return (
    <div>
      <div>
        <div>
          <h2 style={{ height: '45px' }}>
            Select your Racial and Ethnic <br /> Identity
          </h2>
        </div>
        <span style={{ fontWeight: '400', fontSize: '14px', color: ' #3E3C37' }}>
          You can always change this later.
        </span>
        <div className="identity-container">
          <Button
            className={Identity == 'American Indian or Alaska Native' ? 'active' : ''}
            style={{
              marginTop: '20px',
              width: '236px',
              fontWeight: '400',
              fontSize: '14px',
              borderRadius: '5px',
              textTransform: 'inherit',
              fontFamily: 'Lato',
              color: Identity !== 'American Indian or Alaska Native' && 'black',
            }}
            onClick={() => setIdentity('American Indian or Alaska Native')}>
            American Indian or Alaska Native
          </Button>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Button
              className={Identity == 'Black or African American' ? 'active' : ''}
              style={{
                marginTop: '20px',
                width: '185px',
                fontWeight: '400',
                fontSize: '14px',
                borderRadius: '5px',
                textTransform: 'inherit',
                fontFamily: 'Lato',
                color: Identity !== 'Black or African American' && 'black',
              }}
              onClick={() => setIdentity('Black or African American')}>
              Black or African American
            </Button>
            <Button
              className={Identity == 'Hispanic or Latino' ? 'active' : ''}
              style={{
                marginTop: '20px',
                width: '135px',
                fontWeight: '400',
                fontSize: '14px',
                borderRadius: '5px',
                textTransform: 'inherit',
                fontFamily: 'Lato',
                color: Identity !== 'Hispanic or Latino' && 'black',
              }}
              onClick={() => setIdentity('Hispanic or Latino')}>
              Hispanic or Latino
            </Button>
          </div>

          <Button
            className={Identity == 'Native Hawaiian or Other Pacific Islander' ? 'active' : ''}
            style={{
              marginTop: '20px',
              width: '279px',
              fontWeight: '400',
              fontSize: '14px',
              borderRadius: '5px',
              textTransform: 'inherit',
              fontFamily: 'Lato',
              color: Identity !== 'Native Hawaiian or Other Pacific Islander' && 'black',
            }}
            onClick={() => setIdentity('Native Hawaiian or Other Pacific Islander')}>
            Native Hawaiian or other Pacific Islander
          </Button>
          <Button
            className={Identity == 'White Non-Hispanic or Latino' ? 'active' : ''}
            style={{
              marginTop: '20px',
              width: '208px',
              fontWeight: '400',
              fontSize: '14px',
              borderRadius: '5px',
              textTransform: 'inherit',
              fontFamily: 'Lato',
              color: Identity !== 'White Non-Hispanic or Latino' && 'black',
            }}
            onClick={() => setIdentity('White Non-Hispanic or Latino')}>
            White Non-Hispanic or Latino
          </Button>
          <div style={{ display: 'flex' }}>
            <Button
              className={Identity == 'Asian' ? 'active' : ''}
              style={{
                marginTop: '20px',
                width: '56px',
                fontWeight: '400',
                fontSize: '14px',
                borderRadius: '5px',
                fontFamily: 'Lato',
                color: Identity !== 'Asian' && 'black',
              }}
              onClick={() => setIdentity('Asian')}>
              Asian
            </Button>
            <Button
              className={Identity == 'Multi-Racial' ? 'active' : ''}
              style={{
                marginTop: '20px',
                width: '100px',
                marginLeft: '10px',
                fontWeight: '400',
                fontSize: '14px',
                borderRadius: '5px',
                textTransform: 'inherit',
                fontFamily: 'Lato',
                color: Identity !== 'Multi-Racial' && 'black',
              }}
              onClick={() => setIdentity('Multi-Racial')}>
              Multi-racial
            </Button>
          </div>
          <Button
            className={Identity == 'Prefer not to say' ? 'active' : ''}
            style={{
              marginTop: '20px',
              width: '123px',
              fontWeight: '400',
              fontSize: '14px',
              borderRadius: '5px',
              textTransform: 'inherit',
              fontFamily: 'Lato',
              color: Identity !== 'Prefer not to say' && 'black',
            }}
            onClick={() => setIdentity('Prefer not to say')}>
            Prefer not to say
          </Button>
          <Button
            className={Identity == 'Other' ? 'active' : ''}
            style={{
              marginTop: '20px',
              width: '80px',
              fontWeight: '400',
              fontSize: '14px',
              borderRadius: '5px',
              marginLeft: '10px',
              fontFamily: 'Lato',
              color: Identity !== 'Other' && 'black',
            }}
            onClick={() => setIdentity('Other')}>
            Other{' '}
          </Button>
        </div>
      </div>
      <Button
        style={{
          marginTop: '20px',
          color: 'white',
          width: '100%',
          height: '50px',
          backgroundColor: theme.palette.primary.main,
          fontFamily: 'Lato',
          fontWeight: 'bold',

          textTransform: 'capitalize',
        }}
        fullWidth
        variant="contained"
        color="primary"
        onClick={updateIdentity}>
        Save
      </Button>
      <Snackbar
        open={errorSnackbar}
        style={{ zIndex: '10000' }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={eSnackbarClose}>
        <Alert severity="error" variant="filled">
          Please select your Racial and Ethnic Identity
        </Alert>
      </Snackbar>
      <Snackbar
        open={successSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id="ratingSuccess"
        autoHideDuration={3000}
        onClose={sSnackbarClose}>
        <Alert tabindex="0" severity="success" variant="filled">
          Racial and Ethnic Identity successfully updated.
        </Alert>
      </Snackbar>
    </div>
  );
};
