import { ApolloProvider } from '@apollo/client';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import client from './Config/graphQLConf';
import './index.css';
// import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import CustomizedSnackbars from './Components/Common/CustomSnackbar';
import theme from './MaterialUiTheme';
import store from './Store/Reducer';

const AppMain = (
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <Provider store={store}>
            <App />
            <CustomizedSnackbars />
          </Provider>
        </ApolloProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);

ReactDOM.render(AppMain, document.getElementById('root'));
// serviceWorker.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
