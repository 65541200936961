import axios from "axios";

// export const getBaseUrl = (type) => {
//   const baseUrls = {
//     production: 'https://api-v2.mentorhubapp.org/',
//     staging: 'https://api-stag.mentorhubapp.org/',
//     development: 'http://dev-main-server.us-east-2.elasticbeanstalk.com/',
//   };
//   return baseUrls[type];
// };

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 0,
  headers: { "Content-Type": "application/json" },
});
export default instance;
