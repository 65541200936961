import { useMutation } from '@apollo/client';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { generateSnackbarPayload } from '../../../../src/Helper';
import * as types from '../../../Store/actionTypes';
import { getUserDetails } from '../../../Store/reduxSelectors';
import { selectedGoalActivityVar } from '../vars/lessonGoalMenteeVar';
import { ADD_TASK, GET_GOAL_PLAN_MENTEE, MARK_AS_READ, UPDATE_ASSIGNED_TASK } from './gql';

export const UpdateGoalActivityHook = (propsMain) => {
  const currentUser = useSelector((state) => getUserDetails(state));
  const [updateAssignedTask] = useMutation(UPDATE_ASSIGNED_TASK, {
    context: { clientName: 'curriculum' },
  });
  const [addTask] = useMutation(ADD_TASK, {
    context: { clientName: 'curriculum' },
  });
  const [markAsRead] = useMutation(MARK_AS_READ, {
    context: { clientName: 'curriculum' },
  });

  const dispatch = useDispatch();

  const updateBackend = async (props, changedField, setLoadState) => {
    setLoadState && setLoadState(true);
    if (props._id) {
      try {
        const resp = await updateAssignedTask({
          variables: { taskId: props._id, ...changedField },
          // update(cache, mutationResult) {
          //   const cacheObj = cache.readQuery({
          //     query: GET_GOAL_PLAN_MENTEE,
          //     variables: {
          //       curriculumId: props.curriculumId,
          //       menteeId: props.menteeId,
          //     },
          //   });
          //   const obj = _.cloneDeep(cacheObj);
          //   obj.listGoal.activities[props.badgeIndex].tasks[props.activityIndex] = {
          //     ...props,
          //     ...changedField,
          //   };

          //   const status = obj.listGoal.activities[props.badgeIndex].tasks.every((task) => task.completed);

          //   if (status) obj.listGoal.activities[props.badgeIndex].isBadgeGranted = true;

          //   cache.writeQuery({
          //     query: GET_GOAL_PLAN_MENTEE,
          //     variables: {
          //       curriculumId: props.curriculumId,
          //       menteeId: props.menteeId,
          //     },
          //     data: obj,
          //   });
          // },
          refetchQueries: ['listGoal'],
        });
        if (resp.error) throw resp.error;
        else {
          selectedGoalActivityVar({
            ...props,
            ...changedField,
          });
          dispatch({
            type: types.TOGGLE_SNACKBAR,
            payload: generateSnackbarPayload('success', 'Activity has been updated successfully'),
          });
        }
      } catch (err) {
        selectedGoalActivityVar({
          ...props,
        });
        console.error(err);
        dispatch({
          type: types.TOGGLE_SNACKBAR,
          payload: generateSnackbarPayload('error', err.message),
        });
      }
    } else {
      const resp = await addTask({
        variables: {
          title: changedField['title'],
          description: '',
          goalId: props.badgeId,
          order: 1,
          assignedTo: props.menteeId,
        },
        refetchQueries: ['listGoal'],
      });
      if (resp.error) throw resp.error;
      else {
        selectedGoalActivityVar({
          ...props,
          ...changedField,
          dueDate: null,
          _id: resp.data.addActivityToGoal._id,
          assignedToData: { ...currentUser },
          createdBy: currentUser.id,
        });
        dispatch({
          type: types.TOGGLE_SNACKBAR,
          payload: generateSnackbarPayload('success', 'Activity has been added successfully'),
        });
        props.refetch();
      }
    }
    setLoadState && setLoadState(false);
  };

  const updateReadStatus = async (props) => {
    try {
      await markAsRead({
        variables: {
          taskId: props._id,
        },
        update(cache, mutationResult) {
          const cacheObj = cache.readQuery({
            query: GET_GOAL_PLAN_MENTEE,
            variables: {
              curriculumId: props.curriculumId,
              menteeId: props.menteeId,
            },
          });

          const obj = _.cloneDeep(cacheObj);
          obj.listGoal[props.badgeIndex].activities[props.activityIndex].unreadCommentsCount = 0;

          cache.writeQuery({
            query: GET_GOAL_PLAN_MENTEE,
            variables: {
              curriculumId: props.curriculumId,
              menteeId: props.menteeId,
            },
            data: obj,
          });
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return { updateBackend, updateReadStatus };
};
