import { Grid } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
const CustomPhoneInput = ({ value, onChange, error, handleOnLogin, ...props }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <PhoneInput
          inputProps={{
            required: true,
            autoFocus: true,
          }}
          onEnterKeyPress={() => handleOnLogin()}
          placeholder={null}
          inputClass={`phoneInput ${error ? 'react-tel-input-errror' : ''}`}
          country={'us'}
          {...props}
          value={value}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  );
};

export default CustomPhoneInput;
