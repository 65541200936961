import * as types from '../actionTypes';

export const menteeDetails = (payload) => {
  return {
    type: types.GET_MENTEE_DETAILS,
    payload,
  };
};

export const getAssociatedMenteeList = (payload) => {
  return {
    type: types.GET_ASSOCIATED_MENTEE_LIST,
    payload,
  };
};
