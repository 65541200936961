import React, { useState } from 'react';

import { CircularProgress, Grid, TextField, Typography } from '@mui/material';
// import isEmail from 'validator/lib/isEmail';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import learnerAction from '../../Helper/Analytics/learnerAction';
import screenNames from '../../Helper/Analytics/screenNames';
import { trackEvent } from '../../Helper/EventTracker';
import theme from '../../MaterialUiTheme';
import getRoutes from '../../Routes/RoutePaths';
import { loginWithEmail } from '../../Store/Actions/Auth';
import { CustomButtom } from '../Common/ButtonCommon';

const LoginWithEmail = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = () => {
    trackEvent('click', {
      screen: screenNames.Login,
      Action: learnerAction.LOGIN_WITH_EMAIL,
    });
    if (!email) {
      setEmailError('Email is required');
    }
    // else if (!isEmail(email)) {
    //   setEmailError('Email is invalid');
    // }

    if (!password) {
      setPasswordError('Password is required');
    }

    if (email && password) {
      setLoading(true);
      const payload = {
        username: email,
        password,
      };
      dispatch(
        loginWithEmail(payload, (text) => {
          setLoading(false);
          !text && history.push(getRoutes.home);
        }),
      );
    }
  };

  return (
    <>
      {' '}
      <Grid item>
        <TextField
          size="small"
          autoFocus={true}
          name="email"
          label="Email / Username"
          //   type="email"
          value={email}
          required
          sx={{
            '& .MuiFormLabel-root.Mui-focused': {
              color: '#27cad2',
              fontFamily: 'Lato',
            },
            '& .MuiFormLabel-root': {
              fontFamily: 'Lato',
            },
            '& .MuiInputBase-root': {
              borderRadius: '4px',
              backgroundColor: '#F8F8F8',
              fontFamily: 'Lato',
            },
          }}
          helperText={emailError}
          id="standard-email"
          variant="outlined"
          fullWidth
          onChange={(e) => setEmail(e.target.value)}
        />
      </Grid>
      <Grid item>
        <TextField
          size="small"
          autoFocus={true}
          name="Password"
          label="Password"
          sx={{
            '& .MuiFormLabel-root.Mui-focused': {
              color: '#27cad2',
              fontFamily: 'Lato',
            },
            '& .MuiFormLabel-root': {
              fontFamily: 'Lato',
            },
            '& .MuiInputBase-root': {
              borderRadius: '4px',
              backgroundColor: '#F8F8F8',
              fontFamily: 'Lato',
            },
          }}
          type="password"
          value={password}
          required
          helperText={passwordError}
          id="createPassword"
          variant="outlined"
          fullWidth
          onChange={(e) => setPassword(e.target.value)}
        />
      </Grid>
      <Grid item>
        <CustomButtom
          type="submit"
          fullWidth
          disabled={loading}
          size="medium"
          color="primary"
          onClick={handleSubmit}
          variant="contained">
          {loading ? <CircularProgress size={24} /> : 'Login'}
        </CustomButtom>
      </Grid>
      <Grid item>
        <Typography className="description" color="textSecondary">
          <span
            style={{ color: theme.palette.primary.main, cursor: 'pointer', fontSize: '16px' }}
            onClick={() => {
              history.push(getRoutes.forgotPassword);
              trackEvent('click', {
                screen: screenNames.Login,
                Action: learnerAction.MOVE_TO_RESET_PASSWORD,
              });
            }}>
            Reset Password?
          </span>
        </Typography>
      </Grid>
    </>
  );
};

export default LoginWithEmail;
