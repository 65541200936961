const themeColors = {
  headerLeft: '#000000',
  headerCenter: '#1F1F1F',
  primary: '#000000',
  secondary: '#27CAD2',
  chatBubbleReceiver: '#27CAD2',
  chatBubbleSender: '#E1FBFA',
  chatRoomRoot: '#FFFFFF',
  chatBubbleTextSender: '#FFFFFF',
  chatBubbleTextReceiver: ' #3E3C37',
  chatBubbleTextReceiverBlack: '#000000',
};

export default themeColors;
