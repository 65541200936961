import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';

import { startCase } from 'lodash';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logOutUser } from '../../Api/Auth';
import { logoutUser } from '../../Store/Actions/Auth';
import EditProfile from '../Profile/EditProfile';

import getRoutes from '../../Routes/RoutePaths';

import { makeStyles } from '@mui/styles';
// import * as types from '../../Store/actionTypes';
// import getAdditionalPrograms from '../services/getAdditionalPrograms';
// import listCollege from '../services/getListCollege';
// import getlistMajors from '../services/getListMajors';
import learnerAction from '../../Helper/Analytics/learnerAction';
import { trackEvent } from '../../Helper/EventTracker';
import getLoggedUserDetails from '../services/getLoggedUserDetails';

const useStyles = makeStyles(() => {
  return {
    root: {
      '& .MuiMenu-paper': {
        backgroundColor: 'black',
        color: '#FFFFFF',
        border: '1px solid grey',
      },
    },
  };
});

const CurrentUserInfo = ({ userDetails, open }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showLogoutModal, setShowLogoutModal] = React.useState(false);

  const [loggedData, setLoggedData] = React.useState('');
  const [collegeList, setcollegeList] = React.useState();
  const [majorsList, setMajorsList] = React.useState();
  const [additionalProgramsList, setAdditionalProgramsList] = React.useState();

  useEffect(() => {
    getLoggedUserDetails(userDetails.id).then((res) => {
      setLoggedData(res);
    });
    // listCollege().then((res) => {
    //   setcollegeList(res);
    // });
    // getlistMajors().then((res) => {
    //   setMajorsList(res);
    // });
    // getAdditionalPrograms().then((res) => {
    //   setAdditionalProgramsList(res);
    // });
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleProfileModal = () => {
    // dispatch(toggleModal());
    history.push(getRoutes.profile);
    handleClose();
  };

  // const onLogOut = async () => {
  //   const resp = await logOutUser(userDetails.id);
  //   if (Object.keys(resp.data).includes('status')) {
  //     localStorage.removeItem('@authToken');
  //     localStorage.removeItem('@refreshToken');
  //     window.location.replace(resp.data.redirectLink);
  //   } else dispatch(logoutUser());
  // };
  const onLogOut = async () => {
    trackEvent('click', {
      Action: learnerAction.MENTEE_LOGOUT,
    });
    const resp = await logOutUser(localStorage.getItem('@authToken'));
    if (resp?.data && Object.keys(resp?.data).includes('status')) {
      localStorage.removeItem('@authToken');
      localStorage.removeItem('@refreshToken');
      window.location.replace(resp.data.redirectLink);
    } else dispatch(logoutUser());
  };

  const handleLogout = () => {
    trackEvent('click', {
      Action: learnerAction.CANCEL_LOGOUT,
    });
    setShowLogoutModal(!showLogoutModal);
    handleClose();
  };
  return (
    <React.Fragment>
      <EditProfile
        loggedData={loggedData}
        setLoggedData={setLoggedData}
        // collegeList={collegeList}
        // majorsList={majorsList}
        // additionalProgramsList={additionalProgramsList}
        getLoggedUserDetails={getLoggedUserDetails}
      />
      <Grid sx={{ display: 'flex', flexGrow: 1, alignItems: 'flex-end' }}>
        <div
          className="MuiList-root MuiList-padding"
          style={{
            boxShadow: '0px -1px 0px rgba(228, 223, 223, 0.09)',
            width: '100%',
            background: '#000000',
            color: '#FFFFFF',
          }}>
          <ListItem button onClick={handleClick}>
            <Typography variant="h3" noWrap sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar src={userDetails.profileImage} sx={{ marginRight: open ? '10px' : '30px' }} />
              <ListItemText
                primaryTypographyProps={{
                  sx: {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '150px',
                  },
                }}
                primary={`${startCase(userDetails.firstName)} ${startCase(userDetails.lastName)}`}
              />
            </Typography>
          </ListItem>
        </div>
      </Grid>

      <Dialog
        role="alert"
        tabIndex="0"
        aria-labelledby="alert-dialog-description"
        open={showLogoutModal}
        onClose={() => handleLogout()}>
        <div>
          <DialogTitle id="alert-dialog-title">{'Logout?'}</DialogTitle>
          <DialogContent tabIndex="0">
            <DialogContentText id="alert-dialog-description">
              {'Are you sure you want to logout?'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button aria-label="No" onClick={() => handleLogout()} color="primary">
              {'No'}
            </Button>

            <Button
              aria-label="yes"
              onClick={() => {
                onLogOut();
                // logOutUser(userDetails.id);
                // , () => {
                //   dispatch(logoutUser(userDetails));
                // });
              }}
              color="primary"
              autoFocus>
              {'Yes'}
            </Button>
          </DialogActions>
        </div>
      </Dialog>

      {/* Menu Options */}
      <Menu
        className={classes.root}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 20,
          horizontal: 10,
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ background: '#00000', color: 'white', border: '1px solid grey' }}>
        {/* <MenuItem onClick={() => handleProfileModal()}>My Profile</MenuItem> */}
        <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default CurrentUserInfo;
