const getRoutes = {
  home: '/',
  mood: '/MoodRing',
  meetings: '/meetings',
  tools: '/tools',
  resources: '/resources',
  notifications: '/notifications',
  takeAssesement: '/take-assessement/:id',
  takeMoodRing: '/take-moodring',
  resourceDetails: '/resources/:title',
  joinMeeting: '/meetingDetail/:roomId',
  about: '/about',
  optOut: '/optOut',
  features: '/features',
  howItWorks: '/howItWorks',
  termsAndConditions: '/termsAndConditions',
  login: '/login',
  verifyOtp: '/verify-otp',
  resetPassword: '/resetPassword/:id',
  askForAssessment: '/ask-assessement',
  referrals: '/referrals',
  referralDetail: '/referrals/:referralId',
  chooseArticle: '/ChooseArticle',
  savedArticles: '/SavedArticles',
  appTour: '/app-tour',
  ssgProfile: '/mentor-profile',
  emergency: '/emergency',
  messages: '/messages',
  selectLogin: '/select-login',
  setupAccount: '/setup-account',
  forgotPassword: '/forgot-password',
  guides: '/guides',
  guidesdetails: '/guides/:guideId',
  goalsCurriculum: '/goalsCurriculum',
  settings: '/settings',
  profile: '/profile',
  ftue: '/ftue-screen',
  priorityprogress: '/priorityprogress',
  setPriority: '/priorityprogress/setPriority',
  customePriority: '/priorityprogress/customePriority',
  startPriority: '/startPriority',
  ftueSetPriority: '/ftueSetPriority',
  ftueMenteeAdvice: '/ftueMenteeAdvice',
};

export default getRoutes;
