import * as types from '../actionTypes';
import { generateWeekData } from '../../Helper';
const initialState = {
  avgMoodRing: [],
  badgeDetails: {},
  pointSummary: {},
  loadingStates: {
    isAvgMoodRingLoading: true,
    isBadgeDetails: true,
    isPointSummary: true,
  },
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case types.AVG_MOOD_RING_SUCCESS:
      return {
        ...state,
        avgMoodRing: action.data,
        loadingStates: { ...state.loadingStates, ...{ isAvgMoodRingLoading: false } },
      };

    case types.USER_BADGES_SUCCESS:
      return {
        ...state,
        badgeDetails: action.data,
        loadingStates: { ...state.loadingStates, ...{ isBadgeDetails: false } },
      };
    case types.POINT_SUMMARY_SUCCESS:
      let generatedGraphData = Object.values(generateWeekData(action.data.graphData));

      return {
        ...state,
        pointSummary: { ...action.data, generatedGraphData },
        loadingStates: { ...state.loadingStates, ...{ isPointSummary: false } },
      };
    default:
      return state;
  }
};

export default dashboard;
