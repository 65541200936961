import React from 'react';

import Login from './Login';
const Authentication = () => {
  return (
    <React.Fragment>
      <Login />
    </React.Fragment>
  );
};

export default Authentication;
