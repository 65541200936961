import axios from '../Config';
import handleAxiosError from '../Helper/AxiosErrorHanlder';

export const getProblemist = () => {
  return axios({
    url: `/problems/problemList`,
  }).catch((err) => handleAxiosError(err));
};

export const setMoodByMenteeId = (data) => {
  return axios({
    method: 'POST',
    url: `/mentee-mood-rings/setMood/${data.mentorId}`,
    data,
  }).catch((err) => handleAxiosError(err));
};
