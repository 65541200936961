import Cookies from 'js-cookie';
import * as types from '../actionTypes';

export const loginWithPhoneNumber = (payload, callback) => {
  return {
    type: types.LOGIN_REQUEST,
    payload,
    callback,
  };
};

export const validateUser = () => {
  // check for SSO

  if (Cookies.get('mh_token')) {
    localStorage.setItem('@authToken', Cookies.get('mh_token'));
    localStorage.setItem('@refreshToken', Cookies.get('mh_refreshToken'));
    Cookies.remove('mh_token', { domain: '.northeastern.mentorhubapp.org', path: '/' });
  }
  Cookies.remove('mh_token', { domain: '.northeastern.mentorhubapp.org', path: '/' });

  const token = localStorage.getItem('@authToken');

  if (token) return getCurrentUserDetails(() => {});
  return {
    type: types.SET_USER_VALIDATION,
  };
};

export const verifyOtp = (payload, callback) => {
  return {
    type: types.VERIFY_OTP_REQUEST,
    payload,
    callback,
  };
};

export const getCurrentUserDetails = (callback) => {
  return {
    type: types.CURRENT_USER_REQUEST,
    callback,
  };
};

export const logoutUser = () => {
  localStorage.removeItem('@authToken');
  localStorage.removeItem('@refreshToken');
  localStorage.removeItem('organizationLogo');
  window.location.replace('/');
  return {
    type: types.RESET_STORE,
  };
};

export const getFirbaseConfig = (callback) => {
  return {
    type: types.FIREBASE_CONFIG_REQUEST,
    callback,
  };
};

export const getFirebaseUser = (callback) => {
  return {
    type: types.FIREBASE_USER_REQUEST,
    callback,
  };
};

export const updateUserDetails = (payload) => {
  return {
    type: types.UPDATE_USER_DETAILS,
    payload,
  };
};

export const getNewToken = (payload) => {
  return {
    type: types.UPDATE_USER_DETAILS,
    payload,
  };
};

export const setAccountDetails = (payload, callback) => {
  return {
    type: types.SET_ACCOUNT_DETAILS_REQUEST,
    payload,
    callback,
  };
};

export const loginWithEmail = (payload, callback) => {
  return {
    type: types.LOGIN_WITH_EMAIL_REQUEST,
    payload,
    callback,
  };
};
