import { makeVar } from "@apollo/client";

export const lessonGoalMenteeVar = makeVar();
lessonGoalMenteeVar(null);

export const selectedGoalActivityVar = makeVar();
selectedGoalActivityVar(null);

export const selectedGoalBadgeVar = makeVar();
selectedGoalBadgeVar(null);

export const imageVar = makeVar();
imageVar(null);

export const selectedGoalVar = makeVar();
selectedGoalVar(null);

export const limitVar = makeVar(10);

