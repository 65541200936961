import * as types from '../actionTypes';

export const setUnreadNotificationCount = (payload) => {
  return {
    type: types.UNREAD_NOTIFICATION_COUNT_SUCCESS,
    payload,
  };
};

export const setNotifications = (payload) => {
  return {
    type: types.USER_NOTIFICATIONS_SUCCESS,
    payload,
  };
};

export const getUnreadNotificationCount = () => {
  return {
    type: types.UNREAD_NOTIFICATION_COUNT_REQUEST,
  };
};
export const getUserNotifications = () => {
  return {
    type: types.USER_NOTIFICATIONS_REQUEST,
  };
};
