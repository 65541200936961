import axios from '../Config';
import handleAxiosError from '../Helper/AxiosErrorHanlder';

export const getMenteeDetails = (payload) => {
  return axios({
    method: 'POST',
    url: `menteeDetails/${payload.userId}`,
    data: {
      fields: [
        'programManager',
        'matchSupportSpecialist',
        'primaryMentor',
        'secondaryMentors',
        'streaks',
      ],
    },
  }).catch((err) => handleAxiosError(err));
};

export const getGroupMemberMenteeDetails = (payload) => {
  return axios({
    method: 'POST',
    url: `menteeDetails/${payload.userId}`,
    data: {
      fields: [
        'firstName',
        'lastName',
        'email',
        'username',
        'phoneNumber',
        'profileImage',
        'gender',
        'aboutUs',
        'title',
        'role',
        'firestoreId',
        'countryCode',
        'name_id',
        'preferredName',
        'currentCity',
        'homeTown',
        'college',
        'major',
        'collegeProgram',
        'bio',
        'pronoun',
        'collegeIds',
        'collegeDetails',
        'majorIds',
        'majorDetails',
        'additionalProgramIds',
        'additionalProgramDetails',
        'firstLoggedInDate',
        'id',
        'SSGsupport',
      ],
    },
  }).catch((err) => handleAxiosError(err));
};

export const getGroupMemberMentorDetails = (payload) => {
  return axios({
    method: 'POST',
    url: `mentorDetails/${payload.userId}`,
    data: {
      fields: [
        'firstName',
        'lastName',
        'email',
        'username',
        'phoneNumber',
        'profileImage',
        'gender',
        'aboutUs',
        'title',
        'role',
        'firestoreId',
        'countryCode',
        'name_id',
        'preferredName',
        'currentCity',
        'homeTown',
        'college',
        'major',
        'collegeProgram',
        'bio',
        'pronoun',
        'collegeIds',
        'collegeDetails',
        'majorIds',
        'majorDetails',
        'additionalProgramIds',
        'additionalProgramDetails',
        'firstLoggedInDate',
        'id',
      ],
    },
  }).catch((err) => handleAxiosError(err));
};

export const getAssociatedMenteeList = () => {
  return axios({
    method: 'GET',
    url: `users/mentee/menteeList`,
  }).catch((err) => handleAxiosError(err));
};

export const getSecondaryMentorsList = (payload) => {
  return axios({
    method: 'GET',
    url: `/user/mentee/${payload.id}/secSsg?skip=${payload.skip}&limit=10&search=${payload.search}`,
  }).catch((err) => handleAxiosError(err));
};
