import { Button, Divider, Fade, Grid, Slider, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserDetails } from '../../../Store/Actions/Auth';
import {
  getProblemList,
  setMoodByMenteeId,
  setMoodRingInLocal,
} from '../../../Store/Actions/MoodRings';

import { withStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import {
  getProblemListData,
  getUserDetails,
  isProblemListLoading,
} from '../../../Store/reduxSelectors';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import learnerAction from '../../../Helper/Analytics/learnerAction';
import screenNames from '../../../Helper/Analytics/screenNames';
import { trackEvent } from '../../../Helper/EventTracker';
import getRoutes from '../../../Routes/RoutePaths';
import CardWrapper from '../../Common/CardWrapper';
import MoodRingPieChart from './MoodRingPieChart';

const MoodRing = (props) => {
  const dispatch = useDispatch();
  const [isSubmiting, setIsSubmitting] = React.useState(false);

  const history = useHistory();
  const { control, getValues } = useForm();

  const problemList = useSelector((state) => getProblemListData(state));
  const isLoading = useSelector((state) => isProblemListLoading(state));
  const userDetails = useSelector((state) => getUserDetails(state));
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    trackEvent('view', {
      screen: screenNames.TakeMoodRing,
    });
    dispatch(getProblemList(userDetails.id, () => {}));
  }, []);

  const handleOnSubmit = () => {
    trackEvent('click', {
      screen: screenNames.TakeMoodRing,
      Action: learnerAction.SUBMIT_MOOD_RING,
    });
    const values = getValues();
    const moodRingData = values.moodRingData;
    const moodRingUpdated = Object.values(moodRingData).reduce((a, b) => a + b, 0);

    if (moodRingUpdated) updateMoodRingData(moodRingData);
    else handleClickOpen();
  };
  const handleForceUpdate = () => {
    const values = getValues();
    const moodRingData = values.moodRingData;
    updateMoodRingData(moodRingData);
    handleClose();
  };

  const updateMoodRingData = (moodRingData) => {
    setIsSubmitting(!isSubmiting);
    let payload = {
      mentorId: userDetails.id,
      activeProgramId: userDetails.activeProgram,
    };
    const moods = Object.keys(moodRingData).map((key) => ({
      problemId: key.split('_')[1],
      percentage: moodRingData[key],
    }));
    payload.moods = moods;

    dispatch(
      setMoodByMenteeId(payload, () => {
        setIsSubmitting(isSubmiting);
        updateCurrentUserForMoodRing();
      }),
    );
  };
  const updateCurrentUserForMoodRing = () => {
    const shouldGoBack = !userDetails.takeAssessment && !userDetails.takeMoodRingAssessment;
    if (shouldGoBack) {
      history.goBack();
    } else {
      dispatch(updateUserDetails({ takeAssessment: false, takeMoodRingAssessment: false }));
      history.replace(getRoutes.home);
    }
  };

  const PrettoSlider = withStyles({
    root: {
      color: '#fff',
      height: 5,
    },
    thumb: {
      height: 20,
      width: 20,
      backgroundColor: (props) => props.color,
      border: (props) => `1px solid ${props.color}`,
      // marginTop: -7,
      // marginLeft: -10,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)',
    },
    track: {
      backgroundColor: (props) => props.color,
      height: 6,
      borderRadius: 4,
      color: (props) => `${props.color} !important`,
    },
    rail: {
      backgroundColor: '#fff',
      border: (props) => `1px solid ${props.color} !important`,
      height: 4,
      borderRadius: 4,
    },
  })(Slider);

  const RenderItem = ({ item, index, onChange, value }) => {
    return (
      <React.Fragment>
        <Grid className="moodring-each" container spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={5}>
              <Grid item xs={1}>
                <img src={item.problemImage} alt={item.problemName + ' icon'} />
              </Grid>
              <Grid item xs={10} aria-label="update MoodRing">
                <h2 style={{ color: item.color }} aria-label={item.problemName}>
                  {item.problemName}
                </h2>{' '}
              </Grid>
            </Grid>

            <Typography sx={{ minHeight: '40px' }} aria-label={item.problemDescription}>
              {item.problemDescription}
            </Typography>
            <Grid>
              <PrettoSlider
                color={item.color}
                value={value}
                aria-label={item.problemName}
                onChange={(event, value) => onChange(value)}
                onChangeCommitted={(event, value) =>
                  dispatch(
                    setMoodRingInLocal({
                      [item.id]: { name: item.problemName, y: value, color: item.color },
                    }),
                  )
                }
              />
              <span className="progress-label">
                <p
                  style={{
                    color: '#14B09D',
                    marginTop: '0px',
                    fontSize: '12px',
                    fontWeight: 500,
                  }}>
                  Going Well
                </p>
                <p
                  style={{
                    color: '#DE2929',
                    marginTop: '0px',
                    fontSize: '12px',
                    fontWeight: 500,
                  }}>
                  Very Challenging
                </p>
              </span>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  const RightComponent = () => {
    return (
      <Button
        sx={{ width: '140px', minHeight: '20px', height: '40px' }}
        size="small"
        variant="contained"
        color="primary"
        aria-label="Click to Update MoodRing"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleOnSubmit();
          }
        }}
        onClick={() => handleOnSubmit()}>
        <span style={{ color: '#fff' }}>Save</span>
      </Button>
    );
  };

  return (
    <div className="moodring-wrap">
      <CardWrapper
        tabIndex={0}
        title={'Mood Ring'}
        rightComponent={RightComponent()}
        titleStyles={{ fontSize: '20px' }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '14px' }}>
              This is your chance to reflect on how things are going in your life. This also gives
              your mentor a sense of what you might want their help with
            </Typography>
          </Grid>
          <Grid sx={{ paddingTop: '20px' }} item xs={12}>
            <Divider light />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              // borderRight: '1px solid #00000020',
            }}>
            {isLoading ? <Skeleton height={430} /> : <MoodRingPieChart />}
          </Grid>
          <Grid item xs={12} className="moodring-right">
            <Grid container spacing={3}>
              {isLoading ? (
                <Grid container spacing={2} sx={{ padding: '10px' }}>
                  {Array(8)
                    .fill(0)
                    .map((item, index) => (
                      <Grid key={index} item xs={12} md={6}>
                        <Skeleton height={130} />
                      </Grid>
                    ))}
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Fade in={true}>
                    <Grid container spacing={2}>
                      {problemList.map((item, index) => (
                        <Grid item xs={12} md={6} key={index}>
                          <Controller
                            defaultValue={0}
                            control={control}
                            name={`moodRingData.${item.problemName}_${item.id}`}
                            render={({ onChange, onBlur, value, name, ref }) => (
                              <RenderItem
                                onChange={onChange}
                                value={value}
                                item={item}
                                index={index}
                              />
                            )}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Fade>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">Moodring </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you don't have any challenges right now?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" aria-label="Challenges Alert">
              No
            </Button>
            <Button
              onClick={() => handleForceUpdate()}
              color="primary"
              aria-label="Challenges Alert"
              autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </CardWrapper>
    </div>
  );
};

export default MoodRing;
