import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#27cad2',
    },
    secondary: {
      main: '#F8F8F8',
    },
  },
  typography: {
    fontFamily:
      "Lato,Avenir,Nunito,Nunito,source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace ",
  },

  // overrides: {
  //   MuiButton: {
  //     root: {
  //       borderRadius: 4,
  //       fontWeight: 'bold',
  //       textTransform: 'capitalize',
  //       minHeight: 50,
  //     },
  //   },
  //   MuiCard: {
  //     root: {
  //       borderRadius: 4,
  //     },
  //   },
  //   MuiInputBase: {
  //     root: {
  //       borderRadius: '4px !important',
  //       backgroundColor: '#F8F8F8 !important',
  //     },
  //   },
  // },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          fontWeight: 'bold',
          textTransform: 'capitalize',
          minHeight: 50,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: '4px !important',
          backgroundColor: '#F8F8F8 !important',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          width: '163px',
          textTransform: 'capitalize !important',
        },
      },
    },
  },
});
// , 100%
export default theme;
