import { GENERATE_REFRESH_TOKEN, GET_OTP, LOGIN, LOGOUT, VERIFY_OTP } from '../ApolloQueries/User';
import axios from '../Config';
import client from '../Config/graphQLConf';
import handleAxiosError from '../Helper/AxiosErrorHanlder';

// export const getOtpForLogin = ({ countryCode, phoneNumber }) => {
//   let url =
//     process.env.NODE_ENV === 'production'
//       ? `users/getOtp/countryCode/+${countryCode}/phoneNumber/${phoneNumber}`
//       : `/users/getOtpDev/${phoneNumber}`;
//   return axios({
//     url,
//   }).catch((err) => handleAxiosError(err));
// };
export const getOtpForLogin = async ({ countryCode, phoneNumber }) => {
  try {
    return await client.mutate({
      mutation: GET_OTP,
      variables: {
        countryCode: '+' + countryCode,
        phoneNumber: phoneNumber,
      },
      context: { clientName: 'userService' },
    });
  } catch (err) {
    throw new Error(err.message);
  }
};
// export const verifyOtp = (data) => {
//   return axios({
//     method: 'POST',
//     url: 'users/verifyOtp',
//     data,
//   }).catch((err) => handleAxiosError(err));
// };
export const verifyGeneratedOtp = async (payload) => {
  try {
    return await client.mutate({
      mutation: VERIFY_OTP,
      variables: {
        object: {
          country_code: '+' + payload.countryCode,
          // device_type: 'WEB',
          otp: payload.otp,
          phone_number: payload.phoneNumber,
        },
      },
      context: { clientName: 'userService' },
    });
  } catch (err) {
    return await handleAxiosError(err);
  }
};

export const getCurrentUserDetails = () => {
  return axios({
    url: 'users/loggedinApp',
  }).catch((err) => handleAxiosError(err));
};

export const patchCurrentUser = (data) => {
  return axios({
    method: 'PATCH',
    url: `user/${data.userId}`,
    data: { timezone: data.timezone },
  }).catch((err) => handleAxiosError(err));
};

export const getFirebaseConfig = () => {
  return axios({
    url: 'settings/firebaseMenteeWeb',
  }).catch((err) => handleAxiosError(err));
};

export const getFirebaseUser = () => {
  return axios({
    url: 'settings/firebaseUser',
  }).catch((err) => handleAxiosError(err));
};

export const registerDeviceToken = (payload) => {
  return axios({
    method: 'POST',
    url: `user/${payload.userId}/setDeviceToken`,
    data: payload.data,
  }).catch((err) => handleAxiosError(err));
};

// export const logOutUser = (userId) => {
//   return axios({
//     method: 'GET',
//     url: `users/logout/${userId}`,
//   }).catch((err) => handleAxiosError(err));
// };

export const logOutUser = async (data) => {
  return await client
    .mutate({
      mutation: LOGOUT,
      context: {
        clientName: 'userService',
        headers: {
          Authorization: `Bearer ${data}`,
        },
      },
    })
    .catch(async (err) => {
      console.error('err', err.message);
      const response = await getNewToken(localStorage.getItem('refreshToken'));
      const { token } = response.data.generate_token;
      localStorage.setItem('@authToken', token);
      const resp = await logOutUser(localStorage.getItem('@authToken'));
      localStorage.removeItem('@authToken');
      localStorage.removeItem('@refreshToken');
      if (Object.keys(resp?.data).includes('status')) {
        window.location.replace(resp.data.redirectLink);
      }
    });
};

// export const getNewToken = (refreshToken) => {
//   return axios({
//     method: 'POST',
//     url: `auth/refreshToken`,
//     data: { refreshToken },
//   }).catch((err) => {
//     delete axios.defaults.headers.common['Authorization'];
//     localStorage.clear();
//     setTimeout(() => {
//       window.location.href = '/login';
//     }, 100);
//   });
// };
export const getNewToken = async (refreshToken) => {
  return await client
    .mutate({
      mutation: GENERATE_REFRESH_TOKEN,
      variables: {
        refreshToken: refreshToken,
      },
      context: { clientName: 'userService' },
    })
    .catch(() => {
      // delete axios.defaults.headers.common['Authorization'];
      localStorage.clear();
      setTimeout(() => {
        window.location.href = '/login';
      }, 100);
    });
};

export const getFirebaseToken = () => {
  return axios({
    method: 'GET',
    url: `getFirebaseAuthToken`,
  }).catch((err) => handleAxiosError(err));
};

export const getSetupAccountDetails = (token) => {
  return axios({
    method: 'GET',
    url: `users/GetSetupAccountDetails/${token}`,
  }).catch((err) => handleAxiosError(err));
};

export const setUserNameAndPassword = (payload) => {
  return axios({
    method: 'POST',
    url: `user/${payload.token}/setUsernameAndPasswordWithToken`,
    data: payload.data,
  }).catch((err) => handleAxiosError(err));
};

// export const loginWithMail = (data) => {
//   return axios({
//     method: 'POST',
//     url: `users/menteeLogin`,
//     data,
//   }).catch((err) => handleAxiosError(err));
// };
export const loginWithMail = async (data) => {
  try {
    return await client.mutate({
      mutation: LOGIN,
      variables: {
        object: {
          email: data.username,
          password: data.password,
        },
      },
      context: { clientName: 'userService' },
    });
  } catch (err) {
    return await handleAxiosError(err);
  }
};

export const forgotPassword = (data) => {
  return axios({
    method: 'POST',
    url: `users/forgotPasswordForMentees`,
    data,
  }).catch((err) => handleAxiosError(err));
};

export const getMenteeDetails = (data) => {
  return axios({
    method: 'POST',
    url: `/menteeDetails/${data.menteeId}`,
    data: {
      fields: [
        'availableFeatures',
        'thirdPartyApps',
        'hobbies',
        'hobbyIds',
        'countryCode',
        'streaks',
        'username',
        'FtueShowMSS',
        'ftueSetGoals',
        'feedback',
        'organization',
      ],
    },
  }).catch((err) => handleAxiosError(err));
};
