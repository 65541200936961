import * as types from '../actionTypes';

const initialState = {
  snackbarProps: {
    open: false,
    variant: 'success',
    message: '',
  },
  isSideDrawerOpen: true,
  isModalOpen: false,
  notificationPermissionStatus: {},
};

const global = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_SNACKBAR:
      const hasSnackbarProps = action.payload;
      return {
        ...state,
        snackbarProps: hasSnackbarProps ? {...state.snackbarProps, ...{open: true}, ...hasSnackbarProps} : {...state.snackbarProps, ...{open: false}},
      };
    case types.TOGGLE_SIDE_DRAWER:
      return {
        ...state,
        isSideDrawerOpen: !state.isSideDrawerOpen,
      };
    case types.TOGGLE_MODAL:
      return {
        ...state,
        isModalOpen: !state.isModalOpen,
      };
    case types.TOGGLE_NOTIFICATION_PERMISSION_STATUS:
      return {
        ...state,
        notificationPermissionStatus: action.data,
      };
    default:
      return state;
  }
};

export default global;
