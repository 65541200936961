import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { Avatar } from '@mui/material';
import getLoggedUserDetails from '../services/getLoggedUserDetails';

import { Button } from '@mui/material';
// import { trackEvent } from '../../Helper/analytics/EventTracker';
import { useReactiveVar } from '@apollo/client';

import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import imageCompression from 'browser-image-compression';
import ProImage from '../../Assets/Images/ftue-pro-image.svg';
import learnerAction from '../../Helper/Analytics/learnerAction';
import screenNames from '../../Helper/Analytics/screenNames';
import { trackEvent } from '../../Helper/EventTracker';
import theme from '../../MaterialUiTheme';
import { updateUserDetails } from '../../Store/Actions/Auth';
import ReactCropper from '../Common/croper';
import { cropperModalVar } from '../Common/reactiveVar';
import awsFileUpload from '../services/awsFileUpload.js';
import updateProfileDetails from '../services/patchUserDetails.js';

// import screenNames from '../../Helper/analytics/screenNames';
// import learnerActions from '../../Helper/analytics/learnerActions';
export default function FtueOne(props) {
 
  const dispatch = useDispatch();
  const ref = useRef();
  const [profileImage, setProfileImage] = React.useState();
  const [profileImageFile, setProfileImageFile] = React.useState();
  const [profileImagerror, setProfileImagerror] = React.useState(false);
  const [, setLoading] = React.useState(false);
  const [profileImagePreview, setProfileImagePreview] = React.useState('');
  const [preferredName, setPreferredName] = React.useState();
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);
  const cropperModal = useReactiveVar(cropperModalVar);

  const sSnackbar = () => {
    setSuccessSnackbar(true);
  };

  const eSnackbar = () => {
    setErrorSnackbar(true);
  };

  const eSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorSnackbar(false);
  };
  const sSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessSnackbar(false);
    setTimeout(() => {
      props.stepUpdate('next');
    }, 100);
  };

  useEffect(() => {
    setPreferredName(props.userDetails ? props.userDetails.preferredName : '');
    setProfileImage(props.userDetails ? props.userDetails.profileImage : '');
    setProfileImagePreview(props.userDetails ? props.userDetails.profileImage : '');
    setLoading(false);
    cropperModalVar({
      ...cropperModal,
      croppedImage: props.userDetails ? props.userDetails.profileImage : '',
    });
  }, [props]);

  const clearImage = () => {
    ref.current.value = '';
    setProfileImage('');
    setProfileImageFile('');
    setProfileImagePreview('');
    cropperModalVar({ ...cropperModal, croppedImage: '', imgURL: '', croppedFile: '' });
  };

  const handlePreName = (e) => {
    setPreferredName(e);
  };

  const getOrganImagePreview = (file) => {
    let reader = new FileReader();
    reader.onload = () => {
      cropperModalVar({ ...cropperModal, showModal: true, imgURL: reader.result });
      let data = {
        imagePreview: reader.result,
        field: 'profileImage',
        uploadField: 'uploadFile',
        file: profileImage,
      };

      setProfileImagePreview(data.imagePreview);

      setProfileImageFile(file);
      setProfileImagerror(false);
    };
    reader.readAsDataURL(file);
  };

  async function profileUpdate() {
    trackEvent('click', {
      screen: screenNames.FtueWelcome,
      Action: learnerAction.FTUE_PROFILE_SETUP,
    });
    setLoading(true);
    let ProImg = profileImage;

    if (cropperModal.croppedFile !== '') {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(cropperModal.croppedFile, options);

        const res = await awsFileUpload(compressedFile, 'profileImage/mentee');

        ProImg = res.publicUrl;
      } catch (error) {}
    }

    profileEditUpdate(ProImg);
  }

  const profileEditUpdate = (ProImg) => {
    let data = {
      preferredName: preferredName,
      profileImage: ProImg,
      ftueProfileSetup: false,
    };

    updateProfileDetails(props.userDetails.id, data)
      .then((response) => {
        getLoggedUserDetails(props.userDetails.id).then((res) => {
          props.setLoggedData(res);
          dispatch(
            updateUserDetails({
              preferredName: res.data.preferredName,
              profileImage: res.data.profileImage,
            }),
          );
        });
        sSnackbar();
        setProfileImage('');
        setProfileImageFile('');
      })
      .catch((error) => {});
  };

  React.useEffect(() => {
    setTimeout(() => {
      document.getElementById(`ftue-title-one`).focus();
    }, 1000);
  }, []);

  return (
    <div className="ftue-step">
      <h2 id="ftue-title-one" tabIndex="0">
        I just have a few questions that will help me get to know you better. This will take less
        than one minute.
      </h2>
      {props.userDetails && profileImagePreview == '' ? (
        <div
          id="ftue-profile"
          className="thumb-image"
          tabIndex="0"
          area-label="press enter to upload profile picture"
          onKeyPress={() => document.getElementById('new-image-upload').click()}>
          <img
            onClick={() => document.getElementById('new-image-upload').click()}
            src={ProImage}
            alt="profileImage"
          />
          <input
            type="file"
            style={{ display: 'none' }}
            id="new-image-upload"
            onChange={(e) => {
              getOrganImagePreview(e.target.files[0]);
            }}
          />
          <a onClick={() => document.getElementById('new-image-upload').click()}>
            Tap to upload a profile picture
          </a>
        </div>
      ) : (
        <div className="thumb-image" style={{ objectFit: 'cover' }}>
          <Avatar
            alt="profileImage"
            src={cropperModal.croppedImage}
            onClick={() => document.getElementById('edit-image-upload').click()}></Avatar>
          <input
            ref={ref}
            type="file"
            style={{ display: 'none' }}
            id="edit-image-upload"
            onChange={(e) => {
              getOrganImagePreview(e.target.files[0]);
            }}
          />
          <a
            onClick={() => {
              clearImage();
            }}>
            {' '}
            Remove Image{' '}
          </a>
          <br />
          {profileImagerror === true && (
            <span style={{ color: '#D41B2C', fontSize: '10px', display: 'block' }}>
              Uploaded image was too large
            </span>
          )}
        </div>
      )}
      <h2>What should I call you?</h2>
      <form>
        <TextField
          label="Your name or nickname."
          sx={{
            '& .MuiFormLabel-root': {
              fontFamily: 'Lato',
              left: '-13px',
            },
            '& .MuiOutlinedInput-input': {
              padding: '0px',
              paddingLeft: '0px',
              paddingTop: '17px',
              paddingBottom: '7px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              left: '-2px',
            },
          }}
          id="name"
          fullWidth
          defaultValue={props.loggedData ? props.loggedData.data.preferredName : ''}
          onChange={(event) => handlePreName(event.target.value)}
        />
      </form>
      <Button
        style={{
          width: '100%',
          height: '50px',
          backgroundColor: theme.palette.primary.main,
          fontFamily: 'Lato',
          fontWeight: 'bold',
          color: 'white',
          textTransform: 'capitalize',
        }}
        aria-label="Save"
        fullWidth
        variant="contained"
        onClick={() => profileUpdate()}
        color="primary">
        Save
      </Button>
      <Snackbar
        open={errorSnackbar}
        style={{ zIndex: '10000' }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={eSnackbarClose}>
      
        <Alert severity="error" variant="filled">
          Please enter your name or Profile Image
        </Alert>
      </Snackbar>
      <Snackbar
        open={successSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id="ratingSuccess"
        autoHideDuration={3000}
        onClose={sSnackbarClose}>
      
        <Alert severity="success" variant="filled">
          Nickname and Profile Image successfully updated
        </Alert>
      </Snackbar>
      <ReactCropper />
    </div>
  );
}
