export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const PROBLEM_LIST_REQUEST = 'PROBLEM_LIST_REQUEST';
export const PROBLEM_LIST_SUCCESS = 'PROBLEM_LIST_SUCCESS';
export const SET_MOODRING_LOCAL = 'SET_MOODRING_LOCAL';
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';

export const SET_MOOD_REQUEST = 'SET_MOOD_REQUEST';
export const SET_MOOD_SUCESS = 'SET_MOOD_SUCESS';

export const UNREAD_NOTIFICATION_COUNT_REQUEST = 'UNREAD_NOTIFICATION_COUNT_REQUEST';
export const UNREAD_NOTIFICATION_COUNT_SUCCESS = 'UNREAD_NOTIFICATION_COUNT_SUCCESS';
export const USER_NOTIFICATIONS_REQUEST = 'USER_NOTIFICATIONS_REQUEST';
export const USER_NOTIFICATIONS_SUCCESS = 'USER_NOTIFICATIONS_SUCCESS';

export const ASSESSMENT_QUES_REQUEST = 'ASSESSMENT_QUES_REQUEST';
export const ASSESSMENT_QUES_SUCCESS = 'ASSESSMENT_QUES_SUCCESS';
export const ASSESSMENT_QUES_POST = 'ASSESSMENT_QUES_POST';
export const ASSESSMENT_QUES_POST_SUCCESS = 'ASSESSMENT_QUES_POST_SUCCESS';

export const INSTALLED_APPS_REQUEST = 'INSTALLED_APPS_REQUEST';
export const INSTALLED_APPS_SUCCESS = 'INSTALLED_APPS_SUCCESS';
export const RECOMMENDED_APPS_REQUEST = 'RECOMMENDED_APPS_REQUEST';
export const RECOMMENDED_APPS_SUCCESS = 'RECOMMENDED_APPS_SUCCESS';
export const ALL_APPS_REQUEST = 'ALL_APPS_REQUEST';
export const ALL_APPS_SUCCESS = 'ALL_APPS_SUCCESS';

export const AVG_MOOD_RING_REQUEST = 'AVG_MOOD_RING_REQUEST';
export const AVG_MOOD_RING_SUCCESS = 'AVG_MOOD_RING_SUCCESS';

export const MOOD_RING_DASH_REQUEST = 'MOOD_RING_DASH_REQUEST';
export const MOOD_RING_DASH_SUCCESS = 'MOOD_RING_DASH_SUCCESS';
export const MOOD_RING_DATA_STATUS_REQUEST = 'MOOD_RING_DATA_STATUS_REQUEST';
export const MOOD_RING_DATA_STATUS_SUCCESS = 'MOOD_RING_DATA_STATUS_SUCCESS';

export const USER_BADGES_REQUEST = 'USER_BADGES_REQUEST';
export const USER_BADGES_SUCCESS = 'USER_BADGES_SUCCESS';

export const POINT_SUMMARY_REQUEST = 'POINT_SUMMARY_REQUEST';
export const POINT_SUMMARY_SUCCESS = 'POINT_SUMMARY_SUCCESS';

export const VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';

export const CURRENT_USER_REQUEST = 'CURRENT_USER_REQUEST';
export const CURRENT_USER_SUCCESS = 'CURRENT_USER_SUCCESS';
export const SET_USER_VALIDATION = 'SET_USER_VALIDATION';

export const FIREBASE_CONFIG_REQUEST = 'FIREBASE_CONFIG_REQUEST';
export const FIREBASE_CONFIG_SUCCESS = 'FIREBASE_CONFIG_SUCCESS';
export const FIREBASE_USER_REQUEST = 'FIREBASE_USER_REQUEST';
export const FIREBASE_USER_SUCCESS = 'FIREBASE_USER_SUCCESS';
export const FIREBASE_GET_MESSAGE_TOKEN = 'FIREBASE_GET_MESSAGE_TOKEN';

export const TOGGLE_SNACKBAR = 'TOGGLE_SNACKBAR';
export const RESET_STORE = 'RESET_STORE';
export const TOGGLE_SIDE_DRAWER = 'TOGGLE_SIDE_DRAWER';

export const GET_QUICK_LINK = 'GET_QUICK_LINK';
export const GET_QUICK_LINK_SUCCESS = 'GET_QUICK_LINK_SUCCESS';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';

export const TOGGLE_NOTIFICATION_PERMISSION_STATUS = 'TOGGLE_NOTIFICATION_PERMISSION_STATUS';

export const GET_NEW_TOKEN = 'GET_NEW_TOKEN';

export const GET_MENTEE_DETAILS = 'GET_MENTEE_DETAILS';
export const GET_MENTEE_DETAILS_REQUEST = 'GET_MENTEE_DETAILS_REQUEST';
export const GET_MENTEE_DETAILS_SUCCESS = 'GET_MENTEE_DETAILS_SUCCESS';

export const GET_ASSOCIATED_MENTEE_LIST = 'GET_ASSOCIATED_MENTEE_LIST';
export const GET_ASSOCIATED_MENTEE_LIST_REQUEST = 'GET_ASSOCIATED_MENTEE_LIST_REQUEST';
export const GET_ASSOCIATED_MENTEE_LIST_SUCCESS = 'GET_ASSOCIATED_MENTEE_LIST_SUCCESS';

export const SET_ACCOUNT_DETAILS_REQUEST = 'SET_ACCOUNT_DETAILS_REQUEST';
export const LOGIN_WITH_EMAIL_REQUEST = 'LOGIN_WITH_EMAIL_REQUEST';

export const HOBBIES_REQUEST = 'HOBBIES_REQUEST';
export const HOBBIES_SUCCESS = 'HOBBIES_SUCCESS';
