import { Avatar, Grid, IconButton, Menu, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import getRoutes from '../../../Routes/RoutePaths';
import { logoutUser } from '../../../Store/Actions/Auth';
import { toggleModal } from '../../../Store/Actions/Global';
import { getUserDetails, isUserLoggedIn } from '../../../Store/reduxSelectors';
import MenuItems from './MenuItems';
// import MenuIcon from '../../../Assets/Images/burger.svg';

import BrandLogo from '../../../Assets/Images/MentorHub-Logo.svg';
//import { trackEvent } from '../../../Helper/EventTracker';
const doNotShowOnRoutes = [
  getRoutes.takeMoodRing,
  getRoutes.takeAssesement,
  getRoutes.askForAssessment,
];
const doNotShowMenuIcon = [
  getRoutes.login,
  getRoutes.takeAssesement,
  getRoutes.takeMoodRing,
  getRoutes.askForAssessment,
  getRoutes.login,
  getRoutes.verifyOtp,
];
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const CustomAppBar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [logoutOpen, setLogoutOpen] = React.useState(false);
  const userDetails = useSelector((state) => getUserDetails(state));
  const isLoggedIn = useSelector((state) => isUserLoggedIn(state));

  const isMenuOpen = Boolean(anchorEl);
  const location = useLocation();

  const dispatch = useDispatch();

  const toggleMenu = (value) => {
    if (value != undefined && value.currentTarget == undefined) {
      setAnchorEl(value);
    } else setAnchorEl(null);
  };

  const handleMenuSelect = () => {
    // trackEvent('click', 'AppBar');
    toggleMenu();
    dispatch(logoutUser());
  };
  const handleEditProile = () => {
    //  trackEvent('click', 'profile');
    toggleMenu();
    dispatch(toggleModal());
  };
  const handleClose = () => {
    //  trackEvent('click', 'AppBar');
    setLogoutOpen(!logoutOpen);
  };
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={'profile-id'}
      open={isMenuOpen}
      onClose={toggleMenu}>
      <MenuItem onClick={handleEditProile}>Profile</MenuItem>
      <MenuItem onClick={handleClose}>Logout</MenuItem>
    </Menu>
  );
  const organizationImage =
    userDetails && userDetails.organization && userDetails.organization.organizationImage;
  const orgLogo = localStorage.getItem('organizationLogo');
  return (
    <React.Fragment>
      <Grid
        container
        style={{ minHeight: 60, backgroundColor: '#000000' }}
        justifyContent="space-between">
        <Grid
          item
          style={{
            paddingLeft: 22,
            display: 'flex',
            alignItems: 'center',
            maxHeight: 60,
          }}>
          {/* {doNotShowMenuIcon.includes(location.pathname) ? null : (
              <IconButton onClick={() => dispatch(toggleSideDrawer())}>
                <ReactSVG src={MenuIcon} />
              </IconButton>
            )} */}
          <React.Fragment>
            {orgLogo ? (
              <img role="presentation" src={orgLogo} alt="" style={{ maxHeight: 50 }} />
            ) : (
              <img
                tabIndex="-1"
                area-hidden="true"
                src={BrandLogo}
                alt="logo"
                style={{ width: 120 }}
              />
            )}
          </React.Fragment>
        </Grid>

        {isLoggedIn && !doNotShowOnRoutes.includes(location.pathname) ? (
          <Grid item style={{ display: 'flex' }}>
            <MenuItems />
          </Grid>
        ) : null}

        <Grid
          item
          style={{
            paddingRight: 30,
            display: 'flex',
            alignItems: 'center',
            width: '226px',
            justifyContent: 'flex-end',
          }}
          className="user-data">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}>
            <span style={{ fontSize: 18, fontWeight: 400 }}>
              {userDetails.firstName} {userDetails.lastName}
            </span>
            {isLoggedIn && location.pathname !== '/take-moodring' ? (
              <IconButton className="user-img" onClick={(event) => toggleMenu(event.currentTarget)}>
                <Avatar src={userDetails.profileImage} />
              </IconButton>
            ) : (
              <React.Fragment>
                {null}
                {/* <PublicLinks />*/}
              </React.Fragment>
            )}
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CustomAppBar;
