import React, { useEffect } from 'react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { GET_ROOM_ACCESS } from './gql';
import { useSelector } from 'react-redux';
import { meetingVar } from '../services/vars';

export const useRoomAccess = () => {
  const meetingId = useReactiveVar(meetingVar);
  const user = useSelector((state) => state.authentication.userDetails);
  const [getRoomAccess, { loading, data }] = useLazyQuery(GET_ROOM_ACCESS, {
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    if (!meetingId) return;
    getRoomAccess({
      variables: {
        room: meetingId,
        user: user.id,
      },
    });
  }, [meetingId]);
  return { loading, data, user };
};
