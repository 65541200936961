import firebase from 'firebase';
import app from 'firebase/app';
import 'firebase/messaging';

const initializeAppWithFirebase = (config) => app.initializeApp(config);

export default initializeAppWithFirebase;

export const askForPermissionToReceiveNotifications = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const messaging = firebase.messaging();
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/firebase-messaging-sw.js').then(async (registration) => {
          // firebase.messaging().useServiceWorker(registration);
          // await messaging.requestPermission();
          const token = await messaging.getToken({ serviceWorkerRegistration: registration });
          resolve(token);
        });
      }

      return;
    } catch (error) {
      reject(error);
    }
  });
};
