import { takeLatest, call, put, fork } from 'redux-saga/effects';
import * as api from '../Api/GetQuickLink';
import * as types from '../Store/actionTypes';

import logError from '../Helper';

const sagaPath = 'src/Sagas/Auth.js';
function* getQuickLinkData(action) {
    const {  callback } = action;
    try {
        const response = yield call(api.getQuickLinkData);
        const data = response.data;
        yield put({ type: types.GET_QUICK_LINK_SUCCESS,data });
        callback(true);
    } catch (e) {
        logError(e, sagaPath + 'getQuickLinkData');
        callback();
    }
}
function* watchQuickLinkSaga() {
    yield takeLatest(types.GET_QUICK_LINK, getQuickLinkData);
}
const quickLinkSaga = [fork(watchQuickLinkSaga)];
export default quickLinkSaga;