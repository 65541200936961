import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';

const EmptyStates = ({ title, rootStyles = {} }) => {
  return (
    <Grid container justifyContent="center" alignItems="center" style={{ ...rootStyles }}>
      <Grid item>
        <Typography>{title}</Typography>
      </Grid>
    </Grid>
  );
};

export default EmptyStates;
