import { useMutation } from '@apollo/client';
import { Grid, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Close from '../../../Assets/Images/Close.svg';
import Prioritychecked from '../../../Assets/Images/Prioritychecked.svg';
import PriorityUncheck from '../../../Assets/Images/PriorityUncheck.svg';
import { generateSnackbarPayload } from '../../../Helper';
import learnerAction from '../../../Helper/Analytics/learnerAction';
import screenNames from '../../../Helper/Analytics/screenNames';
import { trackEvent } from '../../../Helper/EventTracker';
import * as types from '../../../Store/actionTypes';
import CustomDialogue from '../../Common/CustomDialogue';
import { ADD_CUSTOM_GOAL, UPDATE_GOAL_ENTRY } from '../services/gql';
import { useDeleteCustomGoal } from '../services/hooks';
import useGetGoalProblems from '../services/useGetGoalProblems';

const AddGoal = ({ setOpen, goalData, type }) => {
  const [goal, setGoal] = useState({ ...goalData });
  const [error, setError] = useState({
    goalTitle: '',
    goalProblem: '',
  });
  const [dialogueOpen, setDialogueOpen] = useState(false);
  const { problemList } = useGetGoalProblems();
  const dispatch = useDispatch();

  const [addCustomGoal] = useMutation(ADD_CUSTOM_GOAL, {
    context: { clientName: 'referral' },
    refetchQueries: ['listCustomGoalEntry', 'listAllGoalEntryCount'],
    onCompleted: (data) => {
      dispatch({
        type: types.TOGGLE_SNACKBAR,
        payload: generateSnackbarPayload('success', 'Priority Added Successfully'),
      });
    },
  });

  const [updateCustomGoal] = useMutation(UPDATE_GOAL_ENTRY, {
    context: { clientName: 'referral' },
    refetchQueries: ['listAllGoalEntry', 'listCustomGoalEntry'],
    onCompleted: (data) => {
      dispatch({
        type: types.TOGGLE_SNACKBAR,
        payload: generateSnackbarPayload('success', 'Priority Updated Successfully'),
      });
    },
  });

  const deleteCustomGoal = useDeleteCustomGoal();

  const handleSubmit = () => {
    if (goal.goalTitle === '' || !goal.goalTitle) {
      setError({ ...error, goalTitle: 'Priority title is required' });
    }
    if (goal.problemId === '' || !goal.problemId) {
      setError((prevState) => ({ ...prevState, goalProblem: 'Category is required' }));
    }

    if (goal.goalTitle && goal.problemId) {
      if (type === 'Add') {
        trackEvent('click', {
          screen: screenNames.CustomPriorities,
          Action: learnerAction.ADD_CUSTOM_GOAL,
        });
        addCustomGoal({
          variables: {
            data: {
              goalTitle: goal.goalTitle,
              problemIds: [goal.problemId],
            },
          },
        });
      } else if (type === 'Edit') {
        trackEvent('click', {
          screen: screenNames.CustomPriorities,
          Action: learnerAction.UPDATE_PRIORITY,
        });
        updateCustomGoal({
          variables: {
            goalEntryId: goal._id,
            data: {
              goalTitle: goal.goalTitle,
              problemId: goal.problemId,
            },
          },
        });
      }
      setOpen(false);
    }
  };

  const handleDelete = () => {
    trackEvent('click', {
      screen: screenNames.CustomPriorities,
      Action: learnerAction.REMOVE_PRIORITY,
    });
    deleteCustomGoal({
      variables: {
        goalEntryId: goal._id,
      },
    });
    setOpen(false);
  };

  const handleCheck = (problem) => {
    setGoal({ ...goal, problemId: problem.id });
    setError({ ...error, goalProblem: '' });
  };

  const handleChangeText = (e) => {
    if (e.target.value.length <= 50) {
      setGoal({ ...goal, goalTitle: e.target.value });
      setError({ ...error, goalTitle: '' });
    }
  };

  return (
    <div>
      <div className="article-filter goal-filter" tabIndex={0}>
        <div className="drawer-top">
          <h2 className="goal-filter-head">
            {type === 'Add' ? 'Add Priority' : 'Update Priority'}
            <button
              onClick={() => {
                setOpen(false);
                trackEvent('click', {
                  screen: screenNames.CustomPriorities,
                  Action: learnerAction.CLOSE_PRIORITY_DRAWER,
                });
              }}
              onKeyDown={(e) => e.key === 'Enter' && setOpen(false)}
              style={{ background: 'none', border: 'none' }}>
              <img
                tabIndex="0"
                src={Close}
                // onClick={() => setOpen(false)}
                alt="close"
                className="close-btn"
              />
            </button>
          </h2>
          <p></p>
          {/* <p>{type === 'Add' ? 'Add a custom goal related to your challenge' : ''}</p> */}
        </div>
        <div className="add-field">
          <Grid container>
            <Grid item xs={12}>
              <TextField
                required
                id="name"
                aria-label="Goal Title"
                className="text-field"
                placeholder="Priority Title"
                defaultValue=""
                variant="outlined"
                onChange={handleChangeText}
                helperText={error.goalTitle}
                value={goal.goalTitle}
              />
            </Grid>
          </Grid>
        </div>

        <div className="content">
          <Grid container>
            <Grid item xs={12}>
              <h3>Category</h3>
            </Grid>
            {error.goalProblem && (
              <Grid item xs={12}>
                <div style={{ color: 'red', fontSize: '12px', marginLeft: '0px' }}>
                  {error.goalProblem}
                </div>
              </Grid>
            )}
            <Grid item xs={12}>
              <ul style={{ borderTop: 'none' }}>
                {problemList &&
                  problemList.length &&
                  problemList.map((problem, index) => (
                    <li>
                      <span className="fiter-setting">
                        <img src={problem.problemImage} alt="list" style={{ width: '25px' }} />{' '}
                        {problem.problemName}
                      </span>
                      <Checkbox
                        icon={<img src={PriorityUncheck} />}
                        checkedIcon={<img src={Prioritychecked} />}
                        checked={goal.problemId === problem.id}
                        onChange={() => handleCheck(problem)}
                        value={problem.id}
                        color="primary"
                        inputProps={{ 'aria-label': problem.problemName }}
                      />
                    </li>
                  ))}
              </ul>
            </Grid>
          </Grid>
        </div>
        <div className="drawer-bottom">
          <div className="footer">
            {type === 'Edit' && (
              <button
                style={{ width: '170px', height: '40px' }}
                className="clear"
                onClick={() => setDialogueOpen(true)}>
                Remove
              </button>
            )}
            <button
              className="apply"
              onClick={handleSubmit}
              onKeyDown={(e) => e.key === 'Enter' && handleSubmit}
              style={{ width: '170px', height: '40px' }}>
              {type === 'Edit' ? 'Update' : 'Add Priority'}
            </button>
          </div>
        </div>
      </div>
      <CustomDialogue
        dialogueTitle="Remove Priority"
        dialogueContent="Are you sure you want to remove this priority from your custom priorities?"
        dialogueOpen={dialogueOpen}
        setDialogueOpen={setDialogueOpen}
        confirmFunction={handleDelete}
      />
    </div>
  );
};

export default AddGoal;
