import axios from '../../Config';
import handleAxiosError from '../../Helper/AxiosErrorHanlder';

const awsFileUpload = (file, folderName) =>
  new Promise(async (resolve, reject) => {
    try {
      let s3Data = await axios.post(`${process.env.REACT_APP_BASE_URL}/aws-upload`, {
        originalFilename: `${file.name}`,
        path: `/${folderName}`,
      });
      await fetch(s3Data.data.signedUrl, { method: 'PUT', body: file });
      resolve(s3Data.data);
    } catch (err) {
      reject(err);
      handleAxiosError(err);
    }
  });
export default awsFileUpload;
