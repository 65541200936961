import axios from '../Config';
import handleAxiosError from '../Helper/AxiosErrorHanlder';

export const getInstalledApps = (userId) => {
  return axios({
    url: `/user/${userId}/myApps`,
  }).catch((err) => handleAxiosError(err));
};

export const getRecommendedApps = (userId) => {
  return axios({
    url: `/user/${userId}/recommendedApps`,
  }).catch((err) => handleAxiosError(err));
};

export const getAllApps = (userId) => {
  return axios({
    url: `/user/${userId}/allApps`,
  }).catch((err) => handleAxiosError(err));
};
