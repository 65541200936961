import { Badge, Tab, Tabs } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import ToolsIcon from '../../../Assets/Images/Apps.svg';
import CourseIcon from '../../../Assets/Images/Course.svg';
import HomeIcon from '../../../Assets/Images/Home.svg';
import MoodRingIcon from '../../../Assets/Images/Mood-Ring.svg';
import UnionIcon from '../../../Assets/Images/Notification.svg';
import VideoConfIcon from '../../../Assets/Images/Video-Conf2.svg';
import getRoutes from '../../../Routes/RoutePaths';
import { getUnreadNotificationsCount } from '../../../Store/reduxSelectors';
//import { trackEvent } from '../../../Helper/EventTracker';
const useStyles = makeStyles((theme) => ({
  tabRoot: {
    minWidth: '0px !important',
    // margin: '0px 10px',
    padding: '10px 18px',
  },
  indicator: {
    height: 4,
    bottom: 4,
  },
}));

const MenuItems = () => {
  const [value, setValue] = React.useState(getRoutes.home);

  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();

  React.useEffect(() => {
    // dispatch(getUnreadNotificationCount());
    // graphQlClient
    //   .query({
    //     query: GET_NOTIFICATION_COUNT,
    //     fetchPolicy: 'no-cache',
    //   })
    //   .then((result) => {
    //     dispatch(setUnreadNotificationCount({loading: result.loading, data: result.data.getUnreadCount}));
    //   })
    //   .catch((error) => {
    //     dispatch(setUnreadNotificationCount({loading: false, error}));
    //   });
  }, []);

  React.useEffect(() => {
    const specialRoute = location.pathname.split('/');
    const correctedPath = `/${specialRoute[1]}`;
    if (value !== correctedPath) setValue(correctedPath);
  }, [location.pathname]);

  const handleOnChange = (newValue) => {
    //  trackEvent();
    history.replace(newValue);
    setValue(newValue);
  };
  const unreadNotificationCountPayload = useSelector((state) => getUnreadNotificationsCount(state));
  const unreadNotifCount = unreadNotificationCountPayload && unreadNotificationCountPayload.data;

  return (
    <React.Fragment>
      <Tabs
        classes={{ indicator: classes.indicator }}
        value={value}
        indicatorColor="primary"
        className="header-tab"
        onChange={(event, newValue) => {
          handleOnChange(newValue);
        }}>
        <Tab
          classes={{ root: classes.tabRoot }}
          value={getRoutes.home}
          icon={<ReactSVG src={HomeIcon} />}></Tab>
        <Tab
          classes={{ root: classes.tabRoot }}
          value={getRoutes.mood}
          icon={<ReactSVG src={MoodRingIcon} />}></Tab>
        <Tab
          classes={{ root: classes.tabRoot }}
          value={getRoutes.meetings}
          icon={<ReactSVG src={VideoConfIcon} />}></Tab>
        <Tab
          classes={{ root: classes.tabRoot }}
          value={getRoutes.tools}
          icon={<ReactSVG src={ToolsIcon} />}></Tab>
        <Tab
          classes={{ root: classes.tabRoot }}
          value={getRoutes.resources}
          icon={<ReactSVG src={CourseIcon} />}></Tab>
        <Tab
          classes={{ root: classes.tabRoot }}
          value={getRoutes.notifications}
          icon={
            <Badge showZero={false} color="error" badgeContent={unreadNotifCount}>
              <ReactSVG src={UnionIcon} />
            </Badge>
          }></Tab>
      </Tabs>
    </React.Fragment>
  );
};

export default MenuItems;
