import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';
import logoPng from '../../Assets/Images/logo_login.png';
const PageLoader = () => {
  const orgLogo = localStorage.getItem('organizationLogo');
  console.log('orgLogo', orgLogo);
  return (
    <Box style={{ backgroundColor: '#fff', height: '100vh' }}>
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
        <Grid item>
          <img
            src={orgLogo ? orgLogo : logoPng}
            style={{ width: 200, marginBottom: '20px' }}
            alt="logo"
          />
          {orgLogo ? null : (
            <Grid>
              <LinearProgress />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PageLoader;
