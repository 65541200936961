import { useQuery } from '@apollo/client';
import { LIST_GOAL_PROBLEMS } from '../services/gql';
import { cloneDeep } from 'lodash';

const useGetGoalProblems = () => {
  const { data: goalProblems, loading } = useQuery(LIST_GOAL_PROBLEMS, {
    context: { clientName: 'referral' },
  });
  const problemList = cloneDeep(goalProblems?.listGoalProblems);
  return { problemList, loading };
};

export default useGetGoalProblems;
