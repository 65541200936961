// import DateFnsUtils from '@date-io/date-fns';
// import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { makeStyles } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Blackchecked from '../../Assets/Images/Blackchecked.svg';
import Unchecked from '../../Assets/Images/Unchecked.svg';
import learnerAction from '../../Helper/Analytics/learnerAction';
import screenNames from '../../Helper/Analytics/screenNames';
import { trackEvent } from '../../Helper/EventTracker';
import theme from '../../MaterialUiTheme';
import { updateUserDetails } from '../../Store/Actions/Auth';
import getLoggedUserDetails from '../services/getLoggedUserDetails';
import updateProfileDetails from '../services/patchUserDetails.js';
import DateOfBirth from './../../Assets/Images/DateOfBirth.svg';
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export const FtueFour = (props) => {
  const [checked, setChecked] = React.useState(false);
  // const [dateOfBirth, setDateOfBirth] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);
  const [errorSnackbarT, setErrorSnackbarT] = React.useState(false);
  const dispatch = useDispatch();
  const [selectedDate, handleDateChange] = useState(null);

  const sSnackbar = () => {
    setSuccessSnackbar(true);
  };

  const eSnackbar = () => {
    setErrorSnackbar(true);
  };

  const eSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorSnackbar(false);
  };

  const eSnackbarT = () => {
    setErrorSnackbarT(true);
  };

  const eSnackbarCloseT = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorSnackbarT(false);
  };
  const sSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessSnackbar(false);
    setTimeout(() => {
      props.stepUpdate('next');
    }, 100);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
    // setError({ ...error, checked: '' });
  };
  const profileUpdate = () => {
    trackEvent('click', {
      screen: screenNames.FtueWelcome,
      Action: learnerAction.FTUE_DATEOF_BIRTH,
    });
    if (selectedDate && checked === true) {
      let data = {
        dateOfBirth: selectedDate,
      };
      updateProfileDetails(props.userDetails.id, data)
        .then((response) => {
          getLoggedUserDetails(props.userDetails.id).then((res) => {
            props.setLoggedData(res);
            dispatch(updateUserDetails({ dateOfBirth: res.data.dateOfBirth }));
          });
          sSnackbar();
        })
        .catch((error) => {
          eSnackbar();
        });
    } else if (selectedDate === null) {
      eSnackbar();
    } else if (checked === false) {
      eSnackbarT();
    }
  };

  useEffect(() => {
    handleDateChange(
      props?.loggedData?.data?.dateOfBirth
        ? moment(props.loggedData.data.dateOfBirth).format('YYYY-MM-DD')
        : null,
    );
  }, [props?.loggedData?.data?.dateOfBirth]);

  return (
    <div>
      <div className="dob-container">
        <img src={DateOfBirth} />
        <h2 style={{ height: '45px' }}>Can we get your Month and Year of Birth?</h2>
        <span style={{ fontWeight: '400', fontSize: '12px', color: '#3E3C37' }}>
          MentorHub will use this for occasional notifications.
        </span>
        {/* <TextField
          style={{ marginTop: '35px' }}
          fullWidth
          id="date"
          type="date"
          defaultValue={
            props && props.loggedData
              ? moment(props?.loggedData?.data?.dateOfBirth).format('YYYY-MM-DD')
              : ''
          }
          onChange={(e) => setDateOfBirth(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Date/Month"
        /> */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDatePicker
            style={{ marginTop: '35px', width: '100% !important' }}
            fullWidth
            disableFuture
            label="MM/YYYY"
            views={['year', 'month']}
            sx={{
              width: '100%',
              '& .MuiOutlinedInput-input': {
                padding: '0px',
                paddingLeft: '0px',
                paddingTop: '17px',
                paddingBottom: '7px',
              },
            }}
            value={selectedDate ? dayjs(selectedDate) : null}
            onChange={(newValue) => {
              handleDateChange(newValue);
            }}
            inputFormat="MM/YYYY"
            format="MM/YYYY"
            slotProps={{
              textField: {
                InputLabelProps: { shrink: selectedDate ? true : false },
                variant: 'standard',
                sx: {
                  cursor: 'pointer',
                  width: '100%',
                  marginTop: '5px',
                },
                placeholder: null,
              },
            }}
            // renderInput={(params) => (
            //   <TextField
            //     {...params}
            //     fullWidth
            //     // label="MM/YYYY"
            //     style={{ width: '100% !important' }}
            //     InputLabelProps={{
            //       shrink: selectedDate ? true : undefined, // Only shrink if there is a selected date
            //     }}
            //     sx={{
            //       width: '100%',
            //       '& .MuiOutlinedInput-input': {
            //         padding: '0px',
            //         paddingLeft: '0px',
            //         paddingTop: '17px',
            //         paddingBottom: '7px',
            //       },
            //     }}
            //   />
            // )}
          />
        </LocalizationProvider>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChange}
                name="checkedB"
                color="primary"
                value={checked}
                icon={<img src={Unchecked} />}
                checkedIcon={<img src={Blackchecked} />}
              />
            }
            label={
              <label>
                I have read and agreed to the{' '}
                <a
                  target="_blank"
                  style={{ color: '#000' }}
                  href="https://mentorhubapp.org/terms-conditions">
                  Terms and Conditions
                </a>
                ,{' '}
                <a
                  target="_blank"
                  style={{ color: '#000' }}
                  href="https://mentorhubapp.org/mentorhub-eula/">
                  Privacy Policy
                </a>
                , and{' '}
                <a
                  target="_blank"
                  style={{ color: '#000' }}
                  href="https://mentorhubapp.org/mentorhub-eula/">
                  EULA
                </a>
              </label>
            }></FormControlLabel>
        </div>
        <Button
          style={{
            marginTop: '20px',
            color: 'white',
            width: '100%',
            height: '50px',
            backgroundColor: theme.palette.primary.main,
            fontFamily: 'Lato',
            fontWeight: 'bold',

            textTransform: 'capitalize',
          }}
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => profileUpdate()}>
          Save
        </Button>
        <Snackbar
          open={errorSnackbar}
          style={{ zIndex: '10000' }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={3000}
          onClose={eSnackbarClose}>
          <Alert severity="error" variant="filled">
            Please enter your DOB .
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorSnackbarT}
          style={{ zIndex: '10000' }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={3000}
          onClose={eSnackbarCloseT}>
          <Alert severity="error" variant="filled">
            Please agree the Terms and Conditions.
          </Alert>
        </Snackbar>{' '}
        <Snackbar
          open={successSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          id="ratingSuccess"
          autoHideDuration={3000}
          onClose={sSnackbarClose}>
          <Alert tabindex="0" severity="success" variant="filled">
            Birth Month & Year successfully updated.
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};
