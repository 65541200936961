import { Button } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getUserDetails } from '../../Store/reduxSelectors';

import blueTen from '../../Assets/Images/blue-10.svg';
import blueHundred from '../../Assets/Images/blue-100.svg';
import blueTwentyFive from '../../Assets/Images/blue-25.svg';
import blueFive from '../../Assets/Images/blue-5.svg';
import blueFifty from '../../Assets/Images/blue-50.svg';
import blueSeventyFive from '../../Assets/Images/blue-75.svg';

import orangeOne from '../../Assets/Images/orange-1.svg';
import orangeSixteen from '../../Assets/Images/orange-16.svg';
import orange32 from '../../Assets/Images/orange-32.svg';
import orangeFour from '../../Assets/Images/orange-4.svg';
import orange64 from '../../Assets/Images/orange-64.svg';
import orangeEight from '../../Assets/Images/orange-8.svg';

import greenSixteen from '../../Assets/Images/green-16.svg';
import green32 from '../../Assets/Images/green-32.svg';
import greenTwo from '../../Assets/Images/green-4.svg';
import green64 from '../../Assets/Images/green-64.svg';
import greenThree from '../../Assets/Images/green-8.svg';
import greenOne from '../../Assets/Images/green-one.svg';
import learnerAction from '../../Helper/Analytics/learnerAction';
import screenNames from '../../Helper/Analytics/screenNames';
import { trackEvent } from '../../Helper/EventTracker';

import updateStreakModalView from '../services/updateStreakModalView.js';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: '340px',
    borderRadius: '10px',
  },
}));

export default function StreakPopUp(props) {
  const classes = useStyles();
  const [openL, setOpenL] = React.useState(false);
  const [openMs, setOpenMs] = React.useState(false);
  const [openMo, setOpenMo] = React.useState(false);

  const [logBadge, setLogBadge] = React.useState('');
  const [msBadge, setMsBadge] = React.useState('');
  const [moBadge, setMoBadge] = React.useState('');

  const [logBadgeCount, setLogBadgeCount] = React.useState('');
  const [msBadgeCount, setMsBadgeCount] = React.useState('');
  const [moBadgeCount, setMoBadgeCount] = React.useState('');

  const userDetails = useSelector((state) => getUserDetails(state));

  document.title = 'MentorHub - SSG Streaks Modal';

  let logArr = [5, 10, 25, 50, 75, 100];
  let messageArr = [1, 4, 8, 16, 32, 64];
  let moodArr = [1, 4, 8, 16, 32, 64];

  const logPop = logArr.includes(
    props.streak && props.streak.loginStreak !== null && props.streak.loginStreak,
  );
  const msgPop = messageArr.includes(
    props.streak && props.streak.messageStreak !== null && props.streak.messageStreak,
  );
  const moodPop = moodArr.includes(
    props.streak && props.streak.moodRingStreak !== null && props.streak.moodRingStreak,
  );

  useEffect(() => {
    setAllModal();
  }, []);

  const setAllModal = () => {
    if (logPop && !props.streak.loginIsShown) {
      setOpenL(true);
      // if(props.streak.loginStreak == 1) {
      //    setLogBadge(blueFive)
      //    setLogBadgeCount('zero one')
      // }
      // if(props.streak.loginStreak == 2) {
      //    setLogBadge(blueFive)
      //    setLogBadgeCount('zero two')
      // }
      // else if(props.streak.loginStreak == 3) {
      //    setLogBadge(blueFive)
      //    setLogBadgeCount('zero three')
      // }
      // else if(props.streak.loginStreak == 4) {
      //    setLogBadge(blueFive)
      //    setLogBadgeCount('zero four')
      // }
      if (props.streak.loginStreak == 5) {
        setLogBadge(blueFive);
        setLogBadgeCount('one');
      } else if (props.streak.loginStreak == 10) {
        setLogBadge(blueTen);
        setLogBadgeCount('two');
      } else if (props.streak.loginStreak == 25) {
        setLogBadge(blueTwentyFive);
        setLogBadgeCount('three');
      } else if (props.streak.loginStreak == 50) {
        setLogBadge(blueFifty);
        setLogBadgeCount('four');
      } else if (props.streak.loginStreak == 75) {
        setLogBadge(blueSeventyFive);
        setLogBadgeCount('five');
      } else if (props.streak.loginStreak == 100) {
        setLogBadge(blueHundred);
        setLogBadgeCount('six');
      }
    }
    if (msgPop && !props.streak.messageIsShown) {
      setOpenMs(true);

      if (props.streak.messageStreak == 1) {
        setMsBadge(orangeOne);
        setMsBadgeCount('one');
      } else if (props.streak.messageStreak == 4) {
        setMsBadge(orangeFour);
        setMsBadgeCount('two');
      } else if (props.streak.messageStreak == 8) {
        setMsBadge(orangeEight);
        setMsBadgeCount('three');
      } else if (props.streak.messageStreak == 16) {
        setMsBadge(orangeSixteen);
        setMsBadgeCount('four');
      } else if (props.streak.messageStreak == 32) {
        setMsBadge(orange32);
        setMsBadgeCount('five');
      } else if (props.streak.messageStreak == 64) {
        setMsBadge(orange64);
        setMsBadgeCount('six');
      }
    }
    if (moodPop && !props.streak.moodRingIsShown) {
      setOpenMo(true);

      if (props.streak.moodRingStreak == 1) {
        setMoBadge(greenOne);
        setMoBadgeCount('one');
      }
      // else if(props.streak.moodRingStreak == 2) {
      //    setMoBadge(greenOne)
      //    setMoBadgeCount('zero two')
      // }
      // else if(props.streak.moodRingStreak == 3) {
      //    setMoBadge(greenOne)
      //    setMoBadgeCount('zero three')
      // }
      else if (props.streak.moodRingStreak == 4) {
        setMoBadge(greenTwo);
        setMoBadgeCount('two');
      } else if (props.streak.moodRingStreak == 8) {
        setMoBadge(greenThree);
        setMoBadgeCount('three');
      } else if (props.streak.moodRingStreak == 16) {
        setMoBadge(greenSixteen);
        setMoBadgeCount('four');
      } else if (props.streak.moodRingStreak == 32) {
        setMoBadge(green32);
        setMoBadgeCount('five');
      } else if (props.streak.moodRingStreak == 64) {
        setMoBadge(green64);
        setMoBadgeCount('six');
      }
    }
  };

  const handleCloseL = () => {
    trackEvent('click', { screen: screenNames.Streak, Action: learnerAction.CLOSE_LOGIN_STREAK });
    setOpenL(false);
    let data = {
      type: 'login',
      status: true,
      menteeId: userDetails.id,
    };
    updateStreakModalView(data);
  };
  const handleCloseMs = () => {
    trackEvent('click', { screen: screenNames.Streak, Action: learnerAction.CLOSE_MESSAGE_STREAK });
    setOpenMs(false);
    let data = {
      type: 'message',
      status: true,
      menteeId: userDetails.id,
    };
    updateStreakModalView(data);
  };
  const handleCloseMo = () => {
    trackEvent('click', { screen: screenNames.Streak, Action: learnerAction.CLOSE_CHECKIN_STREAK });
    setOpenMo(false);
    let data = {
      type: 'moodRing',
      status: true,
      menteeId: userDetails.id,
    };
    updateStreakModalView(data);
  };

  return (
    <div>
      <Modal
        style={{ zIndex: 1 }}
        aria-label="Check-In Steak Modal"
        className={classes.modal}
        open={openMo}
        onClose={handleCloseMo}
        closeAfterTransition
        BackdropComponent={Backdrop}
        sx={{
          '& .MuiBackdrop-root': {
            zIndex: '-1',
          },
        }}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={openMo}>
          <div className={classes.paper}>
            <div
              className="rating-wrap streek-wrap"
              role="form"
              aria-label="mood ring Streaks Modal"
              tabIndex="0">
              <h1>Streak {moBadgeCount} is done!</h1>
              <img src={moBadge} alt="Check-In badge" />
              {props.streak && props.streak.moodRingStreak == 1 ? (
                <h2>{props.streak && props.streak.moodRingStreak} Streak</h2>
              ) : (
                <h2>{props.streak && props.streak.moodRingStreak} Streaks</h2>
              )}

              {moBadgeCount == 'one' && (
                <p>
                  We know the first streak can be the hardest, so enjoy your first streak. You’ve
                  earned it.
                </p>
              )}
              <Button
                aria-label="Streak close Button"
                onClick={handleCloseMo}
                style={{ maxWidth: 150 }}
                variant="contained"
                sx={{
                  '&:hover': {
                    backgroundColor: '#27cad2 !important',
                    color: 'white !important',
                  },
                }}
                color="primary">
                Ok
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>

      <Modal
        style={{ zIndex: 1 }}
        aria-label="Message Streaks Modal"
        className={classes.modal}
        open={openMs}
        onClose={handleCloseMs}
        closeAfterTransition
        BackdropComponent={Backdrop}
        sx={{
          '& .MuiBackdrop-root': {
            zIndex: '-1',
          },
        }}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={openMs}>
          <div className={classes.paper}>
            <div
              className="rating-wrap streek-wrap"
              role="form"
              aria-label="message Streaks Modal"
              tabIndex="0">
              <h1>Streak {msBadgeCount} is done!</h1>
              <img src={msBadge} alt="Message badge" />
              {props.streak && props.streak.messageStreak == 1 ? (
                <h2>{props.streak && props.streak.messageStreak} Streak</h2>
              ) : (
                <h2>{props.streak && props.streak.messageStreak} Streaks</h2>
              )}

              {msBadgeCount == 'one' && (
                <p>
                  We know the first streak can be the hardest, so enjoy your first streak. You’ve
                  earned it.
                </p>
              )}
              <Button
                aria-label="Streak close Button"
                onClick={handleCloseMs}
                style={{ maxWidth: 150 }}
                variant="contained"
                sx={{
                  '&:hover': {
                    backgroundColor: '#27cad2 !important',
                    color: 'white !important',
                  },
                }}
                color="primary">
                Ok
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>

      <Modal
        style={{ zIndex: 1 }}
        aria-label="Login streaks Modal"
        className={classes.modal}
        open={openL}
        onClose={handleCloseL}
        closeAfterTransition
        BackdropComponent={Backdrop}
        sx={{
          '& .MuiBackdrop-root': {
            zIndex: '-1',
          },
        }}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={openL}>
          <div className={classes.paper}>
            <div
              className="rating-wrap streek-wrap"
              role="form"
              aria-label="Streaks Modal"
              tabIndex="0">
              <h1>Streak {logBadgeCount} is done!</h1>
              <img src={logBadge} alt="Days badge" />
              {props.streak && props.streak.loginStreak == 1 ? (
                <h2>{props.streak && props.streak.loginStreak} Streak</h2>
              ) : (
                <h2>{props.streak && props.streak.loginStreak} Streaks</h2>
              )}

              {logBadgeCount == 'one' && (
                <p>
                  We know the first streak can be the hardest, so enjoy your first streak. You’ve
                  earned it.
                </p>
              )}
              <Button
                aria-label="Streak close Button"
                onClick={handleCloseL}
                style={{ maxWidth: 150 }}
                variant="contained"
                sx={{
                  '&:hover': {
                    backgroundColor: '#27cad2 !important',
                    color: 'white !important',
                  },
                }}
                color="primary">
                Ok
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
