import * as types from '../actionTypes';

const initialState = {
  linkData:{}
};

const quickLinkData = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_QUICK_LINK_SUCCESS:
            return {
                ...state,
                linkData: action.data
            };
        default:
            return state;
    }
};

export default quickLinkData;
