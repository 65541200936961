import React from 'react';
import PieChart from '../../Common/PieChart';
import { useSelector } from 'react-redux';
import { getPieChartData } from '../../../Store/reduxSelectors';

const MoodRingPieChart = () => {
  const data = useSelector((state) => getPieChartData(state));

  return (
    <PieChart
      titleStyles={{
        x: -195,
        y: 20,
      }}
      data={data}
      pieSize={270}
      chartOptions={{
        legend: {
          align: 'right',
          layout: 'vertical',
          verticalAlign: 'middle',
          itemMarginTop: 10,
          itemMarginBottom: 10,
          x: -200,
          margin: 10,
        },
      }}
      mainChartOptions={{
        height: '350px',
        spacingLeft: 140,
        spacingRight: -40,
      }}
    />
  );
};

export default MoodRingPieChart;
