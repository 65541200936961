import React, { useEffect, useState } from 'react';

import Drawer from '@mui/material/Drawer';

import { useQuery } from '@apollo/client';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { makeStyles, useTheme } from '@mui/styles';
import learnerAction from '../../../Helper/Analytics/learnerAction';
import screenNames from '../../../Helper/Analytics/screenNames';
import { trackEvent } from '../../../Helper/EventTracker';
import CustomDialogue from '../../Common/CustomDialogue';
import EmptyStates from '../../Common/EmptyStates';
import { LIST_CUSTOM_GOALS_COUNT } from '../services/gql';
import { useDeleteCustomGoal } from '../services/hooks';
import useGetGoalProblems from '../services/useGetGoalProblems';
import AddGoal from './AddGoal';
const useStyles = makeStyles((theme) => ({
  maincontainer: {
    backgroundColor: '#FFFFFF',
    '& .MuiFormControl-root': {
      marginTop: '12px',
      marginLeft: '24px',
    },
    '& .MuiTableContainer-root': {
      boxShadow: 'none',
    },
    '& .MuiTablePagination-input': {
      visibility: 'hidden',
    },
  },

  tablehead: {
    '& .MuiTableCell-root': {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '14px',

      color: '#000000',
    },
  },

  maintablehead: {
    borderBottom: '1px solid rgba(224, 224, 224, 1);',
  },
  cardheader: {
    padding: '0px',
  },
  tophead: {
    backgroundColor: '#FFFFFF',
    height: '84px',
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  addgoalbutton: {
    '& .MuiButton-root': {
      minWidth: '0px',
      minHeight: '0px',
      width: '130px',
      height: '40px',
      marginRight: '17px',
      /* Primary / Red */
      color: '#FFFFFF',
      background: '#27CAD2',
      borderRadius: '4px',
    },
  },
  ptags: {
    padding: '16px',
  },
}));
const CustomGoals = ({
  data,
  loading,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [editopen, setEditOpen] = useState(false);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [dialogueOpen, setDialogueOpen] = useState(false);

  const [goalsByProblems, setGoalsByProblems] = useState([]);

  const { problemList } = useGetGoalProblems();
  const { data: customcount, error } = useQuery(LIST_CUSTOM_GOALS_COUNT, {
    variables: {
      customGoalOnly: true,
    },
    context: { clientName: 'referral' },
  });

  useEffect(() => {
    if (data && data.listAllGoalEntry && problemList && problemList.length) {
      problemList.forEach((problem, index) => {
        const goals = [];
        data.listAllGoalEntry.forEach((goal) => {
          if (goal.problemId === problem.id && goal.type === 'CUSTOM_GOAL') {
            goals.push({ ...goal, userGoal: { ...goal.userGoal } });
          }
        });
        problemList[index].goals = goals;
      });
    }
    setGoalsByProblems([...problemList]);
  }, [data]);

  const toggleDrawerEdit = (editopen) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setEditOpen(editopen);
    handleClose();
  };

  const handleClick = (event, goal) => {
    setAnchorEl(event.currentTarget);
    setSelectedGoal({ ...goal });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    trackEvent('click', {
      screen: screenNames.CustomPriorities,
    });
  }, []);

  const deleteCustomGoal = useDeleteCustomGoal();

  const handleDelete = () => {
    deleteCustomGoal({
      variables: {
        goalEntryId: selectedGoal._id,
      },
    });
    setAnchorEl(null);
    setDialogueOpen(false);
  };

  if (data && data.listAllGoalEntry && !data.listAllGoalEntry.length)
    return <EmptyStates title={'No Data Found'} rootStyles={{ minHeight: '300px' }} />;

  return (
    <div className={classes.maincontainer}>
      <Grid
        item
        xs={12}
        //  className={classes.gridtable}
      >
        <Box sx={{ flexGrow: 1, borderRadius: '5px', padding: '10px' }}>
          <div className={classes.tophead}>
            <div className={classes.ptags}>
              <h4
                style={{
                  height: '29px',
                  margin: '0px',
                  fontFamily: 'Lato',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '24px',
                  color: '#000000',
                  marginBottom: '8px',
                }}>
                What are you interested in working on?{' '}
              </h4>
              <p
                style={{
                  width: '100%',
                  height: '17px',
                  margin: '0px',
                  color: '#69625E',
                  fontFamily: 'Lato',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '14px',
                }}>
                Create/Manage a new priority for the challenge you are facing
              </p>
            </div>
            <div className={classes.addgoalbutton}>
              <Button
                aria-label="add custom goal"
                variant="contained"
                disableRipple
                className="custom-button"
                onClick={() => {
                  setSelectedGoal(null);
                  setEditOpen(true);
                  handleClose();
                  trackEvent('click', {
                    screen: screenNames.CustomPriorities,
                    Action: learnerAction.ADD_CUSTOM_GOAL,
                  });
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setSelectedGoal(null);
                    setEditOpen(true);
                    handleClose();
                  }
                }}>
                Add
              </Button>
            </div>
          </div>
          <TableContainer component={Paper}>
            {goalsByProblems && goalsByProblems.length > 0 ? (
              <Table aria-label="simple table">
                <TableHead className={classes.maintablehead}>
                  <TableRow className={classes.tablehead}>
                    <TableCell>Title</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {goalsByProblems.map((item) => {
                    if (item.goals && item.goals.length > 0) {
                      return (
                        <>
                          {item.goals.map((goal) => (
                            <TableRow>
                              <TableCell>{goal.goalTitle}</TableCell>

                              <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                                <CardHeader
                                  className={classes.cardheader}
                                  sx={{ padding: '2px' }}
                                  avatar={
                                    <Avatar
                                      alt="problemImage icon"
                                      sx={{ width: '40px', height: '40px' }}
                                      src={item.problemImage}></Avatar>
                                  }
                                  title={item.problemName}
                                />
                              </TableCell>

                              <TableCell>
                                <IconButton
                                  // size="large"
                                  aria-label="action"
                                  aria-controls="menu-appbar"
                                  aria-haspopup="true"
                                  color="inherit"
                                  onClick={(e) => handleClick(e, goal)}
                                  onKeyPress={(e) => handleClick(e, goal)}
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      handleClick(e, goal);
                                    }
                                  }}>
                                  <MoreHorizIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      );
                    }
                  })}
                </TableBody>
              </Table>
            ) : (
              <p>No Data Found</p>
            )}
            {!loading && (
              <TablePagination
                className={classes.custompagination}
                component="div"
                count={customcount && customcount.listAllGoalEntryCount}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={10}
                rowsPerPageOptions={10}
              />
            )}
          </TableContainer>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            // keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}>
            <MenuItem onClick={toggleDrawerEdit(true)}>Edit</MenuItem>
            <MenuItem onClick={() => setDialogueOpen(true)}>Remove</MenuItem>
          </Menu>
        </Box>
      </Grid>
      <Drawer
        className="common-drawer"
        anchor={'right'}
        open={editopen}
        onClose={toggleDrawerEdit(false)}>
        <AddGoal
          setOpen={setEditOpen}
          goalData={selectedGoal}
          type={selectedGoal ? 'Edit' : 'Add'}
        />
      </Drawer>
      <CustomDialogue
        dialogueTitle="Remove Priority"
        dialogueContent="Are you sure you want to remove this priority from your custom priorities?"
        dialogueOpen={dialogueOpen}
        setDialogueOpen={setDialogueOpen}
        confirmFunction={handleDelete}
      />
    </div>
  );
};

export default CustomGoals;
