import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const CustomButtom = styled(Button)(({ theme }) => ({
  marginTop: '0px',
  color: '#fff',
  fontSize: '14px',
  background: theme.palette.primary.main,

  fontFamily: 'Lato',
  // textTransform: 'capitalize',
  // fontWeight: 'bold',
  minHeight: '50px',
  // borderRadius: '4px',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));
