import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useHistory } from 'react-router-dom';
import SomethingWrongImg from '../Assets/Images/SomethingWrongImg.svg';
import getRoutes from './RoutePaths';
const HandleError = ({ children, state }) => {
  //   const navigate = useNavigate();
  const history = useHistory();
  const Fallback = (props) => {
    const [showError, setShowError] = useState(false);
    return (
      <Box
        role="alert"
        display={'flex'}
        flexDirection={'column'}
        gap={'5px'}
        alignItems={'center'}
        justifyContent={'center'}
        width={state === 'private' ? 'calc(100vw - 310px)' : '100vw'}
        height={state === 'private' ? 'calc(100dvh - 187px)' : '100dvh'}>
        <Typography variant="h2" sx={{ fontSize: '36px', fontWeight: '700' }}>
          Something went wrong!
        </Typography>
        <img src={SomethingWrongImg} alt="something wrong" />
        <Button
          variant="contained"
          onClick={props.resetErrorBoundary}
          sx={{
            fontSize: '16px',
            fontWeight: '700',
            textTransform: 'capitalize',
            width: '138px',
            backgroundColor: '#27cad2',
          }}>
          Try Again
        </Button>
        {/* <Grid container spacing={2}>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            {' '}
            <Button
              onClick={() => navigate(getRoutes.home)}
              sx={{ fontSize: '12px', fontWeight: '400', marginTop: '5px', textTransform: 'none' }}>
              Go back home
            </Button>
          </Grid>{' '}
          <Grid item xs={6}>
            {' '}
            <Button
              onClick={() => setShowError(!showError)}
              sx={{ fontSize: '12px', fontWeight: '400', marginTop: '5px', textTransform: 'none' }}>
              {showError ? 'Hide Error' : 'Show Error'}
            </Button>
          </Grid>
          {showError && (
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              {props.error.stack}
            </Grid>
          )}
        </Grid> */}
        <Button
          variant="contained"
          onClick={() => history.push(getRoutes.home)}
          sx={{
            fontSize: '16px',
            fontWeight: '700',
            textTransform: 'capitalize',
            backgroundColor: '#27cad2',
          }}>
          Go Back Home
        </Button>
      </Box>
    );
  };

  return <ErrorBoundary FallbackComponent={Fallback}>{children}</ErrorBoundary>;
  // return <Fallback />
};

export default HandleError;
