import { useMutation } from '@apollo/client';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { generateSnackbarPayload } from '../../../Helper';
import { TOGGLE_SNACKBAR } from '../../../Store/actionTypes';
import { FAVOURITE_BADGE, GET_GOAL_PLAN_MENTEE, UPDATE_ASSIGNED_GOAL } from './gql';

export const UpdateBadgeHook = (propsMain) => {
  const dispatch = useDispatch();
  const [favouriteBadge] = useMutation(FAVOURITE_BADGE, {
    context: { clientName: 'curriculum' },
  });

  const [updateBadge] = useMutation(UPDATE_ASSIGNED_GOAL, {
    context: { clientName: 'curriculum' },
  });

  const updateGoalBackend = async (props, selectedGoal) => {
    try {
      const response = await updateBadge({
        variables: {
          goalId: props._id,
          title: selectedGoal.title,
          description: selectedGoal.description,
        },
        refetchQueries: ['listGoal'],
      });
      if (response.error) throw response.error;
      else {
        dispatch({
          type: TOGGLE_SNACKBAR,
          payload: generateSnackbarPayload('success', 'Goal has been updated successfully'),
        });
        // store.dispatch({
        //   type: 'OPEN_NOTIFICATION',
        //   status: true,
        //   alertType: 'success',
        //   alertContent: 'Goal has been updated successfully',
        //   position: { vertical: 'top', horizontal: 'right' },
        // });
        // updateLocalTask(props.milestoneIndex, props.taskIndex, {
        //   ...props,
        //   ...changedField,
        // });
      }
    } catch (error) {
      dispatch({
        type: TOGGLE_SNACKBAR,
        payload: generateSnackbarPayload('error', error.message),
      });
    }
  };

  const updateFavourite = async (props) => {
    try {
      await favouriteBadge({
        variables: {
          badgeId: props._id,
          favourite: !props.favourite,
        },
        update(cache, mutnRes) {
          const cacheObj = cache.readQuery({
            query: GET_GOAL_PLAN_MENTEE,
            variables: {
              menteeId: props.menteeId,
              curriculumId: props.curriculumId,
            },
          });
          const clone = _.cloneDeep(cacheObj);
          clone.listLessonPlanMentee.milestone[props.badgeIndex].favourite = !props.favourite;
          cache.writeQuery({
            query: GET_GOAL_PLAN_MENTEE,
            variables: {
              menteeId: props.menteeId,
              curriculumId: props.curriculumId,
            },
            data: clone,
          });
        },
      });
    } catch (error) {
      console.error(error);
    }
  };
  return { updateFavourite, updateGoalBackend };
};
