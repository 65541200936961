import axios from '../Config';
import handleAxiosError from '../Helper/AxiosErrorHanlder';

export const getMoodRingDash = ({ userId, filter }) => {
  return axios({
    url: `/users/dashboard/mentee/${userId}/moodRingDashboard`,
    params: {
      filter,
    },
  }).catch((err) => handleAxiosError(err));
};

export const getMoodRingDataStatus = (userId) => {
  const token = localStorage.getItem('@authToken');
  return axios({
    url: '/users/moodRingData/mentee/' + userId + '?access_token=' + token,
  }).catch((err) => handleAxiosError(err));
};
