import * as types from '../actionTypes';

const initialState = {
  hobbies: [],
  hobbiesLoading: true,
};

const hobbies = (state = initialState, action) => {
  switch (action.type) {
    case types.HOBBIES_SUCCESS:
      return {
        ...state,
        hobbies: action.data,
        isAssessmentQuestionsLoading: false,
      };
    default:
      return state;
  }
};

export default hobbies;
