import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import screenNames from '../../Helper/Analytics/screenNames';
import { trackEvent } from '../../Helper/EventTracker';
import getRoutes from '../../Routes/RoutePaths';
import TourSlider from './slickSlider';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: '900px',
    borderRadius: '10px',
  },
}));

export default function TransitionsModal(props) {
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [showClose, setShowClose] = useState(0);
  // const [lastLoggedIn, setLastLoggedIn] = React.useState(
  //   props.userDetails && props.userDetails.firstLoggedInDate == '' ? true : false,
  // );
  const handleOpen = () => {
    setOpen(true);
  };
  document.title = 'MentorHub - App Tutorial';

  const handleClose = () => {
    setOpen(false);
    history.push(getRoutes.home);
    // window.location.reload();
  };

  React.useEffect(() => {
    trackEvent('view', {
      screen: screenNames.AppTutorial,
    });
  }, []);

  return (
    <div>
      <Modal
        id="App Tutorial-main"
        aria-label="App Tutorial Modal"
        aria-modal="true"
        tabIndex={0}
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        sx={{
          '& .MuiBackdrop-root': {
            zIndex: '-1',
          },
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={open}>
          <div className={classes.paper}>
            <div className="tour-wrap">
              <TourSlider
                showClose={showClose}
                setShowClose={setShowClose}
                handleClose={handleClose}
                setAppTourOpen={props.setAppTourOpen}
              />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
