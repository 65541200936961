export default {
  MenteeHome: 'Home',
  MoodRing: 'Mood-Ring',
  TakeMoodRing: 'Take-Moog-Ring',
  chat: 'chat',
  messages: 'Messages',
  meeting: 'Meeting',
  upComingMeetings: 'upComing-Meetings',
  completedMeetings: 'completed-Meetings',
  Resources: 'Resources-list',
  ResourceDetail: 'Resource-Detail',
  PriorityProgress: 'Priority-Progress',
  CurrentPriority: 'Current-Priority',
  PastPriority: 'Past-Priority',
  MyPriorities: 'My-Priorities',
  CustomPriorities: 'Custom-Priorities',
  Goals: 'Goals',
  AssignedGuides: 'Assigned-Guides',
  AvailableGuides: 'Available-Guides',
  GuideDetail: 'Guide-Detail',
  MyApps: 'My-Apps',
  RecommendedApps: 'Recommended-Apps',
  AllApps: 'All-Apps',
  Apps: 'Apps',
  MenteeProfile: 'Profile',
  MenteeAchievements: 'Achievements',
  MentorProfile: 'Mentor-Profile',
  Notifications: 'Notifications',
  AppTutorial: 'App-Tutorial',
  FtueWelcome: 'Ftue-Welcome',
  MenteeAdvice: 'Mentee-Advice',
  StartPriority: 'Start-Priority',
  TakeAssessment: 'Take-Assessment',
  AnswerAssessment: 'Answer-Assessment',
  SelectLogin: 'Select-Login',
  OneTimeCodeSet: 'One-Time-Code-Set',
  Login: 'Login',
  ForgotPassword: 'Forgot-Password',
  VerifyOtp: 'Verify-Otp',
  SetupAccount: 'Setup-Account',
  Streak: 'Streak',
  Settings: 'Settings',
};
