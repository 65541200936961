import { useReactiveVar } from '@apollo/client';
import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import learnerAction from '../../../Helper/Analytics/learnerAction';
import screenNames from '../../../Helper/Analytics/screenNames';
import { trackEvent } from '../../../Helper/EventTracker';
import { getSideDrawerState, getUserDetails } from '../../../Store/reduxSelectors';
import CustomCircularProgress from '../../Common/CustomCircularProgress';
import useStyles from '../../Home/Styles';
import { GetLessonPlanMentee } from '../services/getLessonPlan';
import { selectedActivityVar } from '../vars/reactiveVars';
import ActivityDetail from './ActivityDetail';
import Badges from './Badges';

const GuidesDetail = (props) => {
  const history = useHistory();
  const currentUser = useSelector((state) => getUserDetails(state));
  // const loadingState = useSelector((state) => getLoadingState(state, true));
  const selectedActivity = useReactiveVar(selectedActivityVar);
  // const [value, setValue] = React.useState(0);
  const [showTasks, setShowtasks] = useState([0]);
  const classes = useStyles();
  const open = useSelector((state) => getSideDrawerState(state));

  const { loading, data, refetch } = GetLessonPlanMentee(
    props.match.params.guideId,
    currentUser.id,
  );

  return (
    <React.Fragment>
      <div className={open ? 'menu-open guides-detail-wrap' : 'guides-detail-wrap'}>
        <Grid container spacing={2}>
          {loading && <CustomCircularProgress />}
          {data && data.milestone.length > 0 && !loading && (
            <Grid item xs={6}>
              <div className="lesson-list">
                <h1>
                  {data.title}{' '}
                  <a
                    onClick={() => {
                      trackEvent('click', {
                        screen: screenNames.GuideDetail,
                        Action: learnerAction.BACK_TO_GUIDE_LIST,
                      });
                      history.push('/guides');
                    }}
                    style={{ cursor: 'pointer' }}>
                    Back to Guides List
                  </a>
                </h1>
                {data.milestone.map((badge, badgeIndex) => (
                  <Badges
                    {...badge}
                    curriculumId={data._id}
                    key={badge._id}
                    badgeIndex={badgeIndex}
                    showTasks={showTasks}
                    setShowtasks={setShowtasks}
                    refetch={refetch}
                    menteeId={currentUser.id}
                  />
                ))}
              </div>
            </Grid>
          )}
          {data && data.milestone.length == 0 && !loading && (
            <div
              className="lesson-list"
              style={{ width: ' 100%', height: '100px ', marginTop: '40px' }}>
              <h1>
                No badges available
                <a onClick={() => history.push('/guides')} style={{ cursor: 'pointer' }}>
                  Back to Guides List
                </a>
              </h1>
            </div>
          )}
          <Grid item xs={6}>
            <div className="task-detail-right">
              {!loading && selectedActivity !== null && selectedActivity._id && (
                <ActivityDetail {...selectedActivity} {...props} />
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default GuidesDetail;
