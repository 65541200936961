import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Fade,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import learnerAction from '../../Helper/Analytics/learnerAction';
import screenNames from '../../Helper/Analytics/screenNames';
import { trackEvent } from '../../Helper/EventTracker';
import theme from '../../MaterialUiTheme';
import getRoutes from '../../Routes/RoutePaths';
import { setAccountDetails } from '../../Store/Actions/Auth';
import CustomPhoneInput from '../Common/PhoneInput';
import { usePasswordValidation } from './services/useCheckPassword';
const SetupAccount = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [checked, setChecked] = React.useState(false);
  const [userDetails, setUserDetails] = useState({ ...props.userDetails.data });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    username: '',
    password: '',
    confirmPassword: '',
    checked: '',
  });

  const { isValid, match } = usePasswordValidation({
    firstPassword: userDetails.password,
    secondPassword: userDetails.confirmPassword,
  });

  const { getValues, control, errors } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      phoneNumber: props.userDetails.data.countryCode + props.userDetails.data.phoneNumber,
    },
  });

  const handleChange = (event) => {
    setChecked(event.target.checked);
    setError({ ...error, checked: '' });
  };

  useEffect(() => {
    if (isValid) {
      setError((prevState) => ({
        ...prevState,
        password: '',
      }));
    }
    if (match) {
      setError((prevState) => ({
        ...prevState,
        confirmPassword: '',
      }));
    }
  }, [isValid, match]);

  const handleSubmit = () => {
    trackEvent('click', { screen: screenNames.SetupAccount, Action: learnerAction.CREATE_ACCOUNT });
    if (!userDetails.username) {
      setError((prevState) => ({ ...prevState, username: 'The username cannot contain spaces' }));
    }
    if (!isValid) {
      setError((prevState) => ({
        ...prevState,
        password:
          'The password should have minimum of 8 characters and should have atleast 1 uppercase character, 1 number and 1 special character',
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        password: '',
      }));
    }
    if (!match) {
      setError((prevState) => ({
        ...prevState,
        confirmPassword: 'Passwords do not match',
      }));
    }
    if (!checked) {
      setError((prevState) => ({
        ...prevState,
        checked: 'Please accept the terms and conditions',
      }));
    }
    let numberOfWords = 1;
    if (userDetails.username) {
      numberOfWords = userDetails.username.trim().split(' ').length;
      if (numberOfWords > 1) {
        setError((prevState) => ({
          ...prevState,
          username: 'The username cannot contain spaces',
        }));
      } else {
        setError((prevState) => ({
          ...prevState,
          username: '',
        }));
      }
    }
    if (
      userDetails.username &&
      numberOfWords === 1 &&
      userDetails.password &&
      checked &&
      isValid &&
      match
    ) {
      setLoading(true);
      const payload = {
        token: userDetails.accessToken,
        data: {
          newUsername: userDetails.username.trim(),
          newPassword: userDetails.password,
          email: userDetails.email.trim(),
          phoneNum: getValues('phoneNumber').replace(userDetails.countryCode, ''),
          countryCode:
            userDetails.countryCode && userDetails.countryCode.charAt(0) !== '+'
              ? '+' + userDetails.countryCode.trim()
              : userDetails.countryCode.trim(),
        },
      };

      dispatch(
        setAccountDetails(payload, () => {
          setLoading(false);
        }),
      );
    }
  };

  const handleChangeUsername = (e) => {
    setUserDetails({ ...userDetails, username: e.target.value });
    if (e.target.value) {
      const numberOfWords = e.target.value.trim().split(' ').length;
      if (numberOfWords > 1) {
        setError((prevState) => ({
          ...prevState,
          username: 'Please enter a valid username',
        }));
      } else
        setError((prevState) => ({
          ...prevState,
          username: '',
        }));
    }
  };

  return (
    <Card
      className="ligin-wrap-inner"
      sx={{
        borderRadius: '25px',
        display: 'flex',
        flexDirection: 'row',
        overflow: 'visible',
        padding: '10px 20px 20px 20px',
        width: '400px',
      }}>
      <Grid container direction="column" spacing={2}>
        <Grid item sx={{ textAlign: 'center' }}>
          <Typography
            className="wel-mentor"
            sx={{
              color: '#000',
              fontSize: '24px',
              fontFamily: 'Lato',
              marginTop: '14px',
              fontWeight: 700,
            }}>
            Create User
          </Typography>
        </Grid>

        <Grid item>
          <TextField
            name="Username"
            label="Create User Name"
            value={userDetails.username}
            id="standard-Name"
            autoFocus
            variant="outlined"
            fullWidth
            helperText={error.username}
            onChange={handleChangeUsername}
          />
        </Grid>
        <Grid item>
          <TextField
            name="Create password"
            label="Create password"
            type="password"
            value={userDetails.password}
            // required
            helperText={error.password}
            id="createPassword"
            variant="outlined"
            fullWidth
            onChange={(e) => setUserDetails({ ...userDetails, password: e.target.value })}
          />
        </Grid>
        <Grid item>
          <TextField
            name="Confirm password"
            label="Confirm password"
            type="password"
            value={userDetails.confirmPassword}
            helperText={error.confirmPassword}
            // required
            id="password"
            variant="outlined"
            fullWidth
            onChange={(e) => setUserDetails({ ...userDetails, confirmPassword: e.target.value })}
          />
        </Grid>
        <Grid item>
          <TextField
            name="email"
            label="Email"
            value={userDetails.email}
            id="standard-email"
            placeholder="Email(Optional)"
            variant="outlined"
            fullWidth
            onChange={(e) => setUserDetails({ ...userDetails, email: e.target.value })}
          />
        </Grid>
        <Grid item>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            name="phoneNumber"
            render={({ value, onChange }) => {
              return (
                <div>
                  <CustomPhoneInput
                    handleOnLogin={handleSubmit}
                    autoFocus={false}
                    value={value}
                    placeholder="Phone Number(Optional)"
                    onChange={(value, country, e, formattedValue) => {
                      let shouldWrite = value !== country.dialCode;
                      setUserDetails({ ...userDetails, countryCode: country.dialCode });
                      if (shouldWrite) onChange(value);
                    }}
                  />
                </div>
              );
            }}
          />
        </Grid>
        <Fade in={errors.phoneNumber}>
          <div style={{ textAlign: 'center' }}>
            <Typography sx={{ color: '#FF0000', fontSize: '14px' }}>
              Please enter a valid phone number.
            </Typography>
          </div>
        </Fade>

        <Grid item>
          <FormControlLabel
            control={
              <Checkbox onChange={handleChange} name="checkedB" color="primary" value={checked} />
            }
            label={
              <label>
                I have read and agreed to the{' '}
                <a
                  target="_blank"
                  style={{ color: '#000' }}
                  href="https://mentorhubapp.org/terms-conditions">
                  Terms and Conditions
                </a>{' '}
                and{' '}
                <a
                  target="_blank"
                  style={{ color: '#000' }}
                  href="https://mentorhubapp.org/mentorhub-eula/">
                  {' '}
                  User Agreement
                </a>
              </label>
            }></FormControlLabel>
        </Grid>
        <Fade in={error.checked}>
          <div style={{ textAlign: 'center' }}>
            <Typography style={{ color: '#FF0000', fontSize: '14px' }}>{error.checked}</Typography>
          </div>
        </Fade>
        <Grid item>
          <Button
            sx={{ marginTop: '5px', color: '#fff' }}
            type="submit"
            fullWidth
            size="large"
            color="primary"
            onClick={handleSubmit}
            variant="contained">
            {loading ? <CircularProgress size={24} /> : 'Get Started'}
            {/* Get Started */}
          </Button>
        </Grid>
        <Grid item>
          <Typography className="description" color="textSecondary" style={{ marginTop: 10 }}>
            <a
              style={{ color: theme.palette.primary.main, cursor: 'pointer' }}
              sx={{ color: theme.palette.primary.main, cursor: 'pointer' }}
              onClick={() => {
                history.push(getRoutes.selectLogin);
                trackEvent('click', {
                  screen: screenNames.SetupAccount,
                  Action: learnerAction.BACK_TO_SELECT_LOGIN,
                });
              }}>
              Back
            </a>
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default SetupAccount;
