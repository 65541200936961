import axios from '../Config';
import handleAxiosError from '../Helper/AxiosErrorHanlder';

export const getHobbies = () => {
  return axios({
    method: 'GET',
    url: `hobbies`,
  }).catch((err) => handleAxiosError(err));
};

export const ftueScreen = (id, ftueValue) => {
  return axios({
    method: 'PATCH',
    url: `user/${id}/`,
    data: { FtueShowMSS: ftueValue },
  });
};

export const ftuePriorityScreen = (id, ftuePriorityValue) => {
  return axios({
    method: 'PATCH',
    url: `user/${id}/`,
    data: { ftueSetGoals: ftuePriorityValue },
  });
};
