import {takeLatest, call, put, fork} from 'redux-saga/effects';
import * as api from '../Api/Programs';
import * as types from '../Store/actionTypes';

import logError, {generateSnackbarPayload} from '../Helper';

const sagaPath = 'src/Sagas/Auth.js';

function* getAssessmentQuestions(action) {
  const {activeProgram, callback} = action;
  try {
    const response = yield call(api.getAssessmentQuestions, activeProgram);
    const data = response.data;

    yield put({type: types.ASSESSMENT_QUES_SUCCESS, data});
    callback(true);
  } catch (e) {
    logError(e, sagaPath + 'getAssessmentQuestions');
    callback();
  }
}
function* postAssessmentQuestions(action) {
  const {questions, activeProgram, callback} = action;
  try {
    const response = yield call(api.postAssessmentQuestions, activeProgram, questions);
    const data = response.data;

    yield put({type: types.ASSESSMENT_QUES_POST_SUCCESS, data});
    yield put({type: types.TOGGLE_SNACKBAR, payload: generateSnackbarPayload('success', data.message)});

    callback(data);
  } catch (e) {
    logError(e, sagaPath + 'postAssessmentQuestions');
    callback();
  }
}

function* watchProgramSagas() {
  yield takeLatest(types.ASSESSMENT_QUES_REQUEST, getAssessmentQuestions);
  yield takeLatest(types.ASSESSMENT_QUES_POST, postAssessmentQuestions);
}

const programSagas = [fork(watchProgramSagas)];

export default programSagas;
