import { gql } from '@apollo/client';

export const ASSIGNED_GOALS = gql`
  query myAssignedGoals($skip: Int, $limit: Int, $filter: filter, $search: search) {
    myAssignedGoals(skip: $skip, limit: $limit, filter: $filter, search: $search) {
      _id
      goalTitle
      userGoal {
        _id
        goalId
        dueDate
        progress
        priority
        completedDate
        topSuggestions {
          _id
          articleTitle
        }
      }
      type
      problem {
        id
        problemImage
        problemName
      }
      article(skip: 0, limit: 5) {
        _id
        articleTitle
      }
      articleTotalCount
    }
  }
`;

export const ASSIGNED_GOALS_WITH_LENGTH = gql`
  query myAssignedGoalsWithCount(
    $skip: Int
    $limit: Int
    $articleLimit: Int
    $articleSkip: Int
    $showCustomGoal: Boolean
    $filter: filter
    $sortBy: JSON
  ) {
    myAssignedGoals(
      skip: $skip
      limit: $limit
      showCustomGoal: $showCustomGoal
      filter: $filter
      sortBy: $sortBy
    ) {
      _id
      goalTitle
      userGoal {
        _id
        goalId
        dueDate
        progress
        priority
      }
      problem {
        id
        problemImage
        problemName
      }
      article(limit: $articleLimit, skip: $articleSkip) {
        _id
        articleTitle
        savedInGoalEntry
      }
      articleTotalCount
    }
    myAssignedGoalsCount
  }
`;

export const UPDATE_ASSIGNED_GOAL = gql`
  mutation updateAssignedGoals($goalId: ID!, $userGoalId: ID!, $data: updateUserGoalInput!) {
    updateAssignedGoalsV2(goalId: $goalId, userGoalId: $userGoalId, data: $data) {
      _id
      goalId
      userId
    }
  }
`;

export const LIST_ALL_GOALS = gql`
  query listAllGoalEntry($skip: Int, $limit: Int) {
    listAllGoalEntry(skip: $skip, limit: $limit) {
      _id
      goalTitle
      problemId
      type
      userGoal {
        _id
        goalId
        userId
        progress
        priority
      }
    }
  }
`;

export const LIST_CUSTOM_GOALS = gql`
  query listCustomGoalEntry($skip: Int, $limit: Int, $customGoalOnly: Boolean) {
    listAllGoalEntry(skip: $skip, limit: $limit, customGoalOnly: $customGoalOnly) {
      _id
      goalTitle
      problemId
      type
      userGoal {
        _id
        goalId
        userId
        progress
        priority
      }
    }
  }
`;

export const ADD_CUSTOM_GOAL = gql`
  mutation addCustomGoalEntry($data: customGoalEntry!) {
    addCustomGoalEntry(data: $data) {
      _id
      goalTitle
      problemId
    }
  }
`;

export const UPDATE_GOAL_ENTRY = gql`
  mutation updateGoalEntry($goalEntryId: String!, $data: updateGoalEntryInput!) {
    updateGoalEntry(goalEntryId: $goalEntryId, data: $data) {
      _id
      goalTitle
    }
  }
`;

export const DELETE_CUSTOM_GOAL = gql`
  mutation deleteGoalEntry($goalEntryId: String!) {
    deleteGoalEntry(goalEntryId: $goalEntryId) {
      status
      message
    }
  }
`;

export const GET_ARTICLES_BY_GOAL = gql`
  query getAllArticlesByGoalId(
    $skip: Int
    $limit: Int
    $goalEntryId: ID
    $search: search
    $savedInGoalEntryGoalEntryId: String
    $userId: String
    $identifiersFilter: identifiersFilter
    $tags: [String]
  ) {
    getAllArticlesByGoalId(
      skip: $skip
      limit: $limit
      goalEntryId: $goalEntryId
      search: $search
      identifiersFilter: $identifiersFilter
      tags: $tags
    ) {
      _id
      articleTitle
      articleTasks {
        articleTaskDescription
        _id
        articleTaskTitle
      }
      savedInGoalEntry(goalEntryId: $savedInGoalEntryGoalEntryId, userId: $userId)
    }
  }
`;

export const ASSIGN_MULTIPLE_GOALS = gql`
  mutation assignMutipleGoalsToUser($data: [userGoalInput!]!) {
    assignMutipleGoalsToUser(data: $data) {
      _id
    }
  }
`;

export const UPDATE_MULTIPLE_GOALS = gql`
  mutation updateMutipleUserGoals($data: [updateMultipleUserGoalInputV2!]!) {
    updateMutipleUserGoalsV2(data: $data) {
      _id
    }
  }
`;

export const DELETE_MULTIPLE_GOALS = gql`
  mutation deleteMutipleUserGoals($userGoalIds: [String!]!) {
    deleteMutipleUserGoalsV2(userGoalIds: $userGoalIds) {
      status
      message
    }
  }
`;

// export const SAVE_ARTICLE_UNDER_GOAL = gql`
//   mutation saveArticleUnderGoal(
//     $goalEntryId: String!
//     $data: updateGoalEntryInput!
//     $createArticleBookmarkData: createArticleBookmarkInput!
//   ) {
//     updateGoalEntry(goalEntryId: $goalEntryId, data: $data) {
//       _id
//     }
//     createArticleBookmark(data: $createArticleBookmarkData) {
//       _id
//     }
//   }
// `;

export const SAVE_ARTICLE_UNDER_GOAL = gql`
  mutation SetTopSuggestionArticles(
    $data: setTopSuggestionsInput!
    $createArticleBookmarkData: createArticleBookmarkInput!
  ) {
    setTopSuggestionArticles(data: $data) {
      _id
    }
    createArticleBookmark(data: $createArticleBookmarkData) {
      _id
    }
  }
`;

export const GET_ARTICLE_BY_ID = gql`
  query getGoalArticleById($articleId: String!, $goalEntryId: String, $userId: String) {
    getArticleById(articleId: $articleId) {
      articleTasks {
        _id
        articleTaskTitle
        articleTaskDescription
        tags
      }
      savedInGoalEntry(goalEntryId: $goalEntryId, userId: $userId)
    }
  }
`;
export const LIST_CUSTOM_GOALS_COUNT = gql`
  query listAllGoalEntryCount($customGoalOnly: Boolean) {
    listAllGoalEntryCount(customGoalOnly: $customGoalOnly)
  }
`;
export const ASSIGNED_GOALS_COUNT = gql`
  query myAssignedGoalsCount($filter: filter, $search: search, $showCustomGoal: Boolean) {
    myAssignedGoalsCount(filter: $filter, search: $search, showCustomGoal: $showCustomGoal)
  }
`;

export const LIST_GOAL_PROBLEMS = gql`
  query listGoalProblems {
    listGoalProblems {
      _id
      id
      problemImage
      problemName
      problemDescription
      order
      status
      color
      __typename
    }
  }
`;
