import { Box, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import menteeAdviceLogo from '../../Assets/Images/menteeAdvice.svg';
import { generateSnackbarPayload } from '../../Helper';
import learnerAction from '../../Helper/Analytics/learnerAction';
import screenNames from '../../Helper/Analytics/screenNames';
import { trackEvent } from '../../Helper/EventTracker';
import getRoutes from '../../Routes/RoutePaths';
import { redirectToHomeVar } from '../../Routes/Vars';
import { updateUserDetails } from '../../Store/Actions/Auth';
import { toggleSnackbar } from '../../Store/Actions/Global';
import { getUserDetails } from '../../Store/reduxSelectors';
import updateProfileDetails from '../services/patchUserDetails.js';
import { ftuePriorityScreen } from './../../Api/Profile';
import BlackLogo from './../../Assets/Images/Blacklogo.svg';
import Mentorhublogo from './../../Assets/Images/Mentorhublogo.svg';
import welcomeLogo from './../../Assets/Images/welcomelogo2.svg';
const MenteeAdvice = ({ children }) => {
  const dispatch = useDispatch();

  const [advice, setAdvice] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector((state) => getUserDetails(state));
  const orgLogo = localStorage.getItem('organizationLogo');
  useEffect(() => {
    if (currentUser?.feedback) setAdvice(currentUser?.feedback);
  }, [currentUser?.feedback]);

  const history = useHistory();

  const handleSkip = () => {
    ftuePriorityScreen(currentUser.id, false);
    dispatch(
      updateUserDetails({
        FtueShowMSS: false,
        ftueSetGoals: false,
        takeMoodRingAssessment: false,
      }),
    );
    redirectToHomeVar(false);
    history.push(getRoutes.askForAssessment);
  };

  const handleSubmit = async () => {
    trackEvent('click', {
      screen: screenNames.MenteeAdvice,
      Action: learnerAction.FTUE_MENTEE_ADVICE,
    });
    if (!advice.trim()) {
      setError('Advice is required');
      return;
    }
    setLoading(true);
    await updateProfileDetails(currentUser.id, { feedback: advice.trim() });
    ftuePriorityScreen(currentUser.id, false);

    dispatch(
      toggleSnackbar(generateSnackbarPayload('success', 'Advice has been updated successfully')),
    );

    dispatch(
      updateUserDetails({
        feedback: advice.trim(),
        FtueShowMSS: false,
        ftueSetGoals: false,
      }),
    );
    redirectToHomeVar(false);
    history.push(getRoutes.askForAssessment);
    setLoading(false);
  };

  const handleChange = (e) => {
    setAdvice(e.target.value);
    if (e.target.value.length) setError('');
  };

  return (
    <section>
      <div className="ftueHeader" style={{ padding: orgLogo && '12px 0px 15px 20px' }}>
        <img
          src={orgLogo ? orgLogo : Mentorhublogo}
          alt="mentorhub"
          style={{ height: orgLogo && '49px' }}
        />
        <span style={{ color: 'white', marginRight: '20px' }}>
          Welcome, {currentUser.firstName}
        </span>
      </div>
      <div>
        <Grid container sx={{ width: '100% !important' }}>
          <Grid item md={4} xs={12} sx={{ backgroundColor: '#f8f8f8' }}>
            <Box display={'flex'} height={'calc(100vh - 131px)'} sx={{ overflowY: 'auto' }} p={3}>
              <Box
                margin={'auto'}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'flex-start'}>
                <img
                  src={orgLogo ? orgLogo : BlackLogo}
                  style={{ width: !orgLogo && '199px', height: orgLogo ? '80px' : '54px' }}
                  alt="mentorhub"
                />
                <h3 style={{ fontSize: '32px' }}>Welcome to MentorHub!</h3>
                <img
                  src={welcomeLogo}
                  style={{ marginTop: '50px', marginLeft: '-57px' }}
                  alt="welcome"
                />
              </Box>
            </Box>
          </Grid>
          <Grid item md={8} xs={12} style={{ backgroundColor: 'white' }}>
            <Box display={'flex'} p={2} height={'calc(100vh - 115px)'} sx={{ overflowY: 'auto' }}>
              <Box maxWidth={'450px'} margin={'auto'} maxHeight={'calc(100vh - 82.5px)'}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <img src={menteeAdviceLogo} alt="mentee advice" style={{ width: '450px' }} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 600, fontSize: '18px' }}>
                      What is one piece of advice you would give your mentor about how to build a
                      strong relationship with you?
                    </Typography>
                    <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#5E5E5E' }}>
                      (This will be shared with your mentor)
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className="advice-input"
                      multiline
                      fullWidth
                      onChange={handleChange}
                      inputProps={{ maxLength: 400 }}
                      value={advice}
                      helperText={error}
                      variant="filled"
                      label="Enter your thoughts"
                      error={error && error.length}
                    />
                    <Typography
                      sx={{
                        textAlign: 'right',
                        fontWeight: 500,
                        fontSize: '12px',
                        color: '#A4A4A4',
                      }}>{`${advice.length}/400`}</Typography>
                  </Grid>{' '}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      sx={{ color: '#FFFFFF', marginTop: '18px' }}
                      onClick={handleSubmit}>
                      {loading ? <CircularProgress size={26} /> : 'Complete Profile'}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

export default MenteeAdvice;
