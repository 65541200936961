import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getSideDrawerState, getUserDetails } from '../../../Store/reduxSelectors';
import AssignedGuides from './assignedGuides';
import AvailableGuides from './availableGuides';

const Guides = () => {
  const history = useHistory();
  const currentUser = useSelector((state) => getUserDetails(state));
  const [value, setValue] = React.useState(0);
  const open = useSelector((state) => getSideDrawerState(state));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  document.title = 'MentorHub - Guides';

  return (
    <React.Fragment>
      <div className={open ? 'menu-open guides-tab' : 'guides-tab'} id="Guides-main" tabIndex={0}>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          className="goaltabs"
          aria-label="guides tabs">
          <Tab active tabIndex="0" disableRipple label="My Guides" />
          <Tab tabIndex="0" label="All guides" disableRipple />
        </Tabs>

        {value === 0 && <AssignedGuides {...currentUser} history={history} />}
        {value === 1 && <AvailableGuides {...currentUser} />}
      </div>
    </React.Fragment>
  );
};

export default Guides;
