import { useReactiveVar } from '@apollo/client';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MessageIcon from '@mui/icons-material/Message';
import { Avatar, TextField, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { generateSnackbarPayload } from '../../../../src/Helper';
import theme from '../../../MaterialUiTheme';
import * as types from '../../../Store/actionTypes';
import { handleChange } from '../services/operations';
import { UpdateActivityHook } from '../services/updateAssignedTask';
import { limitVar, selectedActivityVar } from '../vars/reactiveVars';

const Activity = (props) => {
  console.log('props.completed', props.completed);
  const dispatch = useDispatch();
  useEffect(() => {
    props.badgeIndex === 0 && props.activityIndex === 0 && props._id && selectedActivityVar(props);
    props.badgeIndex === 0 && props.activityIndex === 0 && !props._id && selectedActivityVar(null);
  }, []);

  const [loadState, setLoadState] = useState(false);
  const selectedActivity = useReactiveVar(selectedActivityVar);
  const isSelected = selectedActivity == null || selectedActivity._id !== props._id;
  const styleText = !isSelected ? { display: 'block' } : {};
  const isCurrentActivity = selectedActivity != null && selectedActivity._id === props._id;
  const { updateBackend, updateReadStatus } = UpdateActivityHook();

  const getdueDate = () => {
    if (!props.dueDate) return null;
    return moment(new Date(Number(props.dueDate))).format('MMM DD');
  };

  const isOverdue = () => {
    if (!props.dueDate || props.completed) return false;
    return moment(new Date(Number(props.dueDate))).isBefore(moment(), 'day');
  };

  const dueDateStyle = isOverdue() ? { color: 'red' } : { color: 'black' };

  const handleClick = () => {
    limitVar(10);
    props._id && selectedActivityVar(props);
    props.unreadCommentsCount > 0 && updateReadStatus(props);
  };

  const titleErr = () => {
    dispatch({
      type: types.TOGGLE_SNACKBAR,
      payload: generateSnackbarPayload('error', 'Title is required'),
    });
  };

  const handleAddActivity = (event) => {
    if (event.key === 'Enter') {
      if (event.target.value.trim() === '') titleErr();
      else {
        !loadState && updateBackend(props, handleChange(event, props, 'title'), null, setLoadState);
      }
    }
  };

  return (
    <div className="each-row" onClick={handleClick} style={{ cursor: 'pointer' }}>
      <div className="each-row-left">
        <h2>
          {props.completed ? (
            <CheckCircleIcon style={{ color: theme.palette.primary.main }} />
          ) : (
            <CheckCircleOutlineIcon />
          )}
        </h2>
        {props.menteeId === props.createdBy || !props._id ? (
          <>
            <span className="title-span" style={{ marginLeft: '4px' }}>
              {isSelected && props.title}
            </span>
            <span className="text-span" style={styleText}>
              <TextField
                placeholder="Add Activity and press Enter"
                value={isCurrentActivity && props._id ? selectedActivity.title : props.title}
                onKeyUp={(event) => {
                  handleAddActivity(event);
                }}
                onChange={(event) => handleChange(event, props, 'title')}
                onClick={handleClick}
                sx={{
                  '& .MuiInputBase-root': {
                    height: '32px',
                    '& .MuiOutlinedInput-input': {
                      border: 'none !important',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderRadius: '0px',
                    },
                  },
                }}
              />
            </span>
          </>
        ) : (
          <>
            <span className="title-span" style={{ marginLeft: '4px' }}>
              {props.title}
            </span>
            <span className="text-span" style={{ marginLeft: '4px' }}>
              {props.title}
            </span>
          </>
        )}
      </div>
      <div className="each-row-right">
        <Typography variant="subtitle2" component="h2" style={dueDateStyle}>
          {props.unreadCommentsCount > 0 && <MessageIcon />}
          {getdueDate()}
          <Avatar src={props.assignedToData && props.assignedToData.profileImage}>
            {props.assignedToData && props.assignedToData.firstName[0]}
          </Avatar>
        </Typography>
      </div>
    </div>
  );
};

export default Activity;
