import { useReactiveVar } from '@apollo/client';
import Grid from '@mui/material/Grid';
import React from 'react';
import LeftWing from './LeftWing';
import RightWing from './RightWing';
import { barSizeVar } from './vars';
const Home = () => {
  const barSize = useReactiveVar(barSizeVar);
  document.title = 'MentorHub - Home';

  return (
    <Grid container>
      <Grid item md={12}>
        <LeftWing />
      </Grid>
      {!barSize && (
        <Grid item md={3}>
          <RightWing />
        </Grid>
        // </ErrorBoundary>
      )}
    </Grid>
  );
};

export default Home;
