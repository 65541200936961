import axios from 'axios';
export default function ResetPassword(data, token) {
  delete axios.defaults.headers;
  return axios({
    method: 'POST',
    url: process.env.REACT_APP_BASE_URL + `/user/resetPassword?access_token=${token}`,
    data: {
      password: data,
    },
  });
}
