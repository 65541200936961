import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Video from 'twilio-video';
import { generateSnackbarPayload } from '../../../../src/Helper';
import EndCall from '../../../Assets/Images/endCallRed.svg';
import HideVideo from '../../../Assets/Images/hideVideoRed.svg';
import MeetingMessages from '../../../Assets/Images/MeetingMessages.svg';
import Mute from '../../../Assets/Images/muteRed.svg';
import ParticipantsIcon from '../../../Assets/Images/Participants.svg';
import ScreenShareIcon from '../../../Assets/Images/ScreenShare.svg';
import ShowVideo from '../../../Assets/Images/showVideoWhite.svg';
import StopScreenShareIcon from '../../../Assets/Images/StopScreenShare.svg';
import Unmute from '../../../Assets/Images/unmuteWhite.svg';
import getRoutes from '../../../Routes/RoutePaths';
import * as types from '../../../Store/actionTypes';
import { meetingVar } from '../services/vars';

const MeetingControls = ({
  room,
  setRoom,
  enableAudio,
  changeAudioStatus,
  showVideo,
  changeShowVideoStatus,
  setShowParticipants,
  showParticipants,
  participants,
  screenTrack,
  setScreenTrack,
  setShowFullScreen,
  setShowCard,
}) => {
  const dispatch = useDispatch();

  const history = useHistory();
  const toggleVoice = () => {
    const audioTracks = room && room.localParticipant && room.localParticipant.audioTracks;
    if (audioTracks) {
      if (enableAudio) {
        audioTracks.forEach((audioTrack) => {
          audioTrack.track.disable();
        });
      } else {
        audioTracks.forEach((audioTrack) => {
          audioTrack.track.enable();
        });
      }
      changeAudioStatus(!enableAudio);
    }
  };

  const toggleVideo = () => {
    const videoTracks = room && room.localParticipant && room.localParticipant.videoTracks;

    if (videoTracks) {
      if (showVideo) {
        videoTracks.forEach((videoTrack) => {
          if (videoTrack.trackName === 'camera') videoTrack.track.disable();
        });
      } else {
        videoTracks.forEach((videoTrack) => {
          videoTrack.track.enable();
        });
      }
      changeShowVideoStatus(!showVideo);
    }
  };

  const cancelMeeting = () => {
    setRoom((currentRoom) => {
      if (currentRoom && currentRoom.localParticipant.state === 'connected') {
        currentRoom.localParticipant.tracks.forEach(function (trackPublication) {
          trackPublication.track.stop();
        });
        currentRoom.disconnect();
        return null;
      } else {
        return currentRoom;
      }
    });
    setTimeout(() => {
      setShowFullScreen(false);
      setShowCard(false);
      meetingVar(null);
      history.replace(getRoutes.meetings);
    }, 200);
  };

  const screenShare = () => {
    navigator.mediaDevices
      .getDisplayMedia()
      .then((stream) => {
        const screenTrackVar = new Video.LocalVideoTrack(stream.getTracks()[0], { name: 'screen' });
        setScreenTrack(screenTrackVar);
        const roomVar = room;
        roomVar.localParticipant.publishTrack(screenTrackVar);
        setRoom(roomVar);
      })
      .catch((err) => {
        dispatch({
          type: types.TOGGLE_SNACKBAR,
          payload: generateSnackbarPayload('error', 'Unable to share the screen.'),
        });
      });
  };

  const stopScreenShare = () => {
    room.localParticipant.unpublishTrack(screenTrack);
    screenTrack.stop();
    setScreenTrack(null);
  };

  useEffect(() => {
    if (screenTrack) {
      screenTrack.on('stopped', () => {
        const roomVar = room;
        roomVar.localParticipant.unpublishTrack(screenTrack);
        setRoom(roomVar);
        screenTrack.stop();
        setScreenTrack(null);
      });
    }
  }, [screenTrack]);

  const handleScreenSharing = () => {
    if (!screenTrack) screenShare();
    else stopScreenShare();
  };

  const handleRedirect = () => {
    setShowCard(true);
    setShowFullScreen(false);
    history.push(getRoutes.messages);
  };

  return (
    <div className="action_wrap">
      <div className="action_wrap_date"></div>
      <div>
        <Tooltip title={enableAudio ? 'Turn off microphone' : 'Turn on microphone'}>
          <IconButton aria-label="Enable Audio" sx={{ padding: '10px' }} onClick={toggleVoice}>
            <img
              style={{ width: 50, height: 50 }}
              src={enableAudio ? Unmute : Mute}
              alt="microphone"
            />
          </IconButton>
        </Tooltip>
        <Tooltip title={showVideo ? 'Turn off camera' : 'Turn on camera'}>
          <IconButton sx={{ padding: '10px' }} onClick={toggleVideo}>
            <img
              style={{ width: 50, height: 50 }}
              src={showVideo ? ShowVideo : HideVideo}
              alt="vedio"
            />
          </IconButton>
        </Tooltip>
        <Tooltip title={'Leave call'}>
          <IconButton aria-label="Cancel Meeting" sx={{ padding: '10px' }} onClick={cancelMeeting}>
            <img style={{ width: 50, height: 50 }} src={EndCall} alt="cancel" />
          </IconButton>
        </Tooltip>
        <Tooltip title={screenTrack ? 'Turn off screen sharing' : 'Share Screen'}>
          <IconButton onClick={handleScreenSharing}>
            <img
              style={{ width: 50, height: 50 }}
              src={screenTrack ? StopScreenShareIcon : ScreenShareIcon}
              alt="screen sharing"
            />
          </IconButton>
        </Tooltip>
        <Tooltip title={'Go to messages'}>
          <IconButton aria-label="Go to messages" sx={{ padding: '10px' }} onClick={handleRedirect}>
            <img style={{ width: 50, height: 50 }} src={MeetingMessages} alt="messages" />
          </IconButton>
        </Tooltip>
      </div>
      <div className="action_wrap_usr">
        {participants.length > 1 && (
          <IconButton
            sx={{ cursor: 'pointer' }}
            onClick={() => setShowParticipants(!showParticipants)}>
            <Badge color="primary" badgeContent={participants.length}>
              <img src={ParticipantsIcon} alt="participants" />
            </Badge>
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default MeetingControls;
