import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Fade,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import learnerAction from '../../Helper/Analytics/learnerAction';
import screenNames from '../../Helper/Analytics/screenNames';
import { trackEvent } from '../../Helper/EventTracker';
import { loginWithPhoneNumber, verifyOtp } from '../../Store/Actions/Auth';
import AppBar from '../Common/AppBar';
//import { trackEvent } from '../../Helper/EventTracker';

const resendIntervalTime = 1000;
const resendOtpReActiveIn = 60;
let setIntervalSubscribed = null;
const VerifyOtp = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [counter, setCounter] = React.useState(resendOtpReActiveIn);
  const [checked, setChecked] = React.useState(false);
  const [error, setError] = useState({
    username: '',
    password: '',
    confirmPassword: '',
    checked: '',
  });

  const counterRef = React.useRef();
  counterRef.current = counter;
  const history = useHistory();

  const dispatch = useDispatch();

  const location = useLocation();
  const locationState = location.state;

  const handleOnLogin = (value) => {
    if (!checked) {
      setError((prevState) => ({
        ...prevState,
        checked: 'Please accept the terms and conditions',
      }));
    }

    if (checked) {
      const payload = { ...locationState.payload, otp: value.otp };
      setIsLoading(true);
      dispatch(
        verifyOtp(payload, () => {
          setIsLoading(false);
        }),
      );
    }
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
    setError({ ...error, checked: '' });
  };

  React.useEffect(() => {
    handleResendTimer();
    if (!(locationState && locationState.payload)) history.replace('/');
    return () => {
      if (setIntervalSubscribed) clearInterval(setIntervalSubscribed);
    };
  }, []);

  const resendOtp = () => {
    trackEvent('click', {
      screen: screenNames.VerifyOtp,
      Action: learnerAction.RESEND_OTP,
    });
    handleResendTimer();
    const payload = { ...locationState.payload };
    dispatch(loginWithPhoneNumber(payload, () => {}));
  };
  const { register, handleSubmit, errors } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      phoneNumber: '',
    },
  });

  const handleResendTimer = () => {
    setIntervalSubscribed = setInterval(() => {
      if (Number(counterRef.current) === 0) {
        clearInterval(setIntervalSubscribed);
        setCounter(resendOtpReActiveIn);
      } else setCounter(counterRef.current - 1);
    }, resendIntervalTime);
  };
  const NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        format="####"
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        isNumericString
      />
    );
  };
  const getFormat = () => {
    let formatValue = [];
    Array.from(locationState.payload.countryCode).forEach((rec, index) => {
      formatValue.push('#');
      if (index == Array.from(locationState.payload.countryCode).length - 1) {
        formatValue.push('-###-###-####');
      }
    });
    return formatValue.join('');
  };

  return (
    <section className="login-common">
      <div className="ligin-wrap opt-verify">
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%' }}>
          <AppBar></AppBar>
        </div>{' '}
        <Card
          className="ligin-wrap-inner"
          sx={{
            borderRadius: '25px',
            display: 'flex',
            flexDirection: 'row',
            overflow: 'visible',
            padding: '20px 40px',
          }}>
          <form style={{ width: '100%' }} onSubmit={handleSubmit((value) => handleOnLogin(value))}>
            <Grid container direction="column" spacing={2}>
              <Grid item sx={{ textAlign: 'center' }}>
                <Typography
                  sx={{
                    color: '#000000',
                    fontSize: '24px',
                    fontFamily: 'Lato',
                    fontWeight: 700,
                  }}>
                  <span style={{ fontWeight: 700 }}>Enter Secure Code Below</span>
                </Typography>
              </Grid>

              <Grid item sx={{ textAlign: 'center', color: '#3E3C37' }}>
                <Typography
                  color="textSecondary"
                  sx={{ color: '#3E3C37', fontFamily: 'Lato', fontWeight: 400 }}>
                  Enter the code that was sent to your Phone number
                </Typography>
                <Typography color="textSecondary" sx={{ color: '#3E3C37' }}>
                  <NumberFormat
                    style={{ color: '#3E3C37', fontFamily: 'Lato', fontWeight: 400 }}
                    displayType={'text'}
                    format={getFormat()}
                    value={
                      locationState &&
                      locationState.payload.countryCode + locationState.payload.phoneNumber
                    }
                  />
                </Typography>
              </Grid>

              <Grid item>
                <TextField
                  autoFocus={true}
                  name="otp"
                  inputRef={register({
                    required: true,
                    minLength: 4,
                    maxLength: 4,
                  })}
                  // required
                  sx={{
                    '& .MuiFormLabel-root.Mui-focused': {
                      color: '#27cad2',
                      fontFamily: 'Lato',
                    },
                    '& .MuiInputBase-root': {
                      borderRadius: '4px',
                      backgroundColor: '#F8F8F8',
                      fontFamily: 'Lato',
                    },
                  }}
                  id="standard-basic"
                  variant="outlined"
                  // InputProps={{
                  //   inputComponent: NumberFormatCustom,

                  // }}
                  fullWidth
                  error={!!errors.otp}
                />
              </Grid>
              <Fade in={errors.otp}>
                <div style={{ textAlign: 'center' }}>
                  <Typography sx={{ color: '#FF0000', fontSize: '14px' }}>
                    Please enter the 4 digit OTP.
                  </Typography>
                </div>
              </Fade>

              <Grid item>
                <Grid item container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                  <Grid item className="varify-text">
                    {Number(counterRef.current) < resendOtpReActiveIn ? (
                      <Typography
                        sx={{
                          fontFamily: 'Lato',
                          fontWeight: 400,
                          color: '#8e9496',
                          width: '185px !important',
                          height: '22px',
                        }}>{`Available in ${counterRef.current} seconds`}</Typography>
                    ) : null}
                  </Grid>
                  <Grid item>
                    <Button
                      aria-label="Resend OTP"
                      className="resend-code"
                      sx={{ fontFamily: 'Lato', textTransform: 'capitalize' }}
                      disabled={Number(counterRef.current) < resendOtpReActiveIn || isLoading}
                      size="small"
                      onClick={() => resendOtp()}>
                      Resend Code
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <FormControlLabel
                  sx={{
                    '& .MuiCheckbox-colorPrimary.Mui-checked': {
                      color: '#27cad2',
                    },

                    '& .MuiTypography-root': {
                      fontFamily: 'Lato',
                    },
                  }}
                  control={
                    <Checkbox
                      onChange={handleChange}
                      name="checkedB"
                      color="primary"
                      value={checked}
                    />
                  }
                  label={
                    <label style={{ color: '#000' }}>
                      I have read and agreed to the{' '}
                      <a
                        target="_blank"
                        style={{ color: '#000' }}
                        href="https://mentorhubapp.org/terms-conditions">
                        Terms and Conditions{' '}
                      </a>{' '}
                      and{' '}
                      <a
                        target="_blank"
                        style={{ color: '#000' }}
                        href="https://mentorhubapp.org/mentorhub-eula/">
                        {' '}
                        User Agreement
                      </a>
                    </label>
                  }></FormControlLabel>
              </Grid>
              <Fade in={error.checked}>
                <div style={{ textAlign: 'center' }}>
                  <Typography sx={{ color: '#FF0000', fontSize: '14px' }}>
                    {error.checked}
                  </Typography>
                </div>
              </Fade>

              <Grid item>
                <Button
                  aria-label="Submit"
                  className="otp-section"
                  fullWidth
                  type="submit"
                  disabled={isLoading}
                  size="large"
                  color="primary"
                  style={{
                    marginTop: '0px',
                    color: '#fff',
                    fontSize: '14px',
                    backgroundColor: '#27cad2',

                    fontFamily: 'Lato',
                    textTransform: 'capitalize',
                    fontWeight: 'bold',
                    minHeight: '50px',
                    borderRadius: '4px',
                    '&:hover': {
                      backgroundColor: '#27cad2',
                    },
                  }}
                  variant="contained">
                  {isLoading ? <CircularProgress size={20} /> : 'Done'}
                </Button>
              </Grid>

              <Grid item>
                <Button
                  aria-label="Cancel"
                  // className="cancel-btn"
                  fullWidth
                  onClick={() => {
                    history.replace('/');
                    trackEvent('click', {
                      screen: screenNames.VerifyOtp,
                      Action: learnerAction.BACK_TO_SELECT_LOGIN,
                    });
                  }}
                  sx={{
                    textTransform: 'capitalize',
                    fontWeight: 'bold',
                    fontFamily: 'Lato',
                    color: '#27cad2',
                  }}
                  disabled={isLoading}
                  size="large"
                  color="primary"
                  variant="text">
                  Back
                </Button>
              </Grid>
            </Grid>
          </form>
        </Card>
      </div>
    </section>
  );
};

export default VerifyOtp;
