import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

const CustomDialogue = ({
  dialogueTitle,
  dialogueContent,
  dialogueOpen,
  setDialogueOpen,
  confirmFunction,
}) => {
  return (
    <Dialog
      open={dialogueOpen}
      onClose={() => setDialogueOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        width: '548px',
        height: '218px',
        borderRadius: '8px',
      }}>
      <DialogTitle id="alert-dialog-title">
        <span
          style={{
            fontSize: '20px',
            fontWeight: '700',
            fontFamily: 'Lato',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          {dialogueTitle}
        </span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{
            // marginTop: '14px',
            fontFamily: 'Lato',
            color: '#3E3C37',
            fontSize: '14px',
            fontWeight: '400',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <span>{dialogueContent}</span>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ padding: '0px 40px 40px 40px' }}>
        <Button
          onClick={() => setDialogueOpen(false)}
          color="primary"
          style={{
            fontSize: '14px',
            color: 'white',
            width: '226.5px',
            height: '40px',
            backgroundColor: '#27CAD2',
          }}
          autoFocus>
          No
        </Button>
        <Button
          onClick={() => {
            confirmFunction();
            setDialogueOpen(false);
          }}
          color="primary"
          style={{
            fontSize: '14px',
            color: '#3E3C37',
            width: '226.5px',
            height: '40px',
            border: '1px solid  #C4C4C4',
            marginLeft: '15px',
            backgroundColor: 'white',
          }}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialogue;
