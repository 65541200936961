import {gql} from '@apollo/client';

export const GET_NOTIFICATION_COUNT = gql`
  query getMyNotificationsCount {
    getUnreadCount
  }
`;

export const GET_MY_NOTIFICATIONS = gql`
  query getMyNotifications($limit: Int!, $skip: Int!) {
    getMyNotifications(limit: $limit, skip: $skip) {
      message
      _id
      readStatus
      createdDate
      event
      eventData
    }
  }
`;

export const READ_NOTIFICATION = gql`
  mutation ReadNotification($id: String!) {
    readNotification(id: $id)
  }
`;
export const CLEAR_NOTIFICATION = gql`
  mutation clearNotification($id: String) {
    clearNotification(id: $id)
  }
`;

export const CLEAR_ALL_NOTIFICATION = gql`
  mutation clearAllNotification{
    clearAllNotification
  }
`;