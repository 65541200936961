import * as types from '../actionTypes';

const initialState = {
  problemList: [],
  isProblemListLoading: true,
  pieChartData: {},
};

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case types.PROBLEM_LIST_SUCCESS:
      return {
        ...state,
        problemList: action.data,
        isProblemListLoading: false,
      };
    case types.SET_MOODRING_LOCAL:
      return {
        ...state,
        pieChartData: {...state.pieChartData, ...action.payload},
      };
    default:
      return state;
  }
};

export default authentication;
