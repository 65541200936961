import { Button, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BlackLogo from '../../../Assets/Images/Blacklogo.svg';
import learnerAction from '../../../Helper/Analytics/learnerAction';
import screenNames from '../../../Helper/Analytics/screenNames';
import { trackEvent } from '../../../Helper/EventTracker';
import getRoutes from '../../../Routes/RoutePaths';
import { getUserDetails } from '../../../Store/reduxSelectors';
import useGetMultipleAssessments from './hooks/useGetMultipleAssessment';

export const AssessmentDetail = ({ assessmentData, id, stepVar }) => {
  const history = useHistory();
  const { clearCacheData } = useGetMultipleAssessments();
  const userDetails = useSelector((state) => getUserDetails(state));
  const orgLogo = localStorage.getItem('organizationLogo');
  useEffect(() => {
    trackEvent('view', {
      screen: screenNames.TakeAssessment,
      Action: learnerAction.VIEW_ASK_ASSESSMENT,
    });
  }, []);
  return (
    <div>
      <img
        src={orgLogo ? orgLogo : BlackLogo}
        style={{
          width: !orgLogo && '193px',
          height: orgLogo ? '80px' : '54px',
          padding: '24px 0px 24px 0px',
        }}
      />
      <Typography sx={{ fontFamily: 'Lato', fontSize: '26px', fontWeight: 700 }}>
        {assessmentData &&
          assessmentData.assessmentById &&
          assessmentData.assessmentById.assessmentBank &&
          assessmentData.assessmentById.assessmentBank.title}
      </Typography>
      <Typography
        sx={{
          fontFamily: 'Lato',
          fontSize: '14px',
          fontWeight: 400,
          color: '#3E3C37',
          marginTop: '14px',
        }}>
        {assessmentData &&
          assessmentData.assessmentById &&
          assessmentData.assessmentById.assessmentBank &&
          assessmentData.assessmentById.assessmentBank.description}
      </Typography>
      <Button
        sx={{ marginTop: '40px', color: '#fff' }}
        fullWidth
        onClick={() => {
          history.push(`/take-assessement/${id}`);
          trackEvent('click', {
            screen: screenNames.TakeAssessment,
            Action: learnerAction.TAKE_ASSESSMENT,
          });
        }}
        size="large"
        color="primary"
        variant="contained">
        Take Assessment
      </Button>
      {stepVar !== 0 && (
        <div style={{ width: '443px', display: 'flex', justifyContent: 'center' }}>
          <Button
            sx={{ color: '#787878' }}
            // fullWidth
            onClick={() => {
              clearCacheData();
              history.push({
                pathname: userDetails.takeMoodRingAssessment
                  ? getRoutes.takeMoodRing
                  : getRoutes.home,
              });
              trackEvent('click', {
                screen: screenNames.TakeAssessment,
                Action: learnerAction.TAKE_ASSESSMENT,
              });
            }}
            size="small">
            Skip
          </Button>
        </div>
      )}
    </div>
  );
};
