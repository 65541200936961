import React from 'react';
import { useDispatch } from 'react-redux';

import { validateUser } from './Store/Actions/Auth';
import Navigator from './Routes';

import './App.css';
import './css/style.css';

const App = (props) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    // check if the user is authaurized
    dispatch(validateUser());
  }, []);

  return <Navigator />;
};

export default App;
