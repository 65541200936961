import { makeVar } from '@apollo/client';

export const chatListVar = makeVar({});
export const groupListVar = makeVar({});
export const chatListLoadingVar = makeVar(true);
export const groupListLoadingVar = makeVar(false);
export const pmMessageText = makeVar({});
export const ssgMessageText = makeVar({});
export const groupMessageText = makeVar({});
export const scMessageText = makeVar({});

export const receiverVar = makeVar(null);

export const selectedChatVar = makeVar({
  chatDetails: null,
  userDetails: null,
});

export const selectPhotosVar = makeVar({
  photos: [],
  selected: false,
  chatDetails: null,
  imageDetails: [],
  imageUrls: [],
});

export const selectDocumentsVar = makeVar({
  files: [],
  selected: false,
  documentDetails: [],
  documentUrls: [],
});

export const selectVideoVar = makeVar({
  video: null,
  selected: false,
  videoURL: null,
});

export const videoPreview = makeVar({
  url: undefined,
  isOpen: false,
});
