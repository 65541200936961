import axios from '../../../Config';
import handleAxiosError from '../../../Helper/AxiosErrorHanlder';

// export default function getAwsKey(){
//     return axios.get(apiURL+'settings/aws')
// }

const getAwsKey = async () => {
  return axios({
    method: 'GET',
    url: 'settings/aws',
  }).catch((err) => handleAxiosError(err));
};

export default getAwsKey;
