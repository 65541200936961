import { Card, CircularProgress, Fade, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import themeColors from '../../Constants/themeColors';
import learnerAction from '../../Helper/Analytics/learnerAction';
import screenNames from '../../Helper/Analytics/screenNames';
import { trackEvent } from '../../Helper/EventTracker';
import theme from '../../MaterialUiTheme';
import getRoutes from '../../Routes/RoutePaths';
import { loginWithPhoneNumber } from '../../Store/Actions/Auth';
import AppBar from '../Common/AppBar';
import { CustomButtom } from '../Common/ButtonCommon';
import CustomPhoneInput from '../Common/PhoneInput';
import LoginWithEmail from './LoginWithEmail';

const useStyles = makeStyles((theme, options) => {
  return {
    root: {
      display: 'flex',
    },
    appBar: {
      backgroundColor: themeColors.chatRoomRoot,
      minHeight: 64,
      // zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: `calc(100% - 25%)`,
      left: '0 !important',
      zIndex: 0,
      maxWidth: `64%`,
      borderBottom: `1px solid #e0e0e0`,
    },
  };
});

const Login = () => {
  const [countryCode, setCountryCode] = React.useState('+1');
  const [isLoading, setIsLoading] = React.useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const { register, getValues, handleSubmit, trigger, control, errors, clearErrors, setError } =
    useForm({
      mode: 'onSubmit',
      reValidateMode: 'onChange',
      defaultValues: {
        phoneNumber: '',
      },
    });

  const handleOnLogin = async () => {
    const isValidated = await trigger();
    trackEvent('click', {
      screen: screenNames.Login,
      Action: learnerAction.GET_CODE_VIA_TEXT,
    });
    if (isValidated) {
      const payload = {
        countryCode,
        phoneNumber: getValues('phoneNumber').replace(countryCode, ''),
      };
      setIsLoading(true);
      dispatch(
        loginWithPhoneNumber(payload, (success) => {
          if (success)
            history.push({
              pathname: '/verify-otp',
              state: {
                payload,
              },
            });

          setIsLoading(false);
        }),
      );
    }
  };

  return (
    <React.Fragment>
      <div style={{ position: 'fixed', top: 0, left: 0, width: '100%' }}>
        <AppBar></AppBar>
      </div>
      <section className="login-common" style={{ marginTop: '5%' }}>
        <div className="ligin-wrap">
          <Card
            className="ligin-wrap-inner"
            sx={{
              borderRadius: '25px',
              display: 'flex',
              flexDirection: 'row',
              overflow: 'visible',
              padding: '30px 30px',
              width: '400px',
            }}>
            <Grid container direction="column" spacing={2}>
              <Grid item sx={{ textAlign: 'center', padding: 'none' }}>
                <Typography
                  className="wel-mentor"
                  tabIndex={0}
                  sx={{
                    color: '#000',
                    fontSize: '24px',
                    fontFamily: 'Lato',
                    marginTop: '13px',
                    fontWeight: 700,
                  }}>
                  <span style={{ fontWeight: 700 }}>Login</span>
                </Typography>
              </Grid>
              {/* <Grid item style={{ textAlign: 'center', }}>
                         <Typography className="description"  style={{ color:'#3E3C37'}}>
                           Harnessing the science of supportive accountability.
                         </Typography>
                       </Grid>*/}

              <Grid item>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  name="phoneNumber"
                  render={({ value, onChange }) => {
                    return (
                      <div>
                        <CustomPhoneInput
                          handleOnLogin={handleOnLogin}
                          value={value}
                          placeholder="Phone Number"
                          onChange={(value, country, e, formattedValue) => {
                            let shouldWrite = value !== country.dialCode;
                            setCountryCode(country.dialCode);
                            if (shouldWrite) onChange(value);
                          }}
                        />
                      </div>
                    );
                  }}
                />
              </Grid>
              <Fade in={errors?.phoneNumber}>
                <div style={{ textAlign: 'center' }}>
                  <Typography sx={{ color: '#FF0000', fontSize: '14px' }}>
                    Please enter a valid phone number.
                  </Typography>
                </div>
              </Fade>

              <Grid item>
                <CustomButtom
                  // sx={{
                  //   marginTop: '0px',
                  //   color: '#fff',
                  //   textTransform: 'none',
                  //   fontSize: '14px',
                  //   backgroundColor: '#27cad2',
                  //   textTransform: 'capitalize',
                  //   fontFamily: 'Lato',
                  //   fontWeight: 'bold',
                  //   '&:hover': {
                  //     backgroundColor: '#27cad2',
                  //   },
                  // }}
                  type="submit"
                  fullWidth
                  disableRipple
                  className="custom-button"
                  onClick={() => handleOnLogin()}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleOnLogin();
                    }
                  }}
                  disabled={isLoading}
                  size="large"
                  variant="contained">
                  {isLoading ? <CircularProgress size={20} /> : 'Get Code via Text'}
                </CustomButtom>
              </Grid>
              <Grid item>
                <Typography className="description" color="textSecondary">
                  <span style={{ display: 'inline-block', backgroundColor: '#fff', padding: 10 }}>
                    or
                  </span>
                  <hr style={{ borderColor: '#E3E3E3', marginBottom: 20, marginTop: '-18px' }} />
                </Typography>
              </Grid>
              <LoginWithEmail />
              <Grid item>
                <Typography className="description" color="textSecondary">
                  <a
                    style={{ color: theme.palette.primary.main, cursor: 'pointer' }}
                    onClick={() => {
                      history.push(getRoutes.selectLogin);
                      trackEvent('click', {
                        screen: screenNames.Login,
                        Action: learnerAction.BACK_TO_SELECT_LOGIN,
                      });
                    }}>
                    Back
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Login;
