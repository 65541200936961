import { Container } from '@mui/material';
import React from 'react';
import MoodRing from '../Assessment/MoodRing';
import Appbar from '../Common/AppBar';
const TakeMoodRingWrapper = ({ children }) => {
  return (
    <React.Fragment>
      <Appbar />
      <Container style={{ padding: 20, maxWidth: '960px' }}>
        <MoodRing />
      </Container>
    </React.Fragment>
  );
};

export default TakeMoodRingWrapper;
