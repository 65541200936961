import S3FileUpload from 'react-s3';

const awsFileUpload = async (file, dirName, accessKeyId, secretAccessKey) => {
  const config = {
    bucketName: 'checkmateimages',
    dirName,
    region: 'us-west-2',
    accessKeyId,
    secretAccessKey,
  };

  try {
    const response = await S3FileUpload.uploadFile(file, config);
    return response;
  } catch (err) {
    return err;
  }
};

export default awsFileUpload;
