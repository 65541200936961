import {takeLatest, call, put, fork} from 'redux-saga/effects';
import * as api from '../Api/Dashboard';
import * as types from '../Store/actionTypes';

import logError from '../Helper';

const sagaPath = 'src/Sagas/Dashboard.js';

function* getAvgMoodRingApp(action) {
  const {userId, filter, callback} = action;
  try {
    const response = yield call(api.getAvgMoodRingApp, {userId, filter});
    const data = response.data;
    yield put({type: types.AVG_MOOD_RING_SUCCESS, data});
    callback(true);
  } catch (e) {
    logError(e, sagaPath + 'getAvgMoodRingApp');
    callback();
  }
}
function* getUserBadges(action) {
  const {userId, callback} = action;
  try {
    const response = yield call(api.getUserBadges, userId);
    const data = response.data;
    yield put({type: types.USER_BADGES_SUCCESS, data});
  } catch (e) {
    logError(e, sagaPath + 'getUserBadges');
    callback();
  }
}
function* getPointSummary(action) {
  const {payload, callback} = action;

  try {
    const response = yield call(api.getPointSummary, payload);
    const data = response.data;
    yield put({type: types.POINT_SUMMARY_SUCCESS, data});
  } catch (e) {
    logError(e, sagaPath + 'getUserBadges');
    callback();
  }
}

function* watchDashboardSagas() {
  yield takeLatest(types.AVG_MOOD_RING_REQUEST, getAvgMoodRingApp);
  yield takeLatest(types.USER_BADGES_REQUEST, getUserBadges);
  yield takeLatest(types.POINT_SUMMARY_REQUEST, getPointSummary);
}

const dashboardSagas = [fork(watchDashboardSagas)];

export default dashboardSagas;
