import { Button, Card, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import themeColors from '../../Constants/themeColors';
import learnerAction from '../../Helper/Analytics/learnerAction';
import screenNames from '../../Helper/Analytics/screenNames';
import { trackEvent } from '../../Helper/EventTracker';
import getRoutes from '../../Routes/RoutePaths';
import AppBar from '../Common/AppBar';
import { CustomButtom } from '../Common/ButtonCommon';
const useStyles = makeStyles((theme, options) => {
  return {
    root: {
      display: 'flex',
    },
    appBar: {
      backgroundColor: themeColors.chatRoomRoot,
      minHeight: 64,
      // zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: `calc(100% - 25%)`,
      left: '0 !important',
      zIndex: 0,
      maxWidth: `64%`,
      borderBottom: `1px solid #e0e0e0`,
    },
  };
});
const SelectLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    trackEvent('view', {
      screen: screenNames.SelectLogin,
      Action: learnerAction.VIEW_SELECT_LOGIN,
    });
  }, []);

  return (
    <React.Fragment>
      <div style={{ position: 'fixed', top: 0, left: 0, width: '100%' }}>
        <AppBar></AppBar>
      </div>
      <section className="login-common">
        <div className="ligin-wrap">
          <Card
            className="ligin-wrap-inner"
            sx={{
              borderRadius: '25px',
              display: 'flex',
              flexDirection: 'row',
              overflow: 'visible',
              padding: '30px 30px',
              width: '400px',
              height: '270px',
            }}>
            <Grid container direction="column" spacing={2}>
              <Grid item style={{ textAlign: 'center' }}>
                <Typography
                  className="wel-mentor"
                  tabIndex={0}
                  sx={{
                    color: '#000',
                    fontSize: '24px',
                    fontFamily: 'Lato',
                    marginTop: '14px',
                  }}>
                  <span style={{ fontWeight: 700 }}>Welcome to MentorHub</span>
                </Typography>
              </Grid>
              <Grid item sx={{ textAlign: 'center' }}>
                <Typography
                  className="description"
                  color="textSecondary"
                  sx={{
                    fontFamily: 'Lato',
                    color: '#3E3C37 !important',
                    fontSize: '14px !important',
                  }}>
                  Achieve Your Goals Through The Power of
                  <br />
                  Mentoring.
                </Typography>
              </Grid>
              <Grid item style={{ paddingTop: '24px' }}>
                <CustomButtom
                  // sx={{
                  //   marginTop: '10px',
                  //   color: '#fff',
                  //   backgroundColor: theme.palette.primary.main,
                  //   fontSize: '14px',
                  //   fontFamily: 'Lato',
                  //   textTransform: 'capitalize',
                  //   fontWeight: 'bold',
                  //   minHeight: '50px',
                  //   borderRadius: '4px',
                  //   '&:hover': {
                  //     backgroundColor: theme.palette.primary.main,
                  //   },
                  // }}
                  type="submit"
                  disableRipple
                  className="custom-button"
                  fullWidth
                  size="large"
                  onClick={() => {
                    history.push(getRoutes.setupAccount);
                    trackEvent('click', {
                      screen: screenNames.SelectLogin,
                      Action: learnerAction.LOGIN_WITH_CODE,
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      history.push(getRoutes.setupAccount);
                    }
                  }}
                  aria-label="press enter to set up account"
                  variant="contained">
                  {' '}
                  I Have a Code to Access My Account
                </CustomButtom>
                <Button
                  sx={{
                    marginTop: '20px',
                    color: '#F5F5F5',
                    fontSize: '14px',
                    backgroundColor: '#e0e0e0',
                    textTransform: 'capitalize',
                    fontFamily: 'Lato',
                    fontWeight: 'bold',
                    minHeight: '50px',
                    borderRadius: '4px',
                    '&:hover': {
                      backgroundColor: '#e0e0e0',
                    },
                  }}
                  type="submit"
                  fullWidth
                  size="large"
                  disableRipple
                  className="custom-button"
                  aria-label="press enter to login"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      history.push(getRoutes.login);
                    }
                  }}
                  onClick={() => {
                    history.push(getRoutes.login);
                    trackEvent('click', {
                      screen: screenNames.SelectLogin,
                      Action: learnerAction.LOGIN_PHONE_EMAIL_USERNAME,
                    });
                  }}
                  variant="contained">
                  <span style={{ color: '#000' }}>Login Using my Phone/Username/Email</span>
                </Button>
              </Grid>
            </Grid>
          </Card>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SelectLogin;
