import MicNoneIcon from '@mui/icons-material/MicNone';
import MicOffIcon from '@mui/icons-material/MicOff';
import { Avatar, IconButton } from '@mui/material';
import { startCase } from 'lodash';

const NoVideo = ({ user, audioEnabled, currentUser }) => {
  if (!user) return null;
  return (
    <div className="no-video-overlay">
      <div className="micIcon-wrap">
        <div
          style={{
            borderRadius: '50%',
            backgroundColor: '#292929',
          }}>
          <IconButton sx={{ padding: '5px' }}>
            {audioEnabled ? (
              <MicNoneIcon color="secondary" fontSize="small" />
            ) : (
              <MicOffIcon color="secondary" fontSize="small" />
            )}
          </IconButton>
        </div>
      </div>
      <div>
        <Avatar alt="UserImage" src={user.profileImage} className="no-video-user">
          {user.firstName.charAt(0).toUpperCase()}
        </Avatar>
      </div>
      <div className="participant-name-wrap">
        <span
          style={{
            fontSize: '14px',
            color: '#fff',
          }}>
          {user._id === currentUser.id
            ? 'You'
            : startCase(user.firstName) + ' ' + startCase(user.lastName)}
        </span>
      </div>
    </div>
  );
};

export default NoVideo;
