import { Button } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import Slider from 'react-slick';
import slide1 from '../../Assets/Images/MentorHub-Logo.svg';
import slide13 from '../../Assets/Images/Slide-13.png';
import slide2 from '../../Assets/Images/Slide-2.png';
import slide3 from '../../Assets/Images/Slide-3.png';
import slide4 from '../../Assets/Images/Slide-4.png';
import slide6 from '../../Assets/Images/Slide-6.png';
import slide7 from '../../Assets/Images/Slide-7.png';
import slide8 from '../../Assets/Images/Slide-8.png';
import slide9 from '../../Assets/Images/Slide-9.png';
import learnerAction from '../../Helper/Analytics/learnerAction';
import screenNames from '../../Helper/Analytics/screenNames';
import { trackEvent } from '../../Helper/EventTracker';
import getRoutes from '../../Routes/RoutePaths';
import { updateUserDetails } from '../../Store/Actions/Auth';

const PreviousNextMethods = (props) => {
  React.useEffect(() => {
    setTimeout(() => {
      document.getElementById(`0-tour`).focus();
    }, 2000);
  }, []);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  let reference = useRef(null);
  const next = () => {
    trackEvent('click', {
      screen: screenNames.AppTutorial,
      Action: learnerAction.NEXT_APP_TOUR,
    });
    reference.current.slickNext();
  };
  const [currentSlide, currentSlideUpdate] = useState(0);

  const previous = () => {
    trackEvent('click', {
      screen: screenNames.AppTutorial,
      Action: learnerAction.PREVIOUS_APP_TOUR,
    });
    reference.current.slickPrev();
  };
  const ShowState = (next) => {
    currentSlideUpdate(next);
  };
  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: true,
    beforeChange: (current, next) => props.setShowClose(next),
    afterChange: (currentSlide) => {
      ShowState(currentSlide);
      document.getElementById(`${currentSlide}-tour`).focus();
    },
  };
  const closeTour = () => {
    trackEvent('click', {
      screen: screenNames.AppTutorial,
      Action: learnerAction.CLOSE_APP_TOUR_MODAL,
    });
    dispatch(
      updateUserDetails({
        FtueShowMSS: false,
        takeMoodRingAssessment: false,
      }),
    );
    props.handleClose();
    props.setAppTourOpen(false);
    history.push(getRoutes.home);
  };

  return (
    <div>
      <Button
        aria-label="Exit from App tutorial modal"
        color="primary"
        onClick={closeTour}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            closeTour();
          }
        }}
        className="skip-all">
        Close
      </Button>
      <Slider ref={reference} {...settings}>
        <div className="slide-item" role="TourSlide" title="Introduction" aria-label="Introduction">
          <div className="tour-banner">
            <img src={slide1} alt="tour-mood" />
          </div>
          <div className="tour-cnt">
            <h2
              id="0-tour"
              // tabindex={currentSlide == 0 ? '0' : '1'}
            >
              Introduction
            </h2>
            <p
            // tabindex={currentSlide == 0 ? '0' : '1'}
            >
              Welcome to MentorHub! MentorHub is a tool that helps you get the most out of your
              mentoring experience. It helps you and your mentor stay connected and focused on the
              goals you want to achieve. Tap next to read about MentorHub's features.
            </p>
          </div>
        </div>
        <div title="check in" className="slide-item" role="TourSlide" aria-label="Assessment">
          <div className="tour-banner">
            <img src={slide2} alt="tour-mood" />
          </div>
          <div className="tour-cnt">
            <h2
              id="1-tour"
              // tabindex={currentSlide == 1 ? '0' : '1'}
            >
              Survey
            </h2>
            <p
            // tabindex={currentSlide == 1 ? '0' : '1'}
            >
              Sometimes you may be asked to take surveys when you sign in to MentorHub. These
              surveys will help your program know more about the best ways to support you.
            </p>
          </div>
        </div>
        <div title="Resources" className="slide-item" role="TourSlide" aria-label="Mood Ring">
          <div className="tour-banner">
            <img src={slide3} alt="tour-mood" />
          </div>
          <div className="tour-cnt">
            <h2
              id="2-tour"
              //  tabindex={currentSlide == 2 ? '0' : '1'}
            >
              Mood Ring
            </h2>
            <p
            // tabindex={currentSlide == 2 ? '0' : '1'}
            >
              The Mood Ring gives you the chance to think about how you are doing across different
              parts of your life and to share with your mentor what you might want their help with.
              To complete the Mood Ring, move the slider from left to right to indicate the level of
              support you would like from your mentor. The more you move it to the right, the more
              of a priority for support that area is.
              <br /> <br />
              You will be able to update the Mood Ring every 24 hours.
            </p>
          </div>
        </div>
        <div className="slide-item" role="TourSlide" aria-label="Third-Party Apps">
          <div className="tour-banner">
            <img src={slide4} alt="tour-mood" />
          </div>
          <div className="tour-cnt">
            <h2
              id="3-tour"
              //  tabindex={currentSlide == 3 ? '0' : '1'}
            >
              Third-Party Apps
            </h2>
            <p
            //  tabindex={currentSlide == 3 ? '0' : '1'}
            >
              MentorHub provides information about other apps that have been proven to help with
              everyday challenges people face. To view MentorHub recommended apps, go to the Apps
              section in the menu.
            </p>
          </div>
        </div>
        {/* <div className="slide-item" role="TourSlide" aria-label="Points and Badges">
          <div className="tour-banner">
            <img src={slide5} alt="tour-mood" />
          </div>
          <div className="tour-cnt">
            <h2 id="4-tour" tabindex={currentSlide == 4 ? '0' : '1'}>
              Points and Badges
            </h2>
            <p tabindex={currentSlide == 4 ? '0' : '1'}>
              You can earn points in MentorHub by completing Check-Ins and accessing resources that
              you’ve added to “My Apps”. You can earn badges by accumulating points.
            </p>
          </div>
        </div> */}
        <div className="slide-item" role="TourSlide" aria-label="Goals and Guides">
          <div className="tour-banner">
            <img src={slide6} alt="tour-mood" />
          </div>
          <div className="tour-cnt">
            <h2
              id="4-tour"
              // tabindex={currentSlide == 4 ? '0' : '1'}
            >
              Goals and Guides
            </h2>
            <p
            // tabindex={currentSlide == 4 ? '0' : '1'}
            >
              Set goals for your mentoring experience to help you and your mentor stay focused on
              what you want to accomplish together. Use guides for suggestions of badges and
              activities you can work toward to make progress on your goals.
            </p>
          </div>
        </div>
        <div className="slide-item" role="TourSlide" aria-label="Messaging">
          <div className="tour-banner">
            <img src={slide7} alt="tour-mood" />
          </div>
          <div className="tour-cnt">
            <h2
              id="5-tour"
              // tabindex={currentSlide == 5 ? '0' : '1'}
            >
              Messaging
            </h2>
            <p
            // tabindex={currentSlide == 5 ? '0' : '1'}
            >
              Interact with your mentor and program contact by navigating to the chat tab and
              tapping their names.
            </p>
          </div>
        </div>
        <div className="slide-item" role="TourSlide" aria-label="Video Calling">
          <div className="tour-banner">
            <img src={slide8} alt="tour-mood" />
          </div>
          <div className="tour-cnt">
            <h2
              id="6-tour"
              // tabindex={currentSlide == 6 ? '0' : '1'}
            >
              Video Calling
            </h2>
            <p
            // tabindex={currentSlide == 6 ? '0' : '1'}
            >
              If you want to video chat with your Mentor, navigate to the chat tab and let them
              know. You’ll be notified when they’ve scheduled a call with you. Simply tap on the
              call in the video tab to join it.
            </p>
          </div>
        </div>
        <div className="slide-item" role="TourSlide" aria-label="Video Calling">
          <div className="tour-banner">
            <img src={slide13} alt="tour-mood" />
          </div>
          <div className="tour-cnt">
            <h2
              id="7-tour"
              // tabindex={currentSlide == 7 ? '0' : '1'}
            >
              Profile
            </h2>
            <p tabindex={currentSlide == 7 ? '0' : '1'}>
              Profiles can be viewed by the mentor, mentee, and organization’s program team. Your
              profile allows you to share general information about yourself, such as your hometown,
              preferred name, and bio. To edit your profile, tap on the profile picture in the
              bottom left-hand section of your interface and select the option”My Profile
            </p>
          </div>
        </div>{' '}
        <div className="slide-item" role="TourSlide" aria-label="Resources">
          <div className="tour-banner">
            <img src={slide9} alt="tour-mood" />
          </div>
          <div className="tour-cnt">
            <h2
              id="8-tour"
              // tabindex={currentSlide == 8 ? '0' : '1'}
            >
              Resources
            </h2>
            <p
            // tabindex={currentSlide == 8 ? '0' : '1'}
            >
              Tap on the menu in the top left hand corner of your interface to access the resource
              page. This will show you the resources and information that your program has collected
              for you.
            </p>
          </div>
        </div>
      </Slider>

      <div className="slider-btns">
        {props.showClose > 0 && (
          <Button
            aria-label="Previous"
            color="primary"
            disableRipple
            onClick={previous}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                previous();
              }
            }}
            className="slick-btns prev">
            Prev
          </Button>
        )}
        {props.showClose < 7 ? (
          <Button
            aria-label="Next"
            color="primary"
            onClick={next}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                next();
              }
            }}
            className="slick-btns next">
            Next
          </Button>
        ) : (
          <Button
            aria-label="Close"
            color="primary"
            onClick={closeTour}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                closeTour();
              }
            }}
            className="slick-btns next">
            Close
          </Button>
        )}
      </div>
    </div>
  );
};

export default PreviousNextMethods;
