import mixpanel from 'mixpanel-browser';
import Store from '../Store/Reducer/index';
import { getUserDetails } from '../Store/reduxSelectors';

export const trackEvent = (
  event,
  data = {
    screen: '',
    Action: '',
    sender: '',
    reciever: '',
    chatId: '',
    userid: '',
    orgid: '',
    programid: '',
    orgName: '',
    programname: '',
  },
) => {
  mixpanel.init(process.env.REACT_APP_MIX_PANEL, { debug: true, ignore_dnt: true });

  const state = Store.getState();
  const CurrentUserInfo = getUserDetails(state);

  const submitData = {
    app: 'MenteeWeb',
    userId: CurrentUserInfo.id || data.userid,
    event: event,
    env: process.env.REACT_APP_ENVIRONMENT,
    time: new Date(),
    screen: data.screen,
    Action: data.Action,
    orgId: CurrentUserInfo?.organization?.id || data.orgid,
    ...(data.sender !== '' && { sender: data.sender }),
    ...(data.reciever !== '' && { reciever: data.reciever }),
    ...(data.chatId !== '' && { chatId: data.chatId }),
    ...((CurrentUserInfo?.enrolledInProgramIds || data.programid) && {
      programId:
        (CurrentUserInfo?.enrolledInProgramIds && CurrentUserInfo?.enrolledInProgramIds[0]) ||
        data.programid,
    }),
    ...((CurrentUserInfo?.program || data.programname) && {
      programName: CurrentUserInfo?.program || data.programname,
    }),
    organizationName: CurrentUserInfo?.organization?.organizationName || data.orgName,
  };

  mixpanel.track(event, submitData);
};

export const trackAPIError = (data) => {
  mixpanel.init(process.env.REACT_APP_MIX_PANEL, { debug: true, ignore_dnt: true });

  const state = Store.getState();
  const CurrentUserInfo = getUserDetails(state);

  const submitData = {
    app: 'MenteeWeb',
    userId: CurrentUserInfo.id,
    event: 'API-ERROR',
    env: process.env.REACT_APP_ENVIRONMENT,
    time: new Date(),
    error: data,
    ...(CurrentUserInfo?.enrolledInProgramIds && {
      programId: CurrentUserInfo.enrolledInProgramIds[0],
    }),
    orgId: CurrentUserInfo?.organization?.id,
    ...(CurrentUserInfo?.program && {
      programName: CurrentUserInfo?.program,
    }),
    organizationName: CurrentUserInfo?.organization?.organizationName,
  };
  mixpanel.track('API-ERROR', submitData);
};
