import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import React from 'react';

const Answer = (props) => {
  return (
    <CardActions>
      <Button size="small" onClick={props.onClick}>
        {props?.answer?.value}
      </Button>
    </CardActions>
  );
};
export default Answer;
