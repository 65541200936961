import {takeLatest, call, put, fork} from 'redux-saga/effects';
import * as api from '../Api/Tools';
import * as types from '../Store/actionTypes';

import logError from '../Helper';

const sagaPath = 'src/Sagas/Tools.js';

function* getInstalledApps(action) {
  const {userId, callback} = action;
  try {
    const response = yield call(api.getInstalledApps, userId);
    const data = response.data;
    yield put({type: types.INSTALLED_APPS_SUCCESS, data});
    callback(true);
  } catch (e) {
    logError(e, sagaPath + 'getInstalledApps');
    callback();
  }
}
function* getRecommendedApps(action) {
  const {userId, callback} = action;
  try {
    const response = yield call(api.getRecommendedApps, userId);
    const data = response.data;
    yield put({type: types.RECOMMENDED_APPS_SUCCESS, data});
  } catch (e) {
    logError(e, sagaPath + 'getRecommendedApps');
    callback();
  }
}

function* getAllApps(action) {
  const {userId, callback} = action;
  try {
    const response = yield call(api.getAllApps, userId);
    const data = response.data;
    yield put({type: types.ALL_APPS_SUCCESS, data});
  } catch (e) {
    logError(e, sagaPath + 'getAllApps');
    callback();
  }
}

function* watchToolsSagas() {
  yield takeLatest(types.INSTALLED_APPS_REQUEST, getInstalledApps);
  yield takeLatest(types.RECOMMENDED_APPS_REQUEST, getRecommendedApps);
  yield takeLatest(types.ALL_APPS_REQUEST, getAllApps);
}

const toolsSagas = [fork(watchToolsSagas)];

export default toolsSagas;
