import * as types from '../actionTypes';

export const getProblemList = (payload, callback) => {
  return {
    type: types.PROBLEM_LIST_REQUEST,
    callback,
  };
};

export const setMoodByMenteeId = (payload, callback) => {
  return {
    type: types.SET_MOOD_REQUEST,
    payload,
    callback,
  };
};

export const setMoodRingInLocal = (payload) => {
  return {
    type: types.SET_MOODRING_LOCAL,
    payload,
  };
};
