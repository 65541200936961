export default {
  //Dashboard
  VIEW_ALL_MESSAGES: 'VIEW_ALL_MESSAGES',
  VIEW_ALL_RESOURCES: 'VIEW_ALL_RESOURCES',
  VIEW_ALL_BADGES: 'VIEW_ALL_BADGES',
  VIEW_ALL_GOALS: ' VIEW_ALL_GOALS',

  //MoodRing
  UPDATE_MOOD_RING: 'UPDATE_MOOD_RING',
  SUBMIT_MOOD_RING: 'SUBMIT_MOOD_RING',
  //Message
  OPEN_CHAT_WITH_PM: 'OPEN_CHAT_WITH_PM',
  OPEN_CHAT_WITH_MSS: 'OPEN_CHAT_WITH_MSS',
  OPEN_CHAT_WITH_MENTOR: 'OPEN_CHAT_WITH_MENTOR',
  OPEN_GROUP_DETAILS: 'OPEN_GROUP_DETAILS',
  OPEN_GROUP_CHAT: 'OPEN_GROUP_CHAT',
  NOTIFICATION_MUTED: 'NOTIFICATION_MUTED',
  NOTIFICATION_ENABLED: 'NOTIFICATION_ENABLED',
  FLAG_USER: 'FLAG_MENTOR',
  OPEN_CHAT_INFORMATION: 'OPEN_CHAT_INFORMATION',
  SEND_IMAGE: 'SEND_IMAGE',
  SEND_VIDEO: 'SEND_VIDEO',
  SEND_DOCUMENT: 'SEND_DOCUMENT',
  //Meeting
  OPEN_MEETING_ROOM: 'OPEN_MEETING_ROOM',
  //Resources
  VIEW_RESOURCE_DETAILS: 'REDIRECT_TO_RESOURCES',
  CLOSE_RESOURCE_DETAIL: 'CLOSE_RESOURCE_DETAIL',
  //Prority
  SET_GOAL: 'SET_GOAL',
  MOVE_TO_PAST_PRIORITY: 'MOVE_TO_PAST_PRIORITY',
  MOVE_TO_CURRENT_PRIORITY: 'MOVE_TO_CURRENT_PRIORITY',
  SAVE_PRIORITY: 'SAVE_PRIORITY',
  SELECT_PRIORITY: 'SELECT_PRIORITY',
  ADD_CUSTOM_GOAL: 'ADD_CUSTOM_GOAL',
  START_PRIORITY: 'START_PRIORITY',
  UPDATE_PRIORITY: 'UPDATE_PRIORITY',
  REMOVE_PRIORITY: 'REMOVE_PRIORITY',
  CLOSE_PRIORITY_DRAWER: 'CLOSE_PRIORITY_DRAWER',

  //Goal
  ADD_GOAL: 'ADD_GOAL',
  GOAL_ACTIVITY_UPDATION: 'GOAL_ACTIVITY_UPDATION',
  ADD_COMMENT_UNDER_ACTIVITY: 'ADD_COMMENT_UNDER_ACTIVITY',
  NAVIGATE_TO_ACTIVITY_DETAIL: ' NAVIGATE_TO_ACTIVITY_DETAIL',

  //Guide
  OPEN_GUIDE_DETAIL: 'OPEN_GUIDE_DETAIL',
  PIN_GUIDE: 'PIN_GUIDE',
  UNPIN_GUIDE: 'UNPIN_GUIDE',
  FAVORITE_GUIDE: 'FAVORITE_GUIDE',
  REMOVED_FROM_FAVORITE: 'REMOVED_FROM_FAVORITE',
  BACK_TO_GUIDE_LIST: 'BACK_TO_GUIDE_LIST',
  ADD_TO_MY_GUIDES: 'ADD_TO_MY_GUIDES',
  REMOVE_GUIDE_FROM_MY_GUIDES: 'REMOVE_GUIDE_FROM_MY_GUIDES',
  CANCEL_REMOVE_GUIDE_FROM_MY_GUIDES: 'CANCEL_REMOVE_GUIDE_FROM_MY_GUIDES',

  //ThirdParty Apps
  OPEN_APP_DETAIL: 'OPEN_APP_DETAIL',

  //MentorProfile
  CLOSE_MENTOR_PROFILE: 'CLOSE_MENTOR_PROFILE',

  //AppTour
  NEXT_APP_TOUR: 'NEXT_APP_TOUR',
  PREVIOUS_APP_TOUR: 'PREVIOUS_APP_TOUR',
  CLOSE_APP_TOUR_MODAL: 'CLOSE_APP_TOUR_MODAL',

  //Profile
  SAVE_PROFILE_CHANGES: 'SAVE_PROFILE_CHANGES',

  //Apps
  VIEW_MY_APPS: 'VIEW_MY_APPS',
  VIEW_RECOMMENDED_APPS: 'VIEW_RECOMMENDED_APPS',
  VIEW_ALL_APPS: 'VIEW_ALL_APPS',
  MOVE_TO_PLAYSTORE: 'MOVE_TO_PLAYSTORE',
  MOVE_TO_APP_STORE: 'MOVE_TO_APP_STORE',

  //Settings
  ENABLE_EMAIL_NOTIFICATION: 'ENABLE_EMAIL_NOTIFICATION',
  DISABLE_EMAIL_NOTIFICATION: 'DISABLE_EMAIL_NOTIFICATION',
  ENABLE_SMS_NOTIFICATION: 'ENABLE_SMS_NOTIFICATION',
  DISABLE_SMS_NOTIFICATION: 'DIASBLE_SMS_NOTIFICATION',
  VIEW_SETTINGS: 'VIEW_SETTINGS',
  UPDATE_MOBILE_NUMBER: 'UPDATE_MOBILE_NUMBER',
  UPDATE_EMAIL: 'UPDATE_EMAIL',
  CLOSE_UPDATE_MOBILE_NUMBER: 'CLOSE_UPDATE_MOBILE_NUMBER',
  CLOSE_UPDATE_EMAIL: 'CLOSE_UPDATE_EMAIL',

  //Ftue
  START_FTUE: 'START_FTUE',
  FTUE_PROFILE_SETUP: 'FTUE_PROFILE_SETUP',
  FTUE_PRONOUNS: 'FTUE_PRONOUNS',
  FTUE_DATEOF_BIRTH: 'FTUE_DATEOF_BIRTH',
  FTUE_ZIP_CODE: 'FTUE_ZIP_CODE',
  FTUE_RACIAL_AND_ETHNIC_IDENTITY: 'FTUE_RACIAL_AND_ETHNIC_IDENTITY',
  FTUE_SCHOOL_ENROLL: 'FTUE_SCHOOL_ENROLL',
  SKIP_FTUE: 'SKIP_FTUE',
  PREVIOUS_FTUE: 'PREVIOUS_FTUE',
  FTUE_MENTEE_ADVICE: 'FTUE_MENTEE_ADVICE',

  //Asssessmnet
  TAKE_ASSESSMENT: 'TAKE_ASSESSMENT',
  VIEW_ASK_ASSESSMENT: 'VIEW_ASK_ASSESSMENT',
  ANSWER_ASSESSMENT: 'ANSWER_ASSESSMENT',
  SKIP_ASSESSMNET: 'SKIP_ASSESSMNET',

  //login
  VIEW_SELECT_LOGIN: 'VIEW_SELECT_LOGIN:',
  LOGIN_WITH_CODE: 'LOGIN_WITH_CODE',
  LOGIN_PHONE_EMAIL_USERNAME: 'LOGIN_PHONE_EMAIL_USERNAM',
  ONE_TIME_CODE_SET: 'ONE_TIME_CODE_SET',
  BACK_TO_SELECT_LOGIN: 'BACK_TO_SELECT_LOGIN',
  GET_CODE_VIA_TEXT: 'GET_CODE_VIA_TEXT',
  LOGIN_WITH_EMAIL: 'LOGIN_WITH_EMAIL',
  MOVE_TO_RESET_PASSWORD: 'MOVE_TO_RESET_PASSWORD',
  BACK_TO_LOGIN: 'BACK_TO_LOGIN',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  OTP_VERIFY: 'VERIFY_OTP',
  RESEND_OTP: 'RESEND_OTP',
  CREATE_ACCOUNT: 'CREATE_ACCOUNT',

  //Streak
  CLOSE_CHECKIN_STREAK: 'CLOSE_CHECKIN_STREAK',
  CLOSE_MESSAGE_STREAK: 'CLOSE_MESSAGE_STREAK',
  CLOSE_LOGIN_STREAK: 'CLOSE_LOGIN_STREAK',

  //logout
  CANCEL_LOGOUT: 'CANCEL_LOGOUT',
  MENTEE_LOGOUT: 'MENTEE_LOGOUT',

  //Notification
  CREATED_MEETING: 'CREATED_MEETING',
  JOIN_MEETING: 'JOIN_MEETING',
  MENTEE_APP: 'MENTEE_APP',
  ADDED_COMMENT: 'ADDED_COMMENT',
  ASSIGNED_GUIDE: 'ASSIGNED_GUIDE',
  ASSIGNED_TASK: 'ASSIGNED_TASK',
  COMPLETED_TASK: 'COMPLETED_TASK',
  ADDED_REFERRAL: 'ADDED_REFERRAL',
  REFERRAL_DUE: 'REFERRAL_DUE',
  ADDED_REFERRAL_COMMENT: 'ADDED_REFERRAL_COMMENT',
  MENTEE_MOODRING_NOT_DONE: 'MENTEE_MOODRING_NOT_DONE',
  TASK_DUE_DATE: 'TASK_DUE_DATE',
};
