import * as types from '../actionTypes';
const initialState = {
  data: {},
  loadingStates: {
    moodRingDash: true,
  },
  moodRingDataStatus: {},
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case types.MOOD_RING_DASH_SUCCESS:
      return {
        ...state,
        data: action.data,
        loadingStates: { ...state.loadingStates, ...{ moodRingDash: false } },
      };
    case types.MOOD_RING_DATA_STATUS_SUCCESS:
      return {
        ...state,
        moodRingDataStatus: action.data,
      };
    default:
      return state;
  }
};

export default dashboard;
