import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LinearProgress, Link } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useState } from 'react';
import { selectedGoalActivityVar, selectedGoalVar } from '../vars/lessonGoalMenteeVar';
import GoalActivity from './goalActivity';

const GoalsBadges = (props) => {
  useEffect(() => {
    if (props.activities && props.activities.length > 0 && props.badgeIndex === 0) {
      selectedGoalActivityVar({
        ...props.activities[0],
        key: props.activities[0]._id,
        badgeId: props._id,
        activityIndex: 0,
        badgeIndex: props.badgeIndex,
        badgeTitle: props.title,
        showTasks: props.showTasks,
        refetch: props.refetch,
        menteeId: props.menteeId,
      });
    }
    return () => {
      selectedGoalActivityVar(null);
      selectedGoalVar(null);
    };
  }, []);

  const [showAdd, setShowAdd] = useState(false);
  let arr = props.showTasks;
  const handleClick = () => {
    const index = arr.indexOf(props.badgeIndex);
    if (index >= 0) {
      arr.splice(index, 1);
    } else {
      arr.push(props.badgeIndex);
    }
    props.setShowtasks([...arr]);
  };
  const getCompleted = () => {
    if (props.activities && props.activities.length > 0) {
      let completed = 0;
      props.activities.forEach((task) => {
        if (task.completed) completed += 1;
      });
      return (completed / props.activities.length) * 100;
    }
    return 0;
  };

  const getActivity = () => {
    let list = [];
    if (props.activities.length > 0) {
      props.activities.map((activity, activityIndex) => {
        if (activityIndex < props.activities.length - 1) {
          list.push(
            <GoalActivity
              {...activity}
              key={activity._id}
              badgeId={props._id}
              activityIndex={activityIndex}
              badgeIndex={props.badgeIndex}
              badgeTitle={props.title}
              showTasks={props.showTasks}
              refetch={props.refetch}
              menteeId={props.menteeId}
            />,
          );
        } else {
          list.push(
            <React.Fragment>
              <GoalActivity
                {...activity}
                key={activity._id}
                badgeId={props._id}
                activityIndex={activityIndex}
                badgeIndex={props.badgeIndex}
                badgeTitle={props.title}
                showTasks={props.showTasks}
                refetch={props.refetch}
                menteeId={props.menteeId}
              />
              <GoalActivity
                badgeId={props._id}
                activityIndex={activityIndex}
                badgeIndex={props.badgeIndex}
                badgeTitle={props.title}
                refetch={props.refetch}
                menteeId={props.menteeId}
                completed={false}
              />
            </React.Fragment>,
          );
        }
      });
    } else {
      list.push(
        <GoalActivity
          badgeId={props._id}
          activityIndex={props.activities.length}
          badgeIndex={props.badgeIndex}
          badgeTitle={props.title}
          refetch={props.refetch}
          menteeId={props.menteeId}
          completed={false}
        />,
      );
    }
    return list;
  };

  return (
    <div className="lesson-Qustions">
      <div className="Qustions-head">
        <h3>
          <span>
            {/* {props.badgeImage && props.badgeImage.length > 0 ? (
                    <img src={props.badgeImage} className="img-fuild" />
                    ) : (
                    <img
                        src="../../../checkmate/images/badge-new.svg"
                        className="img-fuild"
                    />
                    )} */}
            <span>
              <b>
                <Link
                  component="button"
                  color="inherit"
                  underline="none"
                  variant="inherit"
                  onClick={() => {
                    selectedGoalActivityVar(null);
                    selectedGoalVar({ ...props });
                  }}>
                  {props.title}
                </Link>
              </b>
              {props.description}
              <LinearProgress variant="determinate" value={getCompleted()} />
            </span>
          </span>
          <span className="button-area">
            {!props.showTasks.includes(props.badgeIndex) ? (
              <IconButton onClick={() => props.setShowtasks([props.badgeIndex])}>
                <ExpandMoreIcon />
              </IconButton>
            ) : (
              <IconButton onClick={() => props.setShowtasks([])}>
                <ExpandLessIcon />
              </IconButton>
            )}
            <IconButton>{/* <FavoriteIcon /> */}</IconButton>
          </span>
        </h3>
      </div>

      {props.badgeIndex !== undefined &&
        props.showTasks.includes(props.badgeIndex) &&
        getActivity()}
      {/* {props.badgeIndex !== undefined &&
        props.showTasks.includes(props.badgeIndex) &&
        (!showAdd ? (
          <div className="new-fileds">
            <div className="row">
              <div className="col-sm-4"></div>
              <div className="col-sm-8">
                <Button
                  onClick={() => {
                    setShowAdd(true);
                  }}>
                  Add Goal
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="new-fileds">
            <div className="row">
              <div className="col-sm-4"></div>
              <GoalAddEdit
                menteeId={props.menteeId}
                refetch={props.refetch}
                setShowAdd={setShowAdd}
                showCancel={true}
              />
            </div>
          </div>
        ))} */}
    </div>
  );
};

export default GoalsBadges;
