import { useReactiveVar } from '@apollo/client';
import firebase from 'firebase';
import 'firebase/database';
import 'firebase/firestore';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getLoadingState, getMenteeDetails, getUserDetails } from '../../Store/reduxSelectors';
import { getChatId } from './services/constants';
import {
  chatListLoadingVar,
  chatListVar,
  groupListLoadingVar,
  groupListVar,
} from './services/vars';

let unsubscribeChat;
let unsubscribeGroups;

const GetChats = () => {
  const currentUser = useSelector((state) => getUserDetails(state));
  const menteeDetails = useSelector((state) => getMenteeDetails(state));

  const loading = useSelector((state) => getLoadingState(state, 'authentication'));
  const firebaseAuthCompleted = !loading.firebaseConfig && !loading.firebaseUser;
  const chatList = useReactiveVar(chatListVar);

  const groupList = useReactiveVar(groupListVar);

  useEffect(() => {
    const chatObj = {};
    const keysArray = ['programManager', 'matchSupportSpecialist'];

    if (menteeDetails && Object.keys(menteeDetails).length > 0) {
      if (menteeDetails?.programManager?._id === menteeDetails?.matchSupportSpecialist?._id)
        delete menteeDetails.matchSupportSpecialist;
      const userDetail = menteeDetails.primaryMentor;

      const chatId = getChatId(currentUser.firestoreId, userDetail.firestoreId);
      let availableChatIds = [chatId];
      chatObj[chatId] = {
        id: userDetail._id,
        firstName: userDetail.firstName,
        lastName: userDetail.lastName,
        profileImage: userDetail.profileImage,
        firestoreId: userDetail.firestoreId,
        chatId,
        lastMessageCreatedDate: '',
        role: 'mentor',
        unreadMessageCount: 0,
        lastMessageType: 'text',
        lastMessageText: '',
        type: 'chat',
      };
      Object.keys(menteeDetails).forEach((key) => {
        if (key === 'secondaryMentors') {
          if (menteeDetails[key].length <= 0) return;
          const secondaryMentors = menteeDetails[key];
          secondaryMentors.forEach((secondaryMentor) => {
            const chatId = getChatId(currentUser.firestoreId, secondaryMentor.firestoreId);
            availableChatIds.push(chatId);
            // chatObj[chatId] = {
            //   chatId,
            //   id: secondaryMentor._id,
            //   firstName: secondaryMentor.firstName,
            //   lastName: secondaryMentor.lastName,
            //   profileImage: secondaryMentor.profileImage,
            //   firestoreId: secondaryMentor.firestoreId,
            //   role: 'secondaryMentor',
            //   lastMessageCreatedDate: '',
            //   unreadMessageCount: 0,
            //   lastMessageType: 'text',
            //   lastMessageText: '',
            //   type: 'chat',
            // };
          });
        } else if (keysArray.includes(key)) {
          const userDetail = menteeDetails[key];

          const chatId = getChatId(currentUser.firestoreId, userDetail.firestoreId);
          availableChatIds.push(chatId);
          chatObj[chatId] = {
            id: userDetail._id,
            firstName: userDetail.firstName,
            lastName: userDetail.lastName,
            profileImage: userDetail.profileImage,
            firestoreId: userDetail.firestoreId,
            chatId,
            lastMessageCreatedDate: '',
            role: key,
            unreadMessageCount: 0,
            lastMessageType: 'text',
            lastMessageText: '',
            type: 'chat',
          };
        }
      });
      chatListVar({ ...chatList, ...chatObj });
      firebaseAuthCompleted && subscribeChat(chatObj, availableChatIds);
      firebaseAuthCompleted && subscribeGroups();
    }
    return () => {
      if (unsubscribeChat) unsubscribeChat();
      if (unsubscribeGroups) unsubscribeGroups();
    };
  }, [menteeDetails, firebaseAuthCompleted]);

  const subscribeChat = (chat, availableChatIds) => {
    try {
      const chatObj = { ...chatList, ...chat };

      const collectionRef = firebase
        .firestore()
        .collection('users')
        .doc(currentUser.firestoreId)
        .collection('userChats');

      unsubscribeChat = collectionRef
        .orderBy('lastMessageCreatedDate', 'desc')
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type === 'added' || change.type === 'modified') {
              const data = {
                ...change.doc.data(),
                lastMessageCreatedDate: change.doc.data().lastMessageCreatedDate.toDate(),
              };

              // if (availableChatIds.includes(data.chatId)) {
              chatObj[data.chatId] = {
                ...chatObj[data.chatId],
                id: data.chatWith.mongoUserId,
                lastMessageCreatedDate: data.lastMessageCreatedDate,
                firstName: data.chatWith.firstName,
                lastName: data.chatWith.lastName,
                profileImage: data.chatWith.profileImage,
                firestoreId: data.chatWith.firestoreId,
                chatId: data.chatId,
                lastMessageType: data.lastMessageType,
                lastMessageText: data.lastMessageText,
                unreadMessageCount: data.totalUnreadMessages,
                role: data.chatWith.role ? data.chatWith.role[0] : chatObj[data.chatId].role,
                type: 'chat',
              };
              // }
            }
          });
          chatListVar({ ...chatList, ...chatObj });
        });
      chatListLoadingVar(false);
      // subscribeGroups({ chatList, ...chatObj });
      subscribeGroups();
    } catch (err) {
      console.error(err);
      chatListLoadingVar(false);
    }
  };

  const subscribeGroups = () => {
    try {
      const groupCollectionRef = firebase.firestore().collection('groups');
      const groupObj = groupList;

      unsubscribeGroups = groupCollectionRef
        .where('members', 'array-contains', currentUser.firestoreId)
        .where('status', '==', true)
        .where('deleted', '==', false)

        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            // if (change.type === 'added' || change.type === 'modified') {
            const data = {
              ...change.doc.data(),
              type: 'group',
              status: change.doc.data().status ?? change.doc.data().status,
              lastMessageType: change.doc.data().lastMessage?.messageType || '',
              lastMessageText: change.doc.data().lastMessage?.messageText || '',
              lastMessageCreatedDate: change.doc.data().lastMessage?.createdDate?.toDate() || '',
              memberCount: change.doc.data().members?.length,
              unreadMessageCount:
                change.doc.data().unreadCounts &&
                Object.keys(change.doc.data().unreadCounts).length &&
                change.doc.data().unreadCounts[currentUser.firestoreId]
                  ? change.doc.data().unreadCounts[currentUser.firestoreId]
                  : 0,
            };
            if (data.status && data.groupId) {
              groupObj[data.groupId] = {
                ...groupObj[data.groupId],
                ...data,
              };
            }

            if (change.type === 'modified') {
              if (!data.status) {
                delete groupObj[data.groupId];
              }
            }
          });
          groupListVar({ ...groupObj });
        });
      groupListLoadingVar(false);
    } catch (err) {
      console.error(err);
      groupListLoadingVar(false);
    }
  };

  return null;
};

export default GetChats;
