import { takeLatest, call, put, fork } from 'redux-saga/effects';
import * as api from '../Api/MoodRingDashboard';
import * as types from '../Store/actionTypes';

import logError from '../Helper';

const sagaPath = 'src/Sagas/MoodRingDashboard.js';

function* getMoodRingDash(action) {
  const { userId, filter, callback } = action;
  try {
    const response = yield call(api.getMoodRingDash, { userId, filter });
    const data = response.data;
    yield put({ type: types.MOOD_RING_DASH_SUCCESS, data });
    callback(true);
  } catch (e) {
    logError(e, sagaPath + 'getMoodRingDash');
    callback();
  }
}

function* getMoodRingDataStatus(action) {
  const { userId, callback } = action;
  try {
    const response = yield call(api.getMoodRingDataStatus, userId);
    const data = response.data;
    yield put({ type: types.MOOD_RING_DATA_STATUS_SUCCESS, data });
    callback(true);
  } catch (e) {
    logError(e, sagaPath + 'getMoodRingDataStatus');
    callback();
  }
}
function* watchMoodRingDashSagas() {
  yield takeLatest(types.MOOD_RING_DASH_REQUEST, getMoodRingDash);
  yield takeLatest(types.MOOD_RING_DATA_STATUS_REQUEST, getMoodRingDataStatus);
}

const moodRingDashSagas = [fork(watchMoodRingDashSagas)];

export default moodRingDashSagas;
