import { useReactiveVar } from '@apollo/client';
import { Button, Modal } from '@mui/material';
import 'cropperjs/dist/cropper.css';
import React, { useRef } from 'react';
import Cropper from 'react-cropper';
import { cropperModalVar } from './reactiveVar';

const ReactCropper = () => {
  const cropperModal = useReactiveVar(cropperModalVar);
  const cropperRef = useRef(null);
  // const [croppedImg, setCroppedImg] = useState('');
  // const onCrop = () => {
  //   const imageElement = cropperRef?.current;
  //   const cropper = imageElement?.cropper;
  //   setCroppedImg(cropper.getCroppedCanvas().toDataURL());
  // };

  const handleClose = () => {
    cropperModalVar({ ...cropperModal, showModal: false });
  };

  const handleSave = () => {
    let url = '';
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      url = cropperRef.current?.cropper.getCroppedCanvas().toDataURL();
    }
    cropperModalVar({
      ...cropperModal,
      showModal: false,
      croppedImage: url,
      croppedFile: dataURItoBlob(url),
    });
  };

  const dataURItoBlob = (dataURI) => {
    var byteString = window.atob(dataURI.split(',')[1]);

    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  return (
    <Modal open={cropperModal.showModal} onModalClose={handleClose} className="crop-modal-outer">
      <div className="crop-modal">
        <React.Fragment className="common-modal">
          <Cropper
            src={cropperModal.imgURL}
            style={{ height: 500, width: '732px' }}
            initialAspectRatio={16 / 9}
            guides={false}
            // crop={onCrop}
            ref={cropperRef}
            viewMode={1}
            // guides={true}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            // background={false}
            responsive={true}
            autoCropArea={1}
            aspectRatio={4 / 3}
            checkOrientation={false}
          />
          <div className="cropImg-btn">
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSave}>Save</Button>
          </div>
        </React.Fragment>
      </div>
    </Modal>
  );
};
export default ReactCropper;
