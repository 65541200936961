import moment from 'moment';

const logError = (error, path) => {
  // alert('Check Console for error');
};

export default logError;

export const getSenderId = () => {
  const senderId = process.env.REACT_APP_BASE_URL;

  return senderId;
};

export const generateSnackbarPayload = (variant, message) => ({
  open: true,
  variant,
  message,
});

export const generateWeekData = (data, hasDate) => {
  let weekDict = {};
  let convertedWeekData = {};

  let currentWeekNumber = moment().week();

  weekDict = {
    [currentWeekNumber - 3]: 1,
    [currentWeekNumber - 2]: 2,
    [currentWeekNumber - 1]: 3,
    [currentWeekNumber]: 4,
  };

  Object.keys(weekDict).forEach((week) => {
    const startFrom = moment().startOf('week').week(week).format('MM/DD');
    const endTo = moment().endOf('week').week(week).format('MM/DD');

    const weekRange = `${startFrom}-${endTo}`;
    data.forEach((item) => {
      let currentPoint = item.totalPoints || item.percentage;
      let totalPoints = currentPoint > 10 ? Math.floor(currentPoint) / 10 : currentPoint;
      if (Number(item.week) === Number(week)) convertedWeekData[weekRange] = totalPoints;
      else convertedWeekData[weekRange] = convertedWeekData[weekRange] || 0;
    });
  });

  // data.forEach((item) => {
  //   const startFrom = moment().startOf('week').week(item.week).format('MM/DD');
  //   const endTo = moment(startFrom).add('days', 6).format('MM/DD');

  //   const weekRange = `${startFrom}-${endTo}`;
  //   Object.keys(weekDict).forEach((week) => {
  //     let currentPoint = item.totalPoints || item.percentage;
  //     let totalPoints = currentPoint > 10 ? Math.floor(currentPoint) / 10 : currentPoint;
  //     if (Number(item.week) === Number(week)) convertedWeekData[weekRange] = totalPoints;
  //     else convertedWeekData[weekRange] = convertedWeekData[weekRange] || 0;
  //   });
  // });
  return convertedWeekData;
};

export const truncateString = (str, num, disabled) => {
  if (disabled || (str && str.length <= num)) return str;
  return str && str.slice(0, num) + '...';
};
