import { Avatar, Box, IconButton } from '@mui/material';
import Draggable from 'react-draggable';
import Audio from '../../../Assets/Images/AudioSkeleton.svg';
import EndCall from '../../../Assets/Images/EndCallSkeleton.svg';
import ExpandCard from '../../../Assets/Images/ExpandCard.svg';
import HideAudio from '../../../Assets/Images/HideAudioSkeleton.svg';
import HideVideo from '../../../Assets/Images/HideVideoSkeleton.svg';
import Video from '../../../Assets/Images/VideoSkeleton.svg';
import { meetingVar } from '../services/vars';

const DraggableCard = ({
  room,
  setRoom,
  enableAudio,
  changeAudioStatus,
  showVideo,
  changeShowVideoStatus,
  setShowFullScreen,
  setShowCard,
  participantDetails,
}) => {
  const toggleVoice = () => {
    const audioTracks = room && room.localParticipant && room.localParticipant.audioTracks;
    if (audioTracks) {
      if (enableAudio) {
        audioTracks.forEach((audioTrack) => {
          audioTrack.track.disable();
        });
      } else {
        audioTracks.forEach((audioTrack) => {
          audioTrack.track.enable();
        });
      }
      changeAudioStatus(!enableAudio);
    }
  };

  const toggleVideo = () => {
    const videoTracks = room && room.localParticipant && room.localParticipant.videoTracks;

    if (videoTracks) {
      if (showVideo) {
        videoTracks.forEach((videoTrack) => {
          if (videoTrack.trackName === 'camera') videoTrack.track.disable();
        });
      } else {
        videoTracks.forEach((videoTrack) => {
          videoTrack.track.enable();
        });
      }
      changeShowVideoStatus(!showVideo);
    }
  };

  const cancelMeeting = () => {
    setRoom((currentRoom) => {
      if (currentRoom && currentRoom.localParticipant.state === 'connected') {
        currentRoom.localParticipant.tracks.forEach(function (trackPublication) {
          trackPublication.track.stop();
        });
        currentRoom.disconnect();
        return null;
      } else {
        return currentRoom;
      }
    });
    setTimeout(() => {
      setShowFullScreen(false);
      setShowCard(false);
      meetingVar(null);
    }, 200);
  };

  const handleExpand = () => {
    setShowCard(false);
    setShowFullScreen(true);
  };
  return (
    <Draggable bounds="body">
      <div className="floating-card">
        <div className="video-container">
          <Box
            sx={{
              borderRadius: '50%',
              border: '2px solid white',
            }}>
            <Avatar
              sx={{
                width: '64px',
                height: '64px',
                position: 'relative',
              }}
              alt="User avatar"
              src={participantDetails?.profileImage}>
              {participantDetails?.firstName?.charAt(0).toUpperCase()}
            </Avatar>
            <Box
              className="blur-image"
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backdropFilter: 'blur(4px)',
                WebkitBackdropFilter: 'blur(4px)',
                display: 'none',
              }}
            />
          </Box>
          <IconButton
            className="expand-icon"
            sx={{
              position: 'absolute',
              display: 'none',
            }}
            onClick={handleExpand}>
            <img style={{ width: 32 }} src={ExpandCard} alt="expand" />
          </IconButton>
        </div>
        <div className="controls">
          <IconButton onClick={toggleVoice}>
            <img style={{ width: 16 }} src={enableAudio ? Audio : HideAudio} alt="toggle voice" />
          </IconButton>
          <IconButton onClick={cancelMeeting}>
            <img style={{ width: 28 }} src={EndCall} alt="end call" />
          </IconButton>
          <IconButton onClick={toggleVideo}>
            <img style={{ width: 16 }} src={showVideo ? Video : HideVideo} alt="toggle video" />
          </IconButton>
        </div>
      </div>
    </Draggable>
  );
};

export default DraggableCard;
