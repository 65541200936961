import * as types from '../actionTypes';

const initialState = {
  unreadNotificationPayload: {
    loading: true,
    data: null,
    error: false,
  },
  userNotifications: {
    loading: true,
    data: [],
    error: false,
  },
};

const notificatons = (state = initialState, action) => {
  switch (action.type) {
    case types.UNREAD_NOTIFICATION_COUNT_SUCCESS:
      return {
        ...state,
        unreadNotificationPayload: {...state.unreadNotificationPayload, ...action.payload},
      };
    case types.USER_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        userNotifications: {...state.userNotifications, ...action.payload},
      };
    default:
      return state;
  }
};

export default notificatons;
