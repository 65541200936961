import { ApolloClient, ApolloLink, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import errorLink from './errorHandling';

// const errorLink = onError((error) => {
//   if (
//     error.networkError &&
//     error.networkError.result &&
//     error.networkError.result.errors &&
//     error.networkError.result.errors[0].extensions.code == 'UNAUTHENTICATED'
//   ) {
//     const refreshToken = localStorage.getItem('@refreshToken');
//     if (refreshToken) {
//       store.dispatch({
//         type: types.GET_NEW_TOKEN,
//       });
//     } else {
//       try {
//         store.dispatch({
//           type: 'TOGGLE_SNACKBAR',
//           payload: generateSnackbarPayload(
//             'warning',
//             'Session has been expired. Please login back to continue',
//           ),
//         });
//         delete axios.defaults.headers.common['Authorization'];
//         localStorage.clear();
//         setTimeout(() => {
//           window.location.href = '/login';
//         }, 100);
//       } catch (err) {
//         store.dispatch({
//           type: 'TOGGLE_SNACKBAR',
//           payload: generateSnackbarPayload('error', 'Something went wrong!'),
//         });
//       }
//     }
//   }
// });
const video = createHttpLink({
  uri: `${process.env.REACT_APP_BASE_URL}/graphql`,
});

const curriculum = createHttpLink({
  uri: `${process.env.REACT_APP_BASE_URL}/curriculum-server`,
});

const referral = createHttpLink({
  uri: `${process.env.REACT_APP_BASE_URL}/knwBaseSrvr`,
});
const userService = createHttpLink({
  uri: `${process.env.REACT_APP_BASE_URL}/user-service`,
});
const assessment = createHttpLink({
  uri: `${process.env.REACT_APP_BASE_URL}/assessment-server`,
});
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('@authToken');
  return {
    headers: {
      ...headers,
      'x-token': token ? `${token}` : '',
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

// GraphQL Init
const client = new ApolloClient({
  link: from([
    errorLink,
    authLink.concat(
      ApolloLink.split(
        (operation) => operation.getContext().clientName === 'referral',
        referral, // <= apollo will send to this if clientName is "third-party"
        ApolloLink.split(
          (operation) => operation.getContext().clientName === 'curriculum',
          curriculum,
          ApolloLink.split(
            (operation) => operation.getContext().clientName === 'userService',
            userService,
            ApolloLink.split(
              (operation) => operation.getContext().clientName === 'assessment',
              assessment,
              video,
            ),
          ),
        ), // <= otherwise will send to this
      ),
    ),
  ]),
  cache: new InMemoryCache(),
});

export default client;
