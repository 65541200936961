import { useReactiveVar } from '@apollo/client';
import DescriptionIcon from '@mui/icons-material/Description';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { Button, CircularProgress, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { UpdateBadgeHook } from '../services/updateAssignedGoal';
import { selectedGoalVar } from '../vars/lessonGoalMenteeVar';

const GoalDetail = (props) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const selGoal = useReactiveVar(selectedGoalVar);
  const [selectedGoal, setSelectedGoal] = useState({
    title: props.title,
    goalDescription: props.description,
  });
  const [isEdit, setIsEdit] = useState(false);
  const { updateGoalBackend } = UpdateBadgeHook();

  const handleSave = async () => {
    setButtonDisable(true);
    await updateGoalBackend(props, selGoal);
    setButtonDisable(false);
    setIsEdit(false);
  };

  const changeGoalDetails = (type, event) => {
    if (type === 'title') {
      setSelectedGoal({ ...selectedGoal, ...{ title: event.target.value } });
      selectedGoalVar({ ...selGoal, ...{ title: event.target.value } });
    } else if (type === 'description') {
      setSelectedGoal({
        ...selectedGoal,
        ...{ goalDescription: event.target.value },
      });
      selectedGoalVar({ ...selGoal, ...{ description: event.target.value } });
    }
  };

  return (
    <div className="milestone-detail-edit ">
      <div className="each-row">
        <div className="task-head">
          <div className="task-head-common">
            <Typography variant="subtitle1" component="h1">
              {selGoal.title}
              <Button
                onClick={() => {
                  setIsEdit(!isEdit);
                }}>
                Edit
              </Button>
            </Typography>
          </div>
        </div>
        {isEdit && (
          <div className="row">
            <div className="container">
              <div className="editgoal">
                <Typography variant="subtitle2" component="h2">
                  {'Edit Goal'}
                </Typography>
              </div>
              <div className="detail-section">
                <div className="col-sm-12">
                  <TextField
                    className="newgoal"
                    id="milestone"
                    label="Goal Name"
                    variant="outlined"
                    value={selGoal ? selGoal.title : null}
                    onChange={(e) => changeGoalDetails('title', e)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LibraryBooksIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="col-sm-12">
                  <TextField
                    id="description"
                    label="Description"
                    variant="outlined"
                    className="newgoal"
                    value={selGoal ? selGoal.description : null}
                    onChange={(e) => changeGoalDetails('description', e)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <DescriptionIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="save-btn-wrap">
                <Button
                  className="save-btn"
                  onClick={() => {
                    handleSave();
                  }}
                  disabled={buttonDisable || !selGoal.title || !selGoal.description}>
                  {buttonDisable ? <CircularProgress size={25} color="primary" /> : 'Save'}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GoalDetail;
