import { DateTime } from 'luxon';
import moment from 'moment';

export const selectRole = (role) => {
  if (!role) {
    return '';
  }
  if (role.includes('matchSupportSpecialist')) {
    return 'MSS';
  } else if (role.includes('programManager')) {
    return 'Program Manager';
  } else if (role.includes('mentor')) {
    return 'Mentor';
  } else if (role.includes('mentee')) {
    return 'Mentee';
  } else if (role.includes('primaryMentor')) {
    return 'Mentor';
  } else if (role.includes('secondaryMentor')) {
    return 'Secondary Mentor';
  }
};

export const getUserRole = {
  mentor: { role: 'M', backgroundColor: '#F9AC31' },
  primaryMentor: { role: 'M', backgroundColor: '#F9AC31' },
  matchSupportSpecialist: { role: 'MSS', backgroundColor: '#F9AC31' },
  programManager: { role: 'PM', backgroundColor: '#F9AC31' },
  secondaryMentor: { role: 'M', backgroundColor: '#F9AC31' },
};

export const selectBannedRole = (role) => {
  if (!role) {
    return '';
  }
  if (role.includes('matchSupportSpecialist')) {
    return 'Match Support Specialist';
  } else if (role.includes('programManager')) {
    return 'Program Manager';
  } else if (role.includes('mentor')) {
    return 'Mentor';
  } else if (role.includes('mentee')) {
    return 'Mentee';
  } else if (role.includes('primaryMentor')) {
    return 'Mentor';
  } else if (role.includes('secondaryMentor')) {
    return 'Mentor';
  }
};

export const getHourAndMinute = (date) => {
  // ***IMPORTANT***
  // This Due to moment()js will depricated soon for better performance.
  let months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let month = months[date.getMonth()];
  let year = date.getFullYear();
  let day = date.getDate();
  day = day < 10 ? '0' + day : day;
  let ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  let time = hours + ':' + minutes + ' ' + ampm;
  let strTime = `${month} ${day}, ${year}, ${time}`;
  return strTime;
};

export const getChatId = (user1firebaseId, user2firebaseId) => {
  if (user1firebaseId < user2firebaseId) return `${user1firebaseId}--${user2firebaseId}`;
  else return `${user2firebaseId}--${user1firebaseId}`;
};

export const getHourAndMint = (user) => {
  let date = user.lastMessageCreatedDate;
  if (!date || user.fromSelected) return '';
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  let time = hours + ':' + minutes + ' ' + ampm;
  let strTime = `${time}`;
  return strTime;
};

export const dispalyTime = (date) => {
  if (!date) return '';
  return moment(new Date(date)).calendar(null, {
    sameDay: 'hh:mm A',
    lastDay: '[Yesterday]',
    lastWeek: 'MM/DD/YYYY',
    sameElse: 'MM/DD/YYYY',
  });
};

export const getInterval = (conversation) => {
  const now = DateTime.fromJSDate(new Date());
  const messageDate = DateTime.fromJSDate(conversation.lastMessageCreatedDate);
  const diff = now
    .diff(messageDate, ['seconds', 'minutes', 'hours', 'days', 'weeks', 'months', 'years'])
    .toObject();
  if (diff.years) return `${Math.trunc(Math.abs(diff.years))} y`;
  else if (diff.months) return `${Math.trunc(Math.abs(diff.months))} M`;
  else if (diff.weeks) return `${Math.trunc(Math.abs(diff.weeks))} w`;
  else if (diff.days) return `${Math.trunc(Math.abs(diff.days))} d`;
  else if (diff.hours) return `${Math.trunc(Math.abs(diff.hours))} h`;
  else if (diff.minutes) return `${Math.trunc(Math.abs(diff.minutes))} m`;
  else if (diff.seconds) return `${Math.trunc(Math.abs(diff.seconds))} s`;
  return null;
};

export const getIntervalLong = (messageCreatedDate) => {
  const now = DateTime.fromJSDate(new Date());
  const messageDate = DateTime.fromJSDate(messageCreatedDate);
  const diff = now
    .diff(messageDate, ['seconds', 'minutes', 'hours', 'days', 'weeks', 'months', 'years'])
    .toObject();
  if (diff.years) return getVal(diff.years, 'years');
  else if (diff.months) return getVal(diff.months, 'months');
  else if (diff.weeks) return getVal(diff.weeks, 'weeks');
  else if (diff.days) return getVal(diff.days, 'days');
  else if (diff.hours) return getVal(diff.hours, 'hours');
  else if (diff.minutes) return getVal(diff.minutes, 'minutes');
  else if (diff.seconds) return getVal(Math.round(diff.seconds), 'seconds');
  return null;
};

const getVal = (value, type) => {
  if (value > 1) return `${value} ${type}`;
  return `${value} ${type.slice(0, -1)}`;
};
