import { Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PronounImg from '../../Assets/Images/PronounImg.svg';
import learnerAction from '../../Helper/Analytics/learnerAction';
import screenNames from '../../Helper/Analytics/screenNames';
import { trackEvent } from '../../Helper/EventTracker';
import theme from '../../MaterialUiTheme';
import { updateUserDetails } from '../../Store/Actions/Auth';
import getLoggedUserDetails from '../services/getLoggedUserDetails';
import updateProfileDetails from '../services/patchUserDetails.js';
export default function FtueThree(props) {
  const dispatch = useDispatch();

  const [pronouns, setPronouns] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [selectedPronoun, setSelectedPronoun] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);

  useEffect(() => {
    setPronouns(props.loggedData ? props.loggedData.data.pronoun : '');
    setLoading(false);
  }, [props]);

  const selectPronouns = (e) => {
    setPronouns(e);
    setSelectedPronoun(true);
  };

  const sSnackbar = () => {
    setSuccessSnackbar(true);
  };

  const eSnackbar = () => {
    setErrorSnackbar(true);
  };

  const eSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorSnackbar(false);
  };
  const sSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessSnackbar(false);
    setTimeout(() => {
      props.stepUpdate('next');
    }, 100);
  };

  const profileUpdate = () => {
    trackEvent('click', {
      screen: screenNames.FtueWelcome,
      Action: learnerAction.FTUE_PRONOUNS,
    });
    if (pronouns !== '') {
      let data = {
        pronoun: pronouns,
        ftueProfileSetup: false,
      };

      updateProfileDetails(props.userDetails.id, data)
        .then((response) => {
          getLoggedUserDetails(props.userDetails.id).then((res) => {
            props.setLoggedData(res);
            dispatch(updateUserDetails({ pronouns: res.data.pronouns }));
          });
          sSnackbar();
        })
        .catch((error) => {});
    } else {
      eSnackbar();
    }
  };

  return (
    <div>
      <div>
        <img src={PronounImg} />
      </div>
      <h3>What are your pronouns?</h3>
      <div className="the-pronouns">
        <div style={{ display: 'flex', gap: '12px' }}>
          <Button
            style={{
              color: pronouns == 'They/Them/Theirs' ? 'white' : 'black',
              fontWeight: 'bold',
              fontFamily: 'Lato',
              textTransform: 'capitalize',
            }}
            onClick={() => selectPronouns('They/Them/Theirs')}
            className={pronouns == 'They/Them/Theirs' ? 'active' : ''}
            aria-label={
              selectedPronoun == true ? 'You have selected They/Them/Theirs as your pronoun' : ''
            }>
            They/Them/Theirs
          </Button>
          <Button
            style={{
              color: pronouns == 'She/Her/Hers' ? 'white' : 'black',
              fontWeight: 'bold',
              fontFamily: 'Lato',
              textTransform: 'capitalize',
            }}
            onClick={() => selectPronouns('She/Her/Hers')}
            className={pronouns == 'She/Her/Hers' ? 'active' : ''}
            aria-label={
              selectedPronoun == true ? 'You have selected She/Her/Hers as your pronoun' : ''
            }>
            She/Her/Hers
          </Button>
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '12px' }}>
          <Button
            style={{
              color: pronouns == 'He/Him/His' ? 'white' : 'black',
              fontWeight: 'bold',
              fontFamily: 'Lato',
              textTransform: 'capitalize',
            }}
            onClick={() => selectPronouns('He/Him/His')}
            className={pronouns == 'He/Him/His' ? 'active' : ''}
            aria-label={
              selectedPronoun == true ? 'You have selected He/Him/His as your pronoun' : ''
            }>
            He/Him/His
          </Button>
          <Button
            style={{
              textTransform: 'inherit',
              color: pronouns == "My pronoun isn't listed" ? 'white' : 'black',
              fontWeight: 'bold',
              fontFamily: 'Lato',
            }}
            onClick={() => selectPronouns("My pronoun isn't listed")}
            className={pronouns == "My pronoun isn't listed" ? 'active' : ''}
            aria-label={
              selectedPronoun == true
                ? 'You have selected My pronoun isnt listed as your pronoun'
                : ''
            }>
            My pronoun isn't listed
          </Button>
        </div>
        <div style={{ marginTop: '12px' }}>
          <Button
            style={{
              textTransform: 'inherit',
              color: pronouns == 'Prefer not to say' ? 'white' : 'black',
              fontWeight: 'bold',
              fontFamily: 'Lato',
            }}
            onClick={() => selectPronouns('Prefer not to say')}
            className={pronouns == 'Prefer not to say' ? 'active' : ''}
            aria-label={
              selectedPronoun == true ? 'You have selected Prefer not to say as your pronoun' : ''
            }>
            Prefer not to say
          </Button>
        </div>
      </div>
      <Button
        style={{
          marginTop: '40px',
          width: '100%',
          height: '50px',
          backgroundColor: theme.palette.primary.main,
          fontFamily: 'Lato',
          fontWeight: 'bold',
          color: 'white',
          textTransform: 'capitalize',
        }}
        aria-label="Profile update Button"
        fullWidth
        variant="contained"
        onClick={() => profileUpdate()}
        color="primary">
        Save
      </Button>
      <Snackbar
        open={errorSnackbar}
        style={{ zIndex: '10000' }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={eSnackbarClose}>
        <Alert severity="error" variant="filled">
          Please select your pronoun
        </Alert>
      </Snackbar>
      <Snackbar
        open={successSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id="ratingSuccess"
        autoHideDuration={3000}
        onClose={sSnackbarClose}>
        <Alert tabindex="0" severity="success" variant="filled">
          Pronoun successfully updated.
        </Alert>
      </Snackbar>
    </div>
  );
}
