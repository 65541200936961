import * as types from '../actionTypes';

const initialState = {
  installedApps: [],
  recommendedApps: {},
  allApps: [],
  loadingStates: {
    isAllLoading: true,
    isRecommendeLoading: true,
    isInstalledLoading: true,
  },
};

const tools = (state = initialState, action) => {
  switch (action.type) {
    case types.INSTALLED_APPS_SUCCESS:
      return {
        ...state,
        installedApps: action.data,
        loadingStates: {...state.loadingStates, ...{isInstalledLoading: false}},
      };

    case types.RECOMMENDED_APPS_SUCCESS:
      return {
        ...state,
        recommendedApps: action.data,
        loadingStates: {...state.loadingStates, ...{isRecommendeLoading: false}},
      };
    case types.ALL_APPS_SUCCESS:
      return {
        ...state,
        allApps: action.data,
        loadingStates: {...state.loadingStates, ...{isAllLoading: false}},
      };
    default:
      return state;
  }
};

export default tools;
