import { fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GENERATE_REFRESH_TOKEN } from '../ApolloQueries/User';
import { generateSnackbarPayload } from '../Helper';
import { trackAPIError } from '../Helper/EventTracker';
import store from '../Store/Reducer';
import client from './graphQLConf';

const logoutUser = () => {
  store.dispatch({
    type: 'TOGGLE_SNACKBAR',
    payload: generateSnackbarPayload(
      'warning',
      'Session has expired. Please login back to continue',
    ),
  });
  localStorage.clear();
  setTimeout(() => {
    window.location.href = '/login';
  }, 100);
};

export const refreshAccessToken = async () => {
  try {
    const refreshToken = localStorage.getItem('@refreshToken');

    const response = await client.mutate({
      mutation: GENERATE_REFRESH_TOKEN,
      context: { clientName: 'userService' },
      variables: {
        refreshToken: refreshToken,
      },
    });
    const { errors } = response;
    console.error('errors', errors);
    const data = response.data?.generate_token.token ?? '';
    localStorage.setItem('@authToken', data);
    return data;
  } catch (err) {
    throw new Error('Refresh token failed');
  }
};

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  console.log('graphQLErrors', graphQLErrors);
  if (operation.operationName === 'Generate_token') {
    logoutUser();
    return;
  }
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, extensions }) => {
      const errorData = {
        type: 'GraphQL Error',
        message,
        locations,
        code: extensions.code,
        operationName: operation.operationName,
      };
      trackAPIError(errorData);
    });

    for (const err of graphQLErrors) {
      switch (err.extensions.code) {
        case 'UNAUTHENTICATED':
          return fromPromise(refreshAccessToken().catch(() => logoutUser()))
            .filter((value) => Boolean(value))
            .flatMap((accessToken) => {
              const oldHeaders = operation.getContext().headers;
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  authorization: `Bearer ${accessToken}`,
                  'x-token': accessToken ? `${accessToken}` : '',
                },
              });
              return forward(operation);
            });
      }
    }
  }
});

export default errorLink;
