import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import React from 'react';
import { animated } from 'react-spring';
// import AssesImage from '../../../Assets/Images/assess-image.svg';
import AssesImage from '../../../Assets/Images/questionLogo.svg';
import learnerAction from '../../../Helper/Analytics/learnerAction';
import screenNames from '../../../Helper/Analytics/screenNames';
import { trackEvent } from '../../../Helper/EventTracker';
import Answer from './Answer';
const AssesmentQuestion = (props) => {
  const inputEl = React.useRef();
  return (
    <animated.div style={props.style}>
      <Card>
        {props.questionLength > 0 && (
          <div ref={inputEl}>
            {/*  ASSESSMENT {currentQuestionPage <= assessmentQuestions.length ? currentQuestionPage : assessmentQuestions.length}/{assessmentQuestions.length}{' '}*/}
            {props.currPage + 1 <= props.questionLength && (
              <span role="alert" sx={{ position: 'absolute', top: '20px', left: '100px' }}>
                Question{' '}
                {props.currentQuestionPage <= props.questionLength
                  ? props.currPage + 1
                  : props.questionLength}{' '}
                /{props.questionLength}{' '}
              </span>
            )}
          </div>
        )}
        <CardContent>
          {/* <Typography color="textSecondary" gutterBottom> */}
          <img
            src={AssesImage}
            alt="assement"
            style={{ width: 160, height: 150, marginBottom: '10px', marginTop: '30px' }}
          />
          {/* </Typography> */}
          <Typography variant="h5" component="h2" sx={{ color: '#000000' }}>
            {props?.assessment?.question?.value}
          </Typography>
        </CardContent>
        <div className="ans-wrap">
          {props?.assessment?.expectedAnswers?.map((answer, index) => {
            return (
              <Answer
                style={props.style}
                answer={answer}
                key={index}
                onClick={() => {
                  props.manager(answer, props.qIndex, props.assessment._id);
                  trackEvent('click', {
                    screen: screenNames.AnswerAssessment,
                    Action: learnerAction.ANSWER_ASSESSMENT,
                  });
                }}
              />
            );
          })}
        </div>
      </Card>
    </animated.div>
  );
};

export default AssesmentQuestion;
