import { Card, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import GoalsActivityDetails from './goalsActivityDetails';
import GoalsBadges from './goalsBadges';

import { useReactiveVar } from '@apollo/client';
import { useSelector } from 'react-redux';
import learnerAction from '../../../Helper/Analytics/learnerAction';
import screenNames from '../../../Helper/Analytics/screenNames';
import { trackEvent } from '../../../Helper/EventTracker';
import { getSideDrawerState, getUserDetails } from '../../../Store/reduxSelectors';
import ContentLoaderCard from '../../Common/ContentLoader/ContentLoader.Card';
import { GetGoalPlanMentee } from '../services/getGoalTasks';
import { selectedGoalActivityVar, selectedGoalVar } from '../vars/lessonGoalMenteeVar';
import AddGoal from './addGoal';
import GoalDetail from './GoalsDetail';

const GoalsCurriculum = (props) => {
  const open = useSelector((state) => getSideDrawerState(state));
  const currentUser = useSelector((state) => getUserDetails(state));
  const { loading, data, refetch } = GetGoalPlanMentee(currentUser.id);
  const [openAddGoal, setopenAddGoal] = React.useState(false);
  const [showTasks, setShowtasks] = useState([0]);
  const selectedGoalActivity = useReactiveVar(selectedGoalActivityVar);
  const selectedGoal = useReactiveVar(selectedGoalVar);

  const openAddGoalModal = () => {
    setopenAddGoal(true);
    trackEvent('click', {
      screen: screenNames.Goals,
      Action: learnerAction.ADD_GOAL,
    });
  };

  document.title = 'MentorHub - Goals';

  useEffect(() => {
    trackEvent('view', {
      screen: screenNames.Goals,
    });
  }, []);

  return (
    <React.Fragment>
      <div
        className={
          open
            ? 'menu-open guides-detail-wrap goals-detail-wrap'
            : 'guides-detail-wrap goals-detail-wrap'
        }
        tabIndex={0}
        id="Goals-main">
        {data && data.length > 0 && !loading && (
          <h2>
            <Button
              style={{ float: 'right' }}
              onClick={() => {
                openAddGoalModal();
              }}
              aria-label="press enter to add goal"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  openAddGoalModal();
                }
              }}
              disableRipple
              className="custom-button"
              variant="contained"
              color="primary">
              <span style={{ color: '#fff' }}>Add Goal</span>
            </Button>
          </h2>
        )}
        {loading && <ContentLoaderCard />}
        <AddGoal
          currentUser={currentUser}
          setopenAddGoal={setopenAddGoal}
          openAddGoal={openAddGoal}
        />
        <Grid container spacing={2}>
          {data && data.length > 0 && !loading && (
            <Grid item xs={6}>
              <div className="lesson-list">
                {data.map((badge, badgeIndex) => (
                  <GoalsBadges
                    {...badge}
                    curriculumId={data._id}
                    key={badge._id}
                    badgeIndex={badgeIndex}
                    showTasks={showTasks}
                    setShowtasks={setShowtasks}
                    refetch={refetch}
                    menteeId={currentUser.id}
                  />
                ))}
              </div>
            </Grid>
          )}
          <Grid item xs={6}>
            <div className="task-detail-right">
              {!loading && selectedGoalActivity !== null && selectedGoalActivity._id ? (
                <GoalsActivityDetails {...selectedGoalActivity} {...props} />
              ) : (
                selectedGoal != null && <GoalDetail {...selectedGoal} {...props} />
              )}
            </div>
          </Grid>
        </Grid>
        {data && data.length < 1 && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card
                style={{
                  minHeight: '300px',
                  padding: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid
                      style={{
                        paddingBottom: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <Typography
                        style={{
                          fontSize: '16px',
                          fontWeight: 800,
                          color: '#000000',
                          marginBottom: '20px',
                        }}>
                        No Goals Available
                      </Typography>
                      <Button
                        onClick={() => openAddGoalModal()}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            openAddGoalModal();
                          }
                        }}
                        variant="contained"
                        color="primary">
                        <span style={{ color: '#fff' }}>Add Goal</span>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        )}
      </div>
    </React.Fragment>
  );
};

export default GoalsCurriculum;
