// import React, { useEffect, useState } from 'react';
// import { takeLatest, call, put, fork } from 'redux-saga/effects';
import { useMutation } from '@apollo/client';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { generateSnackbarPayload } from '../../../../src/Helper';
import * as types from '../../../Store/actionTypes';
import { getUserDetails } from '../../../Store/reduxSelectors';
import { selectedActivityVar } from '../vars/reactiveVars';
import {
  ADD_ASSIGNED_ACTIVITY,
  GET_LESSON_PLAN_MENTEE,
  MARK_AS_READ,
  UPDATE_ASSIGNED_TASK,
} from './gql';

export const UpdateActivityHook = (propsMain) => {
  const currentUser = useSelector((state) => getUserDetails(state));
  const [updateAssignedTask] = useMutation(UPDATE_ASSIGNED_TASK, {
    context: { clientName: 'curriculum' },
  });
  const [addAssignedTask] = useMutation(ADD_ASSIGNED_ACTIVITY, {
    context: { clientName: 'curriculum' },
  });
  const [markAsRead] = useMutation(MARK_AS_READ, {
    context: { clientName: 'curriculum' },
  });

  const dispatch = useDispatch();

  const updateBackend = async (props, changedField, fromActivity, setLoadState) => {
    setLoadState && setLoadState(true);
    if (props._id) {
      try {
        const resp = await updateAssignedTask({
          variables: { taskId: props._id, ...changedField },
          update(cache, mutationResult) {
            const cacheObj = cache.readQuery({
              query: GET_LESSON_PLAN_MENTEE,
              variables: {
                curriculumId: props.curriculumId,
                menteeId: props.menteeId,
              },
            });
            const obj = _.cloneDeep(cacheObj);
            obj.listLessonPlanMentee.milestone[props.badgeIndex].tasks[props.activityIndex] = {
              ...props,
              ...changedField,
            };

            const status = obj.listLessonPlanMentee.milestone[props.badgeIndex].tasks.every(
              (task) => task.completed,
            );

            if (status) obj.listLessonPlanMentee.milestone[props.badgeIndex].isBadgeGranted = true;

            cache.writeQuery({
              query: GET_LESSON_PLAN_MENTEE,
              variables: {
                curriculumId: props.curriculumId,
                menteeId: props.menteeId,
              },
              data: obj,
            });
          },
          // refetchQueries: ["listLessonPlanMentee"],
        });
        if (resp.error) throw resp.error;
        else {
          !fromActivity &&
            selectedActivityVar({
              ...props,
              ...changedField,
            });
          // dispatch({
          //   type: types.TOGGLE_SNACKBAR,
          //   payload: generateSnackbarPayload('success', 'Task has been updated successfully'),
          // });
        }
      } catch (err) {
        selectedActivityVar({
          ...props,
        });
        console.error(err);
        dispatch({
          type: types.TOGGLE_SNACKBAR,
          payload: generateSnackbarPayload('error', err.message),
        });
      }
    } else {
      try {
        const resp = await addAssignedTask({
          variables: {
            title: changedField['title'],
            description: '',
            milestoneId: props.badgeId,
            dueDate: '',
            assignedTo: currentUser.id,
          },
          refetchQueries: ['listLessonPlanMentee'],
        });
        if (resp.error) throw resp.error;
        else {
          !fromActivity &&
            selectedActivityVar({
              ...props,
              ...changedField,
              dueDate: null,
              _id: resp.data.addAssignedActivity._id,
              assignedToData: { ...currentUser },
              createdBy: currentUser.id,
            });
          dispatch({
            type: types.TOGGLE_SNACKBAR,
            payload: generateSnackbarPayload('success', 'Activity has been added successfully'),
          });
          props.refetch();
        }
      } catch (err) {
        console.error(err);
      }
    }
    setLoadState && setLoadState(false);
  };

  const updateReadStatus = async (props) => {
    await markAsRead({
      variables: {
        taskId: props._id,
      },
      update(cache, mutationResult) {
        const cacheObj = cache.readQuery({
          query: GET_LESSON_PLAN_MENTEE,
          variables: {
            curriculumId: props.curriculumId,
            menteeId: props.menteeId,
          },
        });
        const obj = _.cloneDeep(cacheObj);
        obj.listLessonPlanMentee.milestone[props.badgeIndex].tasks[
          props.activityIndex
        ].unreadCommentsCount = 0;

        cache.writeQuery({
          query: GET_LESSON_PLAN_MENTEE,
          variables: {
            curriculumId: props.curriculumId,
            menteeId: props.menteeId,
          },
          data: obj,
        });
      },
    });
  };

  return { updateBackend, updateReadStatus };
};
