import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSnackbar } from '../../Store/Actions/Global';
const CustomizedSnackbars = () => {
  const dispatch = useDispatch();
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    dispatch(toggleSnackbar());
  };

  const snackbarProps = useSelector((state) => state.global.snackbarProps);
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={3000}
      open={snackbarProps.open}
      onClose={handleClose}>
      <Alert onClose={handleClose} severity={snackbarProps.variant} elevation={6} variant="filled">
        {snackbarProps.message}
      </Alert>
    </Snackbar>
  );
};

export default CustomizedSnackbars;
