import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AskForAssessment from '../Components/Assessment/AskForAssessment';
import useGetMultipleAssessments from '../Components/Assessment/AskForAssessment/hooks/useGetMultipleAssessment';
import WelcomeAssessment from '../Components/Assessment/WelcomeAssessment';
import AppTour from '../Components/Common/AppTour';
import NotAuthorized from '../Components/Common/NotAuthorized';
import PageLoader from '../Components/Common/PageLoader';
import SetGoalsWrapper from '../Components/Common/SetGoalsWrapper';
import TakeMoodRingWrapper from '../Components/Common/TakeMoodRingWrapper';
import StreakModal from '../Components/Common/streakModal';
import { FtueSteps } from '../Components/Ftue/FtueSteps';
import MenteeAdvice from '../Components/Ftue/MenteeAdvice';
import { StartPriority } from '../Components/Ftue/StartPriority';
import Home from '../Components/Home';
import Meetings from '../Components/Meetings/JoinMeeting';
import ErrorBoundary from './../Routes/ErrorBoundary';
import getRoutes from './RoutePaths';
const PrivateRoute = ({ userDetails }) => {
  const userHasPermission = userDetails && userDetails.role.includes('mentee');
  const { data: assessmentIds, loading } = useGetMultipleAssessments();
  const [streakModalOpen, setStreakModalOpen] = React.useState(true);
  // const shouldTakeAssessment = userDetails && userDetails.takeAssessment;
  const shouldTakeAssessment = !loading && assessmentIds && assessmentIds.length > 0 ? true : false;
  const shouldTakeMoodRingAssessment = userDetails && userDetails.takeMoodRingAssessment;
  // userDetails && userDetails.takeMoodRingAssessment
  const shouldShowFtue = userDetails && userDetails.FtueShowMSS;
  const shouldShowPrioriry = userDetails && userDetails.ftueSetGoals;
  const [appTourOpen, setAppTourOpen] = React.useState(
    userDetails && userDetails.firstLoggedInDate === '' ? true : false,
  );

  return userHasPermission ? (
    shouldShowFtue === true ? (
      <MandatoryFtue />
    ) : shouldShowPrioriry === true ? (
      <StartGoalRoute />
    ) : loading ? (
      <PageLoader />
    ) : shouldTakeAssessment === true ? (
      <MandatoryAssessmentRoute />
    ) : shouldTakeMoodRingAssessment ||
      (shouldTakeMoodRingAssessment === true && shouldShowFtue === false) ? (
      <React.Fragment>
        <MandatoryTakeMoodRing title="Mood Ring" />
      </React.Fragment>
    ) : appTourOpen ? (
      <React.Fragment>
        <AppTour setAppTourOpen={setAppTourOpen} userDetails={userDetails} />
      </React.Fragment>
    ) : (
      <React.Fragment>
        <ErrorBoundary state={'private'}>
          <Meetings />
          <Home />
          {streakModalOpen && (
            <StreakModal
              setStreakModalOpen={setStreakModalOpen}
              streak={userDetails && userDetails.streaks}
              title="Streak"
            />
          )}
        </ErrorBoundary>
      </React.Fragment>
    )
  ) : (
    <NotAuthorizedRoute />
  );
};

export default PrivateRoute;

const NotAuthorizedRoute = () => {
  return (
    <Switch>
      <Route exact path={getRoutes.home} component={NotAuthorized} />
      <Route
        render={() => (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        )}
      />
    </Switch>
  );
};

const MandatoryAssessmentRoute = () => {
  return (
    <ErrorBoundary state={'private'}>
      <Switch>
        <Route exact path={getRoutes.askForAssessment} component={AskForAssessment} />
        <Route exact path={getRoutes.takeAssesement} component={WelcomeAssessment} />
        {/* <Route exact path={getRoutes.takeMoodRing} component={TakeMoodRingWrapper} /> */}

        <Route
          render={() => (
            <Redirect
              to={{
                pathname: getRoutes.askForAssessment,
              }}
            />
          )}
        />
      </Switch>
    </ErrorBoundary>
  );
};

const MandatoryTakeMoodRing = () => {
  return (
    <ErrorBoundary state={'private'}>
      <Switch>
        <Route exact path={getRoutes.takeMoodRing} component={TakeMoodRingWrapper} />
        <Route
          render={() => (
            <Redirect
              to={{
                pathname: getRoutes.takeMoodRing,
              }}
            />
          )}
        />
      </Switch>
    </ErrorBoundary>
  );
};

const MandatoryFtue = () => {
  return (
    <ErrorBoundary state={'private'}>
      <Switch>
        <Route exact path={getRoutes.ftue} component={FtueSteps} />
        <Route
          render={() => (
            <Redirect
              to={{
                pathname: getRoutes.ftue,
              }}
            />
          )}
        />
      </Switch>
    </ErrorBoundary>
  );
};

const StartGoalRoute = () => {
  return (
    <ErrorBoundary state={'private'}>
      <Switch>
        <Route exact path={getRoutes.startPriority} component={StartPriority} />
        <Route exact path={getRoutes.ftueSetPriority} component={SetGoalsWrapper} />
        <Route exact path={getRoutes.ftueMenteeAdvice} component={MenteeAdvice} />

        {/* <Route
        exact
        path={getRoutes.setPriority}
        component={() => <MyGoals FirstTime="FirstTime" />}
      /> */}
        <Route
          render={() => (
            <Redirect
              to={{
                pathname: getRoutes.startPriority,
              }}
            />
          )}
        />
      </Switch>
    </ErrorBoundary>
  );
};
