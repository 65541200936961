import { Grid } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, withRouter } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';
import PrivateRoute from './PrivateRoute';
import PublicRoutes from './PublicRoutes';

import Cookies from 'js-cookie';
import PageLoader from '../Components/Common/PageLoader';
import { getUserDetails, getUserValidationStatus, isUserLoggedIn } from '../Store/reduxSelectors';

const Navigator = (props) => {
  const userDetails = useSelector((state) => getUserDetails(state));
  const isValidatingUser = useSelector((state) => getUserValidationStatus(state));
  const userLoggedIn = useSelector((state) => isUserLoggedIn(state));
  const role = Cookies.get('role');
  return (
    <React.Fragment>
      {isValidatingUser ? (
        <PageLoader />
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <Router>
              {userLoggedIn ? (
                <PrivateRoute userDetails={userDetails} />
              ) : role === 'no-access' ? (
                <PrivateRoute />
              ) : (
                <ErrorBoundary state={'public'}>
                  <PublicRoutes />
                </ErrorBoundary>
              )}
            </Router>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default withRouter(Navigator);
