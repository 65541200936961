/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMutation, useQuery } from '@apollo/client';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
} from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import PinIconInactive from '../../../Assets/Images/Pin-inactive.svg';
import PinIconActive from '../../../Assets/Images/Pin.svg';
import learnerAction from '../../../Helper/Analytics/learnerAction';
import screenNames from '../../../Helper/Analytics/screenNames';
import { trackEvent } from '../../../Helper/EventTracker';
import ContentLoaderCard from '../../Common/ContentLoader/ContentLoader.Card';
import { LIST_GUIDES, PIN_GUIDE, UNASSIGN_GUIDE } from '../services/gql';

const AssignedGuides = (props) => {
  const [deletePopup, setDeletePopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedGuide, setSelectedGuide] = useState('');
  const open = Boolean(anchorEl);
  const handleMenuClick = (event, guideId) => {
    setSelectedGuide(guideId);
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  const { data, loading } = useQuery(LIST_GUIDES, {
    variables: {
      menteeId: props.id,
    },
    fetchPolicy: 'network-only',
    context: { clientName: 'curriculum' },
  });

  const getValue = (completedActivities, totalActivities) => {
    if (totalActivities === 0) return 0;
    return (completedActivities / totalActivities) * 100;
  };

  const [pinGuide] = useMutation(PIN_GUIDE, {
    context: { clientName: 'curriculum' },
    refetchQueries: ['listGuides'],
  });

  const [unassignGuide] = useMutation(UNASSIGN_GUIDE, {
    context: { clientName: 'curriculum' },
    refetchQueries: ['listGuides'],
  });

  const handleClick = async (event, guideId, pinned, guideIndex) => {
    event.stopPropagation();
    await pinGuide({
      variables: {
        guideId,
        menteeId: props.id,
        pinned: !pinned,
      },
    });
  };

  function capitalizeFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handlePopup = (event) => {
    event.stopPropagation();
    setDeletePopup(true);

    setAnchorEl(null);
  };

  const handleUnassign = async () => {
    trackEvent('click', {
      Action: learnerAction.REMOVE_GUIDE_FROM_MY_GUIDES,
    });
    await unassignGuide({
      variables: {
        curriculumId: selectedGuide,
      },
    });
    setDeletePopup(false);
  };

  useEffect(() => {
    trackEvent('click', {
      screen: screenNames.AssignedGuides,
    });
  }, []);

  return (
    <div className="guides-list-wrap">
      <Grid container spacing={2}>
        {loading && <ContentLoaderCard />}

        {data &&
          data.listGuides.length > 0 &&
          data.listGuides.map((guide, index) => (
            <Grid key={guide._id} item xs={4}>
              <div
                className="each-guide"
                tabIndex="0"
                onClick={() => {
                  trackEvent('click', {
                    screen: screenNames.AssignedGuides,
                    Action: learnerAction.OPEN_GUIDE_DETAIL,
                  });
                  props.history.push(`/guides/${guide._id}`);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    props.history.push(`/guides/${guide._id}`);
                  }
                }}>
                <div className="guide-hd">
                  <h2>{capitalizeFirst(guide.title)}</h2>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    tabIndex={0}
                    onClick={(e) => handleMenuClick(e, guide._id)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleMenuClick(e, guide._id);
                      }
                    }}>
                    <MoreVertIcon />
                  </IconButton>
                </div>

                <p>{guide.description && capitalizeFirst(guide.description)}</p>
                {guide.milestones.length > 0 && (
                  <ul>
                    {guide.milestones.map((milestone, index) => {
                      if (index <= 3) {
                        return milestone.badgeImage && milestone.badgeImage.length > 0 ? (
                          <li key={milestone._id}>
                            <img src={milestone.badgeImage} className="bagdeIcon" alt="badge-new" />
                          </li>
                        ) : (
                          <li key={milestone._id}>
                            <img
                              src="checkmate/images/badge-new.svg"
                              className="bagdeIcon"
                              alt="badge-new"
                            />
                          </li>
                        );
                      }
                      return null;
                    })}
                    {guide.milestones.length > 4 && (
                      <li>
                        <span>+{guide.milestones.length - 4}</span>
                      </li>
                    )}
                  </ul>
                )}
                <LinearProgress
                  variant="determinate"
                  thickness={10}
                  value={getValue(guide.completedActivities, guide.totalActivities)}
                />
                <div className="guide-bottom">
                  <h3>
                    Badges Earned:{' '}
                    <b>
                      {guide.grantedBadges}/{guide.totalBadges}
                    </b>
                  </h3>
                  <h3>
                    Activities Completed:{' '}
                    <b>
                      {guide.completedActivities}/{guide.totalActivities}
                    </b>
                  </h3>
                  {guide.pinned ? (
                    <IconButton
                      tabIndex={0}
                      onClick={(e) => {
                        handleClick(e, guide._id, guide.pinned, index);
                        trackEvent('click', {
                          screen: screenNames.AssignedGuides,
                          Action: learnerAction.UNPIN_GUIDE,
                        });
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleClick(e, guide._id, guide.pinned, index);
                        }
                      }}>
                      <img src={PinIconActive} className="" alt="pinned" />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={(e) => {
                        handleClick(e, guide._id, guide.pinned, index);
                        trackEvent('click', {
                          screen: screenNames.AssignedGuides,
                          Action: learnerAction.PIN_GUIDE,
                        });
                      }}
                      tabIndex={0}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleClick(e, guide._id, guide.pinned, index);
                        }
                      }}>
                      <img src={PinIconInactive} className="" alt="un-pinned" />
                    </IconButton>
                  )}
                </div>
              </div>
            </Grid>
          ))}
      </Grid>
      {data && data.listGuides.length < 1 && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card
              sx={{
                minHeight: '300px',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid
                    sx={{
                      paddingBottom: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 800,
                        color: '#000000',
                        marginBottom: '20px',
                      }}>
                      No Guides Have Been Assigned
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      )}
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <MenuItem onClick={(event) => handlePopup(event)}>Remove from My Guides</MenuItem>
      </Menu>
      <Dialog
        open={deletePopup}
        onClose={() => {
          setDeletePopup(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Remove from My Guides'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this guide from My Guides?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDeletePopup(false);
              trackEvent('click', {
                Action: learnerAction.CANCEL_REMOVE_GUIDE_FROM_MY_GUIDES,
              });
            }}
            color="primary">
            Cancel
          </Button>
          <Button onClick={handleUnassign} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AssignedGuides;
