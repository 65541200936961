import { Button, CircularProgress } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { animated, interpolate, useSprings } from 'react-spring';
import getRoutes from '../../../Routes/RoutePaths';
// import { getAssessmentQuestions, postAssessmentQuestions } from '../../../Store/Actions/Programs';
import { useReactiveVar } from '@apollo/client';
import Mentorhublogo from '../../../Assets/Images/Mentorhublogo.svg';
import { getUserDetails } from '../../../Store/reduxSelectors';
import useGetMultipleAssessments, {
  useGetAssessmentDetail,
} from '../AskForAssessment/hooks/useGetMultipleAssessment';
import usePostAnswers from '../AskForAssessment/hooks/usePostAnswers';
import { assessmentCollection, step } from '../AskForAssessment/var';
import AssementQuestion from './AssesmentQuestion';

const WelcomeAssessment = () => {
  const dispatch = useDispatch();
  const multiple = useReactiveVar(assessmentCollection);
  const inputEl = React.useRef();
  const { id } = useParams();
  const orgLogo = localStorage.getItem('organizationLogo');
  // const step = useReactiveVar(step)
  const { clearCacheData } = useGetMultipleAssessments();
  const { data: assessmentData, loading } = useGetAssessmentDetail(id);
  const questionData =
    assessmentData && assessmentData.assessmentById && assessmentData.assessmentById.assessmentBank;

  const userDetails = useSelector((state) => getUserDetails(state));
  // const questions = useSelector((state) => getAssessmentQuestionsData(state));
  // const [assessmentQuestions, setAssessmentQuestions] = useState([]);
  // const assessmentQuestions = Questions.slice().sort((a, b) => a.order - b.order);
  // const assessmentQuestions = questions.reverse();setAnswers

  const [curerentIndex, setCurrentIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [submit, setSubmit] = useState(false);
  const activeProgram = userDetails.activeProgram;
  const history = useHistory();
  // const [hasReversed, setHasReversed] = useState(false);
  // useEffect(() => {
  //   dispatch(getAssessmentQuestions(activeProgram, () => {}));
  // }, [dispatch, userDetails, activeProgram]);

  // useEffect(() => {
  //   setCurrentIndex(questions.length - 1);
  //   let sortQuestions = [...questions.reverse()];

  //   setAssessmentQuestions(sortQuestions);
  // }, [questions]);
  const handleNextStep = () => {
    // Check if there are more steps available
    if (step() < multiple.length - 1) {
      step(step() + 1);
    }
  };
  const redirectToDashboard = () => {
    const areAllTrue = multiple.every((item) => item.status === true);
    if (areAllTrue) {
      clearCacheData();
      history.push({
        pathname: userDetails.takeMoodRingAssessment ? getRoutes.takeMoodRing : getRoutes.home,
      });
    } else {
      history.push(getRoutes.askForAssessment);
    }
  };

  const { postAnswer } = usePostAnswers(redirectToDashboard);
  const submitAnswers = (ans) => {
    postAnswer({
      variables: {
        assessmentId: id,
        answers: ans,
        startDate: moment().startOf('day').toISOString(),
        endDate: moment().endOf('day').toISOString(),
      },
    });
    const indexToUpdate = multiple.findIndex((item) => item.id === id);
    if (indexToUpdate !== -1) {
      const updatedCollection = [...assessmentCollection()];
      updatedCollection[indexToUpdate].status = true;
      assessmentCollection(updatedCollection);
    }
    handleNextStep();
  };

  // const prepareAnswers = () => {
  //   setSubmit(true);
  //   const questionAnswers = [];
  //   for (let question = 0; question < assessmentQuestions.length; question++) {
  //     questionAnswers.push({
  //       questionText: assessmentQuestions[question].questionText,
  //       order: assessmentQuestions[question].order,
  //       answer: answers[question],
  //     });
  //   }
  //   questionAnswers.reverse();
  //   const body = {
  //     menteeId: userDetails.id,
  //     answers: questionAnswers,
  //   };
  //   dispatch(
  //     postAssessmentQuestions(activeProgram, body, () => {
  //       dispatch(
  //         updateUserDetails({
  //           takeAssessment: false,
  //         }),
  //       );
  //       history.push({
  //         pathname: userDetails.takeMoodRingAssessment ? getRoutes.takeMoodRing : getRoutes.home,
  //       });
  //     }),
  //   );
  //   return questionAnswers;
  // };
  const to = (i) => ({ x: 0, y: i * 6, scale: 1, rot: -10 + Math.random() * 20, delay: i * 100 });
  const from = (i) => ({ x: 0, rot: 0, scale: 1.5, y: -1000 });
  // This is being used down there in the view, it interpolates rotation and scale into a css transform
  const trans = (r, s) => `perspective(1500px) rotateX(30deg) rotateY(0deg) rotateZ(0deg) scale(1)`;

  const [gone] = useState(() => new Set()); // The set flags all the cards that are flicked out
  // const [props, set] = useSprings(assessmentQuestions.length, (i) => ({ ...to(i), from: from(i) }));
  const [props, set] = useSprings(!loading && questionData?.questionnaires?.length, (i) => ({
    ...to(i),
    from: from(),
  }));
  const nextQuestion = (answer, index, qId) => {
    const tempAns = [...answers, { answerId: answer._id, questionnaireId: qId }];
    // ans[index] = answer;
    setAnswers(tempAns);
    const down = false;
    const velocity = 10;
    const xDelta = -100;
    const trigger = velocity > 0.2; // If you flick hard enough it should trigger the card to fly out
    const dir = 1; // Direction should either point left or right
    if (trigger) gone.add(index); // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out

    set((i) => {
      if (!down && index !== i) return; // We're only interested in changing spring-data for the current spring
      setCurrentIndex(index + 1);
      const isGone = gone.has(index);
      const x = isGone ? (200 + window.innerWidth) * dir : down ? xDelta : 0; // When a card is gone it flys out left or right, otherwise goes back to zero
      const rot = xDelta / 100 + (isGone ? dir * 10 * velocity : 0); // How much the card tilts, flicking it harder makes it rotate faster
      const scale = down ? 1.1 : 1; // Active cards lift up a bit
      return {
        x,
        rot,
        scale,
        delay: undefined,
        config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 },
      };
    });

    if (!down && gone.size === questionData?.questionnaires?.length) {
      submitAnswers(tempAns);
      return null;
    }
  };

  set((i) => {
    // bring the active card to straight
    if (curerentIndex === i)
      return { x: 0, rot: 0, scale: 1.1, delay: undefined, config: { friction: 50, tension: 500 } };
  });
  const currentQuestionPage = !loading && questionData?.questionnaires?.length - curerentIndex;

  return (
    <React.Fragment>
      <div className="ftueHeader" style={{ padding: orgLogo && '12px 0px 15px 20px' }}>
        <img src={orgLogo ? orgLogo : Mentorhublogo} style={{ height: orgLogo && '49px' }} />
        <span style={{ color: 'white', marginRight: '20px', fontSize: '20px' }}>
          Welcome, {userDetails.firstName}
        </span>
      </div>
      {loading ? (
        <></>
      ) : assessmentData ? (
        <div className="assessmentWrap">
          {/* {questionData?.questionnaires?.length > 0 && (
            <div className="assessmentWrapLabel" ref={inputEl}>
              {curerentIndex + 1 <= questionData?.questionnaires?.length && (
                <span role="alert">
                  Assessment Question{' '}
                  {currentQuestionPage <= questionData?.questionnaires?.length
                    ? curerentIndex + 1
                    : questionData?.questionnaires?.length}{' '}
                  out of {questionData?.questionnaires?.length}{' '}
                </span>
              )}
            </div>
          )} */}
          {submit && <CircularProgress />}
          {props.map(({ x, y, rot, scale }, i) => (
            <animated.div
              className="eachAssessment"
              key={i}
              style={{
                zIndex: props.length - i,
                transform: interpolate([x, y], (x, y) => `translate3d(${x}px,${y}px,0)`),
              }}>
              <AssementQuestion
                currPage={curerentIndex}
                currentIndex={curerentIndex + 1}
                currentQuestionPage={currentQuestionPage}
                qIndex={i}
                key={i}
                manager={nextQuestion}
                assessment={questionData?.questionnaires[i]}
                questionLength={questionData?.questionnaires?.length}
                style={{ transform: interpolate([rot, scale], trans) }}
              />
            </animated.div>
          ))}
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 'calc( 100vh - 69px )',
          }}>
          <div style={{ width: '300px', textAlign: 'center' }}>
            <h4>Error, Please Try Later </h4>
            <Button
              aria-label="Take Assessment"
              style={{ marginTop: '20px' }}
              // onClick={redirectToDashboard}
              size="large"
              color="primary"
              variant="contained">
              Go to Dashboard
            </Button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default WelcomeAssessment;
