import {
  Avatar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import { startCase } from 'lodash';
import { useSelector } from 'react-redux';
import PinIcon from '../../../Assets/Images/ParticipantPin.svg';
import UnpinIcon from '../../../Assets/Images/ParticipantUnpin.svg';
import closeIcon from '../../../Assets/Images/x.svg';
import { getUserDetails } from '../../../Store/reduxSelectors';

const ParticipantsList = ({
  connectedParticipants,
  participants,
  setShowParticipants,
  setSelectedParticipant,
  selectedParticipant,
}) => {
  const user = useSelector((state) => getUserDetails(state));

  let participantObj = {};
  participants.forEach((participant) => {
    participantObj[participant._id] = { ...participant };
  });
  const connectedParticipantsDetails = connectedParticipants.map(
    (participant) => participantObj[participant.identity],
  );

  const handleClick = (userId) => {
    const selectedUser = connectedParticipants.find(
      (participant) => participant.identity === userId,
    );
    setSelectedParticipant(selectedUser);
  };

  return (
    <div className="participants_list">
      <div className="participants_list_header">
        <span>Participants</span>
        <IconButton onClick={() => setShowParticipants(false)} sx={{ padding: '0px' }}>
          <img src={closeIcon} alt="close" style={{ width: '17px' }} />
        </IconButton>
      </div>
      <Divider sx={{ marginTop: '15px' }} />
      <List>
        {connectedParticipantsDetails.map((participant) => (
          <>
            <ListItem sx={{ paddingLeft: '0px' }}>
              <ListItemAvatar sx={{ minWidth: '45px' }}>
                <Avatar src={participant.profileImage} sx={{ width: '30px', height: '30px' }}>
                  {participant.firstName[0].toUpperCase(0)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText disableTypography>
                <span style={{ fontSize: '14px' }}>
                  {`${startCase(participant.firstName)} ${startCase(participant.lastName)}`}{' '}
                  {user.id === participant._id && ' (You)'}
                </span>
              </ListItemText>
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleClick(participant._id)}>
                  <img
                    src={
                      selectedParticipant && selectedParticipant.identity === participant._id
                        ? PinIcon
                        : UnpinIcon
                    }
                    alt="Pin"
                  />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider light={true} />
          </>
        ))}
      </List>
    </div>
  );
};

export default ParticipantsList;
