import Grid from '@mui/material/Grid';
import React from 'react';

import ContentLoaderCard from '../Common/ContentLoader/ContentLoader.Card';
// import ErrorBoundary from '../Common/ErrorBoundary';
// import ErrorBoundary from './../../Routes/ErrorBoundary';
const Chat = React.lazy(() => import('../Chat'));

const RightWing = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <React.Suspense
          fallback={
            <div>
              <ContentLoaderCard
                height={window.innerHeight}
                width="100%"
                rootStyles={{ borderRadius: 0 }}
              />
            </div>
          }>
          <Chat />
        </React.Suspense>
      </Grid>
    </Grid>
  );
};

export default RightWing;
