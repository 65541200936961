import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';
import { lessonPlanMenteeVar } from '../vars/reactiveVars';
import { GET_LESSON_PLAN_MENTEE } from './gql';
export const GetLessonPlanMentee = (curriculumId, menteeId) => {
  const [getLessonPlanQuery, { loading, data, error, refetch }] = useLazyQuery(
    GET_LESSON_PLAN_MENTEE,
    {
      context: { clientName: 'curriculum' },
    },
  );

  useEffect(() => {
    getLessonPlanQuery({
      variables: {
        curriculumId: curriculumId,
        menteeId: menteeId,
      },
      fetchPolicy: 'network-only',
    });
  }, [curriculumId, getLessonPlanQuery, menteeId]);

  useEffect(() => {
    if (!error && data && data.listLessonPlanMentee) lessonPlanMenteeVar(data.listLessonPlanMentee);
    else lessonPlanMenteeVar(null);
    console.error(error);
  }, [loading, data, error]);
  return {
    loading,
    data: useReactiveVar(lessonPlanMenteeVar),
    refetch,
  };
};
