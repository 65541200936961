import { makeVar } from '@apollo/client';

export const showMainContent = makeVar(false);
export const showFocus = makeVar({});
export const showOnMouseClick = makeVar(false);

export const cropperModalVar = makeVar({
  showModal: false,
  imgURL: '',
  croppedImage: '',
  croppedFile: '',
});
