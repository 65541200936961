import { useQuery } from '@apollo/client';
// import DateFnsUtils from '@date-io/date-fns';
// import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import {
  Avatar,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import arrowDown from '../../../Assets/Images/arrow-down-box-icon.svg';
import { generateSnackbarPayload } from '../../../Helper';
import { TOGGLE_SNACKBAR } from '../../../Store/actionTypes';
import { GET_ASSIGNED_TO, LIST_GOALS } from '../services/gql';
import { handleChange, handleCompletedChange, handleDueDateChange } from '../services/operations';
import { UpdateActivityHook } from '../services/updateAssignedTask';
import EditDescription from './EditDescription';
import ListComment from './ListComment';
const ActivityDetail = (props) => {
  const dispatch = useDispatch();
  const { updateBackend } = UpdateActivityHook();
  const [showDescription, setShowDescription] = useState(false);
  const [messageView, setMessageView] = useState(false);
  const [commentsCount, setCommentsCount] = useState(null);
  const [open, setOpen] = useState(false);

  const { data } = useQuery(GET_ASSIGNED_TO, {
    variables: {
      menteeId: props.menteeId,
    },
    context: { clientName: 'curriculum' },
    fetchPolicy: 'network-only',
  });

  const goals = useQuery(LIST_GOALS, {
    variables: {
      menteeId: props.menteeId,
    },
    context: { clientName: 'curriculum' },
    fetchPolicy: 'network-only',
  });

  const handleDateFormat = (dueDate) => {
    if (!dueDate) return 'Enter due date';
    return moment(dueDate).format('ddd, MMM DD, YYYY');
  };

  const handleDateChange = (event) => {
    console.log('calender');
    if (props.createdBy.toString() === props.menteeId.toString())
      updateBackend(props, handleDueDateChange(event, props, 'dueDate'));
    else {
      dispatch({
        type: TOGGLE_SNACKBAR,
        payload: generateSnackbarPayload('error', "You don't have edit access"),
      });
    }
  };
  const messageViewControl = () => {
    setMessageView(!messageView);
  };

  const getDateComponent = () => {
    let dateComponent;

    if (props.menteeId.toString() === props.createdBy.toString()) {
      dateComponent = (
        <Typography variant="subtitle2" component="h2">
          <CalendarTodayIcon />
          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              variant="dialog"
              value={props.dueDate ? new Date(Number(props.dueDate)) : null}
              autoOk={false}
              defaultValue={'Enter due date'}
              className="duedate"
              labelFunc={(dueDate) => handleDateFormat(dueDate)}
              onChange={handleDateChange}
            />
          </MuiPickersUtilsProvider> */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              fullWidth
              sx={{ cursor: 'pointer' }}
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              onClick={() => setOpen(true)}
              onAccept={(newValue) => {
                handleDateChange(newValue);
                setOpen(false);
              }}
              format="ddd, MMM DD, YYYY"
              value={props.dueDate ? dayjs(new Date(Number(props.dueDate))) : null}
              // onChange={(newValue) => {
              //   handleDateChange(newValue);
              //   setOpen(false);
              // }}
              slotProps={{
                textField: {
                  variant: 'standard',
                  sx: {
                    '& .MuiInputBase-root input': {
                      cursor: 'pointer',
                    },
                  },

                  placeholder: props.dueDate ? null : 'Enter due date',
                  InputProps: {
                    sx: {
                      color: 'black',
                      '& .MuiInputBase-input::placeholder': {
                        color: 'black', // Change this to the desired color
                      },
                    },
                  },
                },
              }}
            />
          </LocalizationProvider>
        </Typography>
      );
    } else {
      dateComponent = (
        <Typography variant="subtitle2" component="h2" onClick={handleDateChange}>
          <CalendarTodayIcon />
          <span>
            {props.dueDate
              ? moment(Number(props.dueDate)).format('ddd, MMM DD, YYYY')
              : 'Enter due date'}
          </span>
        </Typography>
      );
    }
    return dateComponent;
  };

  return (
    <div className="each-row">
      <div className="task-head">
        <Typography variant="subtitle1" component="h1">
          {props.createdBy.toString() === props.menteeId.toString() ? (
            <TextField
              value={props.title}
              variant="standard"
              sx={{ width: '100%' }}
              onKeyUp={(event) => {
                if (event.key === 'Enter')
                  updateBackend(props, handleChange(event, props, 'title'));
              }}
              onChange={(event) => handleChange(event, props, 'title')}
            />
          ) : (
            <span onClick={handleDateChange}>{props.title}</span>
          )}
          <IconButton
            sx={{ borderRadius: '5px' }}
            onClick={() =>
              updateBackend(props, handleCompletedChange(!props.completed, props, 'completed'))
            }>
            {props.completed ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />}
            Complete
          </IconButton>
        </Typography>
      </div>
      <div className="task-sub-head">
        <Typography variant="subtitle2" component="h2">
          <Avatar src={props.assignedToData && props.assignedToData.profileImage}>
            {props.assignedToData && props.assignedToData.firstName[0]}
          </Avatar>
          {props.assignedToData && props.assignedToData.firstName}
        </Typography>
        {getDateComponent()}
        <Typography variant="subtitle2" component="h2">
          <LibraryBooksIcon />
          {props.badgeTitle}
        </Typography>
      </div>
      <div className="task-sub-head">
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="goals">Select Goal</InputLabel>
          <Select
            label="Select Goal"
            variant="outlined"
            labelId="goals"
            value={props.goalId}
            defaultValue="Goals"
            onChange={(event) => {
              updateBackend(props, handleChange(event, props, 'goalId'));
            }}>
            {goals.data && goals.data.listGoal.length ? (
              goals.data.listGoal.map((goal) => (
                <MenuItem value={goal._id} key={goal._id}>
                  {goal.title}
                </MenuItem>
              ))
            ) : (
              <MenuItem>No Options</MenuItem>
            )}
          </Select>
        </FormControl>
      </div>
      <div className={messageView ? 'description' : 'description-full'}>
        <EditDescription
          {...props}
          showDescription={showDescription}
          setShowDescription={setShowDescription}
        />
      </div>
      <h4
        tab-index="0"
        aria-label="message view / message close"
        onClick={() => messageViewControl()}
        className={messageView ? 'messageViewBtn messageViewBtn-up' : 'messageViewBtn '}>
        <a style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {commentsCount} {`Comment${commentsCount === 1 ? '' : 's'}`} <img src={arrowDown} />
        </a>
      </h4>
      <div className={messageView ? 'entire-message' : 'entire-message hide'}>
        {props._id && <ListComment {...props} setCommentsCount={setCommentsCount} />}
      </div>

      {/*  <h4 tab-index="0"  aria-label="message view / message close" onClick={() => messageViewControl()}  className={messageView ? "messageViewBtn messageViewBtn-up" : "messageViewBtn "} ><a style={{display:"flex", alignItems :"center", justifyContent:"space-between"}}>{props.unreadCommentsCount} Comments <img src={arrowDown}/></a></h4>
        <div className={messageView ? "entire-message" : "entire-message hide"}>
          {props._id && <ListComment {...props} />}
        </div>*/}
    </div>
  );
};

export default ActivityDetail;
