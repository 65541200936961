import { gql } from '@apollo/client';

export const GET_OTP = gql`
  mutation Generate_otp($countryCode: String!, $phoneNumber: String!) {
    generate_otp(country_code: $countryCode, phone_number: $phoneNumber) {
      message
      status
    }
  }
`;

export const VERIFY_OTP = gql`
  mutation Verify_otp($object: VerifyOTPInput!) {
    verify_otp(object: $object) {
      firebase_token
      refresh_token
      token
    }
  }
`;

export const USER_CURRENT = gql`
  query Current_user {
    current_user {
      _id
    }
  }
`;

export const LOGIN = gql`
  mutation Login($object: LoginInput!) {
    login(object: $object) {
      firebase_token
      refresh_token
      token
    }
  }
`;

export const VERIFY_CODE = gql`
  mutation VerifyOneTimeAcessCode($token: String) {
    verifyOneTimeAcessCode(token: $token) {
      firebase_token
      refresh_token
      token
    }
  }
`;

export const LOGOUT = gql`
  mutation Logout {
    logout {
      redirectLink
      status
    }
  }
`;
export const GENERATE_REFRESH_TOKEN = gql`
  mutation Generate_token($refreshToken: String!) {
    generate_token(refresh_token: $refreshToken) {
      token
    }
  }
`;
